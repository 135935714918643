<div class="follower-info-container">
    <div class="title">
        <span>Follower Info</span>
    </div>
    <ng-container *ngFor="let socialItem of socialList">
        <ng-container *ngIf="socialItem.data?.audience?.gendersPerAge?.length">
            <div class="d-flex align-items-center display-title">
                <img [src]="socialItem.icon" />
                {{socialItem.name}}
            </div>
            <div class="row">
                <div class="block info-left">
                    <div class="content fake-set">
                        <div class="sub-title">
                            Fake Followers
                            <span nz-tooltip [nzTooltipTitle]="tooltips.follower">
                                <i nz-icon nzType="info-circle" nzTheme="outline"></i>
                            </span>
                        </div>
                        <div class="real-number">
                            <span class="number"> {{ (1 - socialItem.data?.audience?.credibility) * 100 | number: '1.0-1' }}% </span>
                        </div>
                    </div>
                    <div class="content stats">
                        <div class="sub-title">
                            Gender Split
                            <span nz-tooltip [nzTooltipTitle]="tooltips.gender">
                                <i nz-icon nzType="info-circle" nzTheme="outline"></i>
                            </span>
                        </div>
                        <div class="stats-table">
                            <div class="stats-set" *ngFor="let item of socialItem.data?.audience?.genders">
                                <i
                                    nz-icon
                                    nzType="user"
                                    nzTheme="outline"
                                    style="font-size: 18px"
                                    [ngClass]="{femaleStyle: item.code === 'FEMALE', maleStyle: item.code === 'MALE'}"
                                ></i>
                                {{ item.code }}
                                <span class="stats-float">{{ item.weight * 100 | number: '1.0-0' }}%</span>
                                <div class="stats-graph">
                                    <nz-progress
                                        [nzPercent]="item.weight * 100"
                                        [nzShowInfo]="false"
                                        [nzStrokeLinecap]="'square'"
                                        [nzStrokeColor]="item.code === 'MALE' ? '#000' : ''"
                                    ></nz-progress>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="block info-middle">
                    <div class="content">
                        <div class="sub-title">
                            <span>Age & Gender Split</span>&nbsp;
                            <span nz-tooltip [nzTooltipTitle]="tooltips.ageGender">
                                <i nz-icon nzType="info-circle" nzTheme="outline"></i>
                            </span>
                        </div>
                        <div class="bar-chart">
                            <div appEcharts [initialOption]="socialItem.initialGenderOption" class="chart height-100p"></div>
                        </div>
                    </div>
                </div>
                <div class="block info-center">
                    <div class="content">
                        <div class="sub-title">
                            Location By Country
                            <span nz-tooltip [nzTooltipTitle]="tooltips.locationByCountry">
                                <i nz-icon nzType="info-circle" nzTheme="outline"></i>
                            </span>
                        </div>
                        <div class="country" *ngFor="let city of (socialItem.data?.audience?.geoCountries || '').slice(0, 3)">
                            <div class="country-info">
                                <span>{{ city.name }}</span>
                                <span>{{ city.weight * 100 | number: '1.0-0' }} %</span>
                            </div>
                            <div class="country-graph">
                                <nz-progress
                                    [nzPercent]="city.weight * 100"
                                    [nzShowInfo]="false"
                                    [nzStrokeLinecap]="'square'"
                                ></nz-progress>
                            </div>
                        </div>
                        <div class="sub-title cities-title">
                            Location By Top 3 Cities
                            <span nz-tooltip [nzTooltipTitle]="tooltips.locationByCity">
                                <i nz-icon nzType="info-circle" nzTheme="outline"></i>
                            </span>
                        </div>
                        <ol class="cities-ol">
                            <li class="cities-li" *ngFor="let country of (socialItem.data?.audience?.geoCities || '').slice(0, 3)">
                                {{ country.name }}
                            </li>
                        </ol>
                    </div>
                </div>
                <div class="block info-right">
                    <div class="content" [ngClass]="{ 'not-post': isSetHeight }">
                        <div class="sub-title">
                            Popular # And @
                            <span nz-tooltip [nzTooltipTitle]="tooltips.tags">
                                <i nz-icon nzType="info-circle" nzTheme="outline"></i>
                            </span>
                        </div>
                        <div class="small-tag" *ngFor="let tag of (socialItem.data?.hashtags || '').slice(0, 7)">#{{ tag.tag }}</div>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>
