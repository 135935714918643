<div
    [ngClass]="{'main-content': true, 'add-purchase-record': source === 'restock_request', 'relative':true}"
    [ngStyle]="{'padding': '20px 0', 'background-color': source === 'product_purchase'&&!batchIds?'#f0f2f5':''}"
    id="add-product-purchase-record"
>
    <div class="p-20" style="background-color: #fff">
        <section class="btn-operations">
            <span class="main-content-title">{{title}}</span>
        </section>

        <div *ngIf="source==='product_purchase'" class="mode-select">
            <nz-radio-group [(ngModel)]="importMode">
                <label nz-radio nzValue="auto">Auto Import</label>
                <label nz-radio nzValue="manual">Manual Input</label>
            </nz-radio-group>
        </div>

        <nz-divider></nz-divider>

        <div [hidden]="importMode === 'auto'">
            <app-manual-import-purchase-record
                #manualInputComp
                [source]="source"
                [shopList]="shopList"
                [productList]="productList"
                [restockRequestList]="restockRequestList"
                (restockRequestRefresh)="getRestockRequestList()"
                (saveRecord)="saveRecord()"
                (close)="cancelRecord()"
            ></app-manual-import-purchase-record>
        </div>

        <div [hidden]="importMode === 'manual'">
            <app-auto-import-purchase-record
                #autoImportComp
                [restockRequestList]="restockRequestList"
                (saveRecord)="saveRecord()"
            ></app-auto-import-purchase-record>
        </div>
    </div>
</div>
