<div class="login">
    <header class="width-100p">
        <img src="assets/images/project-logo.jpg" height="70" class="p-20" />
    </header>

    <div class="login-box">
        <div class="title">{{ 'SIGN_IN' | translate }}</div>
        <div class="input-box">
            <div>{{ 'EMAIL' | translate }}</div>
            <input nz-input nzSize="large" placeholder="{{ 'EMAIL' | translate }}" [(ngModel)]="email" autocomplete="off" />
        </div>
        <div class="input-box mt-20">
            <div>{{ 'PASSWORD' | translate }}</div>
            <input
                nz-input
                nzSize="large"
                placeholder="{{ 'ENTER_PASSWORD' | translate }}"
                type="password"
                [(ngModel)]="password"
                (keyup.enter)="signIn()"
            />
        </div>
        <br />
        <div class="button-row mt-20">
            <button
                nz-button
                nzType="primary"
                nzSize="large"
                class="large-round-button"
                [disabled]="!formValid"
                [nzLoading]="signing"
                (click)="signIn()"
            >
                {{ 'SIGN_IN' | translate }}
            </button>
        </div>

        <div class="button-row mt-20">
            <img src="assets/images/google_login.png" (click)="googleLogin()" height="60" class="cursor-pointer" />
        </div>
    </div>
</div>
