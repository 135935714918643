<div *ngIf="config.type === 'numberInput'" class="form-item" [class.show-close]="config.showCloseIcon">
    <div class="title">{{ config.title }} <span *ngIf="!config.optional" class="required">*</span></div>
    <div class="inline-box">
        <nz-input-number
            [nzPlaceHolder]="config.placeholder || config.title"
            [(ngModel)]="ngModelValue"
            (ngModelChange)="change($event)"
            [nzPrecision]="config.nzPrecision || 0"
            [disabled]="config.disabled"
            min="0"
            style="width: 100%"
        ></nz-input-number>
        <!-- <input
            nz-input
            type="number"
            
        /> -->

        <i
            *ngIf="config.showCloseIcon"
            nz-icon
            nzType="close-circle"
            nzTheme="outline"
            style="position: absolute; right: 0; bottom: 10px; cursor: pointer"
            (click)="removeInput()"
        ></i>
    </div>
</div>

<div *ngIf="config.type === 'stringInput'" class="form-item" [class.show-close]="config.showCloseIcon">
    <div class="title">{{ config.title }} <span *ngIf="!config.optional" class="required">*</span></div>
    <div class="inline-box">
        <input
            nz-input
            [placeholder]="config.placeholder || config.title"
            [(ngModel)]="ngModelValue"
            [disabled]="config.disabled"
            (ngModelChange)="change($event)"
        />

        <i
            *ngIf="config.showCloseIcon"
            nz-icon
            nzType="close-circle"
            nzTheme="outline"
            style="position: absolute; right: 0; bottom: 10px; cursor: pointer"
            (click)="removeInput()"
        ></i>
    </div>
</div>

<div *ngIf="config.type === 'select'" class="form-item" [class.show-close]="config.showCloseIcon">
    <div class="title">{{ config.title }} <span *ngIf="!config.optional" class="required">*</span></div>
    <div class="inline-box">
        <nz-select
            nzShowSearch
            nzAllowClear
            [nzPlaceHolder]="config.placeholder || config.title"
            [(ngModel)]="ngModelValue"
            [nzDisabled]="config.disabled"
            (ngModelChange)="change($event)"
        >
            <nz-option *ngFor="let option of config.options" [nzValue]="option.value" [nzLabel]="option.label"></nz-option
        ></nz-select>

        <i
            *ngIf="config.showCloseIcon"
            nz-icon
            nzType="close-circle"
            nzTheme="outline"
            style="position: absolute; right: 0; bottom: 10px; cursor: pointer"
            (click)="removeInput()"
        ></i>
    </div>
</div>

<div *ngIf="config.type === 'dateSelect'" class="form-item" [class.show-close]="config.showCloseIcon">
    <div class="title">{{ config.title }} <span *ngIf="!config.optional" class="required">*</span></div>
    <div class="inline-box">
        <nz-date-picker
            [(ngModel)]="ngModelValue"
            nzFormat="MM/dd/yyyy"
            nzPlaceHolder="MM/DD/YYYY"
            [nzDisabled]="config.disabled"
            (ngModelChange)="change($event)"
        ></nz-date-picker>
        <i
            *ngIf="config.showCloseIcon"
            nz-icon
            nzType="close-circle"
            nzTheme="outline"
            style="position: absolute; right: 0; bottom: 10px; cursor: pointer"
            (click)="removeInput()"
        ></i>
    </div>
</div>

<div *ngIf="config.type === 'autoComplete'" class="form-item" [class.show-close]="config.showCloseIcon">
    <div class="title">{{ config.title }} <span *ngIf="!config.optional" class="required">*</span></div>
    <div class="inline-box">
        <input
            nz-input
            type="text"
            [placeholder]="config.placeholder || config.title"
            [(ngModel)]="ngModelValue"
            [nzAutocomplete]="autoComplete"
            [disabled]="config.disabled"
            (ngModelChange)="change($event)"
        />

        <nz-autocomplete #autoComplete>
            <nz-auto-option *ngFor="let option of config.options" [nzValue]="option.value" [nzLabel]="option.label"> </nz-auto-option>

            <nz-auto-option nzDisabled nzCustomContent>
                <i nz-icon nzType="loading" nzTheme="outline" style="margin-right: 5px"></i>
                <span>Loading Data...</span>
            </nz-auto-option>
        </nz-autocomplete>

        <i
            *ngIf="config.showCloseIcon"
            nz-icon
            nzType="close-circle"
            nzTheme="outline"
            style="position: absolute; right: 0; bottom: 10px; cursor: pointer"
            (click)="removeInput()"
        ></i>
    </div>
</div>

<div *ngIf="config.type === 'textarea'" class="form-item" [class.show-close]="config.showCloseIcon">
    <div class="title">{{ config.title }} <span *ngIf="!config.optional" class="required">*</span></div>
    <div class="inline-box">
        <textarea
            nz-input
            [placeholder]="config.placeholder || config.title"
            [(ngModel)]="ngModelValue"
            [disabled]="config.disabled"
            (ngModelChange)="change($event)"
        ></textarea>

        <i
            *ngIf="config.showCloseIcon"
            nz-icon
            nzType="close-circle"
            nzTheme="outline"
            style="position: absolute; right: 0; bottom: 10px; cursor: pointer"
            (click)="removeInput()"
        ></i>
    </div>
</div>
