import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ExtendDatePipe } from '@shared/pipe/date.pipe';
import * as dayjs from 'dayjs';
import { UtilsService } from '@services/util.service';

@Component({
    selector: 'app-form-item',
    templateUrl: './form-item.component.html',
    styleUrls: ['./form-item.component.less'],
    providers: [ExtendDatePipe],
})
export class FormItemComponent implements OnInit {
    @Input() config: {
        type: 'numberInput' | 'stringInput' | 'autoComplete' | 'select' | 'dateSelect' | 'textarea';
        title: string;
        ngModelValue: {
            fieldName: string;
            obj: any;
        };
        showCloseIcon?: boolean;
        disabled?: boolean;
        placeholder?: string;
        options?: {
            value: string;
            label: string;
        }[];
        optional?: boolean;
        nzPrecision?: number;
    };

    @Output() valueChange = new EventEmitter<any>();
    @Output() inputClose = new EventEmitter();

    get ngModelValue() {
        const { fieldName, obj } = this.config.ngModelValue;
        return obj[fieldName];
    }

    set ngModelValue(val) {
        const { fieldName, obj } = this.config.ngModelValue;
        obj[fieldName] = val;
    }

    constructor(private util: UtilsService) {}

    ngOnInit(): void {}

    change(value) {
        this.valueChange.emit(value);
    }

    removeInput() {
        this.inputClose.emit();
    }
}
