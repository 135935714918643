import { OrderDispatchCacheService } from '@services/order-dispatch-cache.service';
import { OrderDispatchService } from '@services/order-dispatch.service';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import * as dayjs from 'dayjs';
import { cloneDeep, isNumber } from 'lodash';
import { CN_CARRIER_LIST, DESTINATION_CHINA_WAREHOUSE, PURCHASE_PLATFORM } from '@shared/const/order-dispatch.const';
import { BehaviorSubject } from 'rxjs';
import { NotificationService } from '@services/notification.service';
import { getProductListByShopId, getShopListByFullSkuList, getSkuListByProductId } from '@shared/methods/order-dispatch.methods';

interface ProductPurchaseItem {
    shop_id?: number;
    product_id?: string;
    product_sku: string;
    restock_request_id: string;
    quantity: number;
    unit_cost: number;
    productList?: any[];
    matchedSkuOption?: any;
    dataInputType: 'search' | 'select';
    skuList?: any[];
    restock_request_list?: any[];
    chinese_declaration_name: string;
    english_declaration_name: string;
    qc_ratio: number;
    check_requirement: string;
    qc_requirement: string;
}

@Component({
    selector: 'app-manual-import-purchase-record',
    templateUrl: './manual-import-purchase-record.component.html',
    styleUrls: ['./manual-import-purchase-record.component.less'],
})
export class ManualImportPurchaseRecordComponent implements OnInit, OnChanges {
    @Input() source: string;
    @Input() productList: any[];
    @Input() shopList: any[];
    @Input() restockRequestList: any[];
    @Output() saveSuccess = new EventEmitter();
    @Output() saveRecord = new EventEmitter();
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() close = new EventEmitter();
    @Output() restockRequestRefresh = new EventEmitter();

    CN_CARRIER_LIST$ = new BehaviorSubject(CN_CARRIER_LIST);
    DESTINATION_CHINA_WAREHOUSE = DESTINATION_CHINA_WAREHOUSE;
    PURCHASE_PLATFORM = PURCHASE_PLATFORM;

    savingRecord = false;

    productPurchase: {
        product_list: ProductPurchaseItem[];
        destination_address: string;
        purchase_date: string;
        deliver_eta: string;
        purchase_platform: '1688' | '淘宝' | 'Other';
        purchase_order_id: string;
        shipping_cost: number;
        carrier: string;
        tracking_number: string;
        order_content: string;
        chinese_declaration_name: string;
        english_declaration_name: string;
        qc_ratio: number;
        check_requirement: string;
        qc_requirement: string;
        logistics: { carrier: string; tracking_number: string }[];
    } = {
        destination_address: null,
        purchase_date: dayjs().format('MM/DD/YYYY'),
        deliver_eta: null,
        purchase_platform: null,
        purchase_order_id: null,
        shipping_cost: 0,
        carrier: null,
        tracking_number: null,
        order_content: null,
        chinese_declaration_name: null,
        english_declaration_name: null,
        qc_ratio: 0,
        check_requirement: null,
        qc_requirement: null,
        logistics: [{ carrier: null, tracking_number: null }],
        product_list: [
            {
                shop_id: null,
                product_id: null,
                product_sku: null,
                quantity: 0,
                unit_cost: 0,
                restock_request_id: null,
                dataInputType: 'search',
                chinese_declaration_name: null,
                english_declaration_name: null,
                qc_ratio: 0,
                check_requirement: null,
                qc_requirement: null,
            },
        ],
    };

    constructor(
        private orderDispatchService: OrderDispatchService,
        private notificationService: NotificationService,
        private orderDispatchCacheService: OrderDispatchCacheService
    ) {}

    formatterPercent = (value: number) => `${value} %`;

    get fullSkuList() {
        return this.orderDispatchCacheService.allSku;
    }
    set fullSkuList(value) {
        this.orderDispatchCacheService.allSku = value;
    }

    get saveBtnDisabled() {
        return (
            !this.productPurchase.destination_address ||
            !this.productPurchase.purchase_date ||
            !this.productPurchase.order_content ||
            !this.productPurchase.purchase_order_id ||
            !this.productPurchase.purchase_platform ||
            this.productPurchase.product_list.some(item => {
                if (item.dataInputType === 'select') {
                    return (
                        !item.product_sku ||
                        !item.quantity ||
                        !item.shop_id ||
                        !item.product_id ||
                        !item.unit_cost ||
                        !item.chinese_declaration_name ||
                        !item.english_declaration_name ||
                        !isNumber(item.qc_ratio)
                    );
                } else {
                    return (
                        !item.product_sku ||
                        !item.quantity ||
                        !item.unit_cost ||
                        !item.chinese_declaration_name ||
                        !item.english_declaration_name ||
                        !isNumber(item.qc_ratio)
                    );
                }
            })
        );
    }

    ngOnChanges(e: SimpleChanges) {
        if (e.productList?.currentValue?.length) {
            this.productPurchase.product_list.length = 0;
            e.productList.currentValue.forEach((ele, ind) => {
                this.productPurchase.product_list.push({
                    shop_id: null,
                    product_id: null,
                    product_sku: ele.product_sku,
                    quantity: ele.requested_quantity,
                    unit_cost: 0,
                    restock_request_id: ele.id,
                    dataInputType: 'search',
                    chinese_declaration_name: null,
                    english_declaration_name: null,
                    qc_ratio: 0,
                    check_requirement: null,
                    qc_requirement: null,
                });
                this.skuChange(ele.product_sku, this.productPurchase.product_list[ind], ele.id);
            });
        }
    }

    ngOnInit(): void {}

    removeProductPurchaseItem(index: number) {
        if (this.productPurchase.product_list.length <= 1) {
            return;
        }

        this.productPurchase.product_list.splice(index, 1);
    }

    addProductPurchaseItem(isCopyLast: boolean) {
        if (isCopyLast && this.productPurchase.product_list.length > 0) {
            const lastItem = this.productPurchase.product_list[this.productPurchase.product_list.length - 1];
            const newItemToBeAdd = cloneDeep(lastItem);
            this.productPurchase.product_list.push(newItemToBeAdd);
            return;
        }

        this.productPurchase.product_list.push({
            shop_id: null,
            product_id: null,
            product_sku: null,
            quantity: 0,
            unit_cost: 0,
            restock_request_id: null,
            dataInputType: 'search',
            chinese_declaration_name: null,
            english_declaration_name: null,
            qc_ratio: 0,
            check_requirement: null,
            qc_requirement: null,
        });
    }

    // When select product
    changeProduct(product_id, listItem: ProductPurchaseItem) {
        listItem.skuList = getSkuListByProductId(product_id, this.fullSkuList);
        listItem.matchedSkuOption = null;
        listItem.product_sku = null;
    }
    // When input sku
    skuChange(value: string, listItem: ProductPurchaseItem, restock_request_id?: string): void {
        if (!value) return;

        listItem.matchedSkuOption = this.fullSkuList.find(item => item.product_sku === value);
        if (listItem.matchedSkuOption) {
            listItem.shop_id = listItem.matchedSkuOption.shop_id;
            listItem.productList = getProductListByShopId(listItem.shop_id, this.fullSkuList);
            listItem.product_id = listItem.matchedSkuOption.product_id;
            listItem.chinese_declaration_name = listItem.matchedSkuOption.chinese_declaration_name;
            listItem.english_declaration_name = listItem.matchedSkuOption.english_declaration_name;
            listItem.qc_ratio = listItem.matchedSkuOption.qc_ratio;
            listItem.check_requirement = listItem.matchedSkuOption.check_requirement;
            listItem.qc_requirement = listItem.matchedSkuOption.qc_requirement;
            listItem.skuList = getSkuListByProductId(listItem.product_id, this.fullSkuList);
            listItem.restock_request_list = this.restockRequestList
                .filter(item => item.product_sku === value)
                .filter(
                    item =>
                        item.fulfilled_quantity + item.in_progress_quantity < item.requested_quantity &&
                        item.fulfillment_status !== 'fulfilled' &&
                        item.fulfillment_status !== 'cancelled'
                ); // unfulfilled
        } else {
            listItem.shop_id = null;
            listItem.product_id = null;
            listItem.productList = [];
            listItem.skuList = [];
            listItem.restock_request_list = [];
        }

        listItem.restock_request_id = restock_request_id ? restock_request_id : null;
    }

    // When select shop
    changeShop(shop_id, listItem: ProductPurchaseItem) {
        listItem.productList = getProductListByShopId(shop_id, this.fullSkuList);
        listItem.product_id = null;
        listItem.product_sku = null;
        listItem.matchedSkuOption = null;
        listItem.skuList = [];
    }

    copyToRecord(data: any) {
        this.productPurchase.product_list = data;
        this.productPurchase.product_list.forEach(item => {
            this.skuChange(item.product_sku, item);
        });
    }

    saveRecordEmit() {
        this.saveRecord.emit();
    }

    carrierFilter(value: string) {
        this.CN_CARRIER_LIST$.next(CN_CARRIER_LIST.filter(option => option.toLowerCase().indexOf(value.toLowerCase()) !== -1));
    }

    cancelRecord() {
        this.close.emit();
    }
}
