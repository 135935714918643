/* eslint-disable no-underscore-dangle */
import { CampaignExtraInfo } from './../shared/type/campaign';
import { CampaignDetail, CommissionType } from '@shared/type/campaign';
import { Injectable } from '@angular/core';
import { InfluencerStatus } from '@shared/type/influencer';
import * as Excel from 'exceljs';
import * as fs from 'file-saver';
import { isArray } from 'lodash';
import { RequestService } from '@services/request.service';
import { environment } from '@src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class UtilsService {
    constructor(private requestService: RequestService) {}

    displayCommission(campaign: CampaignDetail) {
        const extra_info = campaign.extra_info as CampaignExtraInfo;
        // Handle legacy code
        const commission_dollar = campaign.commission_dollar !== undefined ? campaign.commission_dollar : campaign.commission_dollar;
        const commission_percent = campaign.commission_percent !== undefined ? campaign.commission_percent : campaign.commission_percent;
        if (extra_info && extra_info.commissionType) {
            const type: CommissionType = extra_info.commissionType;
            if (type === CommissionType.ONE_TIME_PAY) {
                return `$ ${commission_dollar}`;
            } else if (type === CommissionType.PER_SALES) {
                return `${commission_percent} %`;
            } else if (type === CommissionType.FIX_PAY_PLUS_PER_SALES) {
                return `$ ${commission_dollar} + ${commission_percent} %`;
            }
        }
        return `$ ${commission_dollar}`;
    }

    getInfluencerStatus(influencer) {
        if (influencer.inf_signing_status === 'Recommended') {
            return InfluencerStatus.RECOMMENDED;
        }
        if (influencer.inf_signing_status === 'Brand chosen') {
            if (influencer.inf_contacting_status === 'Email sent') {
                return InfluencerStatus.OFFER_SENT;
            }
            return InfluencerStatus.BRAND_CHOSEN;
        }
        if (influencer.inf_signing_status === 'Skip Offer') {
            return InfluencerStatus.SKIP_OFFER;
        }
        if (influencer.inf_signing_status === 'Influencer declined offer') {
            return InfluencerStatus.OFFER_DECLIEND;
        }
        if (influencer.inf_signing_status === 'Influencer signed up' || influencer.inf_signing_status === 'Influencer accepted offer') {
            return InfluencerStatus.OFFER_ACCEPTED;
        }

        if (
            influencer.inf_signing_status === 'pending contract signing' &&
            influencer.brand_signing_status === 'pending contract signing'
        ) {
            if (influencer.inf_contract_status === 'Email sent') {
                return InfluencerStatus.PENDING_SIGNING;
            }
            return InfluencerStatus.PENDING_CONTRACT_SEND;
        }
        if (influencer.inf_signing_status === 'contract signed' && influencer.brand_signing_status === 'contract signed') {
            return InfluencerStatus.CONTRACT_SIGNED;
        }
        if (influencer.inf_signing_status === 'pending contract signing') {
            return InfluencerStatus.PENDING_INFLUENCER_SIGNING;
        }
        if (influencer.brand_signing_status === 'pending contract signing') {
            return InfluencerStatus.PENDING_BRAND_SIGNING;
        }
    }

    // https://github.com/kennethjiang/js-file-download
    downloadFile(
        data: string | ArrayBuffer | ArrayBufferView | Uint8Array | Blob,

        filename: string,

        mime?: string,

        bom?: string
    ) {
        const blobData = typeof bom !== 'undefined' ? [bom, data] : [data];

        const blob = new Blob(blobData, { type: mime || 'application/octet-stream' });

        if (typeof window.navigator.msSaveBlob !== 'undefined') {
            // IE workaround for "HTML7007: One or more blob URLs were
            // revoked by closing the blob for which they were created.
            // These URLs will no longer resolve as the data backing
            // the URL has been freed."
            window.navigator.msSaveBlob(blob, filename);
        } else {
            const blobURL = window.URL.createObjectURL(blob);

            const tempLink = document.createElement('a');

            tempLink.style.display = 'none';

            tempLink.href = blobURL;

            tempLink.setAttribute('download', filename);

            // Safari thinks _blank anchor are pop ups. We only want to set _blank
            // target if the browser does not support the HTML5 download attribute.
            // This allows you to download files in desktop safari if pop up blocking
            // is enabled.
            if (typeof tempLink.download === 'undefined') {
                tempLink.setAttribute('target', '_blank');
            }

            document.body.appendChild(tempLink);
            tempLink.click();
            document.body.removeChild(tempLink);
            window.URL.revokeObjectURL(blobURL);
        }
    }

    // https://stackoverflow.com/questions/11257062/converting-json-object-to-csv-format-in-javascript
    convertToCSV<T>(arr: T[]) {
        if (arr.length === 0) {
            return '';
        }

        const temp = [Object.keys(arr[0]), ...arr];

        return temp.map(it => String(Object.values(it))).join('\n');
    }

    /**
     *
     * @param file File
     * @param limitations width:px height:px size:KB
     */
    checkIfImagePixelValid(file: File, limitations: { width: number; height: number; size: number }) {
        return new Promise<void>((resolve, reject) => {
            const _URL = window.URL || window.webkitURL;
            const img = new Image();
            img.onload = () => {
                const valid = img.width <= limitations.width && img.height <= limitations.height && file.size <= limitations.size * 1024;
                if (valid) {
                    resolve();
                } else {
                    reject();
                }
            };
            img.src = _URL.createObjectURL(file);
        });
    }

    exportToExcel(config: {
        header: { id: string; name: string; width?: number; style?: any; type?: 'image' | any; imgHeight?: number; imgWidth?: number }[];
        fileName?: string;
        sheets: {
            data: any[];
            sheetName: string;
            mergeCells?: number[][];
            cellHeight?: number;
            dataValidationBySelect?: {
                cell: string;
                row?: string | number;
                formulae: string;
            }[];
        }[];
    }) {
        return new Promise(async (resolve, reject) => {
            const workbook = new Excel.Workbook();
            for (const sheetItem of config.sheets) {
                const workSheet = workbook.addWorksheet(sheetItem.sheetName);
                workSheet.pageSetup.horizontalCentered = true;
                workSheet.pageSetup.verticalCentered = true;
                workSheet.properties.defaultColWidth = 120;
                workSheet.addRow([]);
                const style = { font: { name: 'Microsoft YaHei', size: 12, bold: true } };
                const newColumns = config.header.map(headerItem => {
                    const res = {
                        header: headerItem.name,
                        width: headerItem.width || 25,
                        style: headerItem.style || style,
                    };
                    return res;
                });
                workSheet.columns = [...newColumns];

                for (const [index, dataItem] of sheetItem.data.entries()) {
                    const rowData = [];
                    for (const [headerIndex, headerItem] of config.header.entries()) {
                        if (headerItem.type !== 'image') {
                            rowData[headerIndex] = dataItem[headerItem.id];
                        } else {
                            // Image
                            const base64 = await this.getUrlBase64(dataItem[headerItem.id], 'png');
                            if (base64) {
                                const imageId2 = workbook.addImage({
                                    base64,
                                    extension: 'png',
                                });

                                workSheet.addImage(imageId2, {
                                    tl: { col: headerIndex, row: index + 1 },
                                    ext: { width: headerItem.imgWidth || 60, height: headerItem.imgHeight || 60 },
                                });
                            }

                            rowData[headerIndex] = '';
                        }
                    }
                    const row = workSheet.addRow(rowData);
                    row.font = { name: 'Arial', size: 14 };
                    row.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
                    row.height = sheetItem.cellHeight || 38;
                }
                if (isArray(sheetItem.mergeCells)) {
                    sheetItem.mergeCells.forEach(item => {
                        workSheet.mergeCells(item[0], item[1], item[2], item[3]);
                    });
                }
                if (isArray(sheetItem.dataValidationBySelect)) {
                    sheetItem.dataValidationBySelect.forEach(item => {
                        if (item.row) {
                            workSheet.getCell(item.cell + item.row).dataValidation = {
                                type: 'list',
                                allowBlank: true,
                                formulae: [`"${item.formulae}"`],
                            };
                        } else {
                            sheetItem.data.forEach((ele, ind) => {
                                workSheet.getCell(item.cell + (ind + 2)).dataValidation = {
                                    type: 'list',
                                    allowBlank: true,
                                    formulae: [`"${item.formulae}"`],
                                };
                            });
                        }
                    });
                }
            }

            workbook.xlsx.writeBuffer().then(excelData => {
                const blob = new Blob([excelData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                fs.saveAs(blob, `${config.fileName || 'download'}.xlsx`);
            });

            resolve(true);
        });
    }

    getUrlBase64(url, ext): Promise<any> {
        return new Promise((resolve, reject) => {
            let canvas = document.createElement('canvas'); // 创建canvas DOM元素
            const ctx = canvas.getContext('2d');
            const img = new Image();
            img.crossOrigin = 'Anonymous';
            img.onload = () => {
                canvas.height = 60; // 指定画板的高度,自定义
                canvas.width = 60; // 指定画板的宽度，自定义
                ctx.drawImage(img, 0, 0, 60, 60); // 参数可自定义
                const dataURL = canvas.toDataURL(`image/${ext}`);
                resolve(dataURL); // 回掉函数获取Base64编码
                canvas = null;
            };
            img.onerror = () => resolve('');
            img.src = url;
        }).catch(error => {
            console.log(error);
        });
    }

    downloadFiles(data: { path: string; name?: string }[]) {
        return this.requestService.sendRequest(
            {
                url: '/am/dispatcher/download_pack',
                method: 'POST',
                data: {
                    file_list: data,
                },
            },
            environment.shopApiService
        );
    }
}
