<!-- Edit Campaign -->
<nz-modal
    [(nzVisible)]="editCampaign.visible"
    [nzTitle]="modalTitle"
    [nzFooter]="null"
    [nzContent]="modalContent"
    [nzWidth]="800"
    (nzOnCancel)="handleCancel()"
    nzClassName="edit-campaign-modal"
>
    <ng-template #modalTitle> <b>{{ 'Edit Campaign Information' | translate }}</b> </ng-template>

    <ng-template #modalContent>
        <section class="form">
            <div class="product-purchase-item">
                <div class="flex-box">
                    <div class="title">Campaign name <span class="required">*</span></div>
                    <input nz-input type="text" [(ngModel)]="campaignPlanList.campaignName" />
                </div>

                <div>
                    <div class="form-time-item picker-date">
                        <span class="title">Starts At (PST): <span class="required">*</span></span>
                        <span class="inline-box">
                            <nz-date-picker
                                [nzFormat]="dateFormat"
                                [nzDisabledDate]="disabledDate"
                                [(ngModel)]="startDatePicker"
                                nzShowToday="false"
                                (ngModelChange)="onChangeStartDate()"
                            ></nz-date-picker>
                            <span>at</span>
                            <nz-time-picker
                                [(ngModel)]="startTimePicker"
                                [nzMinuteStep]="30"
                                [nzUse12Hours]="true"
                                (ngModelChange)="onChangeStartTimes()"
                                nzFormat="h:mm a"
                            ></nz-time-picker>
                        </span>
                    </div>

                    <div class="form-time-item picker-date">
                        <span class="title">Ends At (PST): <span class="required">*</span></span>
                        <span class="inline-box">
                            <nz-date-picker
                                [nzFormat]="dateFormat"
                                [nzDisabledDate]="disabledEndDate"
                                [(ngModel)]="endDatePicker"
                                nzShowToday="false"
                                (ngModelChange)="onChangeEndDate()"
                            ></nz-date-picker>
                            <span>at</span>
                            <nz-time-picker
                                [(ngModel)]="endTimePicker"
                                [nzMinuteStep]="30"
                                [nzUse12Hours]="true"
                                (ngModelChange)="onChangeEndTimes()"
                                nzFormat="h:mm a"
                            ></nz-time-picker>
                        </span>
                    </div>
                </div>
                <div class="flex-box set-campaign">
                    <div class="form-inline-item">
                        <div class="title margin-type">Campaign Type <span class="required">*</span></div>
                        <div class="inline-box">
                            <nz-select [(ngModel)]="campaignPlanList.campaingnTypeValue" [nzPlaceHolder]="'Choose type'">
                                <nz-option
                                    *ngFor="let store of campaingnTypeList"
                                    [nzValue]="store.value"
                                    [nzLabel]="store.name"
                                ></nz-option>
                                <nz-option *ngIf="campaingnTypeList?.length <= 0" nzDisabled nzCustomContent>
                                    <i nz-icon nzType="loading" nzTheme="outline" style="margin-right: 5px"></i>
                                    <span>Loading Data...</span>
                                </nz-option>
                            </nz-select>
                        </div>
                    </div>
                    <div class="form-inline-item covers-check">
                        <div class="title">
                            <label nz-checkbox [(ngModel)]="campaignPlanList.coversCosts">Lifo covers costs </label>
                        </div>
                    </div>
                    <div class="form-inline-item">
                        <div class="title margin-type">Cost Type</div>
                        <div class="inline-box">
                            <nz-select [(ngModel)]="campaignPlanList.costTypeValue" [nzPlaceHolder]="'Choose type'">
                                <nz-option *ngFor="let store of costTypeList" [nzValue]="store.value" [nzLabel]="store.name"></nz-option>
                                <nz-option *ngIf="costTypeList?.length <= 0" nzDisabled nzCustomContent>
                                    <i nz-icon nzType="loading" nzTheme="outline" style="margin-right: 5px"></i>
                                    <span>Loading Data...</span>
                                </nz-option>
                            </nz-select>
                            <nz-input-group nzPrefix="$" *ngIf="campaignPlanList.costTypeValue === 'Other'">
                                <input type="text" nz-input [(ngModel)]="campaignPlanList.costAmount" />
                            </nz-input-group>
                        </div>
                    </div>
                </div>
                <div class="flex-box set-campaign">
                    <div class="form-inline-item">
                        <div class="title">Coupon code (if there is)</div>
                        <input nz-input type="text" [(ngModel)]="campaignPlanList.couponCode" />
                    </div>
                </div>
                <div class="flex-box set-campaign">
                    <div class="form-inline-item covers-check" style="margin-left: 0">
                        <div class="title">
                            <label nz-checkbox [(ngModel)]="launchCampaign">First Launch Campaign</label>
                        </div>
                    </div>
                    <div class="form-inline-item">
                        <div class="title margin-type">Fulfilled From <span class="required">*</span></div>
                        <div class="inline-box">
                            <nz-select [(ngModel)]="fulfilledFrom">
                                <nz-option
                                    *ngFor="let fulfilled of fulfilledFromList"
                                    [nzValue]="fulfilled.id"
                                    [nzLabel]="fulfilled.name"
                                ></nz-option>
                            </nz-select>
                        </div>
                    </div>
                </div>
                <div class="flex-box set-campaign">
                    <div class="form-inline-item">
                        <div class="title">Note</div>
                        <div class="inline-box">
                            <textarea rows="4" nz-input [(ngModel)]="campaignPlanList.noteValue" placeholder="Other note"></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="btns">
            <button
                nz-button
                nzType="primary"
                (click)="editCampaignHandleOk()"
                [nzLoading]="editCampaign.isConfirmLoading"
                [disabled]="addSkusDisabled"
            >
                Save
            </button>
            <button nz-button nzType="default" (click)="handleCancel()">Cancel</button>
        </div>
    </ng-template>
</nz-modal>
