import { Component, Input, ViewChild, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { RequestService } from '@services/request.service';
import { environment } from '@src/environments/environment';
import { COLUMNS } from './campaign-history.data';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NotificationService } from '@services/notification.service';
import { UtilsService } from '@services/util.service';
import { OrderDispatchService } from '@services/order-dispatch.service';
import { ExtendDatePipe } from '@shared/pipe/date.pipe';
import * as dayjs from 'dayjs';

interface CampaignItem {
    campaign_name: string;
    campaign_type: string;
    cost_amount: number;
    cost_type: string;
    coupon_code: string;
    created_at: string;
    created_by: string;
    ends_at: string;
    id: any;
    is_cost_covered: boolean;
    is_deleted: boolean;
    note: string;
    shop_id: number;
    sku_list: any[];
    starts_at: string;
    status: string;
    updated_at: string;
    updated_by: string;

    // Runtime variable
    total_needed?: number;
    submitting?: boolean;
    editing?: boolean;
    skuInfoGotten?: boolean;
    expand?: boolean;
}

@Component({
    selector: 'app-campaign-history',
    templateUrl: './campaign-history.component.html',
    styleUrls: ['./campaign-history.component.less'],
    providers: [ExtendDatePipe],
})
export class CampaignHistoryComponent implements OnInit, OnChanges {
    @Input() page: 'trackInventory' | 'storeManagement';
    @Input() shopId: string;
    @ViewChild('editCampaignModal') editCampaignModal;
    COLUMNS = COLUMNS;

    activeTab: 'upcoming' | 'ongoing' | 'done' = 'upcoming';
    dateRange = {
        start: null,
        end: null,
    };
    selectedCampaigns = new Set<CampaignItem>();
    allChecked = false;
    loading = false;
    campaignData = {};

    shownTableData: CampaignItem[];
    shopCampaignHistoryMap = new Map<
        string,
        {
            upcoming: CampaignItem[];
            ongoing: CampaignItem[];
            done: CampaignItem[];
        }
    >();
    skuInfoMap = new Map();

    constructor(
        private requestService: RequestService,
        private modal: NzModalService,
        private notificationService: NotificationService,
        private utilService: UtilsService,
        private orderDispatchService: OrderDispatchService,
        private datePipe: ExtendDatePipe
    ) {}

    ngOnChanges(e: SimpleChanges) {
        if (e.shopId?.currentValue != null) {
            this.getCampaignHistoryByShopId();
        }
    }

    ngOnInit(): void {}

    filterChange() {
        let matchedTabData = (this.shopCampaignHistoryMap.get(this.shopId) || {})[this.activeTab];

        const { start, end } = this.dateRange;

        if (start && end) {
            matchedTabData = matchedTabData.filter(item => {
                const startValid = dayjs(item.starts_at).isAfter(dayjs(start));
                const endValid = dayjs(item.starts_at).isBefore(dayjs(end));
                return startValid && endValid;
            });
        }

        this.shownTableData = [...(matchedTabData || [])];
    }

    deleteCampaign(campaignItem: CampaignItem) {
        this.modal.confirm({
            nzTitle: 'Delete the Campaign?',
            nzContent: `<p>${campaignItem.campaign_name}</p>
            <span>${this.datePipe.transform(campaignItem.starts_at, 'MM/dd/yyyy HH:mm', '-0700')}PST</span>
            <span class="pl-20 pr-20">To</span>
            <span>${this.datePipe.transform(campaignItem.ends_at, 'MM/dd/yyyy HH:mm', '-0700')}PST</span>
            `,
            nzOnOk: () => this.deleteCampaignRequest(campaignItem.id),
            nzWidth: '500px',
        });
    }

    editCampaign(campaignItem: CampaignItem) {
        this.editCampaignModal.data = campaignItem;
        this.editCampaignModal.showModals();
    }

    campaignInfo(data) {
        this.updateInfo(data)
            .then(() => {
                this.editCampaignModal.editCampaign.isConfirmLoading = false;
                this.editCampaignModal.editCampaign.visible = false;
                this.getCampaignHistoryByShopId();
            })
            .catch(() => this.notificationService.showGeneralErrorMessage())
            .finally(() => {
                this.editCampaignModal.editCampaign.isConfirmLoading = false;
                this.editCampaignModal.editCampaign.visible = false;
            });
    }

    checkAll(status: boolean) {
        if (status) {
            this.shownTableData.forEach(item => this.selectedCampaigns.add(item));
        } else {
            this.selectedCampaigns.clear();
        }
    }

    updateSingleCheck(campaignItem: any) {
        if (this.selectedCampaigns.has(campaignItem)) {
            this.selectedCampaigns.delete(campaignItem);
        } else {
            this.selectedCampaigns.add(campaignItem);
        }
        this.allChecked = this.selectedCampaigns.size === this.shownTableData.length;
    }

    updateNote(campaignItem: CampaignItem) {
        campaignItem.submitting = true;
        const data = {
            campaign_id: campaignItem.id,
            sku_list: campaignItem.sku_list,
        };
        this.updateInfo(data)
            .then(() => (campaignItem.editing = false))
            .catch(() => this.notificationService.showGeneralErrorMessage())
            .finally(() => (campaignItem.submitting = false));
    }

    updateQuantity(campaignItem: CampaignItem, skuItem) {
        skuItem.submitting = true;
        const data = {
            campaign_id: campaignItem.id,
            note: campaignItem.note,
            sku_list: campaignItem.sku_list,
        };
        this.updateInfo(data)
            .then(() => {
                skuItem.editing = false;
                campaignItem.total_needed = campaignItem.sku_list.map(item => item.quantity).reduce((prev, curr) => prev + curr);
            })
            .catch(() => this.notificationService.showGeneralErrorMessage())
            .finally(() => (skuItem.submitting = false));
    }

    toggleChildTableVisibility(campaignItem: CampaignItem) {
        campaignItem.expand = !campaignItem.expand;
        const tempSkuMap = new Map();

        const skuList = campaignItem.sku_list
            .map(item => {
                tempSkuMap.set(item.sku, item);
                return item.sku;
            })
            .filter(sku => !this.skuInfoMap.has(sku));

        if (skuList.length > 0) {
            campaignItem.skuInfoGotten = false;
            this.orderDispatchService
                .getSkuInfoBatch(skuList)
                .then((res: any[]) => {
                    res.forEach(item => {
                        const matchedSkuItem = tempSkuMap.get(item.sku);
                        matchedSkuItem.current_us_inventory = item.sf_total_inventory - item.sf_total_fulfilled;
                        matchedSkuItem.us_in_transit_china_total = item.po_arrived - item.sf_total_inventory;
                    });
                })
                .finally(() => (campaignItem.skuInfoGotten = true));
        } else {
            campaignItem.skuInfoGotten = true;
        }
    }

    export() {
        const header = [];
        this.utilService.exportToExcel({
            header,
            fileName: 'test',
            sheets: [],
        });
    }

    getCampaignHistoryByShopId() {
        const shopId = this.shopId;
        this.loading = true;

        return this.requestService
            .sendRequest(
                {
                    method: 'GET',
                    url: `/am/dispatcher/market_campaign?shop_id=${this.shopId}`,
                },
                environment.shopApiService
            )
            .then(res => {
                const data = {
                    upcoming: [],
                    ongoing: [],
                    done: [],
                };

                res.forEach(item => {
                    item.total_needed = (item.sku_list as any[]).map(skuItem => skuItem.quantity).reduce((prev, curr) => prev + curr);
                    if (dayjs(item.starts_at).isAfter(dayjs())) {
                        data.upcoming.push(item);
                    } else if (dayjs(item.ends_at).isBefore(dayjs())) {
                        data.done.push(item);
                    } else {
                        data.ongoing.push(item);
                    }
                });
                this.shopCampaignHistoryMap.set(shopId, data);

                this.filterChange();
                return res;
            })
            .finally(() => (this.loading = false));
    }

    private updateInfo(data) {
        return this.requestService.sendRequest(
            {
                method: 'PUT',
                url: '/am/dispatcher/market_campaign',
                data,
            },
            environment.shopApiService
        );
    }

    private deleteCampaignRequest(id: string) {
        return this.requestService
            .sendRequest({ method: 'DELETE', url: `/am/dispatcher/market_campaign?campaign_id=${id}` }, environment.shopApiService)
            .then(() => {
                const upcomingArr = this.shopCampaignHistoryMap.get(this.shopId)?.upcoming;
                const matchedIndex = upcomingArr?.findIndex(item => item.id === id);
                upcomingArr.splice(matchedIndex, 1);
                this.filterChange();
            })
            .catch(() => this.notificationService.showGeneralErrorMessage());
    }
}
