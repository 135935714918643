import { Injectable } from '@angular/core';
import { SkuListItem } from '@shared/type/order-dispatch.type';
import { CARRIER_LIST } from '@shared/const/order-dispatch.const';
import { RequestService } from './request.service';
import { cloneDeep, isDate, isObject } from 'lodash';
import * as dayjs from 'dayjs';
import * as Excel from 'exceljs';
import * as fs from 'file-saver';
import { environment } from '@src/environments/environment';
interface ProductLogisticsToBeSave {
    quantity: number;
}

@Injectable({
    providedIn: 'root',
})
export class WarehouseService {
    columnIdNameMap = new Map([['quantity', '本次发货数量']]);
    constructor(private requestService: RequestService) {}

    async parseLogisticsRecordData(
        file: File,
        necessaryData: {
            warehouseList: any[];
            skuList: any[];
        }
    ): Promise<{
        data: ProductLogisticsToBeSave[];
        errors: string[];
    }> {
        /**
         * File Column
         * quantity
         */
        const clonedNecessaryData = cloneDeep(necessaryData);
        const res: ProductLogisticsToBeSave[] = [];
        const workbook = new Excel.Workbook();
        const worksheet = await workbook.xlsx.load(await file.arrayBuffer());
        const getCellValue = (value: any) => {
            if (isObject(value)) {
                value = (value as any).richText[0]?.text;
            }
            return value;
        };
        worksheet.eachSheet(sheet => {
            sheet.eachRow((row, rowNumber) => {
                if (rowNumber !== 1) {
                    const [, , , , , , , , , , quantity] = (row.values as any[]).slice(1);
                    const itemToBeAppend: ProductLogisticsToBeSave = {
                        quantity,
                    };
                    res.push(itemToBeAppend);
                }
            });
        });

        const errors = this.validateData(res, necessaryData);

        return {
            data: res,
            errors,
        };
    }
    getProductPurchaseInbound(key = '') {
        return this.requestService.sendRequest<any>(
            {
                method: 'GET',
                url: `/am/dispatcher/product_purchase_inbound?name=${key}`,
            },
            environment.shopApiService
        );
    }

    getRestockRequest(id = null) {
        return this.requestService.sendRequest<any>(
            {
                method: 'GET',
                url: `/am/dispatcher/restock_request?shop_id=${id}`,
            },
            environment.shopApiService
        );
    }

    postProductPurchaseInbound(data) {
        return this.requestService.sendRequest<any>(
            {
                method: 'POST',
                url: '/am/dispatcher/product_purchase_inbound',
                data,
            },
            environment.shopApiService
        );
    }

    getShippingSchedule(name) {
        return this.requestService.sendRequest<any>(
            {
                method: 'GET',
                url: `/am/dispatcher/shipping_schedule?name=${name}`,
            },
            environment.shopApiService
        );
    }

    putScheduleFile(data) {
        return this.requestService.sendRequest<any>(
            {
                method: 'PUT',
                url: '/am/dispatcher/shipping_schedule',
                data,
            },
            environment.shopApiService
        );
    }

    getProductPurchaseInboundDetail(id) {
        return this.requestService.sendRequest<any>(
            {
                method: 'GET',
                url: `/am/dispatcher/product_purchase_inbound_detail?id=${id}`,
            },
            environment.shopApiService
        );
    }

    postProductPurchaseAdjustment(data) {
        return this.requestService.sendRequest<any>(
            {
                method: 'POST',
                url: '/am/dispatcher/product_purchase_adjustment',
                data,
            },
            environment.shopApiService
        );
    }

    private validateData(
        data: ProductLogisticsToBeSave[],
        necessaryData: {
            warehouseList: any[];
            skuList: SkuListItem[];
        }
    ): string[] {
        const errors = [];
        const { skuList } = necessaryData;
        data.forEach((item, i) => {
            const rowNumber = i + 2;
            // quantity
            if (item.quantity <= 0) {
                errors.push(`行${rowNumber}, ${this.columnIdNameMap.get('quantity')}, ${item.quantity}`);
            }
        });
        return errors;
    }
}
