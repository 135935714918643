import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

export interface MenuItem {
    title: string;
    hidden: boolean;
    url: string;
    urlPrefixWhenActive: string;
}

@Component({
    selector: 'app-module-header',
    templateUrl: './module-header.component.html',
    styleUrls: ['./module-header.component.less'],
})
export class ModuleHeaderComponent implements OnInit {
    @Input() menus: MenuItem[];
    @Input() moduleTitle: string;

    get activatedMenu() {
        const url = this.router.url;
        return this.menus?.find(menuItem => url.startsWith(menuItem.urlPrefixWhenActive));
    }

    constructor(private router: Router) {}

    ngOnInit(): void {}

    go(url: string) {
        this.router.navigateByUrl(url);
    }
}
