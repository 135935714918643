import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as dayjs from 'dayjs';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ModalControl } from '@shared/models/ModalControl';
import { OrderDispatchService } from '@services/order-dispatch.service';
import { NotificationService } from '@src/app/services/notification.service';
import { SAMPLE_COLUMNS_LIST, STORE_SAMPLE_COLUMNS_LIST } from './sample-purchase-request-table.data';
import { SampleRequestStatusEnum, SampleRequestStatusText, SamplePurchaseItem, AddressItem } from '@shared/type/order-dispatch.type';
import { TranslateService } from '@ngx-translate/core';

enum PageSourceEnum {
    SAMPLE = 'sample',
    STORE = 'store',
}

@Component({
    selector: 'app-sample-purchase-request-table',
    templateUrl: './sample-purchase-request-table.component.html',
    styleUrls: ['./sample-purchase-request-table.component.less'],
})
export class SamplePurchaseRequestTableComponent implements OnInit {
    @Input() pageSource = PageSourceEnum.SAMPLE;
    @Output() updateSampleSuccess = new EventEmitter();
    @Output() bulkAddRecords = new EventEmitter<Set<any>>();

    pageSourceEnum = PageSourceEnum;
    columnList = [];
    sampleRequestStatusText = SampleRequestStatusText;
    sampleRequestStatusEnum = SampleRequestStatusEnum;
    sampleStatusTab = [
        { name: 'SR_STATUS_UNPURCHASED', value: 'unfulfilled' },
        { name: 'SR_STATUS_FULFILLED', value: 'fulfilled' },
        { name: 'SR_STATUS_VOIDED', value: 'voided' },
    ];
    storeStatusTab = [
        { name: 'SR_STATUS_NOT_DELIVERED', value: 'unfulfilled' },
        { name: 'SR_STATUS_DELIVERED', value: 'fulfilled' },
        { name: 'SR_STATUS_ARCHIVED', value: 'archive' },
        { name: 'SR_STATUS_VOIDED', value: 'voided' },
    ];
    statusTab: 'unfulfilled' | 'fulfilled' | 'archive' | 'voided' = 'unfulfilled';

    allChecked = false;
    selectedPendingSamplePurchaseRequests = new Set<any>();

    fetchingSamplePurchase = false;
    samplePurchaseList = [];
    defaultSamplePurchaseList = [];
    samplePurchaseListInvalid = [];
    samplePurchaseListUnComplete = [];
    samplePurchaseListIsComplete = [];
    isUnConfirmLength: number;
    selectedSampleRequest: SamplePurchaseItem;
    defaultDataObj = {
        unfulfilled: [],
        fulfilled: [],
        archive: [],
        voided: [],
    };

    dateFormat = 'MM/dd/yyyy';
    dateTimes = [];

    instructionVisible = false;
    sampleRequestDetailsVisible = false;

    confirmModalControl = new ModalControl();
    confirmPurchaseParams = {
        id: null,
        purchase_complete: true,
        note: '',
    };

    invalidModalControl = new ModalControl();
    invalidSampleParams = {
        id: null,
        reason: '',
        note: '',
    };

    sampleRequestStatusButton = new Map([
        ['initiated', 'SR_ACTION_SEND_TO_VENDOR'],
        ['sent_to_vendor', 'SR_ACTION_CONFIRM_SENT'],
        ['confirm_sent', 'SR_ACTION_CONFIRM_PRODUCTION'],
        ['sample_in_progress', 'SR_ACTION_ARRIVED_DELIVER_TO_OFFICE'],
        ['deliver_to_office', 'SR_ACTION_SAMPLE_SHIPPED'],
    ]);

    purchaseRequirementModalVisible = false;
    purchaseConfirmParams = {
        id: null,
        batch_purchase_requirement: null,
        production_adjustment: null,
    };
    purchaseDetails = false;
    warehouseList = [];
    warehouseName = '';
    isPurchaseLoading = false;

    addressDetails: AddressItem;
    addressDetailsModalVisible = false;

    shopId: number;
    start: string;
    end: string;

    get invalidSamplePurchaseDisabled() {
        return !this.invalidSampleParams.reason || (this.invalidSampleParams.reason === '其他' && !this.invalidSampleParams.note);
    }

    constructor(
        private modal: NzModalService,
        private orderDispatchService: OrderDispatchService,
        private notificationService: NotificationService,
        private translateService: TranslateService
    ) {}

    ngOnInit(): void {
        this.start = dayjs().subtract(3, 'month').format('YYYY-MM-DD');
        this.end = dayjs().add(1, 'day').format('YYYY-MM-DD');
        this.columnList = this.pageSource === PageSourceEnum.SAMPLE ? SAMPLE_COLUMNS_LIST : STORE_SAMPLE_COLUMNS_LIST;
        this.columnList.forEach(column => {
            if (column.listOfFilter) {
                column.listOfFilter.forEach(item => {
                    item.text = this.translateService.instant(item.text);
                });
            }
        });

        this.defaultTableData();

        this.orderDispatchService.getWarehouseList().then(res => {
            this.warehouseList = this.orderDispatchService.warehouseList;
        });
    }

    defaultTableData() {
        this.fetchingSamplePurchase = true;
        this.orderDispatchService
            .getSampleRequestByDate(this.shopId, this.start, this.end)
            .then(res => {
                this.defaultSamplePurchaseList = this.getSamplePurchaseListByUrgency(res || [], ['high', '高']).concat(
                    this.getSamplePurchaseListByUrgency(res || [], ['medium', '中']),
                    this.getSamplePurchaseListByUrgency(res || [], ['low', '低'])
                );
                this.fetchingSamplePurchase = false;
                this.setDefaultData();
                this.selectStatus(this.statusTab);
            })
            .catch(() => {
                this.defaultSamplePurchaseList = [];
                this.setDefaultData();
                this.selectStatus(this.statusTab);
            });
    }

    setDefaultData() {
        this.defaultDataObj = {
            unfulfilled: [],
            fulfilled: [],
            archive: [],
            voided: [],
        };
        if (this.pageSource === PageSourceEnum.SAMPLE) {
            this.defaultSamplePurchaseList.forEach(item => {
                if (item.status === 'cancelled') {
                    this.defaultDataObj.voided.push(item);
                } else if (item.purchased_quantity >= item.request_quantity || item.purchase_complete) {
                    this.defaultDataObj.fulfilled.push(item);
                } else {
                    this.defaultDataObj.unfulfilled.push(item);
                }
            });
        } else {
            this.defaultSamplePurchaseList.forEach(item => {
                if (item.status === 'cancelled') {
                    this.defaultDataObj.voided.push(item);
                } else if (item.status === 'archived') {
                    this.defaultDataObj.archive.push(item);
                } else if (item.intl_arrived_quantity > 0) {
                    this.defaultDataObj.fulfilled.push(item);
                } else {
                    this.defaultDataObj.unfulfilled.push(item);
                }
            });
        }
    }

    getSamplePurchaseListByUrgency(data: any[], urgency: string[]) {
        return data
            .filter(item => urgency.includes(item.urgency))
            .sort((a, b) => new Date(a.required_date).getTime() - new Date(b.required_date).getTime());
    }

    bulkAddSamplePurchaseRecord() {
        this.bulkAddRecords.emit(this.selectedPendingSamplePurchaseRequests);
    }

    onChangeDateTimes() {
        this.start = this.dateTimes[0] ? dayjs(this.dateTimes[0]).format('YYYY-MM-DD') : dayjs().subtract(3, 'month').format('YYYY-MM-DD');
        this.end = dayjs(this.dateTimes[1]).add(1, 'day').format('YYYY-MM-DD');
        this.defaultTableData();
    }

    selectStatus(key) {
        this.statusTab = key;
        this.samplePurchaseList = [...this.defaultDataObj[key]];
        if (this.statusTab === 'unfulfilled') {
            this.isUnConfirmLength = this.samplePurchaseList.length;
        }
    }

    checkAll(status: boolean) {
        if (status) {
            this.samplePurchaseList.forEach(item => this.selectedPendingSamplePurchaseRequests.add(item));
        } else {
            this.selectedPendingSamplePurchaseRequests.clear();
        }
    }

    updateSingleCheck(purchaseItem: any) {
        if (this.selectedPendingSamplePurchaseRequests.has(purchaseItem)) {
            this.selectedPendingSamplePurchaseRequests.delete(purchaseItem);
        } else {
            this.selectedPendingSamplePurchaseRequests.add(purchaseItem);
        }
        this.allChecked = this.selectedPendingSamplePurchaseRequests.size === this.samplePurchaseList.length;
    }

    searchResultList(value) {
        this.samplePurchaseList = value;
    }

    resetResultList() {
        this.samplePurchaseList = [...this.defaultDataObj[this.statusTab]];
    }

    goProductDetail(data) {
        if (data.qc_result) {
            window.open(`/product-management/product-detail/${data.product_id}`, '_blank');
        }
    }

    showSampleRequestModal(data) {
        this.selectedSampleRequest = data;
        this.sampleRequestDetailsVisible = true;
    }

    viewConfirmNote(data) {
        this.modal.info({
            nzTitle: '备注',
            nzContent: data.note,
            nzOkText: '关闭',
        });
    }

    updateSampleStatus(data) {
        const sampleStatusPar = {
            initiated: 'sent_to_vendor',
            sent_to_vendor: 'confirm_sent',
            confirm_sent: 'sample_in_progress',
            sample_in_progress: 'deliver_to_office',
            deliver_to_office: 'product_shipped',
        };
        const params = {
            id: data.id,
            sample_status: sampleStatusPar[data.sample_status],
        };
        this.modal.confirm({
            nzTitle: '确定此操作吗？',
            nzCancelText: '取消',
            nzOkText: '确定',
            nzOnOk: () =>
                this.orderDispatchService
                    .confirmSellSampleRequest(params)
                    .then(() => {
                        const index = this.samplePurchaseList.findIndex(item => item.id === params.id);
                        this.samplePurchaseList[index] = { ...this.samplePurchaseList[index], ...params };
                        this.samplePurchaseList = this.samplePurchaseList.slice();
                        const ind = this.defaultSamplePurchaseList.findIndex(item => item.id === params.id);
                        this.defaultSamplePurchaseList[ind] = { ...this.defaultSamplePurchaseList[ind], ...params };
                        this.defaultSamplePurchaseList = this.defaultSamplePurchaseList.slice();
                        this.setDefaultData();
                    })
                    .catch(() => {
                        this.notificationService.showGeneralErrorMessage();
                    }),
        });
    }

    showPurchaseRequirement(e, data, title) {
        const { id, note_history } = data;
        let { batch_purchase_requirement } = data;

        let production_adjustment = '';
        if (note_history && note_history.length > 0) {
            note_history.forEach(note => {
                if (note.type === 'production_adjustment') {
                    production_adjustment = note.note;
                }
                if (note.type === 'batch_purchase_requirement') {
                    batch_purchase_requirement = note.note;
                }
            });
        }

        if (title === 'details') {
            this.purchaseDetails = true;
            this.purchaseConfirmParams = { id, batch_purchase_requirement, production_adjustment };
        } else {
            this.purchaseConfirmParams.id = id;
            this.purchaseDetails = false;
            this.purchaseConfirmParams.batch_purchase_requirement = null;
            this.purchaseConfirmParams.production_adjustment = null;
            this.warehouseName = '';
        }
        this.purchaseRequirementModalVisible = true;
        e.stopPropagation();
    }

    confirmSell(e, id, rowStatus) {
        const data = {
            id,
            status: rowStatus,
        };
        this.orderDispatchService.confirmSellSampleRequest(data).then(res => {
            this.defaultTableData();
        });
        e.stopPropagation();
    }

    confirmArchive(e, id) {
        const data = {
            id,
            status: 'archived',
        };
        this.modal.confirm({
            nzTitle: this.translateService.instant('SR_ARCHIVE_MODAL_TITLE'),
            // nzContent: this.translateService.instant('SR_ARCHIVE_MODAL_CONTENT'),
            nzOkText: this.translateService.instant('CONFIRM'),
            nzCancelText: this.translateService.instant('CANCEL'),
            nzOnOk: () =>
                this.orderDispatchService.confirmSellSampleRequest(data).then(res => {
                    this.defaultTableData();
                }),
        });
        e.stopPropagation();
    }

    showAddressDetails(e, address) {
        this.addressDetails = address;
        this.addressDetailsModalVisible = true;
        e.stopPropagation();
    }

    addPurchaseConfirm() {
        this.orderDispatchService.confirmSellSampleRequest({ ...this.purchaseConfirmParams, status: 'confirmed' }).then(res => {
            this.purchaseRequirementModalVisible = false;
            this.defaultTableData();
            this.notificationService.showGeneralSuccessMessage({ message: 'Add Purchase Requirement Success!' });
        });
    }

    updateSampleRequest(data) {
        const index = this.samplePurchaseList.findIndex(item => item.id === data.id);
        this.samplePurchaseList[index] = { ...this.samplePurchaseList[index], ...data };
        this.samplePurchaseList = this.samplePurchaseList.slice();
        const ind = this.defaultSamplePurchaseList.findIndex(item => item.id === data.id);
        this.defaultSamplePurchaseList[ind] = { ...this.defaultSamplePurchaseList[ind], ...data };
        this.defaultSamplePurchaseList = this.defaultSamplePurchaseList.slice();
        this.setDefaultData();
    }

    confirmPurchaseComplete() {
        this.confirmModalControl.showOkBtnLoading();
        this.orderDispatchService
            .confirmSellSampleRequest(this.confirmPurchaseParams)
            .then(res => {
                const ind = this.defaultSamplePurchaseList.findIndex(item => item.id === this.confirmPurchaseParams.id);
                this.defaultSamplePurchaseList[ind].purchase_complete = this.confirmPurchaseParams.purchase_complete;
                this.defaultSamplePurchaseList[ind].note = this.confirmPurchaseParams.note;
                this.setDefaultData();
                this.selectStatus('unfulfilled');
                this.confirmModalControl.hideOkBtnLoading();
                this.confirmModalControl.hideModal();
                this.notificationService.showGeneralSuccessMessage();
                this.confirmPurchaseParams.note = '';
                this.updateSampleSuccess.emit();
            })
            .catch(err => {
                this.confirmModalControl.hideOkBtnLoading();
                this.confirmModalControl.hideModal();
                this.notificationService.showGeneralErrorMessage();
            });
    }

    invalidSamplePurchase() {
        const { id, reason, note } = this.invalidSampleParams;
        this.invalidModalControl.showOkBtnLoading();
        this.orderDispatchService
            .confirmSellSampleRequest({ id, note: reason === '其他' ? note : reason, status: 'cancelled' })
            .then(res => {
                const ind = this.defaultSamplePurchaseList.findIndex(item => item.id === id);
                this.defaultSamplePurchaseList[ind].status = 'cancelled';
                this.defaultSamplePurchaseList[ind].note = reason === '其他' ? note : reason;
                this.setDefaultData();
                this.selectStatus(this.statusTab);
                this.invalidModalControl.hideOkBtnLoading();
                this.invalidModalControl.hideModal();
                this.notificationService.showGeneralSuccessMessage();
                this.invalidSampleParams.reason = '';
                this.invalidSampleParams.note = '';
            })
            .catch(err => {
                this.invalidModalControl.hideOkBtnLoading();
                this.notificationService.showGeneralErrorMessage();
            });
    }
}
