import { cloneDeep } from 'lodash';
import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';
import { DESTINATION_CHINA_WAREHOUSE, PURCHASE_PLATFORM, CN_CARRIER_LIST } from '@shared/const/order-dispatch.const';

@Component({
    selector: 'app-edit-product-purchase',
    templateUrl: './edit-product-purchase.component.html',
    styleUrls: ['./edit-product-purchase.component.less'],
})
export class EditProductPurchaseComponent implements OnChanges {
    @Input() selectedProductPurchase: any;

    productPurchaseInfo: any;

    destinationAddressOptions = DESTINATION_CHINA_WAREHOUSE.map(item => ({ value: item, label: item }));

    purchasePlatformOptions = PURCHASE_PLATFORM.map(item => ({ value: item, label: item }));

    carrierOptions = CN_CARRIER_LIST.map(item => ({ value: item, label: item }));

    constructor() {}

    formatterPercent = (value: number) => `${value} %`;

    ngOnChanges(e: SimpleChanges) {
        if (e.selectedProductPurchase.currentValue) {
            this.productPurchaseInfo = cloneDeep(this.selectedProductPurchase?.matchedDataInTable);
        }
    }
}
