<section class="shipping-box">
    <section class="btn-operations">
        <span class="main-content-title">{{ 'SHIPPING_SCHEDULE' | translate }}</span>
    </section>
    <section class="filter-status">
        <div class="pending" [ngClass]="{ select: isPending }" (click)="selectStatus('isPending')">
            {{ 'IS_PENDING' | translate }}
            <span>{{ isPendingLength }}</span>
        </div>
        <div class="progress" [ngClass]="{ select: isProgress }" (click)="selectStatus('isProgress')">{{ 'IN_PROGRESS' | translate }}</div>
        <!-- <div class="complete" [ngClass]="{ select: isComplete }" (click)="selectStatus('isComplete')">Completed已完成</div> -->
    </section>
    <section class="data-table">
        <nz-table
            #shippingScheduleTable
            nzShowPagination
            nzShowSizeChanger
            [nzLoading]="fetchingShippingScheduleList"
            [nzData]="shippingScheduleList"
            [nzShowPagination]="true"
        >
            <thead>
                <tr>
                    <th>
                        {{ 'SHIPPING_PLAN_NUMBER' | translate }}
                        <app-table-search-dropdown
                            [defaultProductList]="defaultShippingScheduleList"
                            [name]="'id'"
                            (searchList)="searchResultList($event)"
                            (resetList)="resetResultList()"
                        ></app-table-search-dropdown>
                    </th>
                    <th nzWidth="250px" *ngIf="!permissionService.currentUserPermission.isGeneralInternalNoAccess">
                        {{ 'UPLOAD_STATUS' | translate }}
                    </th>
                    <th nzWidth="250px">{{ 'SCHEDULE_UPLOAD_TIME' | translate }}</th>
                    <th nzWidth="300px">{{ 'OPERATE' | translate }}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of shippingScheduleTable.data">
                    <td>
                        <span class="data-id">{{ data.id }}</span>
                    </td>
                    <td *ngIf="!permissionService.currentUserPermission.isGeneralInternalNoAccess">
                        <p class="upload" *ngIf="data.schedule_file_path !== null"><span></span> {{ 'UPLOADED' | translate }}</p>
                        <p class="not-upload" *ngIf="data.schedule_file_path === null"><span></span> {{ 'NOT_UPLOADED' | translate }}</p>
                    </td>
                    <td>
                        <p *ngIf="data.schedule_file_path !== null">{{ data.schedule_uploaded_at | date: 'MM/dd/yyyy' }}</p>
                        <p *ngIf="data.schedule_file_path === null">/</p>
                    </td>
                    <td>
                        <button [disabled]="data.schedule_file_path === null" nz-button nzType="primary">
                            <a [href]="data.schedule_file_path">{{ 'DOWNLOAD' | translate }}</a>
                        </button>
                        <button
                            nz-button
                            nzType="primary"
                            *ngIf="!permissionService.currentUserPermission.isGeneralInternalNoAccess && !data.shipping_file_path"
                            (click)="uploadFile(data)"
                        >
                            {{ data.schedule_file_path === null ? ('UPLOAD' | translate ): ('RE_UPLOAD' | translate ) }}
                        </button>
                    </td>
                </tr>
            </tbody>
        </nz-table>
    </section>
</section>

<nz-modal
    [nzTitle]="modalTitle"
    [nzFooter]="null"
    [nzContent]="modalContent"
    nzClassName="shippingModal"
    [(nzVisible)]="shippingScheduleModalVisible"
    (nzOnCancel)="shippingScheduleModalVisible = false"
>
    <ng-template #modalTitle> <b>{{ 'UPLOAD_A_SHIPPING_PLAN' | translate }}</b> </ng-template>
    <ng-template #modalContent>
        <div class="image-holder" *ngIf="saveBtnDisabled">
            <nz-upload
                [nzMultiple]="false"
                [nzShowUploadList]="false"
                [nzBeforeUpload]="beforeUploadExcel"
                [nzAccept]="['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']"
            >
                <button style="text-decoration: underline" nz-button nzType="link">
                    <i nz-icon nzType="upload" nzTheme="outline"></i>
                    <span class="text">{{ 'UPLOAD_A_SHIPPING_PLAN' | translate }}</span>
                </button>
            </nz-upload>
        </div>
        <div class="upload-result" *ngIf="!saveBtnDisabled">
            <p>
                <a [href]="filePath">{{ fileName }}</a>
                <i class="closeIcon" (click)="removeInvoice()" nz-icon nzType="close-circle" nzTheme="fill"></i>
            </p>
        </div>
        <div class="btns">
            <button nz-button nzType="primary" (click)="handleOk()" [disabled]="excelBthDisable" [nzLoading]="isConfirmLoading">
                {{ 'CONFIRM' | translate }}
            </button>
            <button nz-button nzType="default" (click)="handleCancel()">{{ 'CANCEL' | translate }}</button>
        </div>
    </ng-template>
</nz-modal>
