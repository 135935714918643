import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// ant
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzSliderModule } from 'ng-zorro-antd/slider';
import { NzAnchorModule } from 'ng-zorro-antd/anchor';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzCalendarModule } from 'ng-zorro-antd/calendar';
import { NzCascaderModule } from 'ng-zorro-antd/cascader';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        NzLayoutModule,
        NzMenuModule,
        NzIconModule,
        NzToolTipModule,
        NzModalModule,
        NzGridModule,
        NzTabsModule,
        NzMessageModule,
        NzDatePickerModule,
        NzInputModule,
        NzRadioModule,
        NzUploadModule,
        NzListModule,
        NzBadgeModule,
        NzSelectModule,
        NzFormModule,
        NzButtonModule,
        NzMenuModule,
        NzTableModule,
        NzCheckboxModule,
        NzStepsModule,
        NzDropDownModule,
        NzCollapseModule,
        NzTagModule,
        NzProgressModule,
        NzInputNumberModule,
        NzSpinModule,
        NzSliderModule,
        NzAnchorModule,
        NzSwitchModule,
        NzSkeletonModule,
        NzAlertModule,
        NzCarouselModule,
        NzDividerModule,
        NzPopoverModule,
        NzAutocompleteModule,
        NzTimePickerModule,
        NzCalendarModule,
        NzCascaderModule,
        NzPopconfirmModule,
        NzPaginationModule,
    ],
    exports: [
        NzLayoutModule,
        NzMenuModule,
        NzIconModule,
        NzToolTipModule,
        NzModalModule,
        NzGridModule,
        NzTabsModule,
        NzMessageModule,
        NzDatePickerModule,
        NzInputModule,
        NzRadioModule,
        NzUploadModule,
        NzListModule,
        NzBadgeModule,
        NzSelectModule,
        NzFormModule,
        NzButtonModule,
        NzMenuModule,
        NzTableModule,
        NzCheckboxModule,
        NzStepsModule,
        NzDropDownModule,
        NzCollapseModule,
        NzTagModule,
        NzProgressModule,
        NzInputNumberModule,
        NzSpinModule,
        NzSliderModule,
        NzAnchorModule,
        NzSwitchModule,
        NzSkeletonModule,
        NzAlertModule,
        NzCarouselModule,
        NzDividerModule,
        NzPopoverModule,
        NzAutocompleteModule,
        NzTimePickerModule,
        NzCalendarModule,
        NzCascaderModule,
        NzPopconfirmModule,
        NzPaginationModule,
    ],
})
export class AntdImportModule {}
