<div class="add-sample-purchase-request-box">
    <section class="btn-operations">
        <span class="main-content-title">Add Sample Purchase Request 添加样品采购请求</span>

        <section class="btns">
            <button nz-button nzType="primary" [nzLoading]="savingRecord" [disabled]="saveBtnDisabled" (click)="saveRecord()">
                Add Request 发起请求
            </button>
        </section>
    </section>

    <section class="create-form">
        <section class="product-purchase-list">
            <!-- Product info list -->
            <div class="product-purchase-item">
                <div class="form-item">
                    <div class="title">Product Name 产品名称 <span class="required">*</span></div>
                    <div class="inline-box">
                        <input nz-input type="text" placeholder="Enter product name" [(ngModel)]="samplePurchaseItem.product_name" />
                    </div>
                </div>

                <div class="form-item">
                    <div class="title">Store 店铺 <span class="required">*</span></div>
                    <div class="inline-box">
                        <nz-select
                            [(ngModel)]="samplePurchaseItem.shop_id"
                            [nzPlaceHolder]="'Choose store'"
                            nzShowSearch
                            (ngModelChange)="changeShop()"
                        >
                            <nz-option *ngFor="let shop of shopList" [nzValue]="shop.shop_id" [nzLabel]="shop.name"></nz-option>
                            <nz-option *ngIf="shopList?.length <= 0" nzDisabled nzCustomContent>
                                <i nz-icon nzType="loading" nzTheme="outline" style="margin-right: 5px"></i>
                                <span>Loading Data...</span>
                            </nz-option>
                        </nz-select>
                    </div>
                </div>

                <div class="form-item">
                    <div class="title">Request Qty 需求数量 <span class="required">*</span></div>
                    <div class="inline-box">
                        <nz-input-number
                            type="number"
                            nzMin="0"
                            [(ngModel)]="samplePurchaseItem.request_qty"
                            [nzPlaceHolder]="'Qty'"
                        ></nz-input-number>
                    </div>
                </div>

                <div class="form-item">
                    <div class="title">Related Sourcing Link 关联Sourcing信息链接 <span class="required">*</span></div>
                    <div class="inline-box">
                        <input nz-input type="text" placeholder="Related Sourcing Link" [(ngModel)]="samplePurchaseItem.sourcing_link" />
                    </div>
                </div>
            </div>
        </section>

        <div class="add-more">
            <div class="form-item" style="margin-right: 20px">
                <div class="title">Final Destination 最终目的地 <span class="required">*</span></div>
                <div class="inline-box">
                    <nz-select [(ngModel)]="samplePurchaseItem.address_id" [nzPlaceHolder]="'Destination Address'" style="width: 180px">
                        <nz-option *ngFor="let address of addressLists" [nzValue]="address.id" [nzLabel]="address.name"></nz-option>
                    </nz-select>
                    <span (click)="addAddress()">Add Address</span>
                </div>
                <div style="margin: 10px 0 12px; font-size: 14px">{{ addressInfo }}</div>
            </div>
            <div class="form-item">
                <div class="title">Instruction Notes 说明信息 <span class="required">*</span></div>
                <div class="inline-box">
                    <input nz-input type="text" placeholder="Instruction Notes" [(ngModel)]="samplePurchaseItem.instruction" />
                </div>
            </div>
        </div>
        <div class="add-more">
            <div class="form-item" style="padding-right: 20px">
                <div class="title">Urgency 紧急程度 <span class="required">*</span></div>
                <div class="inline-box">
                    <nz-select [(ngModel)]="samplePurchaseItem.urgency" [nzPlaceHolder]="'Urgency'" style="width: 100%">
                        <nz-option *ngFor="let urgency of urgencyList" [nzValue]="urgency.id" [nzLabel]="urgency.name"></nz-option>
                    </nz-select>
                </div>
            </div>
            <div class="form-item" style="padding-right: 20px">
                <div class="title">Required Date 需要日期 <span class="required">*</span></div>
                <div class="inline-box">
                    <nz-date-picker
                        [(ngModel)]="samplePurchaseItem.required_date"
                        nzFormat="MM/dd/yyyy"
                        [nzPlaceHolder]="'Required Date'"
                        style="width: 100%"
                    ></nz-date-picker>
                </div>
            </div>
            <div class="form-item" style="flex: 2">
                <div class="title" style="margin-bottom: 10px">Check Requirements 验货需求 <span class="required">*</span></div>
                <div class="inline-box">
                    <nz-checkbox-group [(ngModel)]="checkRequirementOptions"></nz-checkbox-group>
                </div>
            </div>
        </div>
    </section>
</div>

<nz-modal
    [(nzVisible)]="addressModalVisible"
    (nzOnCancel)="addressModalVisible = false"
    [nzFooter]="null"
    [nzTitle]="modalTitle"
    nzWidth="500px"
>
    <ng-template #modalTitle>
        <span style="color: #0d053c; font-weight: bold; font-size: 20px">Add Address</span>
    </ng-template>
    <app-address-modal
        type="add"
        (updateAddressSuccess)="addAddressConfirm()"
        (closeModal)="addressModalVisible = false"
    ></app-address-modal>
</nz-modal>
