import { Component, Input, OnInit } from '@angular/core';
import { ProductInventoryService } from '@src/app/services/product-inventory.service';

@Component({
    selector: 'app-kit-tooltip',
    templateUrl: './kit-tooltip.component.html',
    styleUrls: ['./kit-tooltip.component.less'],
})
export class KitTooltipComponent implements OnInit {
    @Input() kitSku: string;

    kitDetails: any;
    kitVisible = false;

    constructor(private productInventoryService: ProductInventoryService) {}

    ngOnInit(): void {}

    getKitContent(e) {
        this.productInventoryService.getKitSkuContent(this.kitSku).then(res => {
            this.kitDetails = res;
        });
        e.stopPropagation();
    }
}
