<section class="manual-input-purchase create-form">
    <button
        *ngIf="source === 'product_purchase'"
        class="absolute"
        style="top: 40px; right: 20px"
        nz-button
        nzType="primary"
        [nzLoading]="savingRecord"
        [disabled]="saveBtnDisabled"
        (click)="saveRecordEmit()"
    >
        Save Record
    </button>

    <section [ngClass]="{'product-purchase-list': true, 'product-purchase-list-maxh': source === 'restock_request'}">
        <div *ngFor="let item of productPurchase.product_list; let i = index">
            <div class="product-purchase-item">
                <nz-radio-group *ngIf="source === 'product_purchase'" class="radios" [(ngModel)]="item.dataInputType" style="width: 200px">
                    <label nz-radio nzValue="search">Search By SKU</label>
                    <label nz-radio nzValue="select">Select SKU</label>
                </nz-radio-group>

                <div class="forms">
                    <div class="row">
                        <ng-container *ngIf="item.dataInputType === 'select' && source === 'product_purchase'; else searchOption">
                            <div class="form-item">
                                <div class="title">Shop 店铺 <span class="required">*</span></div>
                                <div class="inline-box">
                                    <nz-select
                                        [(ngModel)]="item.shop_id"
                                        (ngModelChange)="changeShop($event, item)"
                                        [nzPlaceHolder]="'Shop'"
                                        nzShowSearch
                                    >
                                        <nz-option
                                            *ngFor="let shop of shopList"
                                            [nzValue]="shop.shop_id"
                                            [nzLabel]="shop.shop_name"
                                        ></nz-option>
                                        <nz-option *ngIf="shopList?.length <= 0" nzDisabled nzCustomContent>
                                            <i nz-icon nzType="loading" nzTheme="outline" style="margin-right: 5px"></i>
                                            <span>Loading Data...</span>
                                        </nz-option>
                                    </nz-select>
                                </div>
                            </div>

                            <div class="form-item">
                                <div class="title">Product 产品 <span class="required">*</span></div>
                                <div class="inline-box">
                                    <nz-select
                                        nzShowSearch
                                        [nzOptionHeightPx]="50"
                                        [(ngModel)]="item.product_id"
                                        (ngModelChange)="changeProduct($event, item)"
                                        [nzPlaceHolder]="'Product'"
                                    >
                                        <nz-option
                                            *ngFor="let product of item.productList"
                                            [nzValue]="product.product_id"
                                            [nzLabel]="product.product_name"
                                            nzCustomContent
                                        >
                                            <img src="{{ product.product_image }}" style="width: 40px; height: 40px; margin-right: 10px" />
                                            {{ product.product_name }}
                                        </nz-option>
                                    </nz-select>
                                </div>
                            </div>

                            <div class="form-item">
                                <div class="title">Variant (SKU) <span class="required">*</span></div>
                                <div class="inline-box">
                                    <nz-select
                                        nzPlaceHolder="Variant (SKU)"
                                        (ngModelChange)="skuChange($event, item)"
                                        (nzFocus)="skuChange($event, item)"
                                        [(ngModel)]="item.product_sku"
                                    >
                                        <nz-option
                                            *ngFor="let option of item.skuList"
                                            [nzValue]="option.product_sku"
                                            [nzLabel]="option.product_sku"
                                            nzCustomContent
                                        >
                                            <img src="{{ option.variant_image }}" style="width: 40px; height: 40px; margin-right: 10px" />
                                            {{ option.variant_name }} ({{ option.product_sku }})
                                        </nz-option>

                                        <nz-option *ngIf="shopList?.length <= 0" nzDisabled nzCustomContent>
                                            <i nz-icon nzType="loading" nzTheme="outline" style="margin-right: 5px"></i>
                                            <span>Loading Data...</span>
                                        </nz-option>
                                    </nz-select>
                                </div>
                            </div>
                        </ng-container>

                        <ng-template #searchOption>
                            <div class="form-item">
                                <div class="title">Variant (SKU) <span class="required">*</span></div>
                                <div class="inline-box">
                                    <input
                                        nz-input
                                        type="text"
                                        placeholder="Enter Variant (SKU)"
                                        [(ngModel)]="item.product_sku"
                                        (focus)="skuChange(item.product_sku, item)"
                                        (ngModelChange)="skuChange($event, item)"
                                        [nzAutocomplete]="variantOptions"
                                        [disabled]="source === 'restock_request'"
                                    />

                                    <nz-autocomplete #variantOptions>
                                        <nz-auto-option
                                            *ngIf="item.matchedSkuOption"
                                            [nzValue]="item.matchedSkuOption.product_sku"
                                            [nzLabel]="item.matchedSkuOption.product_sku"
                                        >
                                            <img
                                                src="{{ item.matchedSkuOption.variant_image }}"
                                                style="width: 40px; height: 40px; margin-right: 10px"
                                            />
                                            {{ item.matchedSkuOption.variant_name }} ({{ item.matchedSkuOption.product_sku }})
                                        </nz-auto-option>

                                        <nz-auto-option *ngIf="shopList?.length <= 0" nzDisabled nzCustomContent>
                                            <i nz-icon nzType="loading" nzTheme="outline" style="margin-right: 5px"></i>
                                            <span>Loading Data...</span>
                                        </nz-auto-option>
                                    </nz-autocomplete>
                                </div>
                            </div>
                            <div class="form-item" style="position: relative; min-width: 555px" *ngIf="source === 'restock_request'">
                                <div class="title">Purchase for Restock Request 匹配补货请求</div>
                                <div class="inline-box">
                                    <nz-select
                                        nzShowSearch
                                        nzAllowClear
                                        nzPlaceHolder="Search by Request ID"
                                        [nzOptionHeightPx]="54"
                                        [(ngModel)]="item.restock_request_id"
                                        [disabled]="source === 'restock_request'"
                                    >
                                        <nz-option
                                            *ngFor="let option of item.restock_request_list"
                                            [nzValue]="option.id"
                                            [nzLabel]="option.product_sku"
                                            nzCustomContent
                                        >
                                            <div
                                                class="request-item-container"
                                                style="display: flex; justify-content: space-between; align-items: center; font-size: 12px"
                                            >
                                                <div style="flex: 1; padding: 0 2px">
                                                    <div>ID</div>
                                                    <div>{{ option.id }}</div>
                                                </div>

                                                <div style="flex: 2; padding: 0 2px">
                                                    <div>Requested QTY</div>
                                                    <div>{{ option.requested_quantity }}</div>
                                                </div>
                                                <div style="flex: 2; padding: 0 2px">
                                                    <div>Remaining QTY</div>
                                                    <div>
                                                        {{ option.requested_quantity - option.in_progress_quantity -
                                                        option.fulfilled_quantity }}
                                                    </div>
                                                </div>

                                                <div style="flex: 2; padding: 0 2px">
                                                    <div>Requested Date</div>
                                                    <div>{{ option.created_at | date: 'yyyy-MM-dd' }}</div>
                                                </div>

                                                <div style="flex: 2; padding: 0 2px">
                                                    <div>Requestor</div>
                                                    <div>{{ option.requestor }}</div>
                                                </div>
                                            </div>
                                        </nz-option>
                                        <nz-option *ngIf="restockRequestList.length <= 0" nzDisabled nzCustomContent>
                                            <i nz-icon nzType="loading" nzTheme="outline" style="margin-right: 5px"></i>
                                            <span>Loading Data...</span>
                                        </nz-option>
                                    </nz-select>
                                </div>
                            </div>
                        </ng-template>
                    </div>

                    <div class="row">
                        <div class="form-item">
                            <div class="title">Purchase Quantity 购买数量 <span class="required">*</span></div>
                            <div class="inline-box">
                                <input nz-input type="number" min="0" [(ngModel)]="item.quantity" />
                            </div>
                        </div>

                        <div class="form-item" style="position: relative">
                            <div class="title">Unit Cost 单价 (¥) <span class="required">*</span></div>
                            <div class="inline-box">
                                <input nz-input type="number" min="0" [(ngModel)]="item.unit_cost" />
                            </div>
                        </div>

                        <div class="form-item" *ngIf="source === 'product_purchase'">
                            <div class="title">Purchase for Restock Request 匹配补货请求</div>
                            <div class="inline-box">
                                <nz-select
                                    nzShowSearch
                                    nzAllowClear
                                    nzPlaceHolder="Search by Request ID"
                                    [nzOptionHeightPx]="54"
                                    [(ngModel)]="item.restock_request_id"
                                >
                                    <nz-option
                                        *ngFor="let option of item.restock_request_list"
                                        [nzValue]="option.id"
                                        [nzLabel]="option.product_sku"
                                        nzCustomContent
                                    >
                                        <div
                                            class="request-item-container"
                                            style="display: flex; justify-content: space-between; align-items: center; font-size: 12px"
                                        >
                                            <div style="flex: 1; padding: 0 2px">
                                                <div>ID</div>
                                                <div>{{ option.id }}</div>
                                            </div>

                                            <div style="flex: 2; padding: 0 2px">
                                                <div>Requested QTY</div>
                                                <div>{{ option.requested_quantity }}</div>
                                            </div>
                                            <div style="flex: 2; padding: 0 2px">
                                                <div>Remaining QTY</div>
                                                <div>
                                                    {{ option.requested_quantity - option.in_progress_quantity - option.fulfilled_quantity
                                                    }}
                                                </div>
                                            </div>

                                            <div style="flex: 2; padding: 0 2px">
                                                <div>Requested Date</div>
                                                <div>{{ option.created_at | date: 'yyyy-MM-dd' }}</div>
                                            </div>

                                            <div style="flex: 2; padding: 0 2px">
                                                <div>Requestor</div>
                                                <div>{{ option.requestor }}</div>
                                            </div>
                                        </div>
                                    </nz-option>
                                    <nz-option *ngIf="restockRequestList.length <= 0" nzDisabled nzCustomContent>
                                        <i nz-icon nzType="loading" nzTheme="outline" style="margin-right: 5px"></i>
                                        <span>Loading Data...</span>
                                    </nz-option>
                                </nz-select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-item">
                            <div class="title">Chinese Declaration Name 报关中文名 <span class="required">*</span></div>
                            <div class="inline-box">
                                <input nz-input [(ngModel)]="item.chinese_declaration_name" placeholder="Chinese Declaration Name" />
                            </div>
                        </div>

                        <div class="form-item">
                            <div class="title">English Declaration Name 报关英文名 <span class="required">*</span></div>
                            <div class="inline-box">
                                <input nz-input [(ngModel)]="item.english_declaration_name" placeholder="English Declaration Name" />
                            </div>
                        </div>
                    </div>
                    <div *ngIf="source !== 'product_purchase'">
                        <div class="form-item pr-20" style="width: 50%">
                            <div class="title">QC Percentage 质检比例 <span class="required">*</span></div>
                            <div class="inline-box">
                                <nz-input-number
                                    nzPlaceHolder="QC Percentage"
                                    [(ngModel)]="item.qc_ratio"
                                    nzPrecision="2"
                                    [nzMin]="0"
                                    [nzMax]="100"
                                    [nzFormatter]="formatterPercent"
                                    style="width: 100%"
                                ></nz-input-number>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-item" *ngIf="source === 'product_purchase'">
                            <div class="title">QC Percentage 质检比例 <span class="required">*</span></div>
                            <div class="inline-box">
                                <nz-input-number
                                    nzPlaceHolder="QC Percentage"
                                    [(ngModel)]="item.qc_ratio"
                                    nzPrecision="2"
                                    [nzMin]="0"
                                    [nzMax]="100"
                                    [nzFormatter]="formatterPercent"
                                    style="width: 100%"
                                ></nz-input-number>
                            </div>
                        </div>

                        <div class="form-item">
                            <div class="title">Check Requirements 对版要求</div>
                            <div class="inline-box">
                                <input nz-input [(ngModel)]="item.check_requirement" placeholder="Check Requirements" />
                            </div>
                        </div>

                        <div class="form-item" style="position: relative">
                            <div class="title">QC Requirements 质检要求</div>
                            <div class="inline-box">
                                <input nz-input [(ngModel)]="item.qc_requirement" placeholder="QC Requirements" />
                            </div>
                            <i
                                *ngIf="source === 'product_purchase'"
                                nz-icon
                                nzType="close-circle"
                                nzTheme="outline"
                                style="position: absolute; right: 0; bottom: 10px; cursor: pointer"
                                (click)="removeProductPurchaseItem(i)"
                            ></i>
                        </div>
                    </div>
                </div>
            </div>

            <nz-divider></nz-divider>
        </div>
    </section>

    <div class="add-more" *ngIf="source === 'product_purchase'">
        <span (click)="addProductPurchaseItem(true)" style="margin-right: 20px">
            <i nz-icon nzType="plus-circle" nzTheme="outline"></i>
            <span>Copy Last Record 复制上一行</span>
        </span>

        <span (click)="addProductPurchaseItem(false)">
            <i nz-icon nzType="plus-circle" nzTheme="outline"></i>
            <span>Add Empty Record 添加新纪录</span>
        </span>
    </div>

    <section class="other-info">
        <div class="form-item">
            <div class="inline-form-item">
                <div class="title">Purchase Platform 采购平台 <span class="required">*</span></div>
                <div class="inline-box">
                    <nz-select [(ngModel)]="productPurchase.purchase_platform" [nzPlaceHolder]="'Purchase Platform'">
                        <nz-option *ngFor="let item of PURCHASE_PLATFORM" [nzValue]="item" [nzLabel]="item"> </nz-option>
                    </nz-select>
                </div>
            </div>

            <div class="inline-form-item">
                <div class="title">Purchase Order ID 采购订单号 <span class="required">*</span></div>
                <div class="inline-box">
                    <input nz-input type="text" [(ngModel)]="productPurchase.purchase_order_id" placeholder="Purchase Order ID" />
                </div>
            </div>

            <div class="inline-form-item">
                <div class="title">Order Content 1688货品标题<span class="required">*</span></div>
                <div class="inline-box">
                    <textarea nz-input [(ngModel)]="productPurchase.order_content" placeholder="Order Content"></textarea>
                </div>
            </div>
        </div>

        <div class="form-item">
            <div class="inline-form-item">
                <div class="title">Purchase Date 采购日期 <span class="required">*</span></div>
                <div class="inline-box">
                    <nz-date-picker
                        nzFormat="MM/dd/yyyy"
                        nzPlaceHolder="MM/DD/YYYY"
                        [(ngModel)]="productPurchase.purchase_date"
                    ></nz-date-picker>
                </div>
            </div>

            <div class="inline-form-item">
                <div class="title">Destination Address 目的地 <span class="required">*</span></div>
                <div class="inline-box">
                    <nz-select [(ngModel)]="productPurchase.destination_address" [nzPlaceHolder]="'Destination Address'">
                        <nz-option *ngFor="let item of DESTINATION_CHINA_WAREHOUSE" [nzValue]="item" [nzLabel]="item"> </nz-option>
                    </nz-select>
                </div>
            </div>
        </div>

        <div class="form-item">
            <div class="inline-form-item">
                <div class="title">Deliver ETA 预计送达日期</div>
                <div class="inline-box">
                    <nz-date-picker
                        nzFormat="MM/dd/yyyy"
                        nzPlaceHolder="MM/DD/YYYY"
                        [(ngModel)]="productPurchase.deliver_eta"
                    ></nz-date-picker>
                </div>
            </div>

            <div class="inline-form-item">
                <div class="title">Shipping Cost 运费 (¥)</div>
                <div class="inline-box">
                    <input nz-input type="number" min="0" [(ngModel)]="productPurchase.shipping_cost" />
                </div>
            </div>
        </div>

        <div *ngFor="let logisticsItem of productPurchase.logistics" class="form-item">
            <div class="inline-form-item">
                <div class="title">Carrier 物流公司</div>
                <div class="inline-box">
                    <input #carrierInput nz-input [(ngModel)]="logisticsItem.carrier" placeholder="Carrier" />
                </div>
            </div>

            <div class="inline-form-item">
                <div class="title">Third-party logistics number 第三方订单物流单号</div>
                <div class="inline-box">
                    <input nz-input placeholder="Third-party logistics number" [(ngModel)]="logisticsItem.tracking_number" />
                </div>
            </div>
        </div>

        <div class="cursor-pointer link-color text-decoration-underline mt-20">
            <span (click)="productPurchase.logistics.push({carrier:'',tracking_number:''})">
                <i nz-icon nzType="plus-circle" nzTheme="outline"></i>
                <span>Add new logistics record 添加新物流记录</span>
            </span>
        </div>
    </section>

    <div class="buttons-row" *ngIf="source === 'restock_request'">
        <button nz-button class="button" (click)="cancelRecord()">Cancel</button>
        <button nz-button class="button primary mr-34" [nzLoading]="savingRecord" [disabled]="saveBtnDisabled" (click)="saveRecordEmit()">
            Save Record
        </button>
    </div>
</section>
