import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { filter } from 'lodash';

interface Option {
    text: string;
    value: number;
}

@Component({
    selector: 'app-table-search-dropdown',
    templateUrl: './table-search-dropdown.component.html',
    styleUrls: ['./table-search-dropdown.component.less'],
})
export class TableSearchDropdownComponent implements OnInit, OnChanges {
    @Input() defaultProductList;
    @Input() name: any;
    @Input() options?: Option[];
    @Output() searchList = new EventEmitter<any>();
    @Output() resetList = new EventEmitter<any>();

    searchValue = '';
    searchVisible = false;
    filteredOptions: Option[] = [];

    constructor() {}

    ngOnInit() {}

    ngOnChanges(e: SimpleChanges) {
        if (e.options?.currentValue) {
            this.filteredOptions = [...e.options.currentValue];
        }
    }

    resetTable(name) {
        this[name] = '';
        if (this.options?.length) {
            this.filteredOptions = [...this.options];
        }
        this.resetList.emit();
    }

    searchChange(value) {
        if (!this.options?.length) return false;
        this.filteredOptions = this.options.filter(option => option.text?.toLowerCase().includes(value.toLowerCase()));
    }

    searchValueCompare = (o1: Option | string, o2: Option): boolean => {
        if (o1 && o2) {
            return typeof o1 === 'string' ? o1 === o2.text : o1.value === o2.value;
        } else {
            return false;
        }
    };

    search(): void {
        this.searchVisible = false;
        let productList = [];
        if (typeof this.name == 'string') {
            productList = this.defaultProductList
                .filter(productItem => productItem[this.name] !== null && productItem[this.name] !== undefined)
                .filter(productItem => String(productItem[this.name]).toLowerCase().includes(String(this.searchValue).toLowerCase()));
        }
        if (Array.isArray(this.name)) {
            if (this.name.includes('tracking_number') && this.name.includes('carrier')) {
                productList = [
                    ...this.defaultProductList.filter(productItem =>
                        String(productItem.tracking_number).toLowerCase().includes(String(this.searchValue).toLowerCase())
                    ),
                    ...this.defaultProductList.filter(productItem =>
                        String(productItem.carrier).toLowerCase().includes(String(this.searchValue).toLowerCase())
                    ),
                ];
            } else {
                let searchItemList = [];
                let commonSearch = [];
                if (this.defaultProductList.some(productItem => productItem.inbound_result_list) && !this.name.length) {
                    searchItemList = this.defaultProductList
                        .filter(productItem => productItem.inbound_result_list !== null && productItem.inbound_result_list !== undefined)
                        .filter(productItem =>
                            JSON.stringify(productItem.inbound_result_list).includes(String(this.searchValue).toLowerCase())
                        );
                } else {
                    searchItemList = this.defaultProductList.filter(productItem =>
                        this.name.some(item => String(productItem[item]).toLowerCase().includes(String(this.searchValue).toLowerCase()))
                    );
                }

                commonSearch = commonSearch.concat(searchItemList);
                productList = [...commonSearch];
            }
        }

        this.searchList.emit(productList);
    }
}
