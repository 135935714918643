import { SkuListItem } from '@shared/type/order-dispatch.type';
import { CARRIER_LIST } from '@shared/const/order-dispatch.const';
import { cloneDeep, isDate, isObject } from 'lodash';
import { Injectable } from '@angular/core';
import { RequestService } from '@services/request.service';
import * as dayjs from 'dayjs';
import * as Excel from 'exceljs';
import * as fs from 'file-saver';
import { environment } from '@src/environments/environment';
import { UtilsService } from '@services/util.service';

interface ProductLogisticsToBeSave {
    product_sku: string;
    quantity: number;
    warehouse_id: string;
    carrier: string;
    tracking_number: string;
    shipping_cost: number;
    shipping_date: string;
    deliver_eta: string;
    warehouse_name?: string;
    shipping_method: string;
    shipping_warehouse: string;
    box_id: string;
}

interface ShippingCostsToBeSave {
    tracking_number: string;
    carrier: string;
    shipping_cost: number;
    paid_date: string;
    currency: string;
}

@Injectable({
    providedIn: 'root',
})
export class MassOrderService {
    columnIdNameMap = new Map([
        ['product_sku', 'Variant(SKU) ID'],
        ['quantity', 'Quantity Shipped 发货数量'],
        ['shipping_date', 'Shipping Date发货日期'],
        ['deliver_eta', 'Deliver ETA预计到达日期'],
        ['carrier', 'Carrier 物流公司'],
        ['tracking_number', 'Tracking Number 物流单号'],
        ['warehouse_id', 'Destination Warehouse 目的地仓库'],
        ['shipping_cost', 'Shipping Cost 运费（$）'],
    ]);

    shippingCostColumnIdNameMap = new Map([
        ['tracking_number', 'Tracking Nnumber 物流单号'],
        ['carrier', 'Carrier 物流公司'],
        ['shipping_cost', 'Shipping Cost 运费'],
        ['paid_date', 'Paid Date 付款日期'],
        ['currency', 'Currency 货币种类 (USD/RMB)'],
    ]);

    styles = { font: { name: 'Microsoft YaHei', size: 12, bold: true } };
    mergeCellStyle = {
        font: { size: 12, bold: true, name: 'Microsoft YaHei' },
    };

    constructor(private requestService: RequestService, private utilService: UtilsService) {}

    exportProductLogisticsExcel(logisticsData: any[]) {
        const newColumns = [
            {
                id: 'variant_image',
                name: 'Variant Image',
                width: 20,
                type: 'image',
            },
            {
                id: 'variant_name',
                name: 'SKU',
                width: 25,
            },
            {
                id: 'product_sku',
                name: 'Variant 规格',
                width: 25,
            },
            {
                id: 'shop_name',
                name: 'Shop 店铺',
                width: 20,
            },
            {
                id: 'product_name_id',
                name: 'Product name & ID 产品 & ID',
                width: 32,
            },
            {
                id: 'warehouse_name',
                name: 'Warehouse 仓库',
                width: 32,
            },
            {
                id: 'shipped_arrived_qty',
                name: 'Quantity Shipped/Arrived 发货数/到货数',
                width: 35,
            },
            {
                id: 'tracking',
                name: 'Tracking 物流信息',
                width: 25,
            },
            {
                id: 'shipping_date',
                name: 'Shipping Date 发货日期',
                width: 30,
            },
            {
                id: 'deliver_eta',
                name: 'Deliver ETA 预计到货日期',
                width: 30,
            },

            {
                id: 'shipping_status',
                name: 'Shipping Status 物流状态',
                width: 30,
            },
            {
                id: 'restock_request_id',
                name: 'Restock Request ID 补货请求ID',
                width: 32,
            },
            {
                id: 'purchase_id',
                name: 'Purchase ID 采购单号',
                width: 30,
            },
        ];
        const data = logisticsData.map(item => {
            const res = {
                variant_image: item.variant_image,
                variant_name: item.variant_name,
                product_sku: item.product_sku,
                shop_name: item.shop_name,
                product_name_id: `${item.product_name} \n ${item.product_id}`,
                warehouse_name: item.warehouse_name,
                shipped_arrived_qty: `${item.shipped_quantity}/${item.arrived_quantity}`,
                tracking: `${item.carrier}/${item.tracking_number}`,
                shipping_date: item.shipping_date ? dayjs(item.shipping_date).format('MM/DD/YYYY') : '--',
                deliver_eta: item.deliver_eta ? dayjs(item.deliver_eta).format('MM/DD/YYYY') : '--',
                shipping_status: item.latest_shipping_status?.status ? item.latest_shipping_status?.status : 'Unknown',
                restock_request_id: item.restock_request_id ?? '--',
                purchase_id: item.product_purchase_id ?? '--',
            };
            return res;
        });
        return this.utilService.exportToExcel({
            header: newColumns,
            fileName: 'product_logistics',
            sheets: [
                {
                    sheetName: 'Product Logistics',
                    data,
                },
            ],
        });
    }

    exportProductPurchaseExcel(data: any[]) {
        const workbook = new Excel.Workbook();
        const worksheet = workbook.addWorksheet('Product Purchase');
        worksheet.pageSetup.horizontalCentered = true;
        worksheet.pageSetup.verticalCentered = true;
        worksheet.addRow([]);

        const newColumns = [
            {
                header: 'Note',
                width: 25,
                style: this.styles,
            },
            {
                header: 'Third-party logistics',
                width: 25,
                style: this.styles,
            },
            {
                header: 'Restock request ID',
                width: 25,
                style: this.styles,
            },
            {
                header: 'Purchase ID',
                width: 20,
                style: this.styles,
            },
            {
                header: 'Product name',
                width: 20,
                style: this.styles,
            },
            {
                header: 'SKU',
                width: 20,
                style: this.styles,
            },
            {
                header: 'Variant',
                width: 25,
                style: this.styles,
            },
            {
                header: 'Variant Image',
                width: 20,
                style: this.styles,
            },
            {
                header: 'Purchased Qty',
                width: 25,
                style: this.styles,
            },
            {
                header: 'Destination',
                width: 25,
                style: this.styles,
            },
            {
                header: 'Purchase Order ID',
                width: 25,
                style: this.styles,
            },
            {
                header: 'Third-party logistics No.',
                width: 25,
                style: this.styles,
            },
            {
                header: 'Order Content',
                width: 25,
                style: this.styles,
            },
            {
                header: 'Barcode',
                width: 25,
                style: this.styles,
            },
        ];
        // Add Header Row
        worksheet.columns = [...newColumns];
        // Add Data and Conditional Formatting
        data.map((item, index) => {
            if (item.img_base !== '') {
                const myBase64Image = item.img_base;
                if (myBase64Image) {
                    const imageId2 = workbook.addImage({
                        base64: myBase64Image,
                        extension: 'jpeg',
                    });
                    worksheet.addImage(imageId2, {
                        tl: { col: 7, row: index + 1 },
                        ext: { width: 40, height: 40 },
                    });
                }
            }

            const image3 = workbook.addImage({
                base64: `data:image/png;base64,${item.barcode}`,
                extension: 'png',
            });

            worksheet.addImage(image3, {
                tl: { col: 13, row: index + 1 },
                ext: { width: 80, height: 40 },
            });
            const row = worksheet.addRow([
                '',
                '',
                Number(item.restock_request_id),
                Number(item.id),
                item.product_name,
                item.product_sku,
                item.variant_name,
                '',
                Number(item.purchased_quantity),
                item.destination_address,
                Number(item.purchase_order_id),
                `${item.carrier}/${item.tracking_number}`,
                item.order_content,
                '',
            ]);
            row.font = { name: 'Microsoft YaHei', size: 10 };
            row.alignment = { vertical: 'top', wrapText: true };
            // row.height = 38;
        });
        workbook.xlsx.writeBuffer().then(excelData => {
            const blob = new Blob([excelData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fs.saveAs(blob, 'product_purchase.xlsx');
        });
    }

    exportFinancesSummaryExcel(data: any, fileName?: string) {
        const workbook = new Excel.Workbook();
        const worksheet = workbook.addWorksheet('Finances Summary');
        worksheet.properties.defaultColWidth = 30;
        worksheet.addRow([]);
        worksheet.addTable({
            name: 'Sales',
            ref: 'A1',
            style: {
                theme: 'TableStyleLight9',
            },
            columns: [{ name: 'Sales' }, { name: '$' }],
            rows: [
                ['Gross Sales', `$${data.sales?.gross_sales}`],
                ['Discounts', `-$${data.sales?.discounts}`],
                ['Returns', `-$${data.sales?.returns}`],
                ['Net Sales', `$${data.sales?.net_sales}`],
                ['Shipping', `$${data.sales?.shipping}`],
                ['Taxes', `$${data.sales?.taxes}`],
                ['Total Sales', `$${data.sales?.total_sales}`],
                ['Total Payment Charge Cost', `-$${data.sales?.total_payment_cost}`],
                ['Total Sales After Payment Charge & Without Tax', `-$${data.sales?.total_sales_without_tax_and_cost}`],
                ['Procurement Cost', `-$${data.costs?.procument_cost}`],
                ['International Shipping Cost', `-$${data.costs?.international_shipping_cost}`],
                ['Domestic Fulfillment Cost', `-$${data.costs?.domestic_fulfillment_cost}`],
                ['Warehouse Operation Cost', `-$${data.costs?.warehouse_operation_cost}`],
                ['E-commerce Operation Cost', `-$${data.costs?.ecommerce_operation_cost}`],
                ['(Total Cost for Sales)', `-$${data.costs?.total_cost}`],
                ['Net Profit for Sales', `$${data.costs?.net_profit}`],
            ],
        });

        worksheet.addTable({
            name: 'TotalSpends',
            ref: 'A20',
            style: {
                theme: 'TableStyleLight9',
            },
            columns: [{ name: 'Total Spends' }, { name: '$' }],
            rows: [
                ['Procurement Spends', `$${data.spends?.procument_spends}`],
                [' - Samples', `${((data.spends?.sample_procument_spends * 100) / data.spends?.procument_spends).toFixed(2)}%`],
                [' - Mass Ordering', `${((data.spends?.mass_procurement_spends * 100) / data.spends?.procument_spends).toFixed(2)}%`],
                ['International Shipping Spends', `$${data.spends?.international_shipping_spends}`],
                [
                    ' - Samples',
                    `${((data.spends?.sample_international_shipping_spends * 100) / data.spends?.international_shipping_spends).toFixed(
                        2
                    )}%`,
                ],
                [
                    ' - Mass Ordering',
                    `${((data.spends?.mass_international_shipping_spends * 100) / data.spends?.international_shipping_spends).toFixed(2)}%`,
                ],
                ['Domestic Fulfillment Spends', `$${data.spends?.domestic_fulfillment_spends}`],
                ['Warehouse Operation Spends', `$${data.spends?.warehouse_operation_spends}`],
                ['E-commerce Operation Spends', `$${data.spends?.ecommerce_operation_spends}`],
                ['Total Spends', `$${data.spends?.total_spends}`],
            ],
        });

        worksheet.addTable({
            name: 'LifoRevenue',
            ref: 'A33',
            style: {
                theme: 'TableStyleLight9',
            },
            columns: [{ name: 'Lifo Revenue' }, { name: '$' }],
            rows: [
                ['Platform Revenue', `$${data.revenue_report?.platform_revenue}`],
                [
                    ' - Shopify Revenue',
                    `${((data.revenue_report?.shopify_revenue * 100) / data.revenue_report?.platform_revenue).toFixed(2)}%`,
                ],
                [
                    ' - Amazon Revenue',
                    `${((data.revenue_report?.amazon_revenue * 100) / data.revenue_report?.platform_revenue).toFixed(2)}%`,
                ],
                ['Platform Procurement Revenue', `$${data.revenue_report?.procurement_revenue}`],
                ['Total Revenue', `$${data.revenue_report?.total_revenue}`],
            ],
        });

        worksheet.addTable({
            name: 'Payments',
            ref: 'D1',
            style: {
                theme: 'TableStyleLight9',
            },
            columns: [{ name: 'Payments' }, { name: '$' }],
            rows: [
                ['Amazon Payments', `$${data.payments?.gateway_amazon}`],
                ['PayPal Payments Standards', `$${data.payments?.gateway_paypal}`],
                ['Shopify Payments', `$${data.payments?.gateway_shopify}`],
                ['Total Payments', `$${data.payments?.total_payments}`],
                ['Amazon Payment Cost', `$${data.payments?.payment_cost_amazon}`],
                ['PayPal Payment Cost', `$${data.payments?.payment_cost_paypal}`],
                ['Shopify Payment Cost', `$${data.payments?.payment_cost_shopify}`],
                ['Total Payment Charge Cost', `$${data.payments?.total_payment_cost}`],
            ],
        });

        worksheet.addTable({
            name: 'Special Orders',
            ref: 'D12',
            style: {
                theme: 'TableStyleLight9',
            },
            columns: [{ name: 'Special Orders' }, { name: '$' }],
            rows: [
                ['Gifting Orders ($0)', `$${data.special_orders?.gifting_order_count}`],
                ['Sampling Orders ($0)', `$${data.special_orders?.sample_order_count}`],
                ['Total Special Orders', `$${data.special_orders?.total_order_count}`],
                ['Procurement Cost', `-$${data.special_orders?.procument_cost}`],
                ['International Shipping Cost', `-$${data.special_orders?.international_shipping_cost}`],
                ['Domestic Fulfillment Cost', `-$${data.special_orders?.domestic_fulfillment_cost}`],
                ['Warehouse Operation Cost', `-$${data.special_orders?.warehouse_operation_cost}`],
                ['E-commerce Operation Cost', `-$${data.special_orders?.ecommerce_operation_cost}`],
                ['Total Cost for Special Orders', `-$${data.special_orders?.total_cost}`],
            ],
        });

        worksheet.addTable({
            name: 'Earnings for Sales',
            ref: 'D24',
            style: {
                theme: 'TableStyleLight9',
            },
            columns: [{ name: 'Earnings for Sales' }, { name: '$' }],
            rows: [
                ['Total Sales (Without Tax)', `$${data.creator_earnings_report?.total_sales}`],
                ['Domestic Fulfillment Cost (Creator Section)', `$${data.creator_earnings_report?.domestic_fulfillment_cost}`],
                ['COGS (Creator Section)', `-$${data.creator_earnings_report?.procurement_cost}`],
                ['Lifo Service Fee', `-$${data.creator_earnings_report?.service_fee}`],
                ['Campaign/Event Credit Back (Cost Only)', `$${data.creator_earnings_report?.event_credit_back}`],
                ['Total Earnings', `$${data.creator_earnings_report?.total_earnings}`],
                ['Total Sales (Without Tax)', `$${data.lifo_earings_report?.total_sales}`],
                ['Total Service Fee Earnings', `$${data.lifo_earings_report?.total_service_fee}`],
                ['Campaign/Event Credit (Cost Only)', `-$${data.lifo_earings_report?.event_credit_back}`],
                ['Total Cost for Sales', `-$${data.lifo_earings_report?.total_cost}`],
                ['Total Earnings', `$${data.lifo_earings_report?.total_earnings}`],
            ],
        });
        worksheet.getCell('A10').alignment = { wrapText: true };
        worksheet.getCell('A21').alignment = { wrapText: true };
        worksheet.getCell('D21').alignment = { wrapText: true };
        worksheet.getCell('D26').alignment = { wrapText: true };
        worksheet.getCell('D27').alignment = { wrapText: true };
        worksheet.getCell('D28').alignment = { wrapText: true };
        worksheet.getCell('D29').alignment = { wrapText: true };
        worksheet.getCell('D32').alignment = { wrapText: true };
        worksheet.getCell('D34').alignment = { wrapText: true };
        workbook.xlsx.writeBuffer().then(excelData => {
            const blob = new Blob([excelData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fs.saveAs(blob, `${fileName || 'finances_summary'}.xlsx`);
        });
    }

    exportReportsExcel(data: any[], mode) {
        const workbook = new Excel.Workbook();
        const worksheet = workbook.addWorksheet('Reports');
        worksheet.pageSetup.horizontalCentered = true;
        worksheet.pageSetup.verticalCentered = true;
        worksheet.properties.defaultColWidth = 120;
        worksheet.addRow([]);
        let newColumns = [];
        if (mode === 'salesReport') {
            newColumns = [
                {
                    header: 'Shop Name',
                    width: 20,
                    style: this.styles,
                },
                {
                    header: 'Orders',
                    width: 20,
                    style: this.styles,
                },
                {
                    header: 'Gross sales',
                    width: 20,
                    style: this.styles,
                },
                {
                    header: 'Discounts',
                    width: 20,
                    style: this.styles,
                },
                {
                    header: 'Returns',
                    width: 20,
                    style: this.styles,
                },
                {
                    header: 'Net sales',
                    width: 20,
                    style: this.styles,
                },
                {
                    header: 'Shipping',
                    width: 20,
                    style: this.styles,
                },
                {
                    header: 'Tax',
                    width: 20,
                    style: this.styles,
                },
                {
                    header: 'Total sales',
                    width: 20,
                    style: this.styles,
                },
            ];
        } else if (mode === 'shopSalesReport') {
            newColumns = [
                {
                    header: 'Shop Name',
                    width: 20,
                    style: this.styles,
                },
                {
                    header: 'Gross sales',
                    width: 20,
                    style: this.styles,
                },
                {
                    header: 'Discounts',
                    width: 20,
                    style: this.styles,
                },
                {
                    header: 'Returns',
                    width: 20,
                    style: this.styles,
                },
                {
                    header: 'Net sales',
                    width: 20,
                    style: this.styles,
                },
                {
                    header: 'Shipping',
                    width: 20,
                    style: this.styles,
                },
                {
                    header: 'Tax',
                    width: 20,
                    style: this.styles,
                },
                {
                    header: 'Total sales',
                    width: 20,
                    style: this.styles,
                },
            ];
        } else if (mode === 'taxReport') {
            newColumns = [
                {
                    header: 'Shop Name',
                    width: 20,
                    style: this.styles,
                },
                {
                    header: 'Country',
                    width: 25,
                    style: this.styles,
                },
                {
                    header: 'Region',
                    width: 25,
                    style: this.styles,
                },
                {
                    header: 'Name',
                    width: 25,
                    style: this.styles,
                },
                {
                    header: 'Rate',
                    width: 20,
                    style: this.styles,
                },
                {
                    header: 'Amount',
                    width: 20,
                    style: this.styles,
                },
            ];
        } else if (mode === 'inventoryReport') {
            newColumns = [
                {
                    header: 'Shop Name',
                    width: 20,
                    style: this.styles,
                },
                {
                    header: 'Product title',
                    width: 25,
                    style: this.styles,
                },
                {
                    header: 'Variant title',
                    width: 25,
                    style: this.styles,
                },
                {
                    header: 'Variant SKU',
                    width: 25,
                    style: this.styles,
                },
                {
                    header: 'Quantity sold',
                    width: 20,
                    style: this.styles,
                },
                {
                    header: 'Start date inventory',
                    width: 25,
                    style: this.styles,
                },
                {
                    header: 'End date inventory',
                    width: 25,
                    style: this.styles,
                },
                {
                    header: 'Total inbound',
                    width: 25,
                    style: this.styles,
                },
                {
                    header: 'COGS',
                    width: 25,
                    style: this.styles,
                },
            ];
        }

        // Add Header Row
        worksheet.columns = [...newColumns];
        // Add Data and Conditional Formatting
        data.map((item, index) => {
            let newRows = [];
            if (mode === 'salesReport') {
                newRows = [
                    item.shop_name,
                    Number(item.order_id),
                    Number(item.gross_sales),
                    Number(item.discounts),
                    Number(item.returns),
                    Number(item.net_sales),
                    Number(item.shipping),
                    Number(item.taxes),
                    Number(item.total_sales),
                ];
            } else if (mode === 'shopSalesReport') {
                newRows = [
                    item.shop_name,
                    Number(item.report?.sales?.gross_sales),
                    Number(item.report?.sales?.discounts),
                    Number(item.report?.sales?.returns),
                    Number(item.report?.sales?.net_sales),
                    Number(item.report?.sales?.shipping),
                    Number(item.report?.sales?.taxes),
                    Number(item.report?.sales?.total_sales),
                ];
            } else if (mode === 'taxReport') {
                newRows = [`${item.shop_name}`, `${item.country}`, `${item.region}`, `${item.name}`, `${item.rate}`, `$${item.amount}`];
            } else if (mode === 'inventoryReport') {
                newRows = [
                    item.shop_name,
                    item.product_name,
                    item.variant_name,
                    item.sku,
                    Number(item.total_sold),
                    Number(item.sf_start_inventory + item.hz_start_inventory),
                    Number(item.sf_final_inventory + item.hz_final_inventory),
                    Number(item.sf_enter_inventory + item.hz_enter_inventory),
                    Number(item.cogs.toFixed(2)),
                ];
            }
            const row = worksheet.addRow(newRows);
            row.font = { name: 'Microsoft YaHei', size: 10 };
            row.alignment = { vertical: 'top', wrapText: true };
        });
        workbook.xlsx.writeBuffer().then(excelData => {
            const blob = new Blob([excelData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fs.saveAs(blob, `${mode}.xlsx`);
        });
    }

    exportAutoRestockExcel(data: any[]) {
        const workbook = new Excel.Workbook();
        const worksheet = workbook.addWorksheet('Auto Restock Requests');
        worksheet.pageSetup.horizontalCentered = true;
        worksheet.pageSetup.verticalCentered = true;
        worksheet.properties.defaultColWidth = 30;
        worksheet.addRow([]);
        const newColumns = [
            { header: 'Variant Image', width: 25, style: this.styles },
            { header: 'SKU', width: 25, style: this.styles },
            { header: 'Variant 规格', width: 25, style: this.styles },
            { header: 'Shop 店铺', width: 25, style: this.styles },
            { header: 'Product Name 产品名称', width: 25, style: this.styles },
            { header: 'Product ID 产品ID', width: 25, style: this.styles },
            { header: 'Destination Warehouse 目的地仓库', width: 25, style: this.styles },
            { header: 'Generated Date 生成日期', width: 25, style: this.styles },
            { header: 'Total Historical Inventory 历史总库存', width: 25, style: this.styles },
            { header: 'Total Sold 总卖出数(SF Warehouse)', width: 25, style: this.styles },
            { header: '15-day average sales 15天平均销量', width: 25, style: this.styles },
            { header: 'Total Existing Inventory 总在库差额', width: 25, style: this.styles },
            { header: 'In-Transit 总在途数(SF Warehouse)', width: 25, style: this.styles },
            { header: 'China Total Available Inventory 中国可用库存(在库/在途)', width: 25, style: this.styles },
            { header: 'Optimum Restock Qty 建议补货数量', width: 25, style: this.styles },
            { header: 'Actual Restock Qty 实际补货数量', width: 25, style: this.styles },
            { header: 'Current Status 当前状态', width: 25, style: this.styles },
        ];
        // Add Header Row
        worksheet.columns = [...newColumns];
        // Add Data and Conditional Formatting
        data.map((item, index) => {
            if (item.img_base !== '') {
                const myBase64Image = item.img_base;
                if (myBase64Image) {
                    const imageId2 = workbook.addImage({
                        base64: myBase64Image,
                        extension: 'jpeg',
                    });
                    worksheet.addImage(imageId2, {
                        tl: { col: 0, row: index + 1 },
                        ext: { width: 60, height: 60 },
                    });
                }
            }

            const row = worksheet.addRow([
                '',
                item.product_sku,
                item.variant_name,
                item.shop_name,
                item.product_name,
                Number(item.product_id),
                item.warehouse_name,
                dayjs(item.generated_time).format('MM/DD/YYYY'),
                Number(item.sf_total_inventory),
                Number(item.sf_total_sold),
                Number(item.average_sales),
                Number(item.sf_total_inventory - item.sf_total_sold) || '',
                Number(item.sf_in_transit),
                Number(item.total_purchased - item.sf_total_inventory - item.sf_in_transit) || '',
                Number(item.requested_quantity),
                Number(item.real_restock_quantity),
                item.status,
            ]);
            row.font = { name: 'Microsoft YaHei', size: 10 };
            row.alignment = { vertical: 'top', wrapText: true };
        });
        workbook.xlsx.writeBuffer().then(excelData => {
            const blob = new Blob([excelData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fs.saveAs(blob, 'auto_restock_requests.xlsx');
        });
    }

    async parseLogisticsRecordData(
        file: File,
        necessaryData: {
            warehouseList: any[];
            skuList: any[];
        }
    ): Promise<{
        data: ProductLogisticsToBeSave[];
        errors: string[];
    }> {
        /**
         * File Column
         * product_sku, quantity, shipping_date, deliver_eta, carrier, tracking_number, warehouse_id/name, shipping_cost
         */
        const clonedNecessaryData = cloneDeep(necessaryData);
        const res: ProductLogisticsToBeSave[] = [];
        const workbook = new Excel.Workbook();
        const worksheet = await workbook.xlsx.load(await file.arrayBuffer());
        const getCellValue = (value: any) => {
            if (isObject(value)) {
                value = (value as any).richText[0]?.text;
            }
            return value;
        };
        worksheet.eachSheet(sheet => {
            sheet.eachRow((row, rowNumber) => {
                if (rowNumber !== 1) {
                    const [
                        product_sku,
                        quantity,
                        shipping_date,
                        deliver_eta,
                        carrier,
                        tracking_number,
                        warehouse_name,
                        shipping_cost,
                        shipping_method,
                        shipping_warehouse,
                        box_id,
                    ] = (row.values as any[]).slice(1);
                    const itemToBeAppend: ProductLogisticsToBeSave = {
                        product_sku,
                        quantity,
                        carrier,
                        tracking_number,
                        warehouse_name,
                        shipping_cost: shipping_cost || 0,
                        shipping_date: dayjs(shipping_date).format('MM/DD/YYYY'),
                        deliver_eta: dayjs(deliver_eta).format('MM/DD/YYYY'),
                        warehouse_id: clonedNecessaryData.warehouseList.find(item => item.warehouse_name === warehouse_name)?.warehouse_id,
                        shipping_method: getCellValue(shipping_method),
                        shipping_warehouse: getCellValue(shipping_warehouse),
                        box_id,
                    };

                    res.push(itemToBeAppend);
                }
            });
        });

        const errors = this.validateData(res, necessaryData);

        return {
            data: res,
            errors,
        };
    }

    async parseShippingCostRecordData(file: File): Promise<{
        data: ShippingCostsToBeSave[];
        errors: string[];
    }> {
        /**
         * File Column
         * tracking_number, carrier, shipping_cost, paid_date, currency
         */
        const res: ShippingCostsToBeSave[] = [];
        const workbook = new Excel.Workbook();
        const worksheet = await workbook.xlsx.load(await file.arrayBuffer());
        worksheet.eachSheet(sheet => {
            const mergedCells = new Map<string, any[]>();
            sheet.eachRow((row, rowNumber) => {
                row.eachCell((cell, colNumber) => {
                    if (cell.model.type === 1) {
                        if (mergedCells.has(cell.model.master)) {
                            mergedCells.get(cell.model.master).push(cell.model.address);
                        } else {
                            mergedCells.set(cell.model.master, [cell.model.address]);
                        }
                    }
                });
            });
            mergedCells.forEach((cells, master) => {
                const cost = Number(sheet.getCell(master).value);
                const avgCost = Math.round((cost / (cells.length + 1)) * 100) / 100;
                sheet.getCell(master).value = avgCost;
                sheet.unMergeCells(master);
                cells.forEach(cell => {
                    sheet.getCell(cell).value = avgCost;
                });
            });
            sheet.eachRow((row, rowNumber) => {
                if (rowNumber !== 1) {
                    let shipping_cost = 0;
                    let currency = '';
                    const [tracking_number, carrier, paid_date, cny, usd] = (row.values as any[]).slice(1);
                    if (cny) {
                        shipping_cost = cny;
                        currency = 'CNY';
                    } else if (usd) {
                        shipping_cost = usd;
                        currency = 'USD';
                    }
                    console.log(currency);
                    const itemToBeAppend: ShippingCostsToBeSave = {
                        tracking_number,
                        carrier,
                        shipping_cost: Number(shipping_cost) || 0,
                        paid_date: paid_date ? dayjs(paid_date).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD'),
                        currency,
                    };

                    res.push(itemToBeAppend);
                }
            });
        });
        const errors = this.validateShippingCostData(res);

        return {
            data: res,
            errors,
        };
    }

    batchAddProductLogistics(data) {
        return this.requestService.sendRequest(
            {
                method: 'POST',
                url: '/am/dispatcher/mass_ordering_batch',
                data,
            },
            environment.shopApiService
        );
    }

    importShippingCost(data) {
        return this.requestService.sendRequest(
            {
                method: 'POST',
                url: '/am/dispatcher/import_international_shipping_cost',
                data,
                timeout: 1000 * 60 * 3,
            },
            environment.shopApiService
        );
    }

    exportInventoryReport(data: any[]) {
        const workbook = new Excel.Workbook();
        const worksheet = workbook.addWorksheet('Inventory Report');
        worksheet.columns = [
            { header: 'Product Name' },
            { header: 'SKU' },
            { header: 'HZ COGS' },
            { header: 'HZ Sold' },
            { header: 'HZ Purchase' },
            { header: 'HZ Inventory' },
            { header: 'HZ Sold COGS' },
            { header: 'HZ Purchase COGS' },
            { header: 'HZ Inventory COGS' },
            { header: 'SF COGS' },
            { header: 'SF Sold' },
            { header: 'SF Puchase' },
            { header: 'SF Inventory' },
            { header: 'SF Sold COGS' },
            { header: 'SF Purchase COGS' },
            { header: 'SF Inventory COGS' },
        ];

        data.map((item, index) => {
            const sf_unit_cost = item.unit_cost + item.weight_in_gram * 0.012;
            const row = worksheet.addRow([
                item.product_name,
                item.sku,
                Number(item.unit_cost),
                Number(item.hz_total_sold),
                Number(item.hz_enter_inventory),
                Number(item.hz_real_inventory),
                Number(item.hz_total_sold * item.unit_cost),
                Number(item.hz_enter_inventory * item.unit_cost),
                Number(item.hz_real_inventory * item.unit_cost),
                Number(sf_unit_cost),
                Number(item.sf_total_sold),
                Number(item.sf_enter_inventory),
                Number(item.sf_real_inventory),
                Number(item.sf_total_sold * sf_unit_cost),
                Number(item.sf_enter_inventory * sf_unit_cost),
                Number(item.sf_real_inventory * sf_unit_cost),
            ]);
        });

        workbook.xlsx.writeBuffer().then(excelData => {
            const blob = new Blob([excelData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fs.saveAs(blob, 'inventory_report.xlsx');
        });
    }

    exportStockingSelling(data: any[]) {
        const workbook = new Excel.Workbook();
        const worksheet = workbook.addWorksheet('Inventory Report');
        worksheet.pageSetup.horizontalCentered = true;
        worksheet.pageSetup.verticalCentered = true;
        worksheet.properties.defaultColWidth = 120;

        worksheet.mergeCells('A1:H1');
        worksheet.mergeCells('I1:S1');
        worksheet.mergeCells('T1:V1');
        worksheet.mergeCells('W1:AI1');
        const stylesHeader = { width: 20, style: this.styles };

        const titleColumns = [
            stylesHeader,
            stylesHeader,
            stylesHeader,
            stylesHeader,
            stylesHeader,
            stylesHeader,
            stylesHeader,
            {
                header: 'SKU基本信息',
                width: 20,
                style: this.styles,
            },
            stylesHeader,
            stylesHeader,
            stylesHeader,
            stylesHeader,
            stylesHeader,
            stylesHeader,
            stylesHeader,
            stylesHeader,
            stylesHeader,
            stylesHeader,
            {
                header: '国内直发仓',
                width: 20,
                style: this.styles,
            },
            stylesHeader,
            stylesHeader,
            {
                header: '国内采购集货物仓',
                width: 20,
                style: this.styles,
            },
            stylesHeader,
            stylesHeader,
            stylesHeader,
            stylesHeader,
            stylesHeader,
            stylesHeader,
            stylesHeader,
            stylesHeader,
            stylesHeader,
            stylesHeader,
            stylesHeader,
            stylesHeader,
            {
                header: '海外履约仓',
                width: 20,
                style: this.styles,
            },
        ];
        worksheet.columns = [...titleColumns];
        worksheet.getCell('A1').style = this.mergeCellStyle;
        worksheet.getCell('I1').style = this.mergeCellStyle;
        worksheet.getCell('T1').style = this.mergeCellStyle;
        worksheet.getCell('W1').style = this.mergeCellStyle;
        worksheet.getRow(1).alignment = { vertical: 'middle', horizontal: 'center' };
        // Add Header Row
        worksheet.addRow([
            'Store Name \n 店铺名称',
            'SKU ID \n 规格',
            'Product Name \n 产品名',
            '是否Active',
            'Total Purchased \n 历史累计已采购总量',
            'Total Sold \n 历史累计已出售总量',
            'Unit Cost \n 采购单价($)',
            'Sales Price \n 当前售价($)',

            'Warehouse \n 当前所在采购仓',
            '15 Day Sales \n 近15天销量',
            '30 Day Sales \n 近30天销量',
            '60 Day Sales \n 近60天销量',
            'Current In-Transit \n 实时采购在途数',
            'Total Historical Inventory \n 历史总库存',
            'History Corrected Inventory \n 历史库存变更数',
            'Total Existing Inventory \n 当前库存数（含可售数和应发货数）',
            'Current Occupied by Orders \n 当前应发货数',
            'Total Historical Inventory - Sold \n 当前总在库差额（理论库存/实际库存）',
            'Total Available - Sold \n 当前总在库&途差额（可用）',

            'Warehouse \n 当前所在采购仓',
            'Current In-Transit \n 实时采购在途数',
            'Current Inventory \n 当前库存数',

            'Warehouse \n 当前所在海外仓',
            '15 Day Sales \n 近15天销量',
            '30 Day Sales \n 近30天销量',
            '60 Day Sales \n 近60天销量',
            'Current In-Transit \n 实时国际在途数',
            'Total Historical In-Transit \n 历史累计在途数',
            'Total Historical Inventory \n 历史总库存',
            'History Corrected Inventory \n 历史库存变更数',
            'Total Existing Inventory \n 当前库存数（含未卖出数和应发货数）',
            'Current Occupied by Orders \n 当前应发货数',
            'Fulfilled Quantity \n 历史累计已发货数',
            'Total Historical Inventory - Sold \n 当前总在库差额（理论库存/实际库存）',
            'Total Available - Sold \n 当前总在库&途差额（可用）',
        ]);
        worksheet.getRow(2).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
        worksheet.getRow(2).height = 40;
        // Add Data and Conditional Formatting
        data.map((item, index) => {
            const row = worksheet.addRow([
                item.shop_name,
                item.sku,
                item.product_name,
                String(item.active),
                item.total_purchased,
                item.total_sold,
                item.unit_cost,
                item.sales_price,

                'HZ Warehouse',
                Number(item.hz_15_day_sold),
                Number(item.hz_30_day_sold),
                Number(item.hz_60_day_sold),
                Number(item.hz_in_transit),
                Number(item.hz_total_inventory),
                Number(item.hz_inventory_adjustment),
                Number(item.hz_total_inventory + item.hz_inventory_adjustment - item.hz_total_fulfilled),
                Number(item.hz_total_sold - item.hz_total_fulfilled),
                `${Number(item.hz_total_inventory + item.hz_inventory_adjustment - item.hz_total_sold)}/${Number(item.hz_real_inventory)}`,
                Number(item.hz_total_inventory + item.hz_inventory_adjustment + item.hz_in_transit - item.hz_total_sold),

                'SH Quick',
                Number(item.po_in_transit),
                Number(item.po_arrived - item.po_outbound),

                'SF Warehouse',
                Number(item.sf_15_day_sold),
                Number(item.sf_30_day_sold),
                Number(item.sf_60_day_sold),
                Number(item.sf_in_transit),
                Number(item.sf_total_inventory + item.sf_in_transit),
                Number(item.sf_total_inventory),
                Number(item.sf_inventory_adjustment),
                Number(item.sf_total_inventory + item.sf_inventory_adjustment - item.sf_total_fulfilled),
                Number(item.sf_total_sold - item.sf_total_fulfilled),
                Number(item.sf_total_fulfilled),
                `${Number(item.sf_total_inventory + item.sf_inventory_adjustment - item.sf_total_sold)}/${Number(item.sf_real_inventory)}`,
                Number(item.sf_in_transit + item.sf_total_inventory + item.sf_inventory_adjustment - item.sf_total_sold),
            ]);
            row.font = { name: 'Microsoft YaHei', size: 10 };
            row.alignment = { vertical: 'top', wrapText: true };
        });
        workbook.xlsx.writeBuffer().then(excelData => {
            const blob = new Blob([excelData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fs.saveAs(blob, 'stocking_selling_report.xlsx');
        });
    }

    private validateData(
        data: ProductLogisticsToBeSave[],
        necessaryData: {
            warehouseList: any[];
            skuList: SkuListItem[];
        }
    ): string[] {
        const errors = [];
        const { skuList } = necessaryData;
        data.forEach((item, i) => {
            const rowNumber = i + 2;

            // SKU
            if (!item.product_sku) {
                errors.push(`行${rowNumber}, ${this.columnIdNameMap.get('product_sku')}, SKU为空`);
                return;
            }
            if (!skuList.find(skuItem => skuItem.product_sku === item.product_sku)) {
                errors.push(`行${rowNumber}, ${this.columnIdNameMap.get('product_sku')}, SKU不存在`);
            }

            // quantity
            if (!(item.quantity > 0)) {
                errors.push(`行${rowNumber}, ${this.columnIdNameMap.get('quantity')}, ${item.quantity}`);
            }

            // shipping_date
            if (item.shipping_date === 'Invalid Date') {
                errors.push(`行${rowNumber}, ${this.columnIdNameMap.get('shipping_date')}, 日期格式错误`);
            }

            // deliver_eta
            if (item.deliver_eta === 'Invalid Date') {
                errors.push(`行${rowNumber}, ${this.columnIdNameMap.get('deliver_eta')}, 日期格式错误`);
            }

            // carrier
            if (!CARRIER_LIST.find(carrier => carrier === item.carrier)) {
                errors.push(`行${rowNumber}, ${this.columnIdNameMap.get('carrier')}, ${item.carrier}不存在`);
            }

            // tracking_number
            if (!item.tracking_number) {
                errors.push(`行${rowNumber}, ${this.columnIdNameMap.get('tracking_number')}, 运单号不存在`);
            }

            // warehouse_id
            if (!item.warehouse_id) {
                errors.push(`行${rowNumber}, ${this.columnIdNameMap.get('warehouse_id')}, ${item.warehouse_name} 不存在`);
            }

            // shipping_cost
            // if (!(Number(item.shipping_cost) >= 0)) {
            //     errors.push(`行${rowNumber}, ${this.columnIdNameMap.get('shipping_cost')}, ${item.shipping_cost}`);
            // }
        });
        return errors;
    }

    private validateShippingCostData(data: ShippingCostsToBeSave[]): string[] {
        const errors = [];
        data.forEach((item, i) => {
            const rowNumber = i + 2;
            // tracking_number
            if (!item.tracking_number) {
                errors.push(`行${rowNumber}, ${this.shippingCostColumnIdNameMap.get('tracking_number')}, 运单号不存在`);
            }

            // carrier
            if (!CARRIER_LIST.find(carrier => carrier === item.carrier)) {
                errors.push(`行${rowNumber}, ${this.shippingCostColumnIdNameMap.get('carrier')}, ${item.carrier}不存在`);
            }

            // shipping_cost
            if (!(Number(item.shipping_cost) >= 0)) {
                errors.push(`行${rowNumber}, ${this.shippingCostColumnIdNameMap.get('shipping_cost')}, ${item.shipping_cost}运费不正确`);
            }

            // paid_date
            if (item.paid_date === 'Invalid Date') {
                errors.push(`行${rowNumber}, ${this.shippingCostColumnIdNameMap.get('paid_date')}, 日期格式错误`);
            }

            // currency
            if (!['USD', 'CNY'].includes(item.currency)) {
                errors.push(`行${rowNumber}, ${this.shippingCostColumnIdNameMap.get('currency')}, 货币种类格式错误`);
            }
        });
        return errors;
    }
}
