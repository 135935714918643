import { Component, Input, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { chunk } from 'lodash';

@Component({
    selector: 'app-product-details',
    templateUrl: './product-details.component.html',
    styleUrls: ['./product-details.component.less'],
})
export class ProductDetailsComponent implements OnChanges {
    @Input() productInfo: any;
    @Input() productInfoList: {
        label: string;
        valueKey?: string | string[];
        split?: string;
        type?: string;
        default?: string;
        format?: null;
    }[];

    tempList: any;

    constructor() {}

    ngOnChanges(e: SimpleChanges) {
        if (e.productInfo?.currentValue) {
            this.tempList = chunk(this.productInfoList, 3);
        }
    }

    getMultipleValue(data) {
        let result = '';
        const len = data.valueKey.length - 1;
        data.valueKey.forEach((ele, ind) => {
            result += this.productInfo[ele] + (ind === len ? '' : data.split || '');
        });
        return result;
    }
}
