<div class="pro-details" *ngIf="selectedSampleRequest">
    <div class="pro-line">
        <div class="pro-item">
            <h4 class="label">Sample Request ID 样品请求ID</h4>
            <div class="value">{{sampleRequestInfo.id}}</div>
        </div>
        <div class="pro-item">
            <h4 class="label">Sample Name 样品名</h4>
            <div class="value">
                <ng-container *ngIf="editOptions.isEditProductName; else viewProductName">
                    <input nz-input type="text" [(ngModel)]="sampleRequestInfo.product_name" />
                    <button
                        nz-button
                        nzType="text"
                        [disabled]="!sampleRequestInfo.product_name"
                        (click)="updateSample('isEditProductName', ['product_name'])"
                    >
                        <span style="color: #414bb2">Save</span>
                    </button>
                </ng-container>
                <ng-template #viewProductName>
                    {{sampleRequestInfo.product_name}}
                    <i nz-icon nzType="edit" nzTheme="outline" *ngIf="isEdit" (click)="editOptions.isEditProductName = true"></i>
                </ng-template>
            </div>
        </div>
        <div class="pro-item">
            <h4 class="label">Sample Product ID 样品ID</h4>
            <div class="value">{{sampleRequestInfo.product_id}}</div>
        </div>
    </div>
    <div class="pro-line">
        <div class="pro-item">
            <h4 class="label">Store 店铺</h4>
            <div class="value">{{sampleRequestInfo.shop_name}}</div>
        </div>
        <div class="pro-item">
            <h4 class="label">1688 Product Info 1688货品信息</h4>
            <div class="value">
                <app-product-info-in-list [productInfo]="sampleRequestInfo.product_info"></app-product-info-in-list>
                <!-- {{sampleRequestInfo?.product_info?.name}} -->
            </div>
        </div>
        <div class="pro-item">
            <h4 class="label">Sourcing Link 关联Sourcing信息</h4>
            <div class="value">
                <ng-container *ngIf="editOptions.isEditSourcingLink; else viewSourcingLink">
                    <input nz-input type="text" [(ngModel)]="sampleRequestInfo.sourcing_link" />
                    <button
                        nz-button
                        nzType="text"
                        [disabled]="!sampleRequestInfo.sourcing_link"
                        (click)="updateSample('isEditSourcingLink', ['sourcing_link'])"
                    >
                        <span style="color: #414bb2">Save</span>
                    </button>
                </ng-container>
                <ng-template #viewSourcingLink>
                    <a [href]="sampleRequestInfo.sourcing_link" target="_blank" class="link-text">Link</a>
                    <i nz-icon nzType="edit" nzTheme="outline" *ngIf="isEdit" (click)="editOptions.isEditSourcingLink = true"></i>
                </ng-template>
            </div>
        </div>
    </div>
    <div class="pro-line">
        <div class="pro-item">
            <h4 class="label">
                {{pageSource !== PageSourceEnum.SAMPLE ? 'Requested/purchased/China arrived/Intl shipped /Intl arrvied Qty
                总需/已采/国内到达/国际在途/国际到达' : 'Requested/Purchased Qty 总需/已采'}}
            </h4>
            <div class="value">
                <ng-container *ngIf="editOptions.isEditRequestQuantity; else viewRequestQuantity">
                    <span>总需数</span>
                    <input nz-input type="text" [(ngModel)]="sampleRequestInfo.request_quantity" style="width: 64%; margin-left: 10px" />
                    <button
                        nz-button
                        nzType="text"
                        [disabled]="!sampleRequestInfo.request_quantity"
                        (click)="updateSample('isEditRequestQuantity', ['request_quantity'])"
                    >
                        <span style="color: #414bb2">Save</span>
                    </button>
                </ng-container>
                <ng-template #viewRequestQuantity>
                    {{ sampleRequestInfo.request_quantity }} / {{ sampleRequestInfo.purchased_quantity }}
                    <ng-container *ngIf="pageSource !== PageSourceEnum.SAMPLE">
                        / {{ sampleRequestInfo.arrived_quantity }} / {{ sampleRequestInfo.shipped_quantity }} / {{
                        sampleRequestInfo.intl_arrived_quantity }}
                    </ng-container>
                    <i nz-icon nzType="edit" nzTheme="outline" *ngIf="isEdit" (click)="editOptions.isEditRequestQuantity = true"></i>
                </ng-template>
            </div>
        </div>
        <div class="pro-item">
            <h4 class="label">Urgency 紧急程度</h4>
            <div class="value">
                <ng-container *ngIf="editOptions.isEditUrgency; else viewUrgency">
                    <nz-select [(ngModel)]="sampleRequestInfo.urgency" [nzPlaceHolder]="'Urgency'">
                        <nz-option *ngFor="let urgency of urgencyList" [nzValue]="urgency.id" [nzLabel]="urgency.name"></nz-option>
                    </nz-select>
                    <button
                        nz-button
                        nzType="text"
                        [disabled]="!sampleRequestInfo.urgency"
                        (click)="updateSample('isEditUrgency', ['urgency'])"
                    >
                        <span style="color: #414bb2">Save</span>
                    </button>
                </ng-container>
                <ng-template #viewUrgency>
                    <span [ngSwitch]="sampleRequestInfo.urgency">
                        <span *ngSwitchCase="'high'"> 高 High </span>
                        <span *ngSwitchCase="'medium'"> 中 Medium </span>
                        <span *ngSwitchCase="'low'"> 低 Low </span>
                    </span>
                    <i nz-icon nzType="edit" nzTheme="outline" *ngIf="isEdit" (click)="editOptions.isEditUrgency = true"></i>
                </ng-template>
            </div>
        </div>
        <div class="pro-item">
            <h4 class="label">商品类型</h4>
            <div class="value">
                <span *ngIf="sampleRequestInfo.sample_type === 'advanced_customization'">高级定制</span>
                <span *ngIf="sampleRequestInfo.sample_type === 'print_on_demand'">POD</span>
                <span *ngIf="sampleRequestInfo.sample_type === 'new_product'">新商品</span>
            </div>
        </div>
    </div>
    <div class="pro-line">
        <div class="pro-item">
            <h4 class="label">Expected Arrival Date 要求到达日期（ETA）</h4>
            <div class="value">{{sampleRequestInfo.required_date | date: 'MM/dd/yyyy'}}</div>
        </div>
        <div class="pro-item">
            <h4 class="label">ETA (To Sample Center) 预计送达样品中心日期</h4>
            <div class="value">
                <ng-container *ngIf="editOptions.isEditRequiredDate; else viewEstimateDate">
                    <nz-date-picker [(ngModel)]="sampleRequestInfo.estimate_date" nzFormat="MM/dd/yyyy"></nz-date-picker>
                    <button nz-button nzType="text" [disabled]="!sampleRequestInfo.estimate_date" (click)="updateRequiredDate()">
                        <span style="color: #414bb2">Save</span>
                    </button>
                </ng-container>
                <ng-template #viewEstimateDate>
                    {{sampleRequestInfo.estimate_date | date: 'MM/dd/yyyy'}}
                    <i nz-icon nzType="edit" nzTheme="outline" *ngIf="isEdit" (click)="editOptions.isEditRequiredDate = true"></i>
                    <i
                        nz-icon
                        *ngIf="sampleRequestInfo.required_date_history?.length > 1"
                        [nzTooltipTitle]="RequiredDateRecord"
                        nzTooltipPlacement="top"
                        nz-tooltip
                        nzType="question-circle"
                        nzTheme="outline"
                        style="color: #414bb2"
                    ></i>
                    <ng-template #RequiredDateRecord>
                        <div style="margin-bottom: 5px" *ngFor="let item of sampleRequestInfo.required_date_history">
                            {{item.created_by}} at {{item.created_at | date: 'MM/dd/yyyy HH:mm'}} set as：{{item.required_date | date:
                            'MM/dd/yyyy'}}
                        </div>
                    </ng-template>
                </ng-template>
            </div>
        </div>
        <div class="pro-item">
            <h4 class="label">ETA (Final) 履约送达日期</h4>
            <div class="value">{{sampleRequestInfo.logistics_deliver_eta | date: 'MM/dd/yyyy'}}</div>
        </div>
    </div>
    <div class="pro-line">
        <div class="pro-item">
            <h4 class="label">Requested Date 请求日期</h4>
            <div class="value">{{sampleRequestInfo.created_at | date: 'MM/dd/yyyy'}}</div>
        </div>
        <div class="pro-item">
            <h4 class="label">Requester 请求人</h4>
            <div class="value">{{sampleRequestInfo.requestor}}</div>
        </div>
        <div class="pro-item">
            <h4 class="label">Check Requirements 验货需求</h4>
            <div class="value">
                <ng-container *ngIf="editOptions.isEditCheckRequirements; else viewCheckRequirements">
                    <nz-checkbox-group [(ngModel)]="editCheckRequirementOptions" (ngModelChange)="checkboxChange()"></nz-checkbox-group>
                    <button
                        nz-button
                        nzType="text"
                        (click)="updateSample('isEditCheckRequirements', ['qc_required', 'live_demo_requried', 'photo_shoot_required'])"
                    >
                        <span style="color: #414bb2">Save</span>
                    </button>
                </ng-container>
                <ng-template #viewCheckRequirements>
                    <div style="display: inline-block">
                        <div *ngIf="sampleRequestInfo.qc_required">QC & QC Image</div>
                        <div *ngIf="sampleRequestInfo.live_demo_requried">直播验货</div>
                        <div *ngIf="sampleRequestInfo.photo_shoot_required">国内拍摄上线内容</div>
                    </div>
                    <i nz-icon nzType="edit" nzTheme="outline" *ngIf="isEdit" (click)="editOptions.isEditCheckRequirements = true"></i>
                </ng-template>
            </div>
        </div>
    </div>
    <div class="pro-line">
        <div class="pro-item">
            <h4 class="label">Final Destination 最终目的地</h4>
            <div class="value">
                <ng-container *ngIf="editOptions.isEditAddress; else viewAddress">
                    <nz-select [(ngModel)]="sampleRequestInfo.address" [nzPlaceHolder]="'Destination Address'">
                        <nz-option *ngFor="let address of addressLists" [nzValue]="address.id" [nzLabel]="address.name"></nz-option>
                    </nz-select>
                    <button
                        nz-button
                        nzType="text"
                        [disabled]="!sampleRequestInfo.address"
                        (click)="updateSample('isEditAddress', ['address'])"
                    >
                        <span style="color: #414bb2">Save</span>
                    </button>
                    <button nz-button nzType="text" (click)="showAddressDetails('add')">
                        <span style="color: #414bb2">Add Address</span>
                    </button>
                </ng-container>
                <ng-template #viewAddress>
                    <span class="link-text" (click)="showAddressDetails('view')">Details</span>
                    <i nz-icon nzType="edit" nzTheme="outline" *ngIf="isEdit" (click)="editOptions.isEditAddress = true"></i>
                </ng-template>
            </div>
        </div>
        <div class="pro-item">
            <h4 class="label">Current Progress 当前进度</h4>
            <div class="value">{{sampleRequestStatusText.get(sampleRequestInfo.sample_status) | translate}}</div>
        </div>
    </div>
    <div class="pro-line">
        <div class="pro-item" style="width: 100%">
            <h4 class="label">
                <span>QC Images</span>
                <span
                    *ngIf="sampleRequestInfo.approved_at"
                    style="padding: 2px 6px; border-radius: 18px; background-color: #28b370; color: #fff; margin-left: 5px"
                >
                    网红已确认
                </span>
            </h4>
            <div class="value">
                <div *ngIf="sampleRequestInfo?.qc_result?.images?.length" class="product-img-box">
                    <div class="product-img" *ngFor="let file of sampleRequestInfo.qc_result.images; let i = index">
                        <i
                            nz-icon
                            [nzType]="file.media_type === 'video' ? 'caret-right' : 'expand-alt'"
                            nzTheme="outline"
                            class="expand-icon"
                            (click)="previewImgMode(i, sampleRequestInfo.qc_result.images)"
                            style="margin: 0"
                        ></i>
                        <video
                            *ngIf="file.media_type === 'video'; else imageView"
                            class="draggable-box"
                            [src]="file.src"
                            width="100%"
                            height="100%"
                            (click)="previewImgMode(i, sampleRequestInfo.qc_result.images)"
                        ></video>
                        <ng-template #imageView>
                            <img
                                class="draggable-box"
                                [src]="file.src"
                                style="width: 100%; height: 100%; object-fit: cover"
                                (click)="previewImgMode(i, sampleRequestInfo.qc_result.images)"
                            />
                        </ng-template>
                    </div>
                </div>
                <div *ngIf="sampleRequestInfo?.qc_result?.image_link">
                    Download link:
                    <a [href]="sampleRequestInfo.qc_result.image_link" target="_blank">{{sampleRequestInfo.qc_result.image_link}}</a>
                </div>
                <button
                    *ngIf="sampleRequestInfo?.qc_result?.images?.length || sampleRequestInfo?.qc_result?.image_link"
                    nz-button
                    nzType="primary"
                    style="margin: 15px 0 0; width: 120px"
                    [nzLoading]="downloading"
                    (click)="downloadQcImages()"
                >
                    Download All
                </button>
            </div>
        </div>
    </div>
    <div class="pro-line">
        <div class="instruction-box">
            <app-instruction-notes
                [sampleRequestInfo]="sampleRequestInfo"
                [isEdit]="isEdit"
                (updateNote)="updateNote($event)"
            ></app-instruction-notes>
        </div>
        <div class="custom-info-box">
            <div class="title">Custom Info 定制信息</div>
            <div class="custom-info-list">
                <p class="link-text" *ngFor="let item of customInfo" (click)="showCustomInfoDetail(item)">
                    Details——{{item.created_at | date: 'MM/dd/yyyy HH:mm'}}
                </p>
            </div>
        </div>
    </div>
    <div style="padding: 15px 0 0 15px">
        <button nz-button nzType="primary" style="width: 108px" (click)="close()">关闭</button>
    </div>
</div>

<nz-modal
    [(nzVisible)]="addressDetailsModalVisible"
    (nzOnCancel)="addressDetailsModalVisible = false"
    [nzFooter]="null"
    [nzTitle]="modalTitle1"
    nzWidth="500px"
>
    <ng-template #modalTitle1>
        <span style="color: #0d053c; font-weight: bold; font-size: 20px">Address Details</span>
    </ng-template>
    <app-address-modal
        [type]="addressModalType"
        [addressDetails]="addressDetails"
        (updateAddressSuccess)="addAddressConfirm($event)"
        (closeModal)="addressDetailsModalVisible = false"
    ></app-address-modal>
</nz-modal>

<nz-modal
    [(nzVisible)]="customInfoModalVisible"
    [nzWidth]="780"
    [nzFooter]="null"
    (nzOnCancel)="customInfoModalVisible = false;selectedCustom = null;"
    [nzClosable]="false"
    [nzClassName]="'custom-info-modal'"
>
    <app-custom-info [inputInfo]="selectedCustom" (closeModal)="customInfoModalVisible = false;selectedCustom = null;"></app-custom-info>
</nz-modal>
