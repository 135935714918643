import { Component, OnInit } from '@angular/core';
import { WarehouseService } from '@services/warehouse.service';
import { NotificationService, AlertType } from '@services/notification.service';
import { FirebaseFileUploadService } from '@services/firebase-file-upload.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserPermissionService } from '@services/user-permission.service';
import { DESTINATION_CHINA_WAREHOUSE } from '@shared/const/order-dispatch.const';

@Component({
    selector: 'app-shipping-box-list',
    templateUrl: './shipping-box-list.component.html',
    styleUrls: ['./shipping-box-list.component.less'],
})
export class ShippingBoxListComponent implements OnInit {
    destinationAddressOptions = DESTINATION_CHINA_WAREHOUSE.map(item => ({ value: item, label: item }));
    isPendingLength = 0;
    shippingBoxList = [];
    defaultShippingBoxList = [];
    fetchingShippingBoxList = false;
    shippingScheduleModalVisible = false;
    saveBtnDisabled = true;
    isConfirmLoading = false;
    logo_url = '';
    dropDownValue = '';
    filePath = '';
    selectRow: any;
    fileName = '';
    snapshot: Observable<any>;

    constructor(
        private warehouseService: WarehouseService,
        public permissionService: UserPermissionService,
        private notificationService: NotificationService,
        private firebaseUploadService: FirebaseFileUploadService
    ) {}

    ngOnInit() {
        this.shippingSchedule();
    }

    shippingSchedule() {
        this.fetchingShippingBoxList = true;
        this.warehouseService
            .getShippingSchedule(this.dropDownValue)
            .then(res => {
                this.shippingBoxList = res;
                this.defaultShippingBoxList = res;
            })
            .catch(err => {
                this.notificationService.addMessage({
                    type: AlertType.Error,
                    title: 'Get new records failed, please try again.',
                    message: err,
                    duration: 5000,
                });
            })
            .finally(() => (this.fetchingShippingBoxList = false));
    }

    get fileUploaded() {
        return this.filePath.includes('.xlsx');
    }

    changeInbound(key) {
        this.dropDownValue = key;
        this.shippingSchedule();
    }

    removeInvoice() {
        this.filePath = '';
        this.logo_url = '';
        this.saveBtnDisabled = true;
    }

    onDrop(fileList: FileList) {
        this.fileName = fileList[0].name;
        this.firebaseUploadService.startUpload(fileList[0]).then(({ src, path }) => {
            this.filePath = src;
            this.saveBtnDisabled = false;
        });
    }

    uploadScheduleFile(path) {
        if (path === '') {
            this.notificationService.addMessage({
                type: AlertType.Error,
                title: 'Error',
                message: 'shipping file path cannot be empty.',
                duration: 5000,
            });
            return false;
        }
        const data = {
            id: this.selectRow.id,
            shipping_file_path: path,
        };

        this.warehouseService
            .putScheduleFile(data)
            .then(res => {
                this.shippingSchedule();
                this.notificationService.addMessage({
                    title: '上传成功！',
                    type: AlertType.Success,
                    message: '发货装箱表上传成功',
                });
            })
            .catch(err => {
                this.notificationService.addMessage({
                    type: AlertType.Error,
                    title: '上传失败！',
                    message: err,
                    duration: 5000,
                });
            });
    }

    uploadFile(row) {
        this.saveBtnDisabled = true;
        this.filePath = '';
        this.selectRow = row;
        this.shippingScheduleModalVisible = true;
    }

    handleOk() {
        this.uploadScheduleFile(this.filePath);
        this.shippingScheduleModalVisible = false;
    }

    handleCancel() {
        this.shippingScheduleModalVisible = false;
    }

    searchResultList(value) {
        this.shippingBoxList = value;
    }

    resetResultList() {
        this.shippingBoxList = [...this.defaultShippingBoxList];
    }
}
