import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { OrderDispatchService } from '@services/order-dispatch.service';
import { NotificationService, AlertType } from '@services/notification.service';
import * as dayjs from 'dayjs';

@Component({
    selector: 'app-mass-ordering-statistics',
    templateUrl: './mass-ordering-statistics.component.html',
    styleUrls: ['./mass-ordering-statistics.component.less'],
})
export class MassOrderingStatisticsComponent implements OnInit {
    @Output() childOuter = new EventEmitter();
    pendingCounts = 0;
    tableHeader = [];
    fetchingMassOrderingList = true;
    massOrderingList: any[];
    defaultOrderingList: any[] = [];
    isUnComfirm = true;
    isUnComfirmLength: number;
    restockRequestList: any[];
    productPurchaseList: any[];

    constructor(private orderDispatchService: OrderDispatchService, private notificationService: NotificationService) {}

    ngOnInit() {}

    getMassOrdering(data) {
        this.getDays(6);
        this.calculationArrival(data);
        this.calculationCounts(data);
    }

    getDays(day_count) {
        const dateNow = new Date();
        const dates = [];
        for (let i = 0; i <= day_count; i++) {
            const d = dayjs()
                .set('date', dateNow.getDate() + i)
                .format('M/DD');
            const w = dayjs()
                .set('date', dateNow.getDate() + i)
                .format('ddd');
            dates.push({
                date: d,
                week: w,
                counts: 0,
            });
        }
        this.tableHeader = dates;
    }

    goDistance(): void {
        this.childOuter.emit();
    }

    calculationCounts(val) {
        let nums = 0;
        for (const item of val) {
            if (item.latest_shipping_status?.status === 'delivered' && !item.product_received) {
                nums += item.quantity;
            }
        }
        this.pendingCounts = nums;
    }

    calculationArrival(val) {
        for (const item of val) {
            const get_date = dayjs(item.deliver_eta).format('M/DD');
            for (const pending of this.tableHeader) {
                if (!item.product_received && pending.date === get_date) {
                    pending.counts += item.shipped_quantity;
                }
            }
        }
    }

    selectStatus(key) {
        const unComplete = [];
        const isComplete = [];
        for (const item of this.defaultOrderingList) {
            if (item.product_received) {
                isComplete.push(item);
            } else {
                unComplete.push(item);
            }
        }
        if (key === 'isUnComfirm') {
            this.isUnComfirm = true;
            this.isUnComfirmLength = unComplete.length;
            this.massOrderingList = [...unComplete];
        } else {
            this.isUnComfirm = false;
            this.massOrderingList = [...isComplete];
        }
    }
}
