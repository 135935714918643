import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TokenService } from '@services/token.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    constructor(private tokenService: TokenService, private router: Router) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        // eslint-disable-next-line @typescript-eslint/dot-notation
        const isGoUnAuthorizedPage = state.url === '/sign-in';

        if (isGoUnAuthorizedPage) {
            if (!this.tokenService.tokenValid) {
                return true;
            }

            this.router.navigateByUrl('/dashboard');
            return false;
        }

        if (!isGoUnAuthorizedPage) {
            if (!this.tokenService.tokenValid) {
                this.tokenService.removeAllToken();
                this.router.navigateByUrl('/sign-in');
                return false;
            }

            return true;
        }
    }
}
