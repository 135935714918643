import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { OrderDispatchService } from '@services/order-dispatch.service';
import { NotificationService, AlertType } from '@services/notification.service';
import { isNumber } from 'lodash';
import { ProductPurchaseItem } from '@shared/type/order-dispatch.type';
import { UserPermissionService } from '@services/user-permission.service';
import { CARRIER_LIST, CN_CARRIER_LIST, DESTINATION_CHINA_WAREHOUSE } from '@shared/const/order-dispatch.const';
import { ModalControl } from '@shared/models/ModalControl';
import { MassOrderService } from '@pages/logistics-management/mass-ordering/mass-order.service';
import { ImagePreviewFullscreenService } from '@services/image-preview-fullscreen.service';
import { PRODUCT_INFO_LIST } from '@src/app/pages/purchase-management/product-purchase/product-purchase.data';
import { NzModalService } from 'ng-zorro-antd/modal';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
import * as dayjs from 'dayjs';

@Component({
    selector: 'app-product-purchase-record-table',
    templateUrl: './product-purchase-record-table.component.html',
    styleUrls: ['./product-purchase-record-table.component.less'],
})
export class ProductPurchaseRecordTableComponent implements OnInit, OnChanges {
    @Input() columnList;
    @Input() productPurchase: ProductPurchaseItem[];
    @Input() fetchingProductPurchaseList: boolean;
    @Input() batchId?: number;
    @Output() updateSuccess = new EventEmitter();
    @Output() changeDate = new EventEmitter();

    CARRIER_LIST = CARRIER_LIST;
    DESTINATION_CHINA_WAREHOUSE = DESTINATION_CHINA_WAREHOUSE;
    CN_CARRIER_LIST = CN_CARRIER_LIST;

    isNumber = isNumber;

    filterList = [];

    defaultProductList: ProductPurchaseItem[] = [];
    productPurchaseList: ProductPurchaseItem[] = [];
    productPurchaseListUnComplete: ProductPurchaseItem[] = [];
    productPurchaseListIsComplete: ProductPurchaseItem[] = [];
    productPurchaseListIsErrors: ProductPurchaseItem[] = [];
    dateFormat = 'MM/dd/yyyy';
    dateTimes = [];

    onChangeTitle = 'unConfirm';
    unConfirmLength: number;
    isExporting = false;

    selectedProductPurchase = {
        id: null,
        purchased_quantity: 0,
        quantity: 0,
        arrived_quantity: 0,
        matchedDataInTable: null,
        carrier: null,
        tracking_number: null,
        logistics: [],
        purchase_complete: false,
        note: '',
    };

    versionProductList = {
        product: '产品错误 Incorrect Product',
        color: '颜色错误 Incorrect Color',
        style: '款式错误 Incorrect style',
        size: '尺码错误 Incorrect Size',
        others: '其他 Others',
        dirty: '脏污 Dirty',
        damaged: '破损 Damaged',
        defect: '质量瑕疵 Quality Defect',
        expired: '过期 Expired',
    };

    acceptProductModal = new ModalControl(() => !isNumber(this.selectedProductPurchase?.arrived_quantity));

    editProductModal = new ModalControl();

    productDetailsVisible = false;
    productInfoList = PRODUCT_INFO_LIST;

    allChecked = false;
    indeterminate = false;
    setOfCheckedId = new Set<number>();
    selectedProductPurchaseList: any[];
    transportModalControl = new ModalControl();
    transportParams = {
        destination_address: null,
        logistics: [
            {
                carrier: null,
                tracking_number: null,
            },
        ],
    };

    get isDisabledTransportBtn() {
        return (
            !this.transportParams.destination_address ||
            !this.transportParams.logistics[0].carrier ||
            !this.transportParams.logistics[0].tracking_number
        );
    }

    get isShowConfirmNote() {
        return !(
            this.selectedProductPurchase.purchase_complete &&
            this.selectedProductPurchase.arrived_quantity < this.selectedProductPurchase.purchased_quantity
        );
    }

    constructor(
        private orderDispatchService: OrderDispatchService,
        private notificationService: NotificationService,
        private massOrderService: MassOrderService,
        private modalService: NzModalService,
        private imagePreviewFullscreen: ImagePreviewFullscreenService,
        public permissionService: UserPermissionService
    ) {}

    ngOnInit(): void {}

    ngOnChanges(e: SimpleChanges) {
        if (e.productPurchase?.currentValue) {
            this.defaultProductList = e.productPurchase.currentValue;
            for (const item of this.defaultProductList) {
                item.kitVisible = false;
            }
            this.selectStatus(this.onChangeTitle);
        }
    }

    showModal(purchasedProduct, type: 'accept_purchase' | 'edit_record' | 'product_detail', e) {
        const { quantity, arrived_quantity, id, purchased_quantity, carrier, tracking_number, logistics, purchase_complete, note } =
            purchasedProduct;
        this.selectedProductPurchase = {
            id,
            quantity,
            arrived_quantity,
            purchased_quantity,
            carrier,
            tracking_number,
            logistics: logistics?.filter(item => item.carrier && item.tracking_number),
            matchedDataInTable: purchasedProduct,
            purchase_complete,
            note,
        };

        if (type === 'accept_purchase') {
            const info = this.selectedProductPurchase.matchedDataInTable;
            if (
                !info ||
                !info.unit_cost ||
                !info.purchase_platform ||
                !info.purchase_date ||
                !info.destination_address ||
                !info.purchase_order_id ||
                !info.order_content
            ) {
                this.modalService.confirm({
                    nzTitle: '采购信息不完整',
                    nzContent: '采购信息不完整，请补充完整再确认收货',
                    nzOnOk: () => this.editProductModal.showModal(),
                });
            } else {
                this.acceptProductModal.showModal();
            }
        }

        if (type === 'edit_record') {
            this.editProductModal.showModal();
        }

        if (type === 'product_detail') {
            this.productDetailsVisible = true;
        }

        e.stopPropagation();
    }

    searchResultList(value) {
        this.productPurchaseList = value;
    }

    resetResultList() {
        this.productPurchaseList = [...this.getTitleList(this.onChangeTitle)];
    }

    acceptProductPurchase() {
        this.acceptProductModal.showOkBtnLoading();
        const { id, arrived_quantity, purchase_complete, note } = this.selectedProductPurchase;
        this.orderDispatchService
            .editPurchaseProductInfo(this.onChangeTitle === 'isErrors' ? { id, purchase_complete, note } : { id, arrived_quantity })
            .then(res => {
                this.acceptProductModal.hideModal();
                this.acceptProductModal.hideOkBtnLoading();
                this.selectedProductPurchase.matchedDataInTable.in_stock_quantity +=
                    arrived_quantity - this.selectedProductPurchase.matchedDataInTable.arrived_quantity;
                this.selectedProductPurchase.matchedDataInTable.arrived_quantity = arrived_quantity;
                this.updateSuccess.emit();
            })
            .catch(() => {
                this.acceptProductModal.hideOkBtnLoading();
                this.notificationService.addMessage({
                    type: AlertType.Error,
                    title: 'Error',
                    message: 'Edit quantity failed, please try again later',
                });
            });
    }

    editProductPurchase(purchaseInfo) {
        this.editProductModal.showOkBtnLoading();
        this.orderDispatchService
            .editPurchaseProductInfo(purchaseInfo)
            .then(() => {
                const index = this.productPurchaseList.findIndex(item => item.id === purchaseInfo.id);
                this.productPurchaseList[index] = purchaseInfo;
                this.productPurchaseList = this.productPurchaseList.slice();
                const ind = this.defaultProductList.findIndex(item => item.id === purchaseInfo.id);
                this.defaultProductList[ind] = purchaseInfo;
                this.defaultProductList = this.defaultProductList.slice();
                this.editProductModal.hideModal();
                this.editProductModal.hideOkBtnLoading();
            })
            .catch(() => {
                this.editProductModal.hideOkBtnLoading();
                this.notificationService.showGeneralErrorMessage();
            });
    }

    str(item) {
        return String(item || (isNumber(item) ? '0' : ''));
    }

    errorException(list) {
        let str = '';
        for (const item of list) {
            str += '<p>';
            for (const child of item.fail_reason) {
                if (item) {
                    str += `${this.versionProductList[child]} `;
                }
            }
            str += `:${item.quantity}</p>`;
        }
        return str;
    }

    selectStatus(key) {
        const unComplete = [];
        const isComplete = [];
        const isErrors = [];
        this.onChangeTitle = key;
        for (const item of this.defaultProductList) {
            if (item.arrived_quantity >= item.purchased_quantity || item.purchase_complete) {
                isComplete.push(item);
            } else {
                if (item.exceptions.length > 0) {
                    isErrors.push(item);
                } else {
                    unComplete.push(item);
                }
            }
        }
        if (key === 'unConfirm') {
            this.productPurchaseList = [...unComplete];
            this.productPurchaseListUnComplete = [...unComplete];
            this.unConfirmLength = this.productPurchaseList.length;
            if (this.batchId) {
                this.onChangeDateTimes(this.productPurchaseListUnComplete);
            }
        } else if (key === 'isConfirm') {
            this.productPurchaseList = [...isComplete];
            this.productPurchaseListIsComplete = [...isComplete];
            if (this.batchId) {
                this.onChangeDateTimes(this.productPurchaseListIsComplete);
            }
        } else if (key === 'isErrors') {
            this.productPurchaseList = [...isErrors];
            this.productPurchaseListIsErrors = [...isErrors];
            if (this.batchId) {
                this.onChangeDateTimes(this.productPurchaseListIsErrors);
            }
        }
    }

    onChangeDateTimes(arr) {
        if (this.batchId) {
            const startDate = dayjs(this.dateTimes[0]).format('YYYY-MM-DD');
            const endDate = dayjs(this.dateTimes[1]).format('YYYY-MM-DD');
            const filteredList = arr.filter(item => {
                if (this.dateTimes.length > 0) {
                    return (
                        dayjs(item.created_at).isSameOrBefore(dayjs(endDate), 'day') &&
                        dayjs(item.created_at).isSameOrAfter(dayjs(startDate), 'day')
                    );
                } else {
                    return item;
                }
            });
            if (this.onChangeTitle === 'unConfirm') {
                this.unConfirmLength = filteredList.length;
            }
            this.productPurchaseList = filteredList;
        } else {
            const start = this.dateTimes[0]
                ? dayjs(this.dateTimes[0]).format('YYYY-MM-DD')
                : dayjs().subtract(3, 'month').format('YYYY-MM-DD');
            const end = dayjs(this.dateTimes[1]).add(1, 'day').format('YYYY-MM-DD');
            this.changeDate.emit({
                start,
                end,
            });
        }
    }

    getTitleList(name) {
        if (name === 'unConfirm') {
            return this.productPurchaseListUnComplete;
        } else if (name === 'isConfirm') {
            return this.productPurchaseListIsComplete;
        } else if (name === 'isErrors') {
            return this.productPurchaseListIsErrors;
        }
    }

    getUrlBase64(url, ext) {
        return new Promise((resolve, reject) => {
            let canvas = document.createElement('canvas'); // 创建canvas DOM元素
            const ctx = canvas.getContext('2d');
            const img = new Image();
            img.crossOrigin = 'Anonymous';
            img.onload = () => {
                canvas.height = 60; // 指定画板的高度,自定义
                canvas.width = 60; // 指定画板的宽度，自定义
                ctx.drawImage(img, 0, 0, 60, 60); // 参数可自定义
                const dataURL = canvas.toDataURL(`image/${ext}`);
                resolve(dataURL); // 回掉函数获取Base64编码
                canvas = null;
            };
            img.onerror = reject;
            img.src = url;
        }).catch(error => {
            console.log(error);
        });
    }

    async export() {
        this.isExporting = true;
        for (const purchase of this.productPurchaseList) {
            if (!purchase.img_base) {
                const base64 = await this.getUrlBase64(purchase.variant_image, 'jpeg');
                purchase.img_base = base64;
            }
        }
        this.massOrderService.exportProductPurchaseExcel(this.productPurchaseList);
        this.isExporting = false;
    }

    isEditDisable(purchaseInfo) {
        return (
            !purchaseInfo ||
            !purchaseInfo.unit_cost ||
            !purchaseInfo.purchase_date ||
            !purchaseInfo.purchase_platform ||
            !purchaseInfo.destination_address ||
            !purchaseInfo.purchase_order_id ||
            !isNumber(purchaseInfo.qc_ratio)
        );
    }

    updateCheckedSet(id: number, checked: boolean): void {
        if (checked) {
            this.setOfCheckedId.add(id);
        } else {
            this.setOfCheckedId.delete(id);
        }
    }

    onAllChecked(checked: boolean): void {
        this.productPurchaseList.forEach(item => this.updateCheckedSet(item.id, checked));
        this.refreshCheckedStatus();
    }

    onItemChecked(id: number, checked: boolean): void {
        this.updateCheckedSet(id, checked);
        this.refreshCheckedStatus();
    }

    refreshCheckedStatus(): void {
        this.allChecked = this.productPurchaseList.every(item => this.setOfCheckedId.has(item.id));
        this.indeterminate = this.productPurchaseList.some(item => this.setOfCheckedId.has(item.id)) && !this.allChecked;
    }

    showTransportModal() {
        this.selectedProductPurchaseList = this.productPurchaseList.filter(item => this.setOfCheckedId.has(item.id));
        this.transportModalControl.showModal();
    }

    toTransport() {
        this.transportModalControl.showOkBtnLoading();
        const promises = this.selectedProductPurchaseList
            .map(item => item.id)
            .map(item => {
                const purchaseInfo = {
                    id: item,
                    ...this.transportParams,
                };
                return this.orderDispatchService.editPurchaseProductInfo(purchaseInfo);
            });
        Promise.allSettled(promises).then(res => {
            const rejectedIndexArr = [];
            const fulfilledIndexArr = [];
            res.forEach((item, i) => {
                if (item.status === 'rejected') {
                    rejectedIndexArr.push(i);
                } else {
                    fulfilledIndexArr.push(i);
                }
            });

            this.transportModalControl.hideOkBtnLoading();
            this.transportModalControl.hideModal();

            if (fulfilledIndexArr.length > 0) {
                this.notificationService.addMessage({
                    type: AlertType.Success,
                    title: `${fulfilledIndexArr.length} records Success`,
                    message: 'success',
                    duration: 5000,
                });
                this.setOfCheckedId.clear();
                this.updateSuccess.emit();
                this.transportParams.destination_address = null;
                this.transportParams.logistics[0].carrier = null;
                this.transportParams.logistics[0].tracking_number = null;
            }

            if (rejectedIndexArr.length > 0) {
                this.notificationService.addMessage({
                    type: AlertType.Error,
                    title: `${rejectedIndexArr.length} records Failed`,
                    message: `${rejectedIndexArr.length} records failed, please try again.`,
                    duration: 5000,
                });
            }
        });
    }

    previewImgMode(index: number, imageList: any[], e) {
        this.imagePreviewFullscreen.showModal(imageList, index);
        e.stopPropagation();
    }
}
