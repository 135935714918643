import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '@services/auth.service';
import { AlertType, NotificationService } from '@services/notification.service';
import { TokenService } from '@services/token.service';

import { AngularFireAuth } from '@angular/fire/auth';
import firebaseApp from 'firebase/app';

@Component({
    selector: 'app-sign-in',
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.less'],
})
export class SignInComponent implements OnInit {
    email: string;
    password: string;

    signing: boolean;

    get formValid() {
        return this.email?.trim() && this.password?.trim();
    }

    constructor(
        private authService: AuthService,
        private router: Router,
        private notificationService: NotificationService,
        private fireAuth: AngularFireAuth,
        private tokenService: TokenService
    ) {}

    ngOnInit(): void {}

    signIn() {
        if (!this.formValid) {
            return;
        }

        this.signing = true;
        this.authService
            .signIn({ email: this.email, password: this.password })
            .then(() => this.router.navigateByUrl('/dashboard'))
            .catch((err: HttpErrorResponse) => this.notificationService.showGeneralErrorMessage({ message: err.error.message }))
            .finally(() => (this.signing = false));
    }

    googleLogin() {
        this.fireAuth
            .signInWithPopup(new firebaseApp.auth.GoogleAuthProvider())
            .then(async result => {
                if (result.user.email.indexOf('@lifo.ai') >= 0) {
                    const idToken = await result.user.getIdToken();
                    const refreshToken = result.user.refreshToken;
                    this.tokenService.setToken(idToken);
                    this.tokenService.setRefreshToken(refreshToken);
                    this.router.navigateByUrl('/dashboard');
                } else {
                    this.fireAuth.signOut();
                    this.notificationService.showGeneralErrorMessage({
                        title: 'Not Internal Account',
                        message: 'Please use @lifo.ai email account',
                    });
                }
            })
            .catch(() => this.notificationService.showGeneralErrorMessage());
    }
}
