<div class="instruction-container">
    <div class="title">Instruction Notes 说明信息</div>
    <div class="message-list">
        <div class="message-item" *ngFor="let item of messageRecord">
            <h5 class="name">{{item.created_by}}</h5>
            <p class="time">{{item.created_at | date: 'MM/dd/yyyy HH:mm'}}</p>
            <p class="content">{{item.note}}</p>
        </div>
    </div>
    <div class="message-board" *ngIf="isEdit">
        <textarea nz-input nzBorderless placeholder="请输入留言" [(ngModel)]="instruction"></textarea>
        <button nz-button nzType="primary" [nzLoading]="submitMessageLoading" (click)="submitMessage()">留言</button>
    </div>
</div>
