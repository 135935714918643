import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '@services/auth.service';
import { UserPermissionService } from '@src/app/services/user-permission.service';

@Component({
    selector: 'app-submenu',
    templateUrl: './submenu.component.html',
    styleUrls: ['./submenu.component.less'],
})
export class SubmenuComponent implements OnInit {
    isCollapsed: boolean;

    constructor(private auth: AuthService, private router: Router, public permissionService: UserPermissionService) {}

    ngOnInit(): void {}

    signOut() {
        this.auth.signOut().then(() => this.router.navigateByUrl('/sign-in'));
    }
}
