import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-code-editor',
    templateUrl: './code-editor.component.html',
    styleUrls: ['./code-editor.component.less'],
})
export class CodeEditorComponent implements OnInit {
    @Input() language?: string;

    code: string;

    editorOptions = { theme: 'vs-light', language: 'html' };

    constructor() {}

    ngOnInit(): void {
        this.editorOptions.language = this.language || 'html';
    }
}
