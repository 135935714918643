import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class TokenService {
    idToken: string;
    refreshToken: string;

    get tokenValid() {
        return !!(this.getRefreshToken() || this.getToken());
    }

    constructor() {}

    getToken() {
        return this.idToken || localStorage.getItem('idToken');
    }

    getRefreshToken() {
        return this.refreshToken || localStorage.getItem('refreshToken');
    }

    setToken(token: string) {
        this.idToken = token;
        localStorage.setItem('idToken', token);
    }
    setRefreshToken(refreshToken: string) {
        this.refreshToken = refreshToken;
        localStorage.setItem('refreshToken', refreshToken);
    }

    removeAllToken() {
        this.refreshToken = null;
        this.idToken = null;
        localStorage.removeItem('idToken');
        localStorage.removeItem('refreshToken');
    }
}
