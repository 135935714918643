import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { OrderDispatchService } from '@services/order-dispatch.service';

@Component({
    selector: 'app-label-common',
    templateUrl: './label-common.component.html',
    styleUrls: ['./label-common.component.less'],
})
export class LabelCommonComponent implements OnInit {
    @ViewChild('inputElement', { static: false }) inputElement?: ElementRef;
    @Input() labelType;
    @Input() typeLevel = 'two';
    popVisible = false;
    inputCategoryInputVisible = false;
    inputCategoryValue = '';
    rightSelectValue = [];
    labelList = [];

    constructor(private orderDispatchService: OrderDispatchService) {}

    ngOnInit() {}

    isShowDefault() {
        this.labelList = this.calChildren(this.labelType);
    }

    isMode(arr) {
        return arr.some(item => item.checked === true);
    }

    onTagClose(label) {
        label.checked = false;
    }

    sliceTagName(tag: string): string {
        const isLongTag = tag.length > 20;
        return isLongTag ? `${tag.slice(0, 20)}...` : tag;
    }

    isSelectedFun(label, dataList) {
        for (const item of dataList) {
            item.selected = false;
            if (item.id === dataList[label].id) {
                item.selected = !item.selected;
                item.checked = true;
            }
        }
    }

    showInput(visible): void {
        this[visible] = true;
        setTimeout(() => {
            this.inputElement?.nativeElement.focus();
        }, 10);
    }

    log(data) {
        if (data.children) {
            data.children.forEach(item => {
                item.checked = !!data.checked;
                this.log(item);
            });
        }
    }

    handleInputConfirm() {
        // 正则匹配a-z0-9_Please enter a-z0-9_
        const noSpace = this.inputCategoryValue.toLowerCase().replace(/\s+/g, '');
        const res = /^[a-z0-9_]+$/g;
        if (res.test(noSpace)) {
            this.inputAddValue(noSpace);
        }
    }

    inputAddValue(newel: string) {
        for (const parentItem of this.labelList) {
            if (parentItem.selected) {
                const isNoName = parentItem.children.every(item => item.name !== newel);
                if (isNoName) {
                    this.orderDispatchService
                        .addLabelItem({ name: this.inputCategoryValue, parent: parentItem.id, type: parentItem.type })
                        .then(result => {
                            result.checked = true;
                            parentItem.children.push(result);
                        });
                }
            }
        }
        this.inputCategoryValue = '';
        this.inputCategoryInputVisible = false;
    }

    calChildren(arr) {
        const filterParent = arr.filter(x => x.parent === null);
        const filterChild = arr.filter(x => x.parent !== null);
        for (const k of filterParent) {
            k.children = [];
            if (this.typeLevel === 'three') {
                const twoChild = filterChild.filter(x => x.parent === k.id);
                const threeChild = filterChild.filter(x => x.parent !== k.id);
                for (const j of twoChild) {
                    j.children = [];
                    k.children.push(j);
                    threeChild
                        .filter(threeChildItem => threeChildItem.parent === j.id)
                        .forEach(threeChildItem => j.children.push(threeChildItem));
                }
            } else {
                filterChild
                    .filter(filterChildItem => filterChildItem.parent === k.id)
                    .forEach(filterChildItem => k.children.push(filterChildItem));
            }
        }
        return filterParent;
    }

    calLabelId() {
        const arrParam = [];
        for (const i of this.labelList) {
            if (i.checked) {
                arrParam.push(i.id);
                for (const k of i.children) {
                    if (k.checked) {
                        arrParam.push(k.id);
                        if (this.typeLevel === 'three') {
                            for (const v of k.children) {
                                if (v.checked) {
                                    arrParam.push(v.id);
                                }
                            }
                        }
                    }
                }
            }
        }
        return arrParam;
    }
}
