<div class="confirm-box">
    <p class="message">{{message}}</p>

    <label nz-checkbox [(ngModel)]="isChecked">Ignore inventory and push order to specific warehouse</label>

    <nz-select
        *ngIf="isChecked"
        [(ngModel)]="selectedWarehouseId"
        nzPlaceHolder="Choose Warehouse"
        nzAllowClear
        style="width: 100%; margin-top: 15px"
    >
        <nz-option *ngFor="let item of warehouseList" [nzValue]="item.warehouse_id" [nzLabel]="item.warehouse_name"></nz-option>
    </nz-select>

    <div class="btns">
        <button nz-button nzType="default" (click)="discard()" [disabled]="submitting">Cancel</button>
        <button nz-button nzType="primary" (click)="confirm()" [nzLoading]="submitting" [disabled]="isChecked && !selectedWarehouseId">
            Confirm
        </button>
    </div>
</div>
