import { Component, OnInit, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { WarehouseItem } from '@shared/type/order-dispatch.type';

@Component({
    selector: 'app-recreate-order-dialog',
    templateUrl: './recreate-order-dialog.component.html',
    styleUrls: ['./recreate-order-dialog.component.less'],
})
export class RecreateOrderDialogComponent implements OnInit {
    @Input() message = 'Are you sure you want to recreate these orders?';
    @Input() warehouseList: WarehouseItem[];

    submitting = false;
    isChecked = false;
    selectedWarehouseId;

    constructor(private modal: NzModalRef) {}

    ngOnInit(): void {}

    confirm() {
        this.modal.triggerOk();
    }

    discard() {
        this.modal.close(false);
    }

    destroy() {}
}
