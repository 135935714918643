import { Component, Input, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
    selector: 'app-dialog-before-leave',
    templateUrl: './general-dialog.component.html',
    styleUrls: ['./general-dialog.component.less'],
})
export class GeneralDialogComponent implements OnInit {
    @Input() message: string;
    @Input() bodyHtml: string;
    @Input() cancelText: string;
    @Input() confirmText: string;

    submitting = false;

    constructor(private modal: NzModalRef) {}

    ngOnInit(): void {}

    confirm() {
        this.modal.triggerOk();
    }

    discard() {
        this.modal.close(false);
    }

    destroy() {}
}
