import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { OrderDispatchService } from '@services/order-dispatch.service';

export interface Influencer {
    name: string;
    id: number;
    label_list: any;
}
@Component({
    selector: 'app-store-labeling',
    templateUrl: './store-labeling.component.html',
    styleUrls: ['./store-labeling.component.less'],
})
export class StoreLabelingComponent implements OnInit {
    @ViewChild('listLabel') listLabel;
    storeLabelList: Influencer = {
        name: '',
        id: null,
        label_list: {},
    };
    platform = 'instagram';
    isVisible = false;
    labelTagList = [];
    attributesArr = [];
    productArr = [];
    contentArr = [];
    isEdit = true;
    constructor(private orderDispatchService: OrderDispatchService) {}

    ngOnInit() {}

    getLabelList(id) {
        const attributes = [];
        const product = [];
        this.attributesArr = [];
        this.productArr = [];
        this.storeLabelList.label_list = {};
        this.orderDispatchService.getAllStoreLabel(id).then(
            result => {
                this.labelTagList = result;
                for (const item of this.labelTagList) {
                    if (item.type === 'attributes') {
                        attributes.push(item);
                    } else if (item.type === 'product') {
                        product.push(item);
                    }
                }
                this.attributesArr = this.calChildren(attributes);
                this.productArr = this.calChildren(product);

                this.storeLabelList.label_list = {
                    attributes: this.attributesArr,
                    product: this.productArr,
                };
            },
            err => {}
        );
    }

    getData(msg) {
        this.getLabelList(msg);
    }

    isShowListLabel(ids) {
        this.listLabel.listId = ids;
        this.listLabel.showModal();
    }

    calChildren(arr) {
        const filtParent = arr.filter(x => x.parent === null);
        const filtChild = arr.filter(x => x.parent !== null);
        for (const k of filtParent) {
            k.children = [];
            for (const j of filtChild) {
                j.children = [];
                const filtLastChild = filtChild.filter(x => x.id !== j.parent);
                if (j.parent === k.id) {
                    k.children.push(j);
                }
                for (const v of filtLastChild) {
                    if (v.parent === j.id) {
                        j.children.push(v);
                    }
                }
            }
        }
        return filtParent;
    }
}
