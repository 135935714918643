<div class="add-option-dialog">
    <header class="title">
        <h1>{{ showAddBtn ? 'Add' : 'Edit' }} Option</h1>
        <!-- <i nz-icon nzType="close" nzTheme="outline"></i> -->
    </header>

    <div class="requirements" *ngIf="!showAddBtn && productDetails">
        <textarea
            disabled="true"
            nz-input
            [(ngModel)]="productDetails.batch_purchase_requirement"
            [nzAutosize]="{ minRows: 5, maxRows: 10 }"
        ></textarea>
    </div>

    <!-- Options -->
    <div *ngFor="let optionItem of options; let i = index" class="option-item">
        <div class="option-desc">
            <span class="left">Option {{ i + 1 }}</span>
            <span *ngIf="showAddBtn && i > 0" class="remove" (click)="removeOption(i)">Remove</span>
        </div>

        <div class="option-inputs">
            <div class="option-name">
                <input
                    [(ngModel)]="optionItem.name"
                    nzSize="large"
                    [disabled]="!showAddBtn"
                    nz-input
                    placeholder="Option Name"
                    (focus)="resetFilteredOptionNames()"
                    (blur)="onOptionNameBlur(optionItem)"
                    (ngModelChange)="onOptionNameChange($event)"
                    [nzAutocomplete]="auto"
                />
                <nz-autocomplete #auto>
                    <nz-auto-option *ngFor="let option of filteredOptionNames$ | async" [nzLabel]="option" [nzValue]="option">
                        {{ option }}
                    </nz-auto-option>
                </nz-autocomplete>
            </div>
            <nz-select
                class="option-value"
                nzMode="tags"
                nzSize="large"
                nzPlaceHolder="Separate options with a comma"
                [nzTokenSeparators]="[',', '，']"
                [(ngModel)]="optionItem.values"
                (nzBlur)="onOptionValueBlur(optionItem)"
            >
                <nz-option
                    *ngFor="let option of optionItem.values"
                    [nzLabel]="option"
                    [nzValue]="option"
                    [nzDisabled]="optionsInDBMap?.get(optionItem.name)?.get(option)"
                ></nz-option>
            </nz-select>
        </div>
    </div>

    <nz-divider></nz-divider>

    <!-- Add more -->
    <div *ngIf="options?.length < 3 && this.showAddBtn" class="add-more" (click)="addMoreOption()">
        <i nz-icon nzType="plus-circle" nzTheme="fill"></i>
        <span>Add another option</span>
    </div>

    <!-- buttons -->
    <div class="operation-buttons">
        <button class="save-btn" nz-button nzType="primary" [disabled]="saveBtnDisabled" (click)="confirm()">Save</button>
        <button class="discard-btn" nz-button (click)="discard()">Discard</button>
    </div>
</div>
