import * as dayjs from 'dayjs';
import { DESTINATION_CHINA_WAREHOUSE, PURCHASE_PLATFORM } from '@shared/const/order-dispatch.const';
import { isNumber } from 'lodash';

export const COLUMN_LIST = [
    {
        id: 'id',
        name: 'Purchase ID 采购单号',
        show_search: true,
    },
    {
        id: 'variant_image',
        name: '',
        width: '60px',
    },
    {
        id: ['product_sku', 'variant_name'],
        name: 'SKU & Variant 规格',
        show_search: true,
        width: '150px',
    },
    {
        id: ['chinese_declaration_name', 'english_declaration_name'],
        name: 'Declaration Name 报关中英文名',
        show_search: true,
        width: '180px',
    },
    {
        id: 'shop_name',
        name: 'Shop 店铺',
        sortFn: (a, b) => a.shop_name.localeCompare(b.shop_name),
        show_search: true,
        options: [],
        width: '200px',
    },
    {
        id: ['product_name', 'product_id'],
        name: 'Product 产品',
        show_search: true,
        width: '150px',
    },
    {
        id: 'unit_cost',
        name: 'Unit Cost 单价',
        sortFn: (a, b) => a.unit_cost - b.unit_cost,
    },
    {
        id: 'purchase_date',
        name: 'Purchase Date 采购日期',
        sortFn: (a, b) => (dayjs(new Date(a.purchase_date || null)).isBefore(dayjs(new Date(b.purchase_date) || null)) ? -1 : 1),
    },
    {
        id: 'deliver_eta',
        name: 'Deliver ETA 预计到达日期',
        sortFn: (a, b) => (dayjs(new Date(a.deliver_eta || null)).isBefore(dayjs(new Date(b.deliver_eta || null))) ? -1 : 1),
    },
    {
        id: 'destination_address',
        name: 'Destination Address 目的地',
        listOfFilter: DESTINATION_CHINA_WAREHOUSE.map(item => ({
            text: item,
            value: item,
        })),
        filterFn: (list: string[], item) => list.some(name => item.destination_address && item.destination_address.indexOf(name) !== -1),
    },
    {
        id: 'purchase_platform',
        name: 'Purchase Platform 采购平台',
        listOfFilter: PURCHASE_PLATFORM.map(item => ({
            text: item,
            value: item,
        })),
        filterFn: (list: string[], item) =>
            list.some(name => {
                if (item.purchase_platform !== null) {
                    return item.purchase_platform.indexOf(name) !== -1;
                }
            }),
    },
    {
        id: 'purchase_order_id',
        name: 'Purchase Order ID 采购订单',
        show_search: true,
        width: '150px',
    },
    {
        id: 'tracking_number',
        name: 'Third-party logistics No. 第三方订单物流单号',
        show_search: true,
        width: '280px',
    },
    {
        id: 'shipping_cost',
        name: 'Shipping Cost 运费',
    },
    {
        id: 'order_content',
        name: 'Order Content 1688货品标题',
        show_search: true,
        width: '300px',
    },
    {
        id: 'purchased_quantity',
        name: 'Purchased Quantity 采购数量',
        sortFn: (a, b) => a.purchased_quantity - b.purchased_quantity,
    },
    {
        id: 'arrived_quantity',
        name: 'Arrived Quantity 到货数量',
        sortFn: (a, b) => a.arrived_quantity - b.arrived_quantity,
    },
    {
        id: 'in_stock_quantity',
        name: 'Shipped/Remaining Quantity 寄出/剩余数量',
        sortFn: (a, b) => a.in_stock_quantity - b.in_stock_quantity,
    },
    {
        id: 'restock_request_id',
        name: 'Restock Request ID 补货请求ID',
        show_search: true,
    },
    {
        id: 'qc_ratio',
        name: 'QC Percentage 质检比例',
    },
    {
        id: 'qc_images',
        name: 'QC Image 质检照片',
    },
    {
        id: 'exceptions',
        name: '异常原因',
        width: '180px',
    },
    {
        id: 'is_delivered',
        name: 'Checkpoint 确认收货',
        width: '240px',
        sortFn: a => (a.is_delivered ? 1 : -1),
    },
];

export const PRODUCT_INFO_LIST = [
    {
        label: 'Purchase ID 采购单号',
        valueKey: 'id',
    },
    {
        label: 'SKU & Variant 规格',
        valueKey: ['product_sku', 'variant_name'],
        type: 'multiple',
        split: '<br/>',
    },
    {
        label: 'Shop 店铺',
        valueKey: 'shop_name',
    },
    {
        label: 'Product 产品',
        valueKey: 'product_name',
    },
    {
        label: 'Unit Cost 单价',
        valueKey: 'unit_cost',
        type: 'cost',
    },
    {
        label: 'Purchase Date 采购日期',
        valueKey: 'purchase_date',
        type: 'time',
    },
    {
        label: 'Deliver ETA 预计到达日期',
        valueKey: 'deliver_eta',
        type: 'time',
    },
    {
        label: 'Destination Address 目的地',
        valueKey: 'destination_address',
    },
    {
        label: 'Purchase Platform 采购平台',
        valueKey: 'purchase_platform',
    },
    {
        label: 'Purchase Order ID 采购订单',
        valueKey: 'purchase_order_id',
    },
    {
        valueKey: 'tracking_number',
        label: 'Third-party logistics No. 第三方订单物流单号',
    },
    {
        valueKey: 'shipping_cost',
        label: 'Shipping Cost 运费',
        type: 'cost',
    },
    {
        label: 'Order Content 1688货品标题',
        valueKey: 'order_content',
    },
    {
        valueKey: 'purchased_quantity',
        label: 'Purchased Quantity 采购数量',
    },
    {
        valueKey: 'arrived_quantity',
        label: 'Arrived Quantity 到货数量',
    },
    {
        format: data =>
            `${isNumber(data.in_stock_quantity) ? data.arrived_quantity - data.in_stock_quantity : '--'}/${
                isNumber(data.in_stock_quantity) ? data.in_stock_quantity : '--'
            }`,
        label: 'Shipped/Remaining Quantity 寄出/剩余数量',
        type: 'format',
    },
    {
        valueKey: 'restock_request_id',
        label: 'Restock Request ID 补货请求ID',
        default: '---',
    },
    {
        valueKey: 'created_by',
        label: '采购人 Purchaser',
    },
];
