import { Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { OrderDispatchService } from '@services/order-dispatch.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { SamplePurchaseItem } from '@shared/type/order-dispatch.type';

@Component({
    selector: 'app-instruction-notes',
    templateUrl: './instruction-notes.component.html',
    styleUrls: ['./instruction-notes.component.less'],
})
export class InstructionNotesComponent implements OnInit, OnChanges {
    @Input() sampleRequestInfo: SamplePurchaseItem;
    @Input() isEdit = true;
    @Output() updateNote = new EventEmitter();

    messageRecord = [];
    instruction = '';
    submitMessageLoading = false;

    constructor(private orderDispatchService: OrderDispatchService, private message: NzMessageService) {}

    ngOnInit(): void {}

    ngOnChanges(e: SimpleChanges) {
        if (e.sampleRequestInfo?.currentValue) {
            this.messageRecord = this.sampleRequestInfo?.note_history;
        }
    }

    submitMessage() {
        if (!this.instruction) return;
        const data = {
            id: this.sampleRequestInfo.id,
            note: this.instruction,
        };
        this.orderDispatchService
            .sampleRequestNote(data)
            .then(res => {
                this.messageRecord = res?.note_history;
                this.instruction = '';
                this.updateNote.emit(res);
            })
            .catch(() => {
                this.message.error('Failed.');
            });
    }
}
