import { isNumber } from 'lodash';
import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { environment } from '@src/environments/environment';

enum UserPermissionCodeEnum {
    FULL_ACCESS = 3,
    READ_ONLY = 1,
}

@Injectable({
    providedIn: 'root',
})
export class UserPermissionService {
    currentUserPermission: UserPermission;

    private fullAccess = {
        user_management: 3,

        inventory_management: 3,
        product_listing: 3,

        order_management: 3,
        store_setting: 3,

        product_purchase: 3,

        product_logistics: 3,
        accept_logistics: 3,

        inventory_tracking: 3,
        restock_request: 3,

        sales_report: 3,
        campaign_operation: 3,
        campaign_settings: 3,
        influencer_management: 3,

        general_internal_access: 3,
        warehouse_management: 3,
        standard_product_access: 3,
    };

    constructor(private requestService: RequestService) {}

    initCurrentUserPermission() {
        return this.requestService
            .sendRequest<any>(
                {
                    method: 'GET',
                    url: '/am/permissions',
                    retryTime: 3,
                },
                environment.shopApiService
            )
            .then(res => {
                if (environment.fullAccess) {
                    this.currentUserPermission = new UserPermission(this.fullAccess);
                } else {
                    this.currentUserPermission = new UserPermission(res);
                }
            });
    }
}

class UserPermission {
    private user_management: UserPermissionCodeEnum;

    private inventory_management: UserPermissionCodeEnum;
    private product_listing: UserPermissionCodeEnum;

    private order_management: UserPermissionCodeEnum;
    private store_setting: UserPermissionCodeEnum;

    private product_purchase: UserPermissionCodeEnum;

    private product_logistics: UserPermissionCodeEnum;
    private accept_logistics: UserPermissionCodeEnum;

    private inventory_tracking: UserPermissionCodeEnum;
    private restock_request: UserPermissionCodeEnum;

    private general_internal_access: UserPermissionCodeEnum;
    private warehouse_management: UserPermissionCodeEnum;
    private standard_product_access: UserPermissionCodeEnum;

    private sales_report: UserPermissionCodeEnum;
    private campaign_operation: UserPermissionCodeEnum;
    private campaign_settings: UserPermissionCodeEnum;
    private influencer_management: UserPermissionCodeEnum;

    // Product Inventory Management
    private get hasListingPermission() {
        return this.product_listing === UserPermissionCodeEnum.FULL_ACCESS;
    }
    get isProductInventoryNoAccess() {
        return !isNumber(this.inventory_management);
    }
    get isProductInventoryReadOnly() {
        return this.inventory_management === UserPermissionCodeEnum.READ_ONLY;
    }
    get isProductInventoryFullEdit() {
        return this.inventory_management === UserPermissionCodeEnum.FULL_ACCESS && this.hasListingPermission;
    }
    get isProductInventoryPartiallyEdit() {
        return this.inventory_management === UserPermissionCodeEnum.FULL_ACCESS && !this.hasListingPermission;
    }

    // Order Management
    get isOrderManagementNoAccess() {
        return !isNumber(this.order_management);
    }
    get isOrderManagementReadOnly() {
        return this.order_management === UserPermissionCodeEnum.READ_ONLY;
    }
    get isOrderManagementFullEdit() {
        return this.order_management === UserPermissionCodeEnum.FULL_ACCESS;
    }

    // Store Settings
    get isStoreSettingsNoAccess() {
        return !isNumber(this.store_setting);
    }
    get isStoreSettingsReadOnly() {
        return this.store_setting === UserPermissionCodeEnum.READ_ONLY;
    }
    get isStoreSettingsFullEdit() {
        return this.store_setting === UserPermissionCodeEnum.FULL_ACCESS;
    }

    // Product Purchase
    get isProductPurchaseNoAccess() {
        return !isNumber(this.product_purchase);
    }
    get isProductPurchaseReadOnly() {
        return this.product_purchase === UserPermissionCodeEnum.READ_ONLY;
    }
    get isProductPurchaseFullEdit() {
        return this.product_purchase === UserPermissionCodeEnum.FULL_ACCESS;
    }

    // Product Logistics
    private get hasAcceptPermission() {
        return this.accept_logistics === UserPermissionCodeEnum.FULL_ACCESS;
    }
    get isProductLogisticsNoAccess() {
        return !isNumber(this.product_logistics);
    }
    get isProductLogisticsReadOnly() {
        return this.product_logistics === UserPermissionCodeEnum.READ_ONLY;
    }
    get isProductLogisticsFullEdit() {
        return this.product_logistics === UserPermissionCodeEnum.FULL_ACCESS && this.hasAcceptPermission;
    }
    get isProductLogisticsPartiallyEdit() {
        return this.inventory_management === UserPermissionCodeEnum.FULL_ACCESS && !this.hasAcceptPermission;
    }

    // Inventory tracking
    get isInventoryTrackingNoAccess() {
        return !isNumber(this.inventory_tracking);
    }
    get isInventoryTrackingReadOnly() {
        return this.inventory_tracking === UserPermissionCodeEnum.READ_ONLY;
    }
    get isInventoryTrackingFullEdit() {
        return this.inventory_tracking === UserPermissionCodeEnum.FULL_ACCESS;
    }

    // Restock Request
    get isRestockRequestNoAccess() {
        return !isNumber(this.restock_request);
    }
    get isRestockRequestReadOnly() {
        return this.restock_request === UserPermissionCodeEnum.READ_ONLY;
    }
    get isRestockRequestFullEdit() {
        return this.restock_request === UserPermissionCodeEnum.FULL_ACCESS;
    }

    // For warehouse access
    get isWarehouseManagementNoAccess() {
        return !Number(this.warehouse_management) && !Number(this.general_internal_access);
    }

    // For general internal access
    get isGeneralInternalNoAccess() {
        return !Number(this.general_internal_access);
    }

    get isStandardProductNoAccess() {
        return !Number(this.standard_product_access);
    }

    constructor(props: any) {
        if (props) {
            Object.keys(props).forEach(key => {
                this[key] = props[key];
            });
        }
    }
}
