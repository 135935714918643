<section class="choose-variant-image">
    <header>Choose Image</header>
    <div class="images">
        <img
            *ngFor="let imgItem of images"
            [src]="imgItem.src"
            [class.active]="currentImg?.src === imgItem.src"
            (click)="currentImg = imgItem"
        />
    </div>

    Or <button nz-button nzType="primary" class="ml-20 mt-20" (click)="uploadFromLocal()">Upload Image</button>
</section>
