import { merge } from 'lodash';

export class ModalControl {
    private data = {
        visible: false,
        okBtnLoading: false,
        okBtnDisabled: () => false,
    };

    get visible() {
        return this.data.visible;
    }
    set visible(val: boolean) {
        this.data.visible = val;
    }

    get okBtnLoading() {
        return this.data.okBtnLoading;
    }

    get okBtnDisabled() {
        return this.data.okBtnDisabled();
    }

    constructor(okBtnDisabled?: () => boolean) {
        if (okBtnDisabled) {
            this.data.okBtnDisabled = okBtnDisabled;
        }
    }

    showModal() {
        this.data.visible = true;
    }
    hideModal() {
        this.data.visible = false;
    }

    showOkBtnLoading() {
        this.data.okBtnLoading = true;
    }
    hideOkBtnLoading() {
        this.data.okBtnLoading = false;
    }
}
