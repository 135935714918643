export const URGENCY_SORT_OPTIONS = { low: 1, medium: 2, high: 3 };

export const SAMPLE_COLUMNS_LIST = [
    {
        id: 'id',
        name: 'SR_ID',
        show_search: true,
        sortFn: (a, b) => a.id - b.id,
        width: '140px',
    },
    {
        id: ['product_name', 'product_id'],
        name: 'SR_NAME',
        width: '200px',
        show_search: true,
    },
    {
        id: 'shop_name',
        name: 'SR_SHOP_NAME',
        show_search: true,
        options: [],
    },
    {
        id: 'sample_type',
        name: 'SR_SAMPLE_TYPE',
        listOfFilter: [
            { text: 'SAMPLE_TYPE_ADVANCED', value: 'advanced_customization' },
            { text: 'SAMPLE_TYPE_POD', value: 'print_on_demand' },
            { text: 'SAMPLE_TYPE_NEW', value: 'new_product' },
        ],
        filterFn: (list: string[], item) => list.some(type => item.sample_type.indexOf(type) !== -1),
        width: '110px',
    },
    {
        id: 'requested_quantity',
        name: 'SR_REQUEST_QUANTITY',
    },
    {
        id: 'urgency',
        name: 'SR_URGENCY',
        sortFn: (a, b) => URGENCY_SORT_OPTIONS[a.urgency] - URGENCY_SORT_OPTIONS[b.urgency],
        width: '140px',
    },
    {
        id: 'required_date',
        name: 'SR_ETA',
        sortFn: (a, b) => new Date(a.required_date).getTime() - new Date(b.required_date).getTime(),
        width: '180px',
    },
    {
        id: 'estimate_date',
        name: 'SR_DELIVER_ETA',
        sortFn: (a, b) => new Date(a.estimate_date).getTime() - new Date(b.estimate_date).getTime(),
        width: '180px',
    },
    {
        id: 'logistics_deliver_eta',
        name: 'SR_FULFILLMENT_ETA',
        sortFn: (a, b) => new Date(a.logistics_deliver_eta).getTime() - new Date(b.logistics_deliver_eta).getTime(),
        width: '180px',
    },
    {
        id: 'sample_status',
        name: 'SR_CURRENT_PROGRESS',
        listOfFilter: [
            { text: 'SAMPLE_PROGRESS_INITIATED', value: 'initiated' },
            { text: 'SAMPLE_PROGRESS_SENT_TO_VENDOR', value: 'sent_to_vendor' },
            { text: 'SAMPLE_PROGRESS_CONFIRM_SENT', value: 'confirm_sent' },
            { text: 'SAMPLE_PROGRESS_SAMPLE_IN_PROGRESS', value: 'sample_in_progress' },
            { text: 'SAMPLE_PROGRESS_DELIVER_TO_OFFICE', value: 'deliver_to_office' },
            { text: 'SAMPLE_PROGRESS_PRODUCT_SHIPPED', value: 'product_shipped' },
        ],
        filterFn: (list: string[], item) => list.some(status => item.sample_status.indexOf(status) !== -1),
    },
    {
        id: 'instruction',
        name: 'SR_INSTRUCTION',
        width: '110px',
    },
    {
        id: 'created_at',
        name: 'SR_REQUESTER',
        width: '180px',
    },
    {
        name: 'SR_OPERATION',
        width: '280px',
    },
];

export const STORE_SAMPLE_COLUMNS_LIST = [
    {
        id: 'id',
        name: 'SR_ID',
        show_search: true,
        width: '100px',
        sortFn: (a, b) => a.id - b.id,
    },
    {
        id: ['product_name', 'product_id'],
        name: 'SR_NAME',
        width: '200px',
        show_search: true,
    },
    {
        id: 'shop_name',
        name: 'SR_SHOP_NAME',
        show_search: true,
        options: [],
    },
    {
        id: 'sample_type',
        name: 'SR_SAMPLE_TYPE',
        listOfFilter: [
            { text: 'SAMPLE_TYPE_ADVANCED', value: 'advanced_customization' },
            { text: 'SAMPLE_TYPE_POD', value: 'print_on_demand' },
            { text: 'SAMPLE_TYPE_NEW', value: 'new_product' },
        ],
        filterFn: (list: string[], item) => list.some(type => item.sample_type.indexOf(type) !== -1),
        width: '110px',
    },
    {
        id: 'urgency',
        name: 'SR_URGENCY',
        sortFn: (a, b) => URGENCY_SORT_OPTIONS[a.urgency] - URGENCY_SORT_OPTIONS[b.urgency],
        width: '140px',
    },
    {
        id: 'required_date',
        name: 'SR_ETA',
        sortFn: (a, b) => new Date(a.required_date).getTime() - new Date(b.required_date).getTime(),
        width: '160px',
    },
    {
        id: 'estimate_date',
        name: 'SR_DELIVER_ETA',
        sortFn: (a, b) => new Date(a.estimate_date).getTime() - new Date(b.estimate_date).getTime(),
        width: '180px',
    },
    {
        id: 'logistics_deliver_eta',
        name: 'SR_FULFILLMENT_ETA',
        sortFn: (a, b) => new Date(a.logistics_deliver_eta).getTime() - new Date(b.logistics_deliver_eta).getTime(),
        width: '160px',
    },
    {
        id: 'sample_status',
        name: 'SR_CURRENT_PROGRESS',
        listOfFilter: [
            { text: 'SAMPLE_PROGRESS_INITIATED', value: 'initiated' },
            { text: 'SAMPLE_PROGRESS_SENT_TO_VENDOR', value: 'sent_to_vendor' },
            { text: 'SAMPLE_PROGRESS_CONFIRM_SENT', value: 'confirm_sent' },
            { text: 'SAMPLE_PROGRESS_SAMPLE_IN_PROGRESS', value: 'sample_in_progress' },
            { text: 'SAMPLE_PROGRESS_DELIVER_TO_OFFICE', value: 'deliver_to_office' },
            { text: 'SAMPLE_PROGRESS_PRODUCT_SHIPPED', value: 'product_shipped' },
        ],
        filterFn: (list: string[], item) => list.some(status => item.sample_status.indexOf(status) !== -1),
    },
    {
        id: 'fulfillment_status',
        name: 'SR_FULFILLMENT_STATUS',
        width: '170px',
    },
    {
        id: 'request_quantity',
        name: 'SR_QUANTITY',
        width: '280px',
    },
    {
        id: 'logistics_tracking_number',
        name: 'SR_TRACKING_NUMBER',
        width: '180px',
        show_search: true,
    },
    {
        id: 'qc_required',
        name: 'SR_CHECK_REQUIREMENT',
        width: '220px',
    },
    {
        id: 'instruction',
        name: 'SR_INSTRUCTION',
        width: '100px',
    },
    {
        id: 'custom_info',
        name: 'SR_CUSTOM_INFO',
        width: '100px',
    },
    {
        id: 'destination',
        name: 'SR_DESTINATION',
    },
    {
        id: 'created_at',
        name: 'SR_REQUESTER',
        width: '180px',
    },
    {
        name: 'SR_DECISION',
        width: '240px',
    },
];
