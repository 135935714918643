import { BehaviorSubject } from 'rxjs';
import { Component, Input, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { cloneDeep, trim, toLower, uniq } from 'lodash';

export interface ProductOption {
    name: string;
    values: string[];
}

@Component({
    selector: 'app-add-option-dialog',
    templateUrl: './add-option-dialog.component.html',
    styleUrls: ['./add-option-dialog.component.less'],
})
export class AddOptionDialogComponent implements OnInit {
    @Input() productDetails: any;
    @Input() productOptions: ProductOption[];
    @Input() optionsInDBMap: any = new Map();
    @Input() showAddBtn = true;

    options: ProductOption[];

    optionNames = ['Color', 'Size', 'Material', 'Style', 'Title'];
    filteredOptionNames$ = new BehaviorSubject(this.optionNames);

    get saveBtnDisabled() {
        return this.options.length === 0 || this.options.some(item => item.values.length === 0 || !item.name);
    }

    constructor(private modal: NzModalRef) {}

    ngOnInit(): void {
        if (!this.productOptions || this.productOptions.length === 0) {
            this.options = [{ name: null, values: [] }];
        } else {
            this.options = cloneDeep(this.productOptions);
        }
    }

    onOptionNameChange(value: string) {
        this.filteredOptionNames$.next(this.optionNames.filter(option => option.toLowerCase().indexOf(value.toLocaleLowerCase()) !== -1));
    }

    onOptionNameBlur(optionItem: ProductOption) {
        if (this.options.filter(item => trim(toLower(item.name)) === trim(toLower(optionItem.name))).length > 1) {
            optionItem.name = null;
        }
    }

    onOptionValueBlur(optionItem: ProductOption) {
        optionItem.values = uniq(optionItem.values.map(ele => trim(ele)));
    }

    resetFilteredOptionNames() {
        this.filteredOptionNames$.next(this.optionNames);
    }

    addMoreOption() {
        const newOption: ProductOption = {
            name: null,
            values: [],
        };

        this.options.push(newOption);
    }

    removeOption(index: number) {
        this.options.splice(index, 1);
    }

    confirm() {
        if (!this.saveBtnDisabled) {
            this.modal.triggerOk();
        }
    }

    discard() {
        this.modal.close(false);
    }
}
