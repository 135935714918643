<div class="edit-product-box" *ngIf="productPurchaseInfo">
    <div class="d-flex justify-content-between align-items-end width-100p mb-20">
        <div class="inline-form-item flex-1 mr-20">
            <div class="title">Unit Cost 单价<span class="required"> *</span></div>
            <div class="inline-box">
                <nz-input-group nzCompact>
                    <nz-select [(ngModel)]="productPurchaseInfo.currency" style="width: 60px">
                        <nz-option nzValue="USD" nzLabel="$"></nz-option>
                        <nz-option nzValue="CNY" nzLabel="¥"></nz-option>
                    </nz-select>
                    <input nz-input type="number" min="0" [(ngModel)]="productPurchaseInfo.unit_cost" style="width: calc(100% - 60px)" />
                </nz-input-group>
            </div>
        </div>

        <app-form-item
            class="flex-1 mr-20"
            [config]="{
                type: 'select',
                title: 'Purchase Platform 采购平台',
                options: purchasePlatformOptions,
                ngModelValue: {
                    fieldName: 'purchase_platform',
                    obj: productPurchaseInfo
                }
            }"
        ></app-form-item>

        <app-form-item
            class="flex-1 mr-20"
            [config]="{
                type: 'dateSelect',
                title: 'purchase date 采购日期',
                ngModelValue: {
                    fieldName: 'purchase_date',
                    obj: productPurchaseInfo
                }
            }"
        ></app-form-item>
    </div>

    <div class="d-flex justify-content-between align-items-end width-100p mb-20">
        <div class="inline-form-item flex-1 mr-20">
            <div class="title">Shipping Cost 运费</div>
            <div class="inline-box">
                <nz-input-group nzCompact>
                    <nz-select [(ngModel)]="productPurchaseInfo.currency" style="width: 60px">
                        <nz-option nzValue="USD" nzLabel="$"></nz-option>
                        <nz-option nzValue="CNY" nzLabel="¥"></nz-option>
                    </nz-select>
                    <input
                        nz-input
                        type="number"
                        min="0"
                        [(ngModel)]="productPurchaseInfo.shipping_cost"
                        style="width: calc(100% - 60px)"
                    />
                </nz-input-group>
            </div>
        </div>

        <app-form-item
            class="flex-1 mr-20"
            [config]="{
                type: 'select',
                title: 'Destination (China) 国内仓库',
                options: destinationAddressOptions,
                ngModelValue: {
                    fieldName: 'destination_address',
                    obj: productPurchaseInfo
                }
            }"
        ></app-form-item>

        <app-form-item
            class="flex-1 mr-20"
            [config]="{
                type: 'dateSelect',
                title: 'Deliver ETA 预计到达日期 ',
                ngModelValue: {
                    fieldName: 'deliver_eta',
                    obj: productPurchaseInfo
                },
                optional: true
            }"
        ></app-form-item>
    </div>

    <div class="d-flex justify-content-between align-items-end width-100p mb-20">
        <div class="inline-form-item flex-1 mr-20">
            <div class="title">QC Percentage 质检比例 <span class="required">*</span></div>
            <div class="inline-box">
                <nz-input-number
                    [(ngModel)]="productPurchaseInfo.qc_ratio"
                    nzPrecision="2"
                    [nzMin]="0"
                    [nzMax]="100"
                    [nzFormatter]="formatterPercent"
                    style="width: 100%"
                ></nz-input-number>
            </div>
        </div>

        <app-form-item
            class="flex-1 mr-20"
            [config]="{
                type: 'stringInput',
                title: 'Check Requirements 对版要求',
                ngModelValue: {
                    fieldName: 'check_requirement',
                    obj: productPurchaseInfo
                },
                optional: true
            }"
        ></app-form-item>

        <app-form-item
            class="flex-1 mr-20"
            [config]="{
                type: 'stringInput',
                title: 'QC Requirements 质检要求',
                ngModelValue: {
                    fieldName: 'qc_requirement',
                    obj: productPurchaseInfo
                },
                optional: true
            }"
        ></app-form-item>
    </div>

    <div class="d-flex justify-content-between align-items-end width-100p mb-20">
        <app-form-item
            class="flex-1 mr-20"
            [config]="{
                type: 'stringInput',
                title: 'Purchase Order ID 采购单号',
                ngModelValue: {
                    fieldName: 'purchase_order_id',
                    obj: productPurchaseInfo
                }
            }"
        ></app-form-item>

        <app-form-item
            class="flex-1 mr-20"
            [config]="{
                type: 'textarea',
                title: 'Order Content 1688货品标题',
                ngModelValue: {
                    fieldName: 'order_content',
                    obj: productPurchaseInfo
                }
            }"
        ></app-form-item>
    </div>

    <div
        *ngFor="let logisticsItem of productPurchaseInfo.logistics"
        class="d-flex justify-content-between align-items-end width-100p mb-20"
    >
        <app-form-item
            class="flex-1 mr-20"
            [config]="{
                type: 'stringInput',
                title: 'Carrier 物流公司',
                ngModelValue: {
                    fieldName: 'carrier',
                    obj: logisticsItem
                },
                optional: true
            }"
        ></app-form-item>

        <app-form-item
            class="flex-1 mr-20"
            [config]="{
                type: 'stringInput',
                title: 'Third-Party Logistics Number 第三方物流单号',
                ngModelValue: {
                    fieldName: 'tracking_number',
                    obj: logisticsItem
                },
                optional: true
            }"
        ></app-form-item>
    </div>

    <div class="cursor-pointer link-color text-decoration-underline">
        <span (click)="productPurchaseInfo.logistics.push({carrier:'',tracking_number:''})">
            <i nz-icon nzType="plus-circle" nzTheme="outline"></i>
            <span>Add new logistics record 添加新物流记录</span>
        </span>
    </div>

    <div class="d-flex justify-content-between align-items-end"></div>
</div>
