import { SkuListItem } from '@services/order-dispatch.type';

export const getShopListByFullSkuList = (fullSkuList: SkuListItem[]) => {
    const shopList = [];
    fullSkuList.forEach(sku => {
        const shop_id = sku.shop_id;
        const shop_name = sku.shop_name;

        if (shopList.map(shop => shop.shop_id).indexOf(shop_id) < 0) {
            shopList.push({
                shop_id,
                shop_name,
            });
        }
    });
    return shopList;
};

export const getSkuListByProductId = (productId: string, fullSkuList: SkuListItem[]) => {
    const skuList = [];
    fullSkuList.forEach(sku => {
        if (productId === sku.product_id) {
            skuList.push(sku);
        }
    });
    return skuList;
};

export const getProductListByShopId = (shopId: number, fullSkuList: SkuListItem[]) => {
    const productList = [];
    fullSkuList.forEach(sku => {
        if (shopId === sku.shop_id) {
            const product_id = sku.product_id;
            const product_name = sku.product_name;
            const product_image = sku.image;

            if (productList.map(product => product.product_id).indexOf(product_id) < 0) {
                productList.push({
                    product_id,
                    product_name,
                    product_image,
                });
            }
        }
    });
    return productList;
};

export const optionsHandler = (options: { name: string; values: string[] }[]) => {
    const allOptions = options.map(item => item.values);
    return allOptions.reduce(
        (a, b) => {
            const arr = [];
            a.forEach(itemA => {
                b.forEach(itemB => {
                    arr.push([...itemA, itemB]);
                });
            });
            return arr;
        },
        [[]]
    );
};
