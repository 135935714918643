import { Injectable, ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import { ImageFullscreenComponent } from '@shared/components/image-fullscreen/image-fullscreen.component';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ImagePreviewFullscreenService {
    imageList$: Subject<any[]> = new Subject();

    private rootViewContainer: ViewContainerRef;

    constructor(private factoryResolver: ComponentFactoryResolver) {}

    setRootViewContainerRef(viewContainerRef: ViewContainerRef) {
        this.rootViewContainer = viewContainerRef;
    }

    showModal(images: { src: string; path?: string; media_type?: string }[], activatedIndex: number, isShowCropper: boolean = false) {
        const factory = this.factoryResolver.resolveComponentFactory(ImageFullscreenComponent);
        const component = factory.create(this.rootViewContainer.parentInjector);
        component.instance.images = images;
        component.instance.activatedItemIndex = activatedIndex;
        component.instance.isShowCropper = isShowCropper;

        component.instance.destroy.subscribe(() => {
            component.destroy();
        });

        this.rootViewContainer.insert(component.hostView);
    }

    updateImageList(images) {
        this.imageList$.next([...images]);
    }
}
