<span>
    <img
        [nzTooltipOverlayClassName]="'kit-tooltip'"
        [(nzTooltipVisible)]="kitVisible"
        nzTooltipPlacement="topCenter"
        [nzTooltipTitle]="tooltipsTemplate"
        nz-tooltip
        nzTooltipTrigger="click"
        src="assets/images/kit-icon.png"
        height="25"
        (click)="getKitContent($event)"
        class="kit-info"
    />
    <ng-template #tooltipsTemplate>
        <section class="kit-tooltip-content">
            <div class="info">
                <img class="close-icon" src="assets/images/close-icon.png" (click)="kitVisible = false" alt="" width="14" />
                <div class="fs-14 title">Kit SKU</div>
                <section class="description" *ngFor="let kitItem of kitDetails">
                    <div class="info-block">
                        <img [src]="kitItem.image" />
                    </div>
                    <div class="info-block" style="font-weight: 600">
                        Product Name <br />
                        Product ID
                    </div>
                    <div class="info-block">
                        {{ kitItem.product_name }} <br />
                        {{ kitItem.product_id }}
                    </div>
                    <div class="info-block">X {{ kitItem.quantity }}</div>
                </section>
            </div>
        </section>
    </ng-template>
</span>
