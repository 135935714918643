import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'split',
})
export class SplitPipe implements PipeTransform {
    transform(value: string, splitSymbol: string): string[] {
        if (!value) {
            return [value];
        }

        return value.split(splitSymbol);
    }
}
