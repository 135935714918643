<div>
    <ejs-richtexteditor
        #defaultRTE
        [(ngModel)]="htmlContent"
        id="{{ id }}"
        [toolbarSettings]="tools"
        [quickToolbarSettings]="quickTools"
        [insertImageSettings]="insertImageSettings"
        [height]="height"
        [enableHtmlSanitizer]="false"
        (imageUploadSuccess)="uploadSuccess($event)"
    >
    </ejs-richtexteditor>
</div>
