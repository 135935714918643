import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { NzOptionComponent } from 'ng-zorro-antd/select';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ProductTypeEnum, ProductInfo, ProductInventory, ShopSettingsDetail } from '@shared/type/order-dispatch.type';
import { OrderDispatchService } from '@services/order-dispatch.service';

interface ShopPackageInfo {
    package_type: ProductTypeEnum;
    package_product_sku: string;
    package_product_title: string;
    package_product_image: string;
    is_default: boolean;
    is_selected: boolean;
    package_product_id?: string;
    isToBeSavedItem?: boolean;
}

interface ShopProductInfo extends ProductInfo {
    warehouse_list: any[];
}

@Component({
    selector: 'app-package-info-setting',
    templateUrl: './package-info-setting.component.html',
    styleUrls: ['./package-info-setting.component.less'],
})
export class PackageInfoSettingComponent implements OnInit, OnChanges {
    @Input() shopId: number;
    @Input() productList: ProductInventory[];
    @Input() shopDetail: ShopSettingsDetail;

    fetchingShopDetail = false;

    productTypes = [ProductTypeEnum.PACKAGE, ProductTypeEnum.INSERT, ProductTypeEnum.WRAPPER, ProductTypeEnum.STICKER];

    shopPackageInfos: ShopPackageInfo[];

    productInfoMap = new Map<string, ShopProductInfo>();

    productInventoryInfoBySkuMap = new Map<string, ProductInventory>();
    productInventoryInfoByProductIdMap = new Map<string, ProductInventory>();

    filterOptionsMap = new Map<ProductTypeEnum, ProductInventory[]>();

    constructor(private orderDispatchService: OrderDispatchService, private modal: NzModalService) {}

    ngOnInit() {
        if (this.shopId !== null && this.shopId !== undefined) {
            this.getShopDetail();
        }
    }

    ngOnChanges(e: SimpleChanges) {
        if (e.productList?.currentValue) {
            const productOfWrapper = this.productList.filter(product => product.product_type === ProductTypeEnum.WRAPPER);
            const productOfInsert = this.productList.filter(product => product.product_type === ProductTypeEnum.INSERT);
            const productOfPackage = this.productList.filter(product => product.product_type === ProductTypeEnum.PACKAGE);
            const productOfSticker = this.productList.filter(product => product.product_type === ProductTypeEnum.STICKER);

            this.filterOptionsMap.set(ProductTypeEnum.WRAPPER, productOfWrapper);
            this.filterOptionsMap.set(ProductTypeEnum.INSERT, productOfInsert);
            this.filterOptionsMap.set(ProductTypeEnum.PACKAGE, productOfPackage);
            this.filterOptionsMap.set(ProductTypeEnum.STICKER, productOfSticker);

            this.productList.forEach(product => {
                const productInfo: any = {
                    product_type: product.product_type,
                    img_url: product.image,
                    title: product.title,
                    product_price: 0,
                    shop_name: product.shop_name,
                    sku_list: product.sku_list,
                };
                this.productInfoMap.set(product.product_id, productInfo);
                this.productInventoryInfoByProductIdMap.set(product.product_id, product);
                product.sku_list.forEach(sku => {
                    this.productInventoryInfoBySkuMap.set(sku, product);
                });
            });
            this.setPackageInfo();
        }
    }

    async getShopDetail() {
        this.fetchingShopDetail = true;
        try {
            this.shopDetail = await this.orderDispatchService.getShopDetailById(this.shopId);
            this.fetchingShopDetail = false;
            this.setPackageInfo();
        } catch (e) {
            this.fetchingShopDetail = false;
        }
    }

    filterOption(value: string, option: NzOptionComponent) {
        return !!(
            String(option.nzValue).toLocaleLowerCase().indexOf(String(value).toLocaleLowerCase()) !== -1 ||
            option.nzLabel.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) !== -1
        );
    }

    setPackageInfo() {
        if (this.shopDetail && this.productList) {
            this.shopPackageInfos = this.shopDetail.shop_packages.map(shopPackage => ({
                package_product_id: this.productInventoryInfoBySkuMap.get(shopPackage.package_product_sku).product_id,
                ...shopPackage,
            }));
        }
    }

    addMore() {
        this.shopPackageInfos.push({
            package_type: null,
            package_product_sku: null,
            package_product_image: null,
            package_product_title: null,
            isToBeSavedItem: true,
            is_default: false,
            is_selected: true,
        });
        this.shopPackageInfos = this.shopPackageInfos.slice();
    }

    removePackage(packageItem) {
        this.modal.confirm({
            nzTitle: 'Remove the Packaging?',
            nzOnOk: () => (this.shopPackageInfos = this.shopPackageInfos.filter(item => item !== packageItem)),
        });
    }

    productTypeChange(data: ShopPackageInfo) {
        data.package_product_sku = null;
        data.package_product_image = null;
        data.package_product_title = null;
        data.package_product_id = null;
    }
}
