import * as dayjs from 'dayjs';

export const URGENCY_SORT_OPTIONS = { low: 1, medium: 2, high: 3 };

export const COLUMNS_LIST = [
    {
        id: 'id',
        name: 'Sample Request ID 样品请求ID',
        show_search: true,
    },
    {
        id: ['product_name', 'product_id'],
        name: 'Sample name & ID 样品名及ID',
        width: '200px',
        show_search: true,
    },
    {
        id: 'shop_id',
        name: 'Shop ID & 网红姓名',
        listOfFilter: [],
        filterFn: (list: string[], item) => list.some(name => `${item.shop_id}`.indexOf(name) !== -1),
    },
    {
        id: 'advanced_customization_order_id',
        name: '是否为定制产品',
        width: '90px',
    },
    {
        id: 'requested_quantity',
        name: 'Requested/Purchased Qty 总需/已采',
    },
    {
        id: 'urgency',
        name: 'Urgency 紧急程度',
        sortFn: (a, b) => URGENCY_SORT_OPTIONS[a.urgency] - URGENCY_SORT_OPTIONS[b.urgency],
        width: '140px',
    },
    {
        id: 'required_date',
        name: 'Expected Arrival Date 要求到达日期（ETA）',
        sortFn: (a, b) => new Date(a.required_date).getTime() - new Date(b.required_date).getTime(),
        width: '180px',
    },
    {
        id: 'sample_status',
        name: 'Current Progress 当前进度',
        listOfFilter: [
            { text: '发起需求', value: 'initiated' },
            { text: '需求发给供应商', value: 'sent_to_vendor' },
            { text: '确认打样', value: 'confirm_sent' },
            { text: '打样生产中', value: 'sample_in_progress' },
            { text: '到达样品中心', value: 'deliver_to_office' },
            { text: 'QC样品已发出', value: 'product_shipped' },
        ],
        filterFn: (list: string[], item) => list.some(status => item.sample_status.indexOf(status) !== -1),
    },
    {
        id: 'instruction',
        name: 'Instruction Notes 说明信息',
        width: '110px',
    },
    {
        id: 'created_at',
        name: 'Requester/Requested Date 请求人/请求日期',
        width: '180px',
    },
    {
        name: 'Operation 操作',
        width: '240px',
    },
];

export const PRODUCT_INFO_LIST = [
    {
        valueKey: 'id',
        label: 'Sample Request ID 样品请求ID',
    },
    {
        valueKey: 'product_id',
        label: 'Sample Product ID 样品ID',
    },
    {
        valueKey: 'shop_name',
        label: 'Store 店铺',
    },
    {
        valueKey: 'product_name',
        label: 'Sample Name 样品名',
    },
    {
        valueKey: ['purchased_quantity', 'request_quantity'],
        label: 'Fulfillment Status 已买/总需',
        type: 'multiple',
        split: '/',
    },
    {
        valueKey: 'created_at',
        label: 'Requested Date 请求日期',
        type: 'time',
    },
    {
        valueKey: 'requestor',
        label: 'Requester 请求人',
    },
    {
        format: data => {
            const getValue = value => (value ? `${value}, ` : '');
            return data.address_detail
                ? `${data.address_detail.name}, ${getValue(data.address_detail.phone)}${data.address_detail.address_line_1}, ${getValue(
                      data.address_detail.address_line_2
                  )}${data.address_detail.city}, ${data.address_detail.state}, ${data.address_detail.zip}, ${data.address_detail.country}`
                : '';
        },
        label: 'Final Destination 最终目的地',
        type: 'format',
    },
    {
        format: data => `<a href="${data.sourcing_link}" target="_blank">${data.sourcing_link}</a>`,
        label: 'Sourcing Link 关联Sourcing信息',
        type: 'format',
    },
    {
        valueKey: 'instruction',
        label: 'Instruction 说明信息',
    },
    {
        format: data => {
            const result = {
                high: '高 High',
                medium: '中 Medium',
                low: '低 Low',
            };
            return result[data.urgency];
        },
        label: 'Urgency 紧急程度',
        type: 'format',
    },
    {
        valueKey: 'required_date',
        label: 'Required Date 需要日期',
        type: 'time',
    },
    {
        format: data =>
            `${data.qc_required ? 'QC & QC Image<br/>' : ''}${data.live_demo_requried ? '直播验货<br/>' : ''}${
                data.photo_shoot_required ? '国内拍摄上线内容' : ''
            }`,
        label: 'Check Requirements 验货需求',
        type: 'format',
    },
];

export const CHECK_REQUIREMENT_OPTIONS = [
    { label: '是否需要QC及QC照片', value: 'qc_required', checked: false },
    { label: '是否需要直播验货', value: 'live_demo_requried', checked: false },
    { label: '是否需要国内拍摄上线内容', value: 'photo_shoot_required', checked: false },
];
