<div class="confirm-box">
    <div *ngIf="bodyHtml" [innerHTML]="bodyHtml"></div>

    <p *ngIf="message" class="message">{{ message }}</p>

    <div class="btns">
        <button nz-button nzType="default" (click)="discard()" [disabled]="submitting">{{ cancelText }}</button>
        <button nz-button nzType="primary" (click)="confirm()" [nzLoading]="submitting">{{ confirmText }}</button>
    </div>
</div>
