<div class="address-container">
    <ng-container *ngIf="addressDetails || type !== 'view'">
        <div class="name-form">
            <div class="form-item pr-20">
                <div>First Name<span class="red" *ngIf="type !== 'view'"> *</span></div>
                <p *ngIf="type === 'view'; else firstnameInput;">{{ addressDetails.first_name }}</p>
                <ng-template #firstnameInput>
                    <input nz-input type="text" placeholder="First Name" [(ngModel)]="address.first_name" />
                </ng-template>
            </div>
            <div class="form-item pl-20">
                <div>Last Name<span class="red" *ngIf="type !== 'view'"> *</span></div>
                <p *ngIf="type === 'view'; else lastnameInput;">{{ addressDetails.last_name }}</p>
                <ng-template #lastnameInput>
                    <input nz-input type="text" placeholder="Last Name" [(ngModel)]="address.last_name" />
                </ng-template>
            </div>
        </div>
        <div class="form-item">
            <div>Phone Number</div>
            <p *ngIf="type === 'view'; else phoneInput;">{{ addressDetails.phone_number }}</p>
            <ng-template #phoneInput>
                <input nz-input type="text" placeholder="Enter phone" [(ngModel)]="address.phone_number" />
            </ng-template>
        </div>
        <div class="form-item">
            <div>Address Line 1<span class="red" *ngIf="type !== 'view'"> *</span></div>
            <p *ngIf="type === 'view'; else addressLine1Input;">{{ addressDetails.address_line_1 }}</p>
            <ng-template #addressLine1Input>
                <input nz-input type="text" placeholder="Address Line 1" [(ngModel)]="address.address_line_1" />
            </ng-template>
        </div>
        <div class="form-item">
            <div>Address Line 2</div>
            <p *ngIf="type === 'view'; else addressLine2Input;">{{ addressDetails.address_line_2 }}</p>
            <ng-template #addressLine2Input>
                <input nz-input type="text" placeholder="Address Line 2" [(ngModel)]="address.address_line_2" />
            </ng-template>
        </div>
        <div class="form-item">
            <div>City<span class="red" *ngIf="type !== 'view'"> *</span></div>
            <p *ngIf="type === 'view'; else cityInput;">{{ addressDetails.city }}</p>
            <ng-template #cityInput>
                <input nz-input type="text" placeholder="Enter city" [(ngModel)]="address.city" />
            </ng-template>
        </div>
        <div class="form-item">
            <div>State<span class="red" *ngIf="type !== 'view'"> *</span></div>
            <p *ngIf="type === 'view'; else stateInput;">{{ addressDetails.state }}</p>
            <ng-template #stateInput>
                <input nz-input type="text" placeholder="Enter state" [(ngModel)]="address.state" />
            </ng-template>
        </div>
        <div class="form-item">
            <div>ZIP<span class="red" *ngIf="type !== 'view'"> *</span></div>
            <p *ngIf="type === 'view'; else zipInput;">{{ addressDetails.zip }}</p>
            <ng-template #zipInput>
                <input nz-input type="text" placeholder="Enter zip number" [(ngModel)]="address.zip" />
            </ng-template>
        </div>
        <div class="form-item">
            <div>Country<span class="red" *ngIf="type !== 'view'"> *</span></div>
            <p *ngIf="type === 'view'; else countryInput;">{{ addressDetails.country }}</p>
            <ng-template #countryInput>
                <input nz-input type="text" placeholder="Enter country" [(ngModel)]="address.country" />
            </ng-template>
        </div>
        <div class="modal-buttons" *ngIf="type !== 'view'">
            <button nz-button nzType="primary" (click)="updateAddress()" [nzLoading]="isAddressLoading" [disabled]="addBtnDisabled">
                Add
            </button>
            <button nz-button nzType="default" (click)="closeAddressModal()">Cancel</button>
        </div>
    </ng-container>
</div>
