import { Injectable } from '@angular/core';
import { RequestService } from '@services/request.service';
import { environment } from '@src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class Api1688Service {
    constructor(private requestService: RequestService) {}

    getOrderDetailByOrderId(orderId: string): Promise<{
        result: any;
        errorCode: any;
        errorMessage: string;
        totalRecord: any;
    }> {
        return this.requestService.sendRequest(
            {
                method: 'GET',
                url: `/am/open1688/buyerView/${orderId}`,
            },
            environment.dataApiService
        );
    }
}
