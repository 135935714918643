<section class="search-filter-box">
    <section class="search-filter" [ngClass]="{'search-filters': filterList.length < 4 }">
        <div class="search-box-list">
            <section class="search">
                <nz-select class="search-select" [(ngModel)]="searchParams.selectedCategory" [nzPlaceHolder]="'Search Category'">
                    <nz-option *ngFor="let item of searchOptions" [nzLabel]="item.name" [nzValue]="item.id"></nz-option>
                </nz-select>
                <input
                    class="search-input"
                    type="text"
                    nz-input
                    nzBorderless="true"
                    placeholder="Search"
                    [(ngModel)]="searchParams.searchValue"
                    (keyup.enter)="startSearch()"
                />

                <section class="search-btns">
                    <button nz-button nzType="text" (click)="clearSearch()"><i nz-icon nzType="close"></i></button>
                    <button nz-button nzType="text" (click)="startSearch()"><i nz-icon nzType="search"></i></button>
                    <button nz-button nzType="text" (click)="reset()">Reset</button>
                </section>
            </section>

            <section class="date-times" *ngIf="isShowDatePicker">
                <nz-range-picker
                    [nzFormat]="dateFormat"
                    [nzPlaceHolder]="datePickerPlaceholder"
                    [(ngModel)]="dateTimes"
                    (ngModelChange)="onChangeDate()"
                ></nz-range-picker>
            </section>
        </div>
        <section class="filter-btn" [ngClass]="{'filter-btns': filterList.length > 3 }" *ngIf="isShowFilterBtns">
            <nz-select
                *ngFor="let filter of filterList"
                class="filter-item"
                nzSize="large"
                nzMode="multiple"
                [(ngModel)]="filter.selectedOptionIds"
                [nzPlaceHolder]="filter.placeholder"
                [nzShowArrow]="true"
                [nzMaxTagCount]="1"
                [nzMaxTagPlaceholder]="typePlaceHolder"
                (ngModelChange)="filterUpdate()"
            >
                <nz-option *ngFor="let option of filter.options" [nzValue]="option.id" [nzLabel]="option.name"></nz-option>
            </nz-select>

            <ng-template #typePlaceHolder let-selectedList> +{{ selectedList.length }} </ng-template>
        </section>
        <ng-content></ng-content>
    </section>

    <section class="tag-section" *ngIf="hasFilter">
        <ng-container *ngFor="let filter of filterList">
            <nz-tag *ngIf="filter.selectedOptionIds?.length > 0" nzMode="closeable" (nzOnClose)="removeFilterTag(filter)">
                {{ filter.placeholder }}: {{ filterParamsOfTag.get(filter).join(', ') }}
            </nz-tag>
        </ng-container>
    </section>
</section>
