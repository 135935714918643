<nz-modal
    [(nzVisible)]="isVisible"
    [nzTitle]="modalTitle"
    [nzContent]="modalContent"
    [nzFooter]="null"
    [nzWidth]="800"
    (nzOnCancel)="handleCancel()"
>
    <ng-template #modalTitle>
        <h3>{{ title }}</h3>
    </ng-template>

    <ng-template #modalContent>
        <div class="create-container">
            <div class="interest">
                <h4># Creator Attributes</h4>
                <app-label-common #labelCommon [labelType]="attributesArr" typeLevel="two"></app-label-common>
            </div>
            <div class="interest">
                <h4># Store Attributes</h4>
                <app-label-common #threeLevel [labelType]="productArr" typeLevel="three"></app-label-common>
            </div>
            <div class="btnGroup">
                <button nz-button nzType="primary" (click)="handleOk()" [nzLoading]="isConfirmLoading">Save</button>
                <button nz-button nzType="default" (click)="handleCancel()">Cancel</button>
            </div>
        </div>
    </ng-template>
</nz-modal>
