<div class="main-content">
    <section class="btn-operations" style="height: 40px">
        <span class="main-content-title">
            {{ (pageSource === pageSourceEnum.SAMPLE ? 'SR_TITLE_SAMPLE_PURCHASE' : 'SR_TITLE_SAMPLE_STATUS') | translate}}
        </span>
        <button
            *ngIf="pageSource === pageSourceEnum.SAMPLE && statusTab !== 'voided'"
            nz-button
            nzSize="large"
            nzType="primary"
            [disabled]="selectedPendingSamplePurchaseRequests.size === 0"
            (click)="bulkAddSamplePurchaseRecord()"
        >
            {{ 'SR_ACTION_BULK_ADD_PURCHASE' | translate }}
        </button>
    </section>

    <section class="filter-status">
        <section>
            <div
                class="complete"
                *ngFor="let status of (pageSource === pageSourceEnum.SAMPLE ? sampleStatusTab : storeStatusTab)"
                [ngClass]="{ select: statusTab === status.value }"
                (click)="selectStatus(status.value)"
            >
                {{ status.name | translate }}
                <span *ngIf="status.value === 'unfulfilled'">{{ isUnConfirmLength }}</span>
            </div>
        </section>
        <section class="date-times">
            <nz-range-picker [nzFormat]="dateFormat" [(ngModel)]="dateTimes" (ngModelChange)="onChangeDateTimes()"></nz-range-picker>
        </section>
    </section>

    <section class="data-table">
        <nz-table
            #rowSelectionTable
            nzShowPagination
            nzShowSizeChanger
            [nzLoading]="fetchingSamplePurchase"
            [nzData]="samplePurchaseList"
            [nzShowPagination]="true"
            [nzScroll]="{ x: pageSource === pageSourceEnum.SAMPLE ? '2100px' : '2900px' }"
        >
            <thead>
                <tr>
                    <th
                        nzWidth="50px"
                        *ngIf="pageSource === pageSourceEnum.SAMPLE && statusTab !== 'voided'"
                        [(nzChecked)]="allChecked"
                        (nzCheckedChange)="checkAll($event)"
                    ></th>
                    <ng-container *ngFor="let column of columnList">
                        <th
                            *ngIf="!column.show_search"
                            [nzSortFn]="column.sortFn"
                            [nzFilters]="column.listOfFilter"
                            [nzFilterFn]="column.filterFn"
                            [nzWidth]="column.width"
                        >
                            {{ column.name | translate }}
                        </th>
                        <th *ngIf="column.show_search" [nzSortFn]="column.sortFn" [nzWidth]="column.width">
                            {{ column.name | translate }}
                            <app-table-search-dropdown
                                [defaultProductList]="defaultSamplePurchaseList"
                                [name]="column.id"
                                [options]="column.options"
                                (searchList)="searchResultList($event)"
                                (resetList)="resetResultList()"
                            ></app-table-search-dropdown>
                        </th>
                    </ng-container>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of rowSelectionTable.data" [class.selected]="!!selectedPendingSamplePurchaseRequests.has(data)">
                    <td
                        nzWidth="50px"
                        *ngIf="pageSource === pageSourceEnum.SAMPLE && statusTab !== 'voided'"
                        [nzChecked]="!!selectedPendingSamplePurchaseRequests.has(data)"
                        (nzCheckedChange)="updateSingleCheck(data)"
                    ></td>
                    <td>{{ data.id }}</td>
                    <td>
                        <div class="d-flex align-items-center">
                            <div style="width: 60px; height: 60px">
                                <img
                                    *ngIf="data.advanced_customization_images"
                                    [src]="data.advanced_customization_images"
                                    width="60"
                                    height="60"
                                />
                            </div>
                            <div style="padding-left: 5px">
                                <div>{{ data.product_name }}</div>
                                <div class="text-underline" (click)="goProductDetail(data)">{{ data.product_id }}</div>
                            </div>
                        </div>
                    </td>
                    <td>
                        {{ data.shop_id }} <br />
                        {{ data.shop_name }} <nz-tag [nzColor]="'cyan'" *ngIf="data.testing_account">Testing</nz-tag>
                    </td>
                    <td>
                        <span *ngIf="data.sample_type === 'advanced_customization'">{{'SAMPLE_TYPE_ADVANCED' | translate }}</span>
                        <span *ngIf="data.sample_type === 'print_on_demand'">{{'SAMPLE_TYPE_POD' | translate }}</span>
                        <span *ngIf="data.sample_type === 'new_product'">{{'SAMPLE_TYPE_NEW' | translate }}</span>
                    </td>
                    <td *ngIf="pageSource === pageSourceEnum.SAMPLE">{{ data.request_quantity }} / {{ data.purchased_quantity }}</td>
                    <td>
                        <div [ngSwitch]="data.urgency">
                            <section *ngSwitchCase="'high'" class="urgency-status" [class.orange]="data.urgency === 'high'">
                                <section class="dot fill-dot"></section>
                                <span style="text-transform: capitalize">高 High</span>
                            </section>
                            <section *ngSwitchCase="'medium'" class="urgency-status" [class.yellow]="data.urgency === 'medium'">
                                <section class="dot fill-dot"></section>
                                <span style="text-transform: capitalize">中 Medium</span>
                            </section>
                            <section *ngSwitchCase="'low'" class="urgency-status" [class.yellow]="data.urgency === 'medium'">
                                <section class="dot fill-dot"></section>
                                <span style="text-transform: capitalize">低 Low</span>
                            </section>
                        </div>
                    </td>
                    <td>{{ data.required_date | date: 'MM/dd/yyyy' }}</td>
                    <td>
                        {{ data.estimate_date | date: 'MM/dd/yyyy' }}
                        <i
                            nz-icon
                            *ngIf="data.required_date_history?.length > 1"
                            [nzTooltipTitle]="RequiredDateRecord"
                            nzTooltipPlacement="top"
                            nz-tooltip
                            nzType="question-circle"
                            nzTheme="outline"
                            style="color: #414bb2"
                        ></i>
                        <ng-template #RequiredDateRecord>
                            <div style="margin-bottom: 5px" *ngFor="let item of data.required_date_history">
                                {{item.created_by}} at {{item.created_at | date: 'MM/dd/yyyy HH:mm'}} set as：{{item.required_date | date:
                                'MM/dd/yyyy'}}
                            </div>
                        </ng-template>
                    </td>
                    <td>{{ data.logistics_deliver_eta | date: 'MM/dd/yyyy' }}</td>
                    <td>{{sampleRequestStatusText.get(data.sample_status) | translate}}</td>
                    <td *ngIf="pageSource === pageSourceEnum.STORE">
                        <div [ngSwitch]="data.display_status" style="font-size: 13px">
                            <span *ngSwitchCase="'unpurchased'">未采购 Unpurchased</span>
                            <span *ngSwitchCase="'in_transit'">采购在途 In-Transit</span>
                            <span *ngSwitchCase="'china_arrived'">国内到达 China Arrived</span>
                            <span *ngSwitchCase="'intl_in_transit'">国际在途 Intl In-Transit</span>
                            <span *ngSwitchCase="'intl_delivered'">国际到达 Intl Delivered</span>
                        </div>
                        <div *ngIf="data.display_status !== 'unpurchased' || this.statusTab === 'fulfilled'">
                            {{ data.display_quantity }}件 {{ data.display_date | date: 'MM/dd/yyyy' }}
                        </div>
                    </td>
                    <td *ngIf="pageSource === pageSourceEnum.STORE">
                        {{ data.request_quantity }} / {{ data.purchased_quantity }} / {{ data.arrived_quantity }} / {{ data.shipped_quantity
                        }} / {{ data.intl_arrived_quantity }}
                    </td>
                    <td *ngIf="pageSource === pageSourceEnum.STORE">
                        <div *ngIf="data.logistics_carrier">
                            <!-- <p>国际<i class="tracking-icon" nz-icon nzType="eye" nzTheme="fill" style="margin-left: 4px; color: #414bb2"></i></p> -->
                            <p>{{data.logistics_carrier}} {{data.logistics_tracking_number}}</p>
                        </div>
                        <div *ngIf="data.purchase_carrier">
                            <!-- <p>国内<i class="tracking-icon" nz-icon nzType="eye" nzTheme="fill" style="margin-left: 4px; color: #414bb2"></i></p> -->
                            <p>{{data.purchase_carrier}} {{data.purchase_tracking_number}}</p>
                        </div>
                    </td>
                    <td class="check-requirements" *ngIf="pageSource === pageSourceEnum.STORE">
                        <div *ngIf="data.qc_required">
                            <ng-container *ngIf="data.qc_result?.images?.length || data.qc_result?.image_link; else qcUnconfirmed"
                                ><span class="view-qc-image" (click)="showSampleRequestModal(data)"
                                    ><i nz-icon nzType="check" nzTheme="outline"></i>View QC Images</span
                                ></ng-container
                            >
                            <ng-template #qcUnconfirmed> - QC & QC Image </ng-template>
                        </div>
                        <div *ngIf="data.live_demo_requried" style="white-space: nowrap; padding: 0 10px">
                            <i nz-icon nzType="check" nzTheme="outline"></i>
                            <span>直播验货</span>
                            <span
                                *ngIf="data.approved_at"
                                style="padding: 2px 6px; border-radius: 18px; background-color: #28b370; color: #fff; margin-left: 5px"
                            >
                                网红已确认
                            </span>
                        </div>
                        <div *ngIf="data.photo_shoot_required"><i nz-icon nzType="check" nzTheme="outline"></i>国内拍摄上线内容</div>
                    </td>
                    <td>
                        <a (click)="selectedSampleRequest = data; instructionVisible = true"> {{ 'SR_ACTION_VIEW_DETAIL' | translate }} </a>
                    </td>
                    <td *ngIf="pageSource === pageSourceEnum.STORE">
                        <a (click)="showSampleRequestModal(data)"> {{ 'SR_ACTION_VIEW_DETAIL' | translate }} </a>
                    </td>
                    <td *ngIf="pageSource === pageSourceEnum.STORE">
                        {{ data.address_detail.name }} <br />
                        <a (click)="showAddressDetails($event, data.address_detail)" class="address-details"
                            >{{ 'SR_ACTION_DETAIL' | translate }}</a
                        >
                    </td>
                    <td>
                        <div>{{ data.requestor }}</div>
                        <div>{{ data.created_at | date: 'MM/dd/yyyy' }}</div>
                    </td>
                    <td>
                        <ng-container *ngIf="pageSource === pageSourceEnum.SAMPLE">
                            <button nz-button nzType="primary" (click)="showSampleRequestModal(data)" style="margin-right: 10px">
                                <span>{{ 'SR_ACTION_DETAIL' | translate }}</span>
                            </button>
                            <ng-container *ngIf="data.purchased_quantity >= data.request_quantity || data.purchase_complete">
                                <button *ngIf="data.note" nz-button nzType="text" (click)="viewConfirmNote(data)" style="padding: 4px 5px">
                                    <span style="color: #414bb2">{{ 'SR_ACTION_NOTE' | translate }}</span>
                                </button>
                            </ng-container>
                            <button
                                *ngIf="data.status === 'cancelled'"
                                nz-button
                                nzType="text"
                                (click)="viewConfirmNote(data)"
                                style="padding: 4px 5px"
                            >
                                <span style="color: #414bb2">{{ 'SR_ACTION_NOTE' | translate }}</span>
                            </button>
                            <ng-container
                                *ngIf="data.purchased_quantity < data.request_quantity && !data.purchase_complete && data.status !== 'cancelled'"
                            >
                                <button
                                    nz-button
                                    nzType="primary"
                                    (click)="updateSampleStatus(data)"
                                    style="margin-right: 10px"
                                    *ngIf="data.sample_status === sampleRequestStatusEnum.INITIATED || data.sample_status === sampleRequestStatusEnum.SENT_TO_VENDOR"
                                >
                                    <span>{{ sampleRequestStatusButton.get(data.sample_status) | translate }}</span>
                                </button>
                                <button
                                    nz-button
                                    nzType="text"
                                    (click)="confirmPurchaseParams.id = data.id; confirmModalControl.showModal()"
                                    style="padding: 4px 5px"
                                >
                                    <span style="color: #414bb2">{{ 'SR_ACTION_MANUAL_PURCHASED' | translate }}</span>
                                </button>
                                <button
                                    *ngIf="data.fulfillment_status !== 'fulfilled'"
                                    nz-button
                                    nzType="text"
                                    (click)="invalidSampleParams.id = data.id;invalidModalControl.showModal()"
                                    style="padding: 4px 5px; margin-left: 5px"
                                >
                                    <span style="color: #414bb2">{{ 'SR_ACTION_VOID' | translate }}</span>
                                </button>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="pageSource === pageSourceEnum.STORE">
                            <button nz-button nzType="primary" (click)="showSampleRequestModal(data)" style="margin-right: 10px">
                                <span>{{ 'SR_ACTION_DETAIL' | translate }}</span>
                            </button>

                            <ng-container *ngIf="data.status === 'confirmed'">
                                <button nz-button nzType="primary" (click)="showPurchaseRequirement($event, data, 'details')">
                                    {{ 'SR_ACTION_CONFIRMED_DETAILS' | translate }}
                                </button>
                            </ng-container>
                            <ng-container *ngIf="data.status == null">
                                <button
                                    *ngIf="data.sample_type === 'new_product'"
                                    nz-button
                                    nzType="primary"
                                    (click)="showPurchaseRequirement($event, data, 'confirm')"
                                >
                                    {{ 'SR_ACTION_CONFIRM_SELL' | translate }}
                                </button>
                                <button nz-button nzType="text" (click)="confirmSell($event, data.id, 'skipped')">
                                    <span style="color: #414bb2">{{ 'SR_ACTION_SKIP' | translate }}</span>
                                </button>
                            </ng-container>
                            <ng-container *ngIf="data.status === 'skipped'"
                                ><span class="skipped">{{ 'SR_ACTION_SKIPPED' | translate }}</span></ng-container
                            >
                            <button
                                *ngIf="data.intl_arrived_quantity > 0 && data.status == null"
                                nz-button
                                nzType="text"
                                (click)="confirmArchive($event, data.id)"
                            >
                                <span style="color: #414bb2">{{ 'SR_ACTION_ARCHIVE' | translate }}</span>
                            </button>
                        </ng-container>
                    </td>
                </tr>
            </tbody>
        </nz-table>
    </section>
</div>

<nz-modal
    [(nzVisible)]="addressDetailsModalVisible"
    (nzOnCancel)="addressDetailsModalVisible = false"
    [nzFooter]="null"
    [nzTitle]="modalTitle1"
    nzWidth="500px"
>
    <ng-template #modalTitle1>
        <span style="color: #0d053c; font-weight: bold; font-size: 20px">Address Details</span>
    </ng-template>
    <app-address-modal [addressDetails]="addressDetails"></app-address-modal>
</nz-modal>

<nz-modal
    [(nzVisible)]="purchaseRequirementModalVisible"
    (nzOnCancel)="purchaseRequirementModalVisible = false"
    [nzFooter]="null"
    [nzTitle]="purchaseModalTitle"
    nzWidth="500px"
>
    <ng-template #purchaseModalTitle>
        <span style="color: #0d053c; font-weight: bold; font-size: 20px">上品要求</span>
    </ng-template>
    <div class="purchase-container clearfix">
        <div class="form-item">
            <div class="title">要售卖的SKU<span class="required">*</span></div>
            <textarea
                [readonly]="purchaseDetails"
                nz-input
                [(ngModel)]="purchaseConfirmParams.batch_purchase_requirement"
                [nzAutosize]="{ minRows: 5, maxRows: 10 }"
            ></textarea>
        </div>
        <div class="form-item">
            <div class="title">生产大货的微调意见</div>
            <textarea
                [readonly]="purchaseDetails"
                nz-input
                [(ngModel)]="purchaseConfirmParams.production_adjustment"
                [nzAutosize]="{ minRows: 3, maxRows: 5 }"
            ></textarea>
        </div>
        <div class="modal-buttons" *ngIf="!purchaseDetails">
            <button
                nz-button
                nzType="primary"
                (click)="addPurchaseConfirm()"
                [disabled]="!purchaseConfirmParams.batch_purchase_requirement"
                [nzLoading]="isPurchaseLoading"
            >
                确定
            </button>
            <button nz-button nzType="default" (click)="purchaseRequirementModalVisible = false">取消</button>
        </div>
    </div>
</nz-modal>

<nz-modal
    nzTitle="View Sample Request Information 查看样品采购请求信息"
    [(nzVisible)]="sampleRequestDetailsVisible"
    [nzWidth]="1000"
    [nzFooter]="null"
    (nzOnCancel)="sampleRequestDetailsVisible = false; selectedSampleRequest = null"
>
    <app-edit-sample-request
        *ngIf="selectedSampleRequest"
        [selectedSampleRequest]="selectedSampleRequest"
        [isEdit]="statusTab === 'unfulfilled'"
        [pageSource]="pageSource"
        (updateSampleRequest)="updateSampleRequest($event)"
        (closeModal)="sampleRequestDetailsVisible = false; selectedSampleRequest = null"
    ></app-edit-sample-request>
</nz-modal>

<nz-modal
    [(nzVisible)]="instructionVisible"
    [nzWidth]="588"
    [nzFooter]="null"
    (nzOnCancel)="instructionVisible = false; selectedSampleRequest = null"
>
    <app-instruction-notes
        *ngIf="selectedSampleRequest"
        [isEdit]="statusTab === 'unfulfilled'"
        [sampleRequestInfo]="selectedSampleRequest"
        (updateNote)="updateSampleRequest($event)"
    ></app-instruction-notes>
</nz-modal>

<nz-modal
    nzTitle="手动采购完成"
    nzOkText="确定"
    nzCancelText="取消"
    [nzOkDisabled]="!confirmPurchaseParams.note"
    [nzOkLoading]="confirmModalControl.okBtnLoading"
    [nzMaskClosable]="false"
    [(nzVisible)]="confirmModalControl.visible"
    (nzOnCancel)="confirmModalControl.hideModal()"
    (nzOnOk)="confirmPurchaseComplete()"
>
    <div>
        <div style="margin-bottom: 10px">
            <div style="margin-bottom: 10px">备注</div>
            <textarea
                nz-input
                [(ngModel)]="confirmPurchaseParams.note"
                placeholder="备注内容"
                [nzAutosize]="{ minRows: 3, maxRows: 3 }"
            ></textarea>
        </div>
    </div>
</nz-modal>

<nz-modal
    nzTitle="作废请求"
    nzOkText="确定"
    nzCancelText="取消"
    [nzOkDisabled]="invalidSamplePurchaseDisabled"
    [nzOkLoading]="invalidModalControl.okBtnLoading"
    [nzMaskClosable]="false"
    [(nzVisible)]="invalidModalControl.visible"
    (nzOnCancel)="invalidModalControl.hideModal()"
    (nzOnOk)="invalidSamplePurchase()"
>
    <div>
        <div style="margin-bottom: 10px">
            <nz-select [(ngModel)]="invalidSampleParams.reason" style="width: 100%">
                <nz-option nzValue="网红不要了" nzLabel="网红不要了"></nz-option>
                <nz-option nzValue="样品采买不到了" nzLabel="样品采买不到了"></nz-option>
                <nz-option nzValue="重复的打样请求" nzLabel="重复的打样请求"></nz-option>
                <nz-option nzValue="其他" nzLabel="其他"></nz-option>
            </nz-select>
        </div>
        <div style="margin-bottom: 10px" [hidden]="invalidSampleParams.reason !== '其他'">
            <div style="margin-bottom: 10px">其他理由</div>
            <textarea
                nz-input
                [(ngModel)]="invalidSampleParams.note"
                placeholder="在此填写其他理由"
                [nzAutosize]="{ minRows: 3, maxRows: 3 }"
            ></textarea>
        </div>
    </div>
</nz-modal>
