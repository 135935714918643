import { Injectable } from '@angular/core';
import { SkuListItem } from './order-dispatch.type';

@Injectable({
    providedIn: 'root',
})
export class OrderDispatchCacheService {
    platformSkuMap = new Map<string, SkuListItem>();

    private pAllSku: SkuListItem[];

    get allSku() {
        return this.pAllSku;
    }

    set allSku(value) {
        this.pAllSku = value;
        value.forEach(skuItem => {
            this.platformSkuMap.set(skuItem.platform_sku, skuItem);
        });
    }

    constructor() {}
}
