import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import * as fs from 'file-saver';
import { UtilsService } from '@services/util.service';
import { map } from 'lodash';

@Component({
    selector: 'app-custom-info',
    templateUrl: './custom-info.component.html',
    styleUrls: ['./custom-info.component.less'],
})
export class CustomInfoComponent implements OnInit {
    @Input() set inputInfo(info: any) {
        this.customInfo = info;
        this.requirements = [];
        if (this.customInfo) {
            Object.entries(this.customInfo.customization_detail.customization_detail).forEach(item => {
                if (item[0] !== 'free_input') {
                    if (this.customInfo.customization_detail.customization_detail[item[0]] === 'Others') {
                        const value = this.customInfo.customization_detail.customization_detail.free_input[item[0]];
                        item.push(value);
                    }
                    this.requirements.push(item);
                }
            });
            if (this.customInfo.template_detail) {
                const { customization_detail } = this.customInfo.template_detail;
                this.customInfo.template_detail.customization_detail_list = map(customization_detail, (ele, index) => ({
                    name: index,
                    value: ele === 'Others' ? customization_detail?.free_input[index] : ele,
                }));
            }
        }
    }
    @Output() closeModal = new EventEmitter();

    customInfo: any;
    requirements = [];

    downloading = false;

    constructor(private utilService: UtilsService) {}

    ngOnInit(): void {}

    download(imgItem: { src: string; name: string; path: string }) {
        if (imgItem.path && imgItem.name) {
            fs.saveAs(`https://storage.googleapis.com/influencer-272204.appspot.com/${imgItem.path}`, imgItem.name);
        } else {
            fs.saveAs(imgItem.src);
        }
    }

    downloadAll() {
        const paths: { path: string; name?: string }[] = [];
        const customization_detail = this.customInfo.customization_detail;
        customization_detail?.images.forEach(imgItem => {
            paths.push({ path: imgItem.path, name: imgItem.name });
        });
        customization_detail?.logo_images.forEach(imgItem => {
            paths.push({ path: imgItem.path, name: imgItem.name });
        });

        if (paths.length > 0) {
            this.downloading = true;
            this.utilService
                .downloadFiles(paths)
                .then((res: { path: string; src: string }) => fs.saveAs(res.src))
                .finally(() => (this.downloading = false));
        }
    }

    close() {
        this.closeModal.emit();
    }
}
