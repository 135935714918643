import { cloneDeep, find } from 'lodash';
import { Component, Input, SimpleChanges, OnInit, OnChanges, Output, EventEmitter } from '@angular/core';
import { OrderDispatchService } from '@services/order-dispatch.service';
import { CHECK_REQUIREMENT_OPTIONS } from '@pages/purchase-management/sample-purchase/sample-purchase.data';
import { NzMessageService } from 'ng-zorro-antd/message';
import { SamplePurchaseItem, SampleRequestStatusText } from '@shared/type/order-dispatch.type';
import { ImagePreviewFullscreenService } from '@services/image-preview-fullscreen.service';
import * as fs from 'file-saver';
import { UtilsService } from '@services/util.service';

enum PageSource {
    SAMPLE = 'sample',
}
@Component({
    selector: 'app-edit-sample-request',
    templateUrl: './edit-sample-request.component.html',
    styleUrls: ['./edit-sample-request.component.less'],
})
export class EditSampleRequestComponent implements OnInit, OnChanges {
    @Input() selectedSampleRequest: SamplePurchaseItem;
    @Input() isEdit = true;
    @Input() pageSource = PageSource.SAMPLE;
    @Output() updateSampleRequest = new EventEmitter();
    @Output() closeModal = new EventEmitter();

    PageSourceEnum = PageSource;

    sampleRequestInfo: any;
    addressLists = [];
    urgencyList = [
        { id: 'high', name: '高 High' },
        { id: 'medium', name: '中 Medium' },
        { id: 'low', name: '低 Low' },
    ];
    editCheckRequirementOptions = cloneDeep(CHECK_REQUIREMENT_OPTIONS);

    addressDetails: any;
    addressDetailsModalVisible = false;
    addressModalType: 'add' | 'view';
    customInfoModalVisible = false;
    customInfo: any[];
    selectedCustom: any;

    editOptions = {
        isEditProductName: false,
        isEditSourcingLink: false,
        isEditRequestQuantity: false,
        isEditUrgency: false,
        isEditRequiredDate: false,
        isEditCheckRequirements: false,
        isEditAddress: false,
        productName: '',
        sourcingLink: '',
        requestQuantity: null,
        urgency: '',
        requiredDate: '',
        address: '',
    };

    sampleRequestStatusText = SampleRequestStatusText;

    downloading;

    constructor(
        private orderDispatchService: OrderDispatchService,
        private message: NzMessageService,
        private imagePreviewFullscreen: ImagePreviewFullscreenService,
        private utilService: UtilsService
    ) {}

    ngOnInit(): void {
        this.orderDispatchService.getAddressBook().then(res => {
            this.addressLists = res;
        });
    }

    ngOnChanges(e: SimpleChanges) {
        if (e.selectedSampleRequest?.currentValue) {
            this.sampleRequestInfo = cloneDeep(this.selectedSampleRequest);
            this.editCheckRequirementOptions[0].checked = this.sampleRequestInfo.qc_required || false;
            this.editCheckRequirementOptions[1].checked = this.sampleRequestInfo.live_demo_requried || false;
            this.editCheckRequirementOptions[2].checked = this.sampleRequestInfo.photo_shoot_required || false;
            if (this.sampleRequestInfo?.advanced_customization_order_id) {
                this.getCustomInfo();
            }
        }
    }

    checkboxChange() {
        this.sampleRequestInfo.qc_required = this.editCheckRequirementOptions[0].checked;
        this.sampleRequestInfo.live_demo_requried = this.editCheckRequirementOptions[1].checked;
        this.sampleRequestInfo.photo_shoot_required = this.editCheckRequirementOptions[2].checked;
    }

    showAddressDetails(type) {
        this.addressModalType = type;
        this.addressDetails = type === 'view' ? this.addressLists.find(ele => ele.id === this.sampleRequestInfo.address) : null;
        this.addressDetailsModalVisible = true;
    }

    async addAddressConfirm(data) {
        this.addressLists.push(data);
        this.sampleRequestInfo.address = data.id;
        this.addressDetailsModalVisible = false;
        this.updateSample('isEditAddress', ['address']);
    }

    getCustomInfo() {
        this.orderDispatchService.getCustomInfo(this.sampleRequestInfo.advanced_customization_order_id).then(res => {
            if (res?.customization_detail_history?.length) {
                this.customInfo = res.customization_detail_history.map(ele => ({
                    ...res,
                    ...ele,
                }));
            } else {
                this.customInfo = [res];
            }
        });
    }

    showCustomInfoDetail(data) {
        this.selectedCustom = data;
        this.customInfoModalVisible = true;
    }

    updateRequiredDate() {
        const data = {
            id: this.sampleRequestInfo.id,
            estimate_date: this.sampleRequestInfo.estimate_date,
        };
        this.orderDispatchService
            .confirmSellSampleRequest(data)
            .then(res => {
                this.editOptions.isEditRequiredDate = false;
                this.updateSampleRequest.emit(res);
            })
            .catch(() => {
                this.message.error('Save failed.');
            });
    }

    updateSample(editKey, formKeys) {
        const data: any = {
            id: this.sampleRequestInfo.id,
        };
        formKeys.forEach(key => (data[key] = this.sampleRequestInfo[key]));
        this.orderDispatchService
            .confirmSellSampleRequest(data)
            .then(res => {
                this.editOptions[editKey] = false;
                this.updateSampleRequest.emit(res);
            })
            .catch(() => {
                this.message.error('Save failed.');
            });
    }

    updateNote(data) {
        this.updateSampleRequest.emit(data);
    }

    close() {
        this.closeModal.emit();
    }

    previewImgMode(index: number, imageList: any[]) {
        this.imagePreviewFullscreen.showModal(imageList, index);
    }

    downloadQcImages() {
        if (this.sampleRequestInfo.qc_result?.images?.length) {
            this.downloading = true;
            this.utilService
                .downloadFiles(this.sampleRequestInfo.qc_result.images)
                .then((res: { path: string; src: string }) => fs.saveAs(res.src))
                .finally(() => (this.downloading = false));
        } else {
            window.open(this.sampleRequestInfo.qc_result?.image_link, '_blank');
        }
    }
}
