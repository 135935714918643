import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { UserPermissionService } from '@services/user-permission.service';
import { LoadingSpinnerService } from '@services/loading-spinner.service';
import { TokenService } from '@services/token.service';

@Injectable({
    providedIn: 'root',
})
export class InternalPermissionGuard implements CanActivate {
    constructor(
        private router: Router,
        private userPermissionService: UserPermissionService,
        private loadingService: LoadingSpinnerService,
        private tokenService: TokenService
    ) {}

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        this.loadingService.show();
        try {
            await this.userPermissionService.initCurrentUserPermission();
            this.loadingService.hide();
        } catch (err) {
            this.loadingService.hide();
            this.tokenService.removeAllToken();
            this.router.navigateByUrl('/sign-in');
            return false;
        }
        return true;
    }
}
