import { Component, Input, OnInit } from '@angular/core';
import { OrderListService, ShippingDetail } from '@pages/order-management/order-list/order-list.service';

@Component({
    selector: 'app-tracking-detail',
    templateUrl: './tracking-detail.component.html',
    styleUrls: ['./tracking-detail.component.less'],
})
export class TrackingDetailComponent implements OnInit {
    @Input() orderId;
    trackingDetail: ShippingDetail;
    fetchingTracking = false;

    constructor(private orderListService: OrderListService) {}

    ngOnInit(): void {
        this.fetchingTracking = true;
        // test orderId 3893780775072
        this.orderListService
            .getLogisticsByOrderId(this.orderId)
            .then(data => (this.trackingDetail = data))
            .finally(() => (this.fetchingTracking = false));
    }
}
