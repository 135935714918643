import { Component, OnInit, Output, EventEmitter } from '@angular/core';

import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import * as timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

@Component({
    selector: 'app-edit-campaign-information-modal',
    templateUrl: './edit-campaign-information-modal.component.html',
    styleUrls: ['./edit-campaign-information-modal.component.less'],
})
export class EditCampaignInformationModalComponent implements OnInit {
    @Output() campaignData = new EventEmitter();
    data: any;
    sendData: any;
    editCampaign = {
        visible: false,
        isConfirmLoading: false,
    };

    startDatePicker = null;
    endDatePicker = null;
    startTimePicker = null;
    endTimePicker = null;
    newStartDatePicker = null;
    newStartTimePicker = null;
    newEndDatePicker = null;
    newEndTimePicker = null;
    campaignPlanList = {
        campaignName: '',
        campaingnTypeValue: '',
        coversCosts: false,
        costTypeValue: '',
        couponCode: '',
        noteValue: '',
        costAmount: null,
    };

    campaingnTypeList = [
        { name: 'Free shipping', value: 'Free shipping' },
        { name: 'Buy X get Y free', value: 'Buy X get Y free' },
        { name: 'X% / $X off', value: 'X% / $X off' },
        { name: 'Other', value: 'Other' },
    ];
    costTypeList = [
        { name: 'Product cost', value: 'Product cost' },
        { name: 'Domestic shipping', value: 'Domestic shipping' },
        { name: 'Other', value: 'Other' },
    ];

    fulfilledFromList = [
        { id: 'us_only', name: 'US Warehouse' },
        { id: 'globally', name: 'China Warehouse' },
        { id: 'us_only,globally', name: 'Both US Warehouse (To US & CA) And China Warehouse (Global)' },
    ];
    fulfilledFrom: string;
    launchCampaign = false;

    noteValue?: string;
    dateFormat = 'MM/dd/yyyy';
    get addSkusDisabled() {
        return (
            !this.campaignPlanList.campaignName ||
            !this.campaignPlanList.campaingnTypeValue ||
            !this.startDatePicker ||
            !this.endDatePicker ||
            !this.startTimePicker ||
            !this.endTimePicker ||
            !this.fulfilledFrom
        );
    }
    constructor() {}

    ngOnInit() {}

    showModals() {
        this.campaignPlanList = {
            campaignName: this.data.campaign_name,
            campaingnTypeValue: this.data.campaign_type,
            coversCosts: this.data.is_cost_covered,
            costTypeValue: this.data.cost_type,
            couponCode: this.data.coupon_code,
            noteValue: this.data.note,
            costAmount: this.data.cost_amount,
        };
        this.startDatePicker = dayjs(this.data.starts_at).subtract(15, 'hour').format('YYYY-MM-DD');
        this.endDatePicker = dayjs(this.data.ends_at).subtract(15, 'hour').format('YYYY-MM-DD');
        this.startTimePicker = dayjs(this.data.starts_at).subtract(15, 'hour');
        this.endTimePicker = dayjs(this.data.ends_at).subtract(15, 'hour');
        this.newStartDatePicker = dayjs(this.startDatePicker).format('YYYY-MM-DD');
        this.newStartTimePicker = dayjs(this.startTimePicker).format('HH:mm:ss');
        this.newEndDatePicker = dayjs(this.endDatePicker).format('YYYY-MM-DD');
        this.newEndTimePicker = dayjs(this.endTimePicker).format('HH:mm:ss');
        this.editCampaign.isConfirmLoading = false;
        this.editCampaign.visible = true;
    }

    handleCancel() {
        this.editCampaign.visible = false;
    }

    editCampaignHandleOk() {
        this.editCampaign.isConfirmLoading = true;
        this.newStartDatePicker = dayjs(this.startDatePicker).format('YYYY-MM-DD');
        this.newStartTimePicker = dayjs(this.startTimePicker).format('HH:mm:ss');
        this.newEndDatePicker = dayjs(this.endDatePicker).format('YYYY-MM-DD');
        this.newEndTimePicker = dayjs(this.endTimePicker).format('HH:mm:ss');
        this.sendData = {
            campaign_name: this.campaignPlanList.campaignName,
            starts_at: dayjs.tz(`${this.newStartDatePicker} ${this.newStartTimePicker}`, 'America/Los_Angeles').format(),
            ends_at: dayjs.tz(`${this.newEndDatePicker} ${this.newEndTimePicker}`, 'America/Los_Angeles').format(),
            campaign_type: this.campaignPlanList.campaingnTypeValue,
            is_cost_covered: this.campaignPlanList.coversCosts,
            cost_type: this.campaignPlanList.costTypeValue,
            cost_amount: this.campaignPlanList.costAmount,
            coupon_code: this.campaignPlanList.couponCode,
            note: this.campaignPlanList.noteValue,
            shop_id: this.data.shop_id,
            launch_campaign: this.launchCampaign,
            fulfilled_from: this.fulfilledFrom,
            sku_list: this.data.sku_list,
            campaign_id: this.data.id,
        };
        this.campaignData.emit(this.sendData);
        this.editCampaign.visible = false;
    }

    onChangeStartDate() {
        this.newStartDatePicker = dayjs(this.startDatePicker).format('YYYY-MM-DD');
    }

    onChangeStartTimes() {
        this.newStartTimePicker = dayjs(this.startTimePicker).format('HH:mm:ss');
    }

    onChangeEndDate() {
        this.newEndDatePicker = dayjs(this.endDatePicker).format('YYYY-MM-DD');
    }

    onChangeEndTimes() {
        this.newEndTimePicker = dayjs(this.endTimePicker).format('HH:mm:ss');
    }

    disabledDate = (current: Date): boolean => dayjs(current).diff(dayjs(), 'day') < 0;

    disabledEndDate = (current: Date): boolean => dayjs(current).diff(this.startDatePicker, 'day') < 0;
}
