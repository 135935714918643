import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-loading-spinner',
    templateUrl: './loading-spinner.component.html',
    styleUrls: ['./loading-spinner.component.less'],
})
export class LoadingSpinnerComponent implements OnInit {
    @Input() public message: string;
    constructor() {}

    public ngOnInit() {}
}
