<div class="expandTr">
    <h4>
        Store Labeling
        <a *ngIf="isEdit" (click)="isShowListLabel(storeLabelList?.id)"
            ><span><i nz-icon nzType="edit" nzTheme="outline"></i> Edit</span></a
        >
    </h4>
    <div class="label-type clearfix">
        <div class="left"># Creator Attributes</div>
        <div class="right">
            <ng-container *ngFor="let item of storeLabelList?.label_list?.attributes; let i = index">
                <nz-tag class="tag-father" nzMode="default"> #{{ item['name'] }} </nz-tag>
                <ng-container *ngFor="let k of item['children']">
                    <span class="tag-child-margin">
                        <nz-tag class="tag-children" nzMode="default"> #{{ k['name'] }}</nz-tag>
                    </span>
                </ng-container>
            </ng-container>
        </div>
    </div>
    <div class="label-type clearfix" style="margin-bottom: 20px">
        <div class="left"># Store Attributes</div>
        <div class="right">
            <ng-container *ngFor="let item of storeLabelList?.label_list?.product; let i = index">
                <div class="tag-title">
                    <h4 class="tag-father-top">#{{ item.name }}</h4>
                    <ng-container *ngFor="let k of item['children']">
                        <div class="tag-subtitle">
                            <nz-tag class="tag-father" nzMode="default"> #{{ k.name }} </nz-tag>
                            <ng-container *ngFor="let v of k['children']">
                                <span class="tag-child-margin">
                                    <nz-tag class="tag-children" nzMode="default"> #{{ v.name }}</nz-tag>
                                </span>
                            </ng-container>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<app-list-labeling-modal #listLabel [defaultLabelTagList]="labelTagList" (childOuter)="getData($event)"></app-list-labeling-modal>
