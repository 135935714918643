<ng-template #emptyTable>
    <div class="empty-table-box">
        <img src="assets/images/no-activity.png" alt="" width="105" />
        <div class="desc">No data</div>
    </div>
</ng-template>

<ng-template #tableLoading>
    <div class="table-loading-box">
        <img src="assets/images/loading.jpg" width="30" />
    </div>
</ng-template>
