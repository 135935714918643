<div class="campaign-history">
    <div class="section-title">Campaigns History</div>

    <div class="btn-operations mt-20 mb-20">
        <div class="width-100p d-flex justify-content-start align-items-center mb-20">
            <button
                nz-button
                class="mr-20 {{activeTab === 'upcoming'?'active':''}}"
                [nzType]="activeTab === 'upcoming'?'primary':'default'"
                (click)="activeTab = 'upcoming';filterChange();"
            >
                Upcoming
            </button>
            <button
                nz-button
                class="mr-20 {{activeTab === 'ongoing'?'active':''}}"
                [nzType]="activeTab === 'ongoing'?'primary':'default'"
                (click)="activeTab = 'ongoing';filterChange();"
            >
                Ongoing
            </button>
            <button
                nz-button
                class="mr-20 {{activeTab === 'done'?'active':''}}"
                [nzType]="activeTab === 'done'?'primary':'default'"
                (click)="activeTab = 'done';filterChange();"
            >
                Done
            </button>
        </div>
        <div class="width-100p d-flex justify-content-between align-items-center">
            <div class="left">
                <span class="mr-20">Date Range: Starts From</span>
                <nz-date-picker [(ngModel)]="dateRange.start" (ngModelChange)="filterChange()"></nz-date-picker>
                <span class="mr-20 ml-20">To</span>
                <nz-date-picker [(ngModel)]="dateRange.end" (ngModelChange)="filterChange()"></nz-date-picker>
            </div>
            <button nz-button [hidden]="true">Export</button>
        </div>
    </div>

    <!-- Table -->
    <nz-table #campaignHistoryTable [nzData]="shownTableData" [nzLoading]="loading" [nzScroll]="{ x: '1200px'}">
        <thead>
            <tr>
                <th
                    *ngIf="page === 'trackInventory'"
                    [nzChecked]="true"
                    [(nzChecked)]="allChecked"
                    (nzCheckedChange)="checkAll($event)"
                ></th>
                <th *ngFor="let column of COLUMNS" [nzSortFn]="column.sortFn" [nzWidth]="column.width" [nzSortOrder]="column.sortOrder">
                    {{column.name}}
                </th>
            </tr>
        </thead>
        <tbody>
            <ng-template ngFor let-data [ngForOf]="campaignHistoryTable.data">
                <tr>
                    <td
                        *ngIf="page === 'trackInventory'"
                        [nzChecked]="true"
                        [nzChecked]="!!selectedCampaigns.has(data)"
                        (nzCheckedChange)="updateSingleCheck(data)"
                    ></td>
                    <td *ngFor="let column of COLUMNS" [nzRight]="column.id === 'more'">
                        <div [ngSwitch]="column.id">
                            <div *ngSwitchCase="'date_needed'">
                                <div>{{data['starts_at'] | date:'MM/dd/yyyy HH:mm':'-0700'}} PST</div>
                                <span>to</span>
                                <div>{{data['ends_at'] | date:'MM/dd/yyyy HH:mm':'-0700'}} PST</div>
                            </div>

                            <div *ngSwitchCase="'sku_quantity'">{{data['sku_list']?.length || 0}}</div>
                            <div *ngSwitchCase="'is_cost_covered'">{{data['is_cost_covered']? data['cost_type']: 'No Coverage'}}</div>

                            <div *ngSwitchCase="'note'">
                                <div *ngIf="!data.editing" class="d-flex justify-content-start align-items-center">
                                    <div class="flex-1">{{data['note']}}</div>
                                </div>
                            </div>

                            <div *ngSwitchCase="'shop_name'">
                                <div>{{ data['shop_name'] }}</div>
                                {{ data | json }}
                                <span class="first-launch" *ngIf="data['is_first_launch']">First Launch Campaign</span>
                            </div>

                            <div *ngSwitchCase="'fulfillment_warehouse_name'">{{ data['fulfillment_warehouse_name'] }}</div>

                            <div *ngSwitchCase="'operation'" class="link-color cursor-pointer">
                                <div *ngIf="page==='trackInventory'" [hidden]="true">Export</div>
                                <a
                                    *ngIf="page==='storeManagement' && activeTab === 'upcoming'"
                                    style="margin-right: 10px"
                                    (click)="editCampaign(data)"
                                >
                                    <i nz-icon nzType="edit" nzTheme="fill"></i> Edit
                                </a>
                                <a *ngIf="page==='storeManagement' && activeTab === 'upcoming'" (click)="deleteCampaign(data)"> Delete </a>
                            </div>

                            <div *ngSwitchCase="'more'">
                                <i
                                    nz-icon
                                    class="cursor-pointer"
                                    nzType="caret-{{data.expand?'up':'down'}}"
                                    nzTheme="outline"
                                    (click)="toggleChildTableVisibility(data)"
                                ></i>
                            </div>

                            <div *ngSwitchDefault>{{data[column.id]}}</div>
                        </div>
                    </td>
                </tr>

                <tr [nzExpand]="data.expand">
                    <nz-table
                        #innerTable
                        [nzData]="data.sku_list"
                        [nzSize]="'small'"
                        [nzShowPagination]="false"
                        [nzPageSize]="500"
                        [nzLoading]="!data.skuInfoGotten"
                    >
                        <thead>
                            <tr>
                                <th [nzWidth]="'50px'"></th>
                                <th>SKU & Variant 规格</th>
                                <th>Product Information & ID 产品&ID</th>
                                <th>Current US Inventory</th>
                                <th>US in-transit Inventory + China Total Available inventory</th>
                                <th>Pending Purchase</th>
                                <th>Total In-Transit</th>
                                <th>Total Inventory Of Fulfillment Warehouse</th>
                                <th>Qty needed (Reference)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let innerItem of innerTable.data">
                                <td>
                                    <img width="40" height="40" class="object-fit-cover" [src]="innerItem.image" alt="" />
                                </td>
                                <td>
                                    <div>{{ innerItem.sku }}</div>
                                </td>
                                <td>{{ innerItem.title }}</td>

                                <td>{{innerItem.current_us_inventory || 0}}</td>
                                <td>{{innerItem.us_in_transit_china_total || 0}}</td>
                                <td>--</td>
                                <td>--</td>
                                <td>--</td>
                                <td>
                                    <div *ngIf="!innerItem.editing">
                                        <span>{{ innerItem.quantity }}</span>
                                        <i
                                            *ngIf="page==='storeManagement' && activeTab === 'upcoming'"
                                            nz-icon
                                            nzType="edit"
                                            nzTheme="outline"
                                            class="cursor-pointer ml-20"
                                            (click)="innerItem.editing = true"
                                        ></i>
                                    </div>
                                    <div *ngIf="innerItem.editing" class="d-flex justify-content-start align-items-center">
                                        <nz-input-number [(ngModel)]="innerItem.quantity" [nzMin]="1" [nzStep]="1"></nz-input-number>
                                        <button
                                            nz-button
                                            nzType="primary"
                                            [nzLoading]="innerItem.submitting"
                                            (click)="updateQuantity(data,innerItem)"
                                        >
                                            <i nz-icon nzType="check"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </nz-table>
                </tr>
            </ng-template>
        </tbody>
    </nz-table>
</div>
<app-edit-campaign-information-modal #editCampaignModal (campaignData)="campaignInfo($event)"></app-edit-campaign-information-modal>
