import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { NotificationService } from '@services/notification.service';
import { ImagePreviewFullscreenService } from '@services/image-preview-fullscreen.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less'],
})
export class AppComponent implements OnInit {
    constructor(
        private notificationService: NotificationService,
        private imageFullScreenService: ImagePreviewFullscreenService,
        public vcr: ViewContainerRef
    ) {}

    ngOnInit() {
        this.notificationService.setRootViewContainerRef(this.vcr);
        this.imageFullScreenService.setRootViewContainerRef(this.vcr);
    }
}
