import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { OrderDispatchService } from '@services/order-dispatch.service';

@Component({
    selector: 'app-list-labeling-modal',
    templateUrl: './list-labeling-modal.component.html',
    styleUrls: ['./list-labeling-modal.component.less'],
})
export class ListLabelingModalComponent implements OnInit {
    @Output() childOuter = new EventEmitter();
    @Input() defaultLabelTagList;
    @ViewChild('labelCommon') labelCommon;
    @ViewChild('threeLevel') threeLevel;

    isVisible = false;
    title = 'Store Labeling Board';
    listId: number;
    isConfirmLoading = false;

    influencerContent01 = [];
    influencerContent02 = [];
    attributesArr = [];
    productArr = [];

    constructor(private orderDispatchService: OrderDispatchService) {}
    ngOnInit() {}

    getAllList() {
        this.attributesArr = [];
        this.productArr = [];
        this.orderDispatchService.getLabelList().then(
            result => {
                for (const item of result) {
                    item.checked = false;
                    for (const k of this.defaultLabelTagList) {
                        if (k.id === item.id) {
                            item.checked = true;
                        }
                    }
                    if (item.type === 'attributes') {
                        this.attributesArr.push(item);
                    } else if (item.type === 'product') {
                        this.productArr.push(item);
                    }
                }
                this.labelCommon.isShowDefault();
                this.threeLevel.isShowDefault();
            },
            err => {}
        );
    }

    showModal(): void {
        this.isVisible = true;
        this.getAllList();
    }

    handleClose(obj, removedTag: any): void {
        this[obj] = this[obj].filter(tag => tag !== removedTag);
    }

    handleOk() {
        let arrParam01 = [];
        let arrParam02 = [];
        arrParam01 = this.labelCommon.calLabelId();
        arrParam02 = this.threeLevel.calLabelId();
        this.orderDispatchService
            .addLabelShop({
                shop_id: this.listId,
                label_ids: [...arrParam01, ...arrParam02],
            })
            .then(
                result => {
                    this.isVisible = false;
                    this.childOuter.emit(this.listId);
                },
                err => {}
            );
    }

    handleCancel(): void {
        this.isVisible = false;
    }
}
