import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-logistic-record-error',
    templateUrl: './logistic-record-error.component.html',
    styleUrls: ['./logistic-record-error.component.less'],
})
export class LogisticRecordErrorComponent implements OnInit {
    @Input() errors: string[];

    constructor() {}

    ngOnInit(): void {}
}
