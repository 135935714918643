<div class="top" *ngIf="labelList.length > 0">
    <ng-container *ngFor="let item of labelList; let i = index">
        <ng-container *ngIf="typeLevel==='two'">
            <nz-tag
                class="tag-father"
                *ngIf="item.checked"
                [nzMode]="isMode(item['children'])?'default':'closeable'"
                (nzOnClose)="onTagClose(item)"
            >
                #{{ sliceTagName(item.name) }}
            </nz-tag>
            <ng-container *ngFor="let k of item['children']">
                <span class="tag-child-margin" *ngIf="k.checked">
                    <nz-tag class="tag-children" nzMode="closeable" (nzOnClose)="onTagClose(k)"> #{{ k.name }}</nz-tag>
                </span>
            </ng-container>
        </ng-container>

        <div *ngIf="typeLevel==='three'">
            <div class="tag-title" *ngIf="item.checked">
                <nz-tag class="tag-father-top" [nzMode]="isMode(item['children'])?'default':'closeable'" (nzOnClose)="onTagClose(item)">
                    #{{ sliceTagName(item.name) }}
                </nz-tag>

                <ng-container *ngFor="let k of item['children']">
                    <div>
                        <nz-tag
                            class="tag-father"
                            *ngIf="k.checked"
                            [nzMode]="isMode(k['children'])?'default':'closeable'"
                            (nzOnClose)="onTagClose(k)"
                        >
                            #{{ sliceTagName(k.name) }}
                        </nz-tag>
                        <ng-container *ngFor="let v of k['children']">
                            <span class="tag-child-margin" *ngIf="v.checked">
                                <nz-tag class="tag-children" nzMode="closeable" (nzOnClose)="onTagClose(v)"> #{{ v.name }}</nz-tag>
                            </span>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-container>
    <button
        class="add-new-label"
        nz-button
        nzType="primary"
        nz-popover
        nzPopoverPlacement="bottom"
        [(nzPopoverVisible)]="popVisible"
        nzPopoverTrigger="click"
        [nzPopoverContent]="contentTemplate"
    >
        <i nz-icon nzType="plus"></i> Add New Label
    </button>
    <ng-template #contentTemplate>
        <div class="box-container">
            <div class="regionalBox">
                <nz-checkbox-wrapper style="width: 100%">
                    <ul class="containers isSelected">
                        <li
                            *ngFor="let item of labelList;let i = index;"
                            (click)="isSelectedFun(i, labelList)"
                            [ngClass]="{'selected': item['selected']}"
                        >
                            <label nz-checkbox [(ngModel)]="item.checked" (ngModelChange)="log(item)" [nzValue]="item.name"></label>{{
                            item.name }}
                        </li>
                    </ul>
                </nz-checkbox-wrapper>
            </div>

            <div class="regionalBox">
                <nz-checkbox-wrapper style="width: 100%">
                    <ul class="containers isSelected">
                        <ng-container *ngFor="let item of labelList">
                            <ng-container *ngIf="item.selected">
                                <li
                                    *ngFor="let keys of item.children;let i = index;"
                                    (click)="isSelectedFun(i, item.children)"
                                    [ngClass]="{'selected': keys['selected']}"
                                >
                                    <label
                                        nz-checkbox
                                        [(ngModel)]="keys['checked']"
                                        (ngModelChange)="log(keys)"
                                        [nzValue]="keys.name"
                                    ></label
                                    >{{ keys.name }}
                                </li>
                            </ng-container>
                        </ng-container>
                        <li *ngIf="typeLevel==='two'">
                            <nz-tag
                                *ngIf="!inputCategoryInputVisible"
                                class="editable-tag"
                                nzNoAnimation
                                (click)="showInput('inputCategoryInputVisible')"
                            >
                                # <span>Add New Label</span> <i nz-icon nzType="plus"></i
                            ></nz-tag>

                            <nz-input-group
                                style="width: 180px"
                                nzSearch
                                *ngIf="inputCategoryInputVisible"
                                [nzAddOnAfter]="suffixIconButton"
                            >
                                <input
                                    #inputElement
                                    nz-input
                                    nzSize="small"
                                    type="text"
                                    placeholder="Create New"
                                    [(ngModel)]="inputCategoryValue"
                                    style="background: #ecedf7; height: 28px; border-radius: 4px; color: #000; outline: medium"
                                    (keydown.enter)="handleInputConfirm()"
                                />
                            </nz-input-group>
                            <ng-template #suffixIconButton>
                                <button nz-button nzType="primary" style="margin-left: 10px" nzSize="small" (click)="handleInputConfirm()">
                                    <i nz-icon nzType="plus" nzTheme="outline"></i>
                                </button>
                            </ng-template>
                        </li>
                    </ul>
                </nz-checkbox-wrapper>
            </div>

            <div class="regionalBox" *ngIf="typeLevel==='three'">
                <nz-checkbox-wrapper style="width: 100%">
                    <ul class="containers isSelected">
                        <ng-container *ngFor="let item of labelList">
                            <ng-container *ngIf="item.selected">
                                <ng-container *ngFor="let j of item.children">
                                    <ng-container *ngIf="j.selected">
                                        <li
                                            *ngFor="let keys of j.children;let i = index;"
                                            (click)="isSelectedFun(i, j.children)"
                                            [ngClass]="{'selected': keys['selected']}"
                                        >
                                            <label
                                                nz-checkbox
                                                [(ngModel)]="keys['checked']"
                                                (ngModelChange)="log(keys)"
                                                [nzValue]="keys"
                                            ></label
                                            >{{ keys.name }}
                                        </li>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </ul>
                </nz-checkbox-wrapper>
            </div>
        </div>
    </ng-template>
</div>
