import { Component, OnInit } from '@angular/core';
import { ShopItem } from '@shared/type/order-dispatch.type';
import { OrderDispatchService } from '@services/order-dispatch.service';
import { environment } from '@src/environments/environment';

@Component({
    selector: 'app-visit-creator-hub-modal',
    templateUrl: './visit-creator-hub-modal.component.html',
    styleUrls: ['./visit-creator-hub-modal.component.less'],
})
export class VisitCreatorHubModalComponent implements OnInit {
    shopList: ShopItem[];
    shopVisible = false;
    isConfirmLoading = false;
    shopId = null;

    get saveBtnDisabled() {
        return !this.shopId;
    }

    constructor(private orderDispatchService: OrderDispatchService) {}

    async ngOnInit() {
        this.shopList = (await this.orderDispatchService.getShopList()) as ShopItem[];
    }

    handleCancel() {
        this.shopVisible = false;
    }

    handleOk() {
        this.isConfirmLoading = true;
        this.orderDispatchService.getCustomToken(this.shopId).then(res => {
            this.isConfirmLoading = false;
            this.shopVisible = false;
            const url = `${environment.creatorHubUrl}/custom-sign-in?idToken=${res.custom_token}`;
            window.open(url);
        });
    }
}
