import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-edit-popover',
    templateUrl: './edit-popover.component.html',
    styleUrls: ['./edit-popover.component.less'],
})
export class EditPopoverComponent implements OnInit {
    @Input() fields: {
        fieldName: string;
        title?: string;
        value?: any;
        addOnBefore?: string;
        addOnAfter?: string;
        addPlaceholder?: string;
        optionObj?: {
            name: string;
            value: string;
        };
    }[];

    @Output() private childOuter = new EventEmitter<{ fieldName: string; value?: string }[]>();

    visible = false;
    value = 0;

    constructor() {}

    formatterRMB = (value: number) => `￥ ${value}`;
    parserRMB = (value: string) => value.replace('￥ ', '');

    formatterWeight = (value: number) => `${value} g`;
    parserWeight = (value: string) => value.replace(' g', '');

    formatterCM = (value: number) => `${value} cm`;
    parserCM = (value: string) => value.replace(' cm', '');

    ngOnInit() {}

    cancel(): void {
        this.visible = false;
    }

    confirm(): void {
        this.visible = false;
        this.childOuter.emit(this.fields);
    }
}
