import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-product-info-in-list',
    templateUrl: './product-info-in-list.component.html',
    styleUrls: ['./product-info-in-list.component.less'],
})
export class ProductInfoInListComponent implements OnInit {
    @Input() productInfo: any;

    constructor() {}

    ngOnInit() {}
}
