import { Injectable } from '@angular/core';
import { RequestService } from '@services/request.service';
import { ProductInventoryDetail } from '@shared/type/order-dispatch.type';
import { environment } from '@src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class ProductInventoryService {
    constructor(private requestService: RequestService) {}

    submitUnpublishedProductToReview(product_id: string) {
        return this.requestService.sendRequest(
            {
                method: 'PUT',
                url: '/am/dispatcher/unpublish_product/submit_review',
                data: { product_id },
            },
            environment.shopApiService
        );
    }

    getSkuByProductId(productId): Promise<ProductInventoryDetail> {
        return this.requestService.sendRequest(
            {
                method: 'GET',
                url: `/am/dispatcher/sku?product_id=${productId}`,
            },
            environment.shopApiService
        );
    }

    updateProductInfo(productDetail: ProductInventoryDetail) {
        return this.requestService.sendRequest(
            {
                method: 'PUT',
                url: '/am/dispatcher/product_detail',
                data: productDetail,
            },
            environment.shopApiService
        );
    }

    approveAndPublishProduct(productId: string) {
        return this.requestService.sendRequest(
            {
                method: 'PUT',
                url: '/am/dispatcher/unpublish_product/approve_unpublished_product',
                data: {
                    product_id: productId,
                },
            },
            environment.shopApiService
        );
    }

    printBarcodeByShopId(shopId: number) {
        return this.requestService.sendRequest(
            {
                method: 'GET',
                url: `/am/dispatcher/print_barcode_by_shop?shop_id=${shopId}`,
            },
            environment.shopApiService
        );
    }

    printBarcodeByProductId(productId: string) {
        return this.requestService.sendRequest(
            {
                method: 'GET',
                url: `/am/dispatcher/print_barcode_by_product?product_id=${productId}`,
            },
            environment.shopApiService
        );
    }

    printBarcodeByProductSku(productSku: string[]) {
        return this.requestService.sendRequest(
            {
                method: 'GET',
                url: `/am/dispatcher/print_barcode_by_sku?${productSku.map(sku => `product_sku=${sku}&`).join('')}`,
            },
            environment.shopApiService
        );
    }

    cropImage(data) {
        return this.requestService.sendRequest(
            {
                method: 'POST',
                url: '/am/dispatcher/crop_image',
                data,
            },
            environment.shopApiService
        );
    }

    getKitSkuContent(sku: string) {
        return this.requestService.sendRequest(
            {
                method: 'GET',
                url: `/am/dispatcher/get_kit_item_content?sku=${sku}`,
            },
            environment.shopApiService
        );
    }

    removeImageBackground(data: { src: string }) {
        return this.requestService.sendRequest(
            {
                method: 'POST',
                url: '/am/dispatcher/image/background_remove',
                data,
            },
            environment.shopApiService
        );
    }

    removeImageText(data: { src: string }) {
        return this.requestService.sendRequest(
            {
                method: 'POST',
                url: '/am/dispatcher/image/text_remove',
                data,
            },
            environment.shopApiService
        );
    }
}
