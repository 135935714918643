<ng-container>
    <div *ngIf="productInfo?.skuID; else emptyProduct" class="product-info-1688">
        <img [src]="productInfo.productImgUrl" alt="" />
        <div>
            <p>{{ productInfo.name }}</p>
            <h5>ID: {{ productInfo.skuID }}</h5>
            <a [href]="productInfo.productSnapshotUrl" target="_blank" (click)="$event.stopPropagation();">
                <i nz-icon nzType="link" nzTheme="outline"></i><span class="text-underline">Link</span>
            </a>
        </div>
    </div>
    <ng-template #emptyProduct>/</ng-template>
</ng-container>
