<nz-layout class="app-layout">
    <!-- Left menu -->
    <nz-sider class="menu-sidebar" nzCollapsible nzWidth="256px" nzBreakpoint="md" [(nzCollapsed)]="isCollapsed" [nzTrigger]="null">
        <div class="sidebar-logo">
            <a [routerLink]="'/dashboard'">
                <img src="assets/images/lifo-icon.jpg" alt="logo" />
                <h1>Lifo Supply Chain</h1>
            </a>
        </div>
        <ul nz-menu nzMode="inline" class="left-menu" [nzInlineCollapsed]="isCollapsed">
            <li nz-menu-item nzMatchRouter routerLink="/dashboard">
                <i nz-icon nzType="dashboard"></i>
                <span>{{'DASHBOARD' | translate}}</span>
            </li>

            <li nz-submenu [nzTitle]="'W3W'| translate" nzIcon="inbox">
                <ul>
                    <li nz-menu-item nzMatchRouter>
                        <a routerLink="/web3wear/official-project">{{'Official Project' | translate}}</a>
                    </li>
                    <li nz-menu-item nzMatchRouter>
                        <a routerLink="/web3wear/batch-orders">{{'Batch Orders' | translate}}</a>
                    </li>
                    <li nz-menu-item nzMatchRouter>
                        <a routerLink="/web3wear/product">{{'Product' | translate}}</a>
                    </li>
                    <li nz-menu-item nzMatchRouter>
                        <a routerLink="/web3wear/campaign">{{'Campaign' | translate}}</a>
                    </li>
                    <li nz-menu-item nzMatchRouter>
                        <a routerLink="/web3wear/perk">{{'Collabs' | translate}}</a>
                    </li>
                    <li nz-menu-item nzMatchRouter>
                        <a routerLink="/web3wear/report">{{'Report' | translate}}</a>
                    </li>
                    <li nz-menu-item nzMatchRouter>
                        <a routerLink="/web3wear/games">{{'Games' | translate}}</a>
                    </li>
                </ul>
            </li>

            <li
                nz-menu-item
                nzMatchRouter
                routerLink="/product-management"
                *ngIf="!permissionService.currentUserPermission.isProductInventoryNoAccess && !permissionService.currentUserPermission.isGeneralInternalNoAccess"
            >
                <i nz-icon nzType="skin"></i>
                <span>{{'PRODUCT_INVENTORY' | translate}}</span>
            </li>

            <li
                nz-menu-item
                nzMatchRouter
                routerLink="/order-management"
                *ngIf="!permissionService.currentUserPermission.isOrderManagementNoAccess"
            >
                <i nz-icon nzType="inbox"></i>
                <span>{{'ORDER_MANAGEMENT' | translate}}</span>
            </li>

            <li
                nz-submenu
                [nzTitle]="'SHOP_MANAGEMENT'| translate"
                nzIcon="shop"
                *ngIf="!permissionService.currentUserPermission.isGeneralInternalNoAccess"
            >
                <ul>
                    <li nz-menu-item nzMatchRouter>
                        <a routerLink="/shop-management/store-management">{{'STORE_MANAGEMENT' | translate}}</a>
                    </li>

                    <li nz-menu-item nzMatchRouter *ngIf="!permissionService.currentUserPermission.isStoreSettingsNoAccess">
                        <a routerLink="/shop-management/store-settings">{{'STORE_SETTINGS' | translate}}</a>
                    </li>

                    <li nz-menu-item nzMatchRouter>
                        <a routerLink="/shop-management/calendar">{{'CALENDAR' | translate}}</a>
                    </li>
                </ul>
            </li>

            <!-- Co Sell -->
            <li
                nz-submenu
                [nzTitle]="'CO_SELL'| translate"
                nzIcon="shopping"
                *ngIf="!permissionService.currentUserPermission.isGeneralInternalNoAccess"
            >
                <ul>
                    <li nz-menu-item nzMatchRouter>
                        <a routerLink="/co-sell/product-list">{{'Product Listing' | translate}}</a>
                    </li>
                    <li nz-menu-item nzMatchRouter>
                        <a routerLink="/co-sell/amazon-product-importer">{{'Amazon Product Importer' | translate}}</a>
                    </li>
                    <li nz-menu-item nzMatchRouter>
                        <a routerLink="/co-sell/shop-application">{{'Shop Application' | translate}}</a>
                    </li>
                </ul>
            </li>

            <!-- Purchase Management -->
            <li
                nz-submenu
                [nzTitle]="'PURCHASE_MANAGEMENT'| translate"
                nzIcon="shopping"
                *ngIf="!permissionService.currentUserPermission.isGeneralInternalNoAccess"
            >
                <ul>
                    <li nz-menu-item nzMatchRouter *ngIf="!permissionService.currentUserPermission.isRestockRequestNoAccess">
                        <a routerLink="/purchase-management/restock-request">{{'RESTOCK_REQUEST' | translate}}</a>
                    </li>
                    <li nz-menu-item nzMatchRouter>
                        <a routerLink="/purchase-management/sample-product-purchase">{{'SAMPLE_PURCHASE' | translate}}</a>
                    </li>
                    <li nz-menu-item nzMatchRouter *ngIf="!permissionService.currentUserPermission.isProductPurchaseNoAccess">
                        <a routerLink="/purchase-management/product-purchase">{{'PRODUCT_PURCHASE' | translate}}</a>
                    </li>
                </ul>
            </li>

            <!-- Logistics Management -->
            <li
                nz-submenu
                [nzTitle]="'LOGISTICS_MANAGEMENT'| translate"
                nzIcon="shopping-cart"
                *ngIf="!permissionService.currentUserPermission.isWarehouseManagementNoAccess || !permissionService.currentUserPermission.isGeneralInternalNoAccess"
            >
                <ul>
                    <li nz-menu-item nzMatchRouter>
                        <a routerLink="/logistics-management/sample-product-logistics">{{'SAMPLE_LOGISTICS' | translate}}</a>
                    </li>
                    <li
                        nz-menu-item
                        nzMatchRouter
                        *ngIf="!permissionService.currentUserPermission.isProductLogisticsNoAccess && !permissionService.currentUserPermission.isGeneralInternalNoAccess"
                    >
                        <a routerLink="/logistics-management/mass-ordering">{{'PRODUCT_LOGISTICS' | translate}}</a>
                    </li>
                    <li
                        nz-menu-item
                        nzMatchRouter
                        *ngIf="!permissionService.currentUserPermission.isProductLogisticsNoAccess && !permissionService.currentUserPermission.isGeneralInternalNoAccess"
                    >
                        <a routerLink="/logistics-management/shipping-schedule">{{'SHIPPING_SCHEDULE' | translate}}</a>
                    </li>
                </ul>
            </li>

            <!-- Inventory Management -->
            <li
                nz-submenu
                [nzTitle]="'INVENTORY_MANAGEMENT'| translate"
                nzIcon="appstore-add"
                *ngIf="!permissionService.currentUserPermission.isInventoryTrackingNoAccess"
            >
                <ul>
                    <li nz-menu-item nzMatchRouter>
                        <a routerLink="/inventory-management/track-inventory">{{'TRACK_INVENTORY' | translate}}</a>
                    </li>
                </ul>
            </li>

            <li
                nz-submenu
                [nzTitle]="'WAREHOUSE_MANAGEMENT'| translate"
                nzIcon="database"
                *ngIf="!permissionService.currentUserPermission.isWarehouseManagementNoAccess"
            >
                <ul>
                    <li nz-menu-item nzMatchRouter>
                        <a routerLink="/warehouse-management/purchases">{{'PURCHASES_INBOUND' | translate}}</a>
                    </li>
                    <li nz-menu-item nzMatchRouter>
                        <a routerLink="/warehouse-management/allocate">{{'ALLOCATE_INBOUND' | translate}}</a>
                    </li>
                    <li nz-menu-item nzMatchRouter>
                        <a routerLink="/warehouse-management/outbound">{{'OUTBOUND_MANAGEMENT' | translate}}</a>
                    </li>
                </ul>
            </li>

            <!-- Standard Product Management -->
            <li
                nz-submenu
                [nzTitle]="'NIDAVELLIER'| translate"
                nzIcon="block"
                *ngIf="!permissionService.currentUserPermission.isGeneralInternalNoAccess || !permissionService.currentUserPermission.isStandardProductNoAccess"
            >
                <ul>
                    <li nz-menu-item nzMatchRouter>
                        <a routerLink="/standard-product/standard-product-inventory">{{'LIFO_PRODUCT_INVENTORY' | translate}}</a>
                    </li>
                    <li nz-menu-item nzMatchRouter>
                        <a routerLink="/standard-product/product-status-overview">{{'PRODUCT_STATUS_OVERVIEW' | translate}}</a>
                    </li>
                    <li nz-menu-item nzMatchRouter *ngIf="!permissionService.currentUserPermission.isGeneralInternalNoAccess">
                        <a routerLink="/standard-product/standard-product-sourcing">{{'SOURCING' | translate}}</a>
                    </li>
                    <li nz-menu-item nzMatchRouter *ngIf="!permissionService.currentUserPermission.isGeneralInternalNoAccess">
                        <a routerLink="/standard-product/standard-product-orders">{{'ORDERS' | translate}}</a>
                    </li>
                    <li nz-menu-item nzMatchRouter>
                        <a routerLink="/standard-product/product-catelog">{{'PRODUCT_CATELOG' | translate}}</a>
                    </li>
                </ul>
            </li>

            <!-- Payment Management -->
            <li
                nz-submenu
                [nzTitle]="'PAYMENT_MANAGEMENT'| translate"
                nzIcon="money-collect"
                *ngIf="!permissionService.currentUserPermission.isGeneralInternalNoAccess"
            >
                <ul>
                    <li nz-menu-item nzMatchRouter>
                        <a routerLink="/payment-management/payment-request">{{'PAYMENT_REQUEST' | translate}}</a>
                    </li>
                    <li nz-menu-item nzMatchRouter>
                        <a routerLink="/payment-management/transaction-history">{{'PAYOUT_HISTORY' | translate}}</a>
                    </li>
                </ul>
            </li>

            <!-- Internal Utils -->
            <li nz-menu-item nzMatchRouter [routerLink]="['/','internal-tools']">
                <span nz-icon nzType="tool" nzTheme="outline"></span>
                <span>{{'INTERNAL_TOOLS' | translate}}</span>
            </li>
            <li nz-menu-item nzMatchRouter class="red" (click)="signOut()">
                <i nz-icon nzType="logout"></i>
                <span>{{'LOG_OUT' | translate}}</span>
            </li>
        </ul>
    </nz-sider>

    <!-- Content -->
    <nz-layout>
        <nz-header>
            <div class="app-header">
                <span class="header-trigger" (click)="isCollapsed = !isCollapsed">
                    <i class="trigger" nz-icon [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"></i>
                </span>
            </div>
        </nz-header>
        <nz-content>
            <div class="inner-content">
                <router-outlet></router-outlet>
            </div>
        </nz-content>
    </nz-layout>
</nz-layout>
