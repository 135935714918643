<div>
    <header style="color: #0d053c; font-weight: bold; font-size: 20px">Customer Information</header>

    <div class="address-container">
        <form nz-form [formGroup]="formGroup">
            <div class="name-form">
                <!-- First Name -->
                <div class="form-item pr-20">
                    <div>First Name<span class="red"> *</span></div>
                    <nz-form-item>
                        <nz-form-control nzErrorTip="Please enter your first name!">
                            <input nz-input formControlName="first_name" placeholder="Enter first name" />
                        </nz-form-control>
                    </nz-form-item>
                </div>

                <!-- Last Name -->
                <div class="form-item pl-20">
                    <div>Last Name<span class="red"> *</span></div>
                    <nz-form-item>
                        <nz-form-control nzErrorTip="Please enter your last name!">
                            <input nz-input formControlName="last_name" placeholder="Enter last named" />
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>

            <!-- Phone Number -->
            <div class="form-item">
                <div>Phone Number</div>
                <nz-form-item>
                    <nz-form-control nzErrorTip="Phone number format not correct">
                        <input nz-input formControlName="phone_number" placeholder="Enter Phone Number" />
                    </nz-form-control>
                </nz-form-item>
            </div>

            <!-- Email -->
            <div class="form-item">
                <div>Email<span class="red"> *</span></div>
                <nz-form-item>
                    <nz-form-control nzErrorTip="Please enter your email!">
                        <input nz-input formControlName="email" placeholder="Enter Email" />
                    </nz-form-control>
                </nz-form-item>
            </div>

            <!-- Address line 1 -->
            <div class="form-item">
                <div>Address Line 1<span class="red"> *</span></div>
                <nz-form-item>
                    <nz-form-control nzErrorTip="Please enter your street address!">
                        <input nz-input formControlName="address_line_1" placeholder="Enter street address" />
                    </nz-form-control>
                </nz-form-item>
            </div>

            <!-- Address line 2 -->
            <div class="form-item">
                <div>Address Line 2</div>
                <nz-form-item>
                    <nz-form-control>
                        <input nz-input formControlName="address_line_2" placeholder="Enter unit/suite/apt" />
                    </nz-form-control>
                </nz-form-item>
            </div>

            <!-- City -->
            <div class="form-item">
                <div>City<span class="red"> *</span></div>
                <nz-form-item>
                    <nz-form-control nzErrorTip="Please enter your city!">
                        <input nz-input formControlName="city" placeholder="Enter city" />
                    </nz-form-control>
                </nz-form-item>
            </div>

            <!-- State -->
            <div class="form-item">
                <div>State<span class="red"> *</span></div>
                <nz-form-item>
                    <nz-form-control nzErrorTip="Please enter your state!">
                        <input nz-input formControlName="state" placeholder="Enter state" />
                    </nz-form-control>
                </nz-form-item>
            </div>

            <!-- ZIP -->
            <div class="form-item">
                <div>ZIP<span class="red"> *</span></div>
                <nz-form-item>
                    <nz-form-control nzErrorTip="Please enter your zip!">
                        <input nz-input formControlName="zip" placeholder="Enter zip" />
                    </nz-form-control>
                </nz-form-item>
            </div>

            <!-- Country -->
            <div class="form-item">
                <div>Country<span class="red"> *</span></div>
                <nz-form-item>
                    <nz-form-control nzErrorTip="Please enter your country!">
                        <input nz-input formControlName="country" placeholder="Enter country" />
                    </nz-form-control>
                </nz-form-item>
            </div>
        </form>

        <div class="modal-buttons">
            <button nz-button nzType="primary" (click)="confirm()" [disabled]="addBtnDisabled">Confirm</button>
            <button nz-button nzType="default" (click)="cancel()">Cancel</button>
        </div>
    </div>
</div>
