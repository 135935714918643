<nz-modal
    [nzTitle]="modalTitle"
    [nzFooter]="null"
    [nzContent]="modalContent"
    nzClassName="shippingModal"
    [(nzVisible)]="shopVisible"
    [nzWidth]="400"
    (nzOnCancel)="shopVisible = false"
>
    <ng-template #modalTitle> <b>Visit Creator Hub</b> </ng-template>
    <ng-template #modalContent>
        <section class="shop-selection">
            <nz-select [(ngModel)]="shopId" nzPlaceHolder="Shop" nzShowSearch>
                <nz-option *ngFor="let shop of shopList" [nzValue]="shop.shop_id" [nzLabel]="shop.name"></nz-option>

                <nz-option *ngIf="!shopList" nzDisabled nzCustomContent>
                    <i nz-icon nzType="loading" class="loading-icon" style="margin-right: 10px"></i> Loading Data...
                </nz-option>
            </nz-select>
        </section>
        <div class="btns">
            <button nz-button nzType="primary" (click)="handleOk()" [disabled]="saveBtnDisabled" [nzLoading]="isConfirmLoading">
                Visit
            </button>
            <button nz-button nzType="default" (click)="handleCancel()">Close</button>
        </div>
    </ng-template>
</nz-modal>
