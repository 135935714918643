import { Component, OnInit, AfterViewInit, ViewChild, Input, ViewEncapsulation } from '@angular/core';
import {
    ToolbarService,
    LinkService,
    ImageService,
    HtmlEditorService,
    RichTextEditorComponent,
    TableService,
    QuickToolbarService,
} from '@syncfusion/ej2-angular-richtexteditor';
import { ToolbarModule } from '@syncfusion/ej2-angular-navigations';

import { environment } from '@src/environments/environment';

@Component({
    selector: 'app-lifo-rich-editor',
    templateUrl: './lifo-rich-editor.component.html',
    styleUrls: ['./lifo-rich-editor.component.less'],
    encapsulation: ViewEncapsulation.None,
    providers: [ToolbarService, LinkService, ImageService, HtmlEditorService, TableService, QuickToolbarService],
})
export class LifoRichEditorComponent implements OnInit, AfterViewInit {
    @Input() htmlContent: string;
    @Input() height = '400px';

    @ViewChild('defaultRTE') defaultRTE: RichTextEditorComponent;

    public tools: ToolbarModule = {
        items: [
            'Bold',
            'Italic',
            'Underline',
            'StrikeThrough',
            'FontName',
            'FontSize',
            'FontColor',
            'BackgroundColor',
            'LowerCase',
            'UpperCase',
            'SuperScript',
            'SubScript',
            '|',
            'Formats',
            'Alignments',
            'OrderedList',
            'UnorderedList',
            'Outdent',
            'Indent',
            '|',
            'CreateTable',
            'CreateLink',
            'Image',
            'FileManager',
            '|',
            'ClearFormat',
            'Print',
            'SourceCode',
            'FullScreen',
            '|',
            'Undo',
            'Redo',
        ],
    };
    public quickTools = {
        image: ['Replace', 'Align', 'Caption', 'Remove', 'InsertLink', '-', 'Display', 'AltText', 'Dimension'],
    };
    id = Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '')
        .substr(0, 5);

    public insertImageSettings = {
        saveUrl: `${environment.campaignService}/shared/upload_event_image`,
        path: '',
        display: 'block',
    };

    constructor() {}

    ngOnInit(): void {}

    ngAfterViewInit(): void {}

    getHtmlContent() {
        return this.defaultRTE.getHtml();
    }

    uploadSuccess(data) {
        const url = JSON.parse(data.e.target.response)?.url;
        data.file.name = url;
    }
}
