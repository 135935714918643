import { Component, OnInit, Input, ViewChild, TemplateRef, Injector, ComponentFactoryResolver } from '@angular/core';
import { NzConfig } from 'ng-zorro-antd/core/config';

@Component({
    selector: 'app-empty-table',
    templateUrl: './empty-table.component.html',
    styleUrls: ['./antd-global-config.component.less'],
})
export class AntdEmptyTableComponent implements OnInit {
    @Input() description = 'No Data';

    constructor() {}

    ngOnInit(): void {}
}

@Component({
    selector: 'app-antd-custom',
    templateUrl: './antd-global-config.component.html',
    styleUrls: ['./antd-global-config.component.less'],
})
export class AntdGlobalConfigComponent {
    @ViewChild('emptyTable', { static: true }) emptyTable: TemplateRef<any>;
    @ViewChild('tableLoading', { static: true }) tableLoading: TemplateRef<any>;
}

export const nzConfigFactory = (injector: Injector, resolver: ComponentFactoryResolver): NzConfig => {
    const factory = resolver.resolveComponentFactory(AntdGlobalConfigComponent);
    const { emptyTable, tableLoading } = factory.create(injector).instance;
    return {
        empty: {
            nzDefaultEmptyContent: emptyTable,
        },
        table: {
            nzLoadingIndicator: tableLoading,
        },
    };
};
