import { Component, OnInit, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
    selector: 'app-choose-variant-image',
    templateUrl: './choose-variant-image.component.html',
    styleUrls: ['./choose-variant-image.component.less'],
})
export class ChooseVariantImageComponent implements OnInit {
    @Input() images: { src: string; path?: string }[];
    @Input() currentImg: { src: string; path?: string };

    showUploadModal = false;

    constructor(private modal: NzModalRef) {}

    ngOnInit(): void {}

    uploadFromLocal() {
        this.showUploadModal = true;
        this.modal.triggerOk();
    }
}
