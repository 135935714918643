import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { COLUMNS_LIST } from '@pages/logistics-management/mass-ordering/mass-ordering.data';
import { UserPermissionService } from '@services/user-permission.service';
import { CARRIER_LIST, SHIPPING_METHOD_LIST, DESTINATION_CHINA_WAREHOUSE, PURCHASE_PLATFORM } from '@shared/const/order-dispatch.const';
import { OrderDispatchCacheService } from '@services/order-dispatch-cache.service';
import { OrderDispatchService } from '@services/order-dispatch.service';
import { NotificationService, AlertType } from '@services/notification.service';
import * as dayjs from 'dayjs';
import { NzModalService } from 'ng-zorro-antd/modal';
import { MassOrderService } from '@pages/logistics-management/mass-ordering/mass-order.service';
import { cloneDeep, isNumber } from 'lodash';
import { LogisticRecordErrorComponent } from '@pages/logistics-management/mass-ordering/\
logistic-record-error/logistic-record-error.component';
import { ModalControl } from '../../models/ModalControl';
import { Router } from '@angular/router';

@Component({
    selector: 'app-mass-ordering-confirm-table',
    templateUrl: './mass-ordering-confirm-table.component.html',
    styleUrls: ['./mass-ordering-confirm-table.component.less'],
})
export class MassOrderingConfirmTableComponent implements OnInit {
    @Input() permissionLogistics = true;
    @Output() childOuter = new EventEmitter();
    CARRIER_LIST = CARRIER_LIST;
    CARRIER_LIST_US = CARRIER_LIST.concat(['自取']);
    columnList = COLUMNS_LIST;
    SHIPPING_METHOD_LIST = SHIPPING_METHOD_LIST;
    DESTINATION_CHINA_WAREHOUSE = DESTINATION_CHINA_WAREHOUSE;
    onChangeTitle = 'unConfirm';
    unConfirmLength: number;
    legacyLength: number;
    defaultOrderingList: any[] = [];
    massOrderingListUnComplete = [];
    massOrderingListIsComplete = [];
    massOrderingListIsLegacy = [];
    dateFormat = 'MM/dd/yyyy';
    dateTimes = [];
    massOrderingList: any[];
    warehouseList = [];
    isExporting = false;
    dataFromFile: { data: any[]; errors: string[]; file: File };
    shippingCostDataFile: { data: any[]; errors: string[]; file: File };

    // View Control
    fetchingMassOrderingList = true;
    showCreateNewRecord = false;
    showTrackingDetail = false;
    acceptMassOrderingModalVisible = false;
    acceptMassOrderingSubmitting = false;
    editMassOrderingModalVisible = false;
    editMassOrderingSubmitting = false;

    trackingDetail: any = {};

    printBarcodeModal = new ModalControl();

    selectedRecordItem = {
        id: null,
        arrived_quantity: 0,
        shipped_quantity: 0,
        product_sku: null,
        storage_location_id: null,
        storageOfCurrentSku: null,
        product_received: false,
        note: '',
    };

    shippingCostExcelTemplateDownloadUrl =
        'https://storage.googleapis.com/influencer-272204.appspot.com/public/files/shipping_cost_template.xlsx';

    selectedMassOrder = {
        warehouse_id: 0,
        shipped_quantity: 0,
        shipping_method: '',
        carrier: '',
        tracking_number: '',
        shipping_cost: 0,
        shipping_date: '',
        deliver_eta: '',
        logistics_type: '',
        product_received: null,
        currency: '',
        shipping_warehouse: null,
        purchase_platform: null,
        purchase_order_id: null,
        purchase_date: dayjs().format('MM/DD/YYYY'),
    };
    uploadingShippingCost = false;

    start: string;
    end: string;

    get acceptMassOrderingDisabled() {
        return (
            !isNumber(this.selectedRecordItem?.arrived_quantity) ||
            !this.selectedRecordItem?.storage_location_id ||
            (this.selectedRecordItem.product_received &&
                this.selectedRecordItem.arrived_quantity < this.selectedRecordItem?.shipped_quantity &&
                !this.selectedRecordItem.note)
        );
    }

    get editMassOrderingDisabled() {
        if (this.selectedMassOrder.product_received) {
            return !isNumber(this.selectedMassOrder.shipping_cost);
        }
        if (this.selectedMassOrder.logistics_type === 'international') {
            return (
                !this.selectedMassOrder.deliver_eta ||
                !this.selectedMassOrder.shipping_date ||
                !this.selectedMassOrder.warehouse_id ||
                !this.selectedMassOrder.shipped_quantity ||
                !this.selectedMassOrder.shipping_warehouse ||
                !isNumber(this.selectedMassOrder.shipping_cost) ||
                !isNumber(this.selectedMassOrder.shipped_quantity)
            );
        } else {
            return (
                !this.selectedMassOrder.deliver_eta ||
                !this.selectedMassOrder.shipping_date ||
                !this.selectedMassOrder.warehouse_id ||
                !this.selectedMassOrder.shipped_quantity ||
                !isNumber(this.selectedMassOrder.shipping_cost) ||
                !isNumber(this.selectedMassOrder.shipped_quantity)
            );
        }
    }

    get fullSkuList() {
        return this.orderDispatchCacheService.allSku;
    }
    set fullSkuList(value) {
        this.orderDispatchCacheService.allSku = value;
    }

    get isShowConfirmNote() {
        return !(
            this.selectedRecordItem.product_received && this.selectedRecordItem.arrived_quantity < this.selectedRecordItem.shipped_quantity
        );
    }

    constructor(
        public permissionService: UserPermissionService,
        public orderDispatchCacheService: OrderDispatchCacheService,
        private orderDispatchService: OrderDispatchService,
        private massOrderService: MassOrderService,
        private modal: NzModalService,
        private notificationService: NotificationService,
        private router: Router
    ) {}

    beforeUploadShippingCostExcel = (file: File) => {
        this.uploadingShippingCost = true;
        this.handleShippingCostExcelFile(file);
        return false;
    };

    ngOnInit() {
        this.start = dayjs().subtract(3, 'month').format('YYYY-MM-DD');
        this.end = dayjs().add(1, 'day').format('YYYY-MM-DD');
        this.getMassOrdering();
        this.orderDispatchService.getWarehouseList().then(res => {
            this.warehouseList = this.orderDispatchService.warehouseList;
        });
        this.orderDispatchService.getShopList().then(res => {
            const shopList = res.filter(ele => ele.shop_id > 0).map(ele => ({ text: ele.name, value: ele.name }));
            this.columnList.find(item => item.id === 'shop_name').options = shopList;
        });
    }

    getMassOrdering() {
        this.fetchingMassOrderingList = true;
        this.orderDispatchService
            .getMassOrderingByDate(this.start, this.end)
            .then(res => {
                res.forEach(item => {
                    if (item.latest_shipping_status?.statusCode === 'delivered' || item.latest_shipping_status?.status === 'delivered') {
                        if (item.product_received) {
                            item.trackingStatusCode = 4;
                        } else {
                            if (dayjs().diff(dayjs(item.latest_shipping_status.latestShipmentDetail.date), 'hour') > 48) {
                                item.trackingStatusCode = 3;
                            } else {
                                item.trackingStatusCode = 2;
                            }
                        }
                    } else {
                        item.trackingStatusCode = 1;
                    }
                });
                this.massOrderingList = res;
                this.defaultOrderingList = this.massOrderingList;
                this.selectStatus(this.onChangeTitle);
                this.childOuter.emit(res);
            })
            .catch(() => {
                this.notificationService.addMessage({
                    type: AlertType.Error,
                    title: 'Get new records failed, please try again.',
                    message: 'success',
                    duration: 5000,
                });
            })
            .finally(() => (this.fetchingMassOrderingList = false));
    }

    setColor(item) {
        if (!item.deliver_eta) {
            const shippingStatus = this.showTrackingStatus(item.latest_shipping_status);
            if (shippingStatus === 'Unknown') {
                return '#ea9905';
            }
        } else {
            const timeOut = dayjs(item.deliver_eta).subtract(24, 'hour');
            const isRed = dayjs().isBefore(dayjs(timeOut));
            if (isRed) {
                return 'red';
            }
        }
    }

    editMassOrdering() {
        this.editMassOrderingSubmitting = true;
        this.orderDispatchService
            .updateMassOrdering(this.selectedMassOrder)
            .then(() => {
                this.editMassOrderingModalVisible = false;
                this.notificationService.addMessage({
                    type: AlertType.Success,
                    title: 'Success',
                    message: 'Update successful',
                });
            })
            .catch(() => {
                this.notificationService.addMessage({
                    type: AlertType.Error,
                    title: 'Error',
                    message: 'Failed, please try again',
                });
            })
            .finally(() => (this.editMassOrderingSubmitting = false));
    }

    getUrlBase64(url, ext) {
        return new Promise((resolve, reject) => {
            let canvas = document.createElement('canvas'); // 创建canvas DOM元素
            const ctx = canvas.getContext('2d');
            const img = new Image();
            img.crossOrigin = 'Anonymous';
            img.onload = () => {
                canvas.height = 60; // 指定画板的高度,自定义
                canvas.width = 60; // 指定画板的宽度，自定义
                ctx.drawImage(img, 0, 0, 60, 60); // 参数可自定义
                const dataURL = canvas.toDataURL(`image/${ext}`);
                resolve(dataURL); // 回掉函数获取Base64编码
                canvas = null;
            };
            img.onerror = () => resolve('');
            img.src = url;
        }).catch(error => {
            console.log(error);
        });
    }

    async handleShippingCostExcelFile(file: File) {
        this.massOrderService.parseShippingCostRecordData(file).then(res => {
            this.shippingCostDataFile = { ...res, file };

            if (res.errors.length > 0) {
                this.modal.create({
                    nzContent: LogisticRecordErrorComponent,
                    nzComponentParams: { errors: res.errors },
                    nzTitle: 'Import shipping cost failed',
                });
                this.uploadingShippingCost = false;
            } else {
                this.massOrderService
                    .importShippingCost(res.data)
                    .then(response => {
                        if ('errors' in response && response.errors.length > 0) {
                            this.modal.create({
                                nzContent: LogisticRecordErrorComponent,
                                nzComponentParams: { errors: response.errors },
                                nzTitle: 'Import shipping cost failed',
                            });
                            this.shippingCostDataFile.errors = response.errors;
                        } else {
                            this.notificationService.addMessage({
                                type: AlertType.Success,
                                title: `Import ${res.data.length} shipping cost records Success`,
                                message: 'success',
                                duration: 5000,
                            });
                        }
                    })
                    .catch(err => {
                        this.notificationService.addMessage({
                            type: AlertType.Error,
                            title: 'Import shipping cost records Failed',
                            message: 'Import shipping cost records failed, please try again.',
                            duration: 5000,
                        });
                    })
                    .finally(() => {
                        this.uploadingShippingCost = false;
                    });
            }
        });
    }

    showEditQuantityModal(selectedRecordItem) {
        const { id, shipped_quantity, arrived_quantity, product_sku } = selectedRecordItem;
        this.selectedRecordItem = {
            id,
            shipped_quantity,
            arrived_quantity,
            product_sku,
            storage_location_id: null,
            storageOfCurrentSku: null,
            product_received: false,
            note: '',
        };
        this.getStorageBySku(product_sku);
        this.acceptMassOrderingModalVisible = true;
    }

    getStorageBySku(sku: string) {
        this.orderDispatchService.getStorageBySku(sku).then(res => {
            this.selectedRecordItem.storageOfCurrentSku = res.sort((a, b) => b.quantity - a.quantity);
        });
    }

    showTrackingStatus(tracking_data) {
        if (tracking_data?.status) {
            return tracking_data?.status;
        }
        if (tracking_data?.statusCode) {
            return tracking_data?.statusCode;
        }
        return 'Unknown';
    }

    showEditMassOrderModal(selectedMassOrder) {
        this.selectedMassOrder = selectedMassOrder;
        this.editMassOrderingModalVisible = true;
    }

    viewTrackingDetail(trackingDetail) {
        this.trackingDetail = trackingDetail;
        this.showTrackingDetail = true;
    }

    closeTrackingDetail() {
        this.showTrackingDetail = false;
    }

    searchResultList(value) {
        this.massOrderingList = value;
    }

    resetResultList() {
        this.massOrderingList = [...this.defaultOrderingList];
        this.selectStatus(this.onChangeTitle);
    }

    async export() {
        this.isExporting = true;
        this.massOrderService.exportProductLogisticsExcel(this.massOrderingList).then(() => (this.isExporting = false));
    }

    acceptMassOrdering() {
        this.acceptMassOrderingSubmitting = true;
        const { id, arrived_quantity, storage_location_id, product_received, note } = this.selectedRecordItem;
        this.orderDispatchService
            .acceptMassOrdering({ id, arrived_quantity, storage_location_id, product_received, note })
            .then(() => {
                const ind = this.defaultOrderingList.findIndex(item => item.id === id);
                this.defaultOrderingList[ind].arrived_quantity = arrived_quantity;
                this.defaultOrderingList[ind].product_received = product_received;
                this.defaultOrderingList[ind].note = note;
                this.acceptMassOrderingModalVisible = false;
                this.selectedRecordItem.storageOfCurrentSku = null;
                this.notificationService.addMessage({
                    type: AlertType.Success,
                    title: 'Success',
                    message: 'Accept successful',
                });
                this.selectedRecordItem.note = '';
                this.printBarcodeModal.showModal();
            })
            .catch(() => {
                this.notificationService.addMessage({
                    type: AlertType.Error,
                    title: 'Error',
                    message: 'Failed, please try again',
                });
            })
            .finally(() => (this.acceptMassOrderingSubmitting = false));
    }

    selectStatus(key) {
        const unComplete = [];
        const isComplete = [];
        const isLegacy = [];
        this.onChangeTitle = key;
        for (const item of this.defaultOrderingList) {
            if (item.product_received) {
                isComplete.push(item);
            } else {
                if (!item.deliver_eta || dayjs().diff(dayjs(item.deliver_eta), 'day') > 90) {
                    isLegacy.push(item);
                } else {
                    unComplete.push(item);
                }
            }
        }
        this.legacyLength = isLegacy.length;
        if (key === 'unConfirm') {
            this.massOrderingList = [...unComplete];
            this.massOrderingListUnComplete = [...unComplete];
            this.unConfirmLength = this.massOrderingList.length;
        } else if (key === 'isConfirm') {
            this.massOrderingList = [...isComplete];
            this.massOrderingListIsComplete = [...isComplete];
        } else if (key === 'isLegacy') {
            this.massOrderingList = [...isLegacy];
            this.massOrderingListIsLegacy = [...isLegacy];
        }
    }

    onChangeDateTimes() {
        this.start = this.dateTimes[0] ? dayjs(this.dateTimes[0]).format('YYYY-MM-DD') : dayjs().subtract(3, 'month').format('YYYY-MM-DD');
        this.end = dayjs(this.dateTimes[1]).add(1, 'day').format('YYYY-MM-DD');
        this.getMassOrdering();
    }

    viewConfirmNote(data) {
        this.modal.info({
            nzTitle: '备注',
            nzContent: data.note,
            nzOkText: '关闭',
        });
    }

    printBarcode() {
        const url = this.router.serializeUrl(
            this.router.createUrlTree(['/product-management/print-barcode'], {
                queryParams: { sku: [this.selectedRecordItem.product_sku] },
            })
        );
        window.open(url, '_blank');
        this.printBarcodeModal.hideModal();
    }
}
