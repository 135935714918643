<section style="height: 60vh; overflow-y: auto">
    <ng-container *ngIf="trackingDetail">
        <p>Tracking Number: {{trackingDetail.carrier}} / {{trackingDetail.track_number}}</p>

        <div>
            <div *ngFor="let item of trackingDetail.details" class="mb-10 d-flex width-100p justify-content-between align-items-start">
                <div style="width: 100px; margin-right: 30px">{{item.date | date: 'MM/dd HH:mm'}}</div>
                <div class="flex-1">{{item.status}}</div>
                <div style="width: 100px; margin-left: 30px">{{item.location}}</div>
            </div>
        </div>

        <div
            *ngIf="!trackingDetail.details || trackingDetail.details.length === 0"
            style="margin: 100px auto; font-size: 22px; font-weight: bold; text-align: center"
        >
            No Data
        </div>
    </ng-container>

    <div *ngIf="fetchingTracking" style="margin: 100px auto; font-size: 22px; font-weight: bold; text-align: center">Loading</div>
</section>
