<a nz-popover [(nzPopoverVisible)]="visible" nzPopoverTrigger="click" [nzPopoverContent]="contentTemplate" (nzOnConfirm)="cancel()">
    <i nz-icon class="popover-icon" nzType="edit" nzTheme="outline"></i>
</a>

<ng-template #contentTemplate>
    <div class="popovers">
        <div class="popver-title">Batch Edit 批量编辑</div>
        <div class="inputs">
            <div *ngFor="let item of fields" class="input-item">
                <span *ngIf="item.title" class="pr-20">{{ item.title }}</span>
                <nz-input-group [nzAddOnBefore]="item.addOnBefore" [nzAddOnAfter]="item.addOnAfter">
                    <nz-select *ngIf="item?.optionObj?.name === 'currency'" [(ngModel)]="item.optionObj.value">
                        <nz-option [nzLabel]="'¥'" [nzValue]="'CNY'"></nz-option>
                        <nz-option [nzLabel]="'$'" [nzValue]="'USD'"></nz-option>
                    </nz-select>
                    <input type="text" nz-input [(ngModel)]="item.value" [ngStyle]="{ width: item?.optionObj?.name ? '75%' : '100%' }" />
                </nz-input-group>
            </div>
        </div>

        <div class="btns">
            <button nz-button nzType="default" nzSize="small" (click)="cancel()">Cancel</button>
            <button nz-button nzType="primary" nzSize="small" (click)="confirm()">Confirm</button>
        </div>
    </div>
</ng-template>
