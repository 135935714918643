import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { OrderDispatchService } from '@services/order-dispatch.service';
import { cloneDeep } from 'lodash';
import { AddressItem } from '../../type/order-dispatch.type';

@Component({
    selector: 'app-address-modal',
    templateUrl: './address-modal.component.html',
    styleUrls: ['./address-modal.component.less'],
})
export class AddressModalComponent implements OnInit {
    @Input() addressDetails: any;
    @Input() type: 'view' | 'add' = 'view';
    @Output() updateAddressSuccess = new EventEmitter();
    @Output() closeModal = new EventEmitter();

    isAddressLoading = false;
    address: AddressItem = {
        name: '',
        first_name: '',
        last_name: '',
        phone_number: '',
        address_line_1: '',
        address_line_2: '',
        city: '',
        state: '',
        zip: '',
        country: '',
    };

    get addBtnDisabled() {
        return (
            !this.address.first_name ||
            !this.address.last_name ||
            !this.address.address_line_1 ||
            !this.address.city ||
            !this.address.state ||
            !this.address.zip ||
            !this.address.country
        );
    }

    constructor(private orderDispatchService: OrderDispatchService) {}

    ngOnInit() {}

    updateAddress() {
        this.isAddressLoading = true;
        const data = cloneDeep(this.address);
        data.name = `${data.first_name} ${data.last_name}`;
        this.orderDispatchService.createAddressBook(data).then(res => {
            this.address = {
                name: '',
                first_name: '',
                last_name: '',
                phone_number: '',
                address_line_1: '',
                address_line_2: '',
                city: '',
                state: '',
                zip: '',
                country: '',
            };
            this.isAddressLoading = false;
            this.updateAddressSuccess.emit(res);
        });
    }

    closeAddressModal() {
        this.closeModal.emit();
    }
}
