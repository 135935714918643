<div class="main-content">
    <section class="pending-product">
        <span class="main-content-title">{{ 'PENDING_PRODUCT' | translate }}</span>
        <div class="counts">{{ pendingCounts }}</div>

        <button nz-button nzType="primary" (click)="goDistance()">
            <img class="restart-img" src="assets/images/restart.png" width="15" />{{ 'MANAGE_PENDING_PRODUCT' | translate }}
        </button>
    </section>
    <section class="pending-product arrival">
        <span class="main-content-title">{{ 'ESTIMATE_ARRIVAL_REPORT' | translate }} </span>
        <nz-table #basicTable [nzData]="tableHeader" [nzBordered]="true" *ngIf="tableHeader.length > 0" [nzHideOnSinglePage]="true">
            <thead>
                <tr>
                    <th *ngFor="let item of basicTable.data">
                        <span class="top"> {{ item?.date }}</span>
                        <span class="bottom"> {{ item?.week | translate}}</span>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td *ngFor="let item of basicTable.data">{{ item.counts }}</td>
                </tr>
            </tbody>
        </nz-table>
    </section>
</div>
