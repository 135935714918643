export const COLUMNS = [
    {
        id: 'date_needed',
        name: 'Date Needed',
        sortFn: (a, b) => new Date(a.starts_at).getTime() - new Date(b.starts_at).getTime(),
        sortOrder: 'ascend',
        width: '200px',
    },
    {
        id: 'shop_name',
        name: 'Shop',
        width: '200px',
    },
    {
        id: 'campaign_name',
        name: 'Campaign Name',
        width: '200px',
    },
    {
        id: 'sku_quantity',
        name: '# Of SKUs',
        width: '100px',
    },
    {
        id: 'total_needed',
        name: 'Total Needed',
        width: '150px',
    },
    {
        id: 'fulfillment_warehouse_name',
        name: 'Fulfillment Warehouse',
        width: '150px',
    },
    {
        id: 'campaign_type',
        name: 'Campaign Type',
        width: '200px',
    },
    {
        id: 'coupon_code',
        name: 'Coupon Code',
        width: '150px',
    },
    {
        id: 'is_cost_covered',
        name: 'Lifo Cover Costs',
        width: '150px',
    },
    {
        id: 'note',
        name: 'Note',
        width: '200px',
    },
    {
        id: 'created_by',
        name: 'Event Initiator',
        width: '200px',
    },
    {
        id: 'operation',
        name: 'Operation',
        width: '200px',
    },
    {
        id: 'more',
        name: 'More',
        width: '100px',
    },
];
