import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
    selector: 'app-address-form',
    templateUrl: './address-form.component.html',
    styleUrls: ['./address-form.component.less'],
})
export class AddressFormComponent implements OnInit {
    @Input() addressForm = {
        first_name: null,
        last_name: null,
        address_line_1: null,
        address_line_2: null,
        city: null,
        state: null,
        zip: null,
        country: null,
        phone_number: null,
        email: null,
    };

    formGroup: FormGroup;

    get addBtnDisabled() {
        return !this.formGroup?.valid;
    }

    constructor(private modal: NzModalRef, private fb: FormBuilder) {}

    ngOnInit(): void {
        if (!this.addressForm) {
            this.addressForm = {
                first_name: null,
                last_name: null,
                address_line_1: null,
                address_line_2: null,
                city: null,
                state: null,
                zip: null,
                country: null,
                phone_number: null,
                email: null,
            };
        }

        // E-mail verification
        const ValidatorEmail = (control: FormControl): any => {
            if (!control.value) {
                return { required: true };
            } else {
                const regs = /^[A-Za-z0-9\._-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
                const valid = regs.test(control.value);
                return valid ? null : { amount: true };
            }
        };

        this.formGroup = this.fb.group({
            first_name: [this.addressForm.first_name, [Validators.required]],
            last_name: [this.addressForm.last_name, [Validators.required]],
            address_line_1: [this.addressForm.address_line_1, [Validators.required]],
            address_line_2: [this.addressForm.address_line_2, []],
            city: [this.addressForm.city, [Validators.required]],
            state: [this.addressForm.state, [Validators.required]],
            zip: [this.addressForm.zip, [Validators.required]],
            country: [this.addressForm.country, [Validators.required]],
            phone_number: [this.addressForm.phone_number, [Validators.minLength(10), Validators.maxLength(10)]],
            email: [this.addressForm.email, [Validators.required, ValidatorEmail]],
        });
    }

    confirm() {
        this.addressForm = this.formGroup.getRawValue();
        this.modal.triggerOk();
    }

    cancel() {
        this.modal.triggerCancel();
    }
}
