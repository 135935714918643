<nz-filter-trigger [(nzVisible)]="searchVisible" [nzActive]="searchValue?.length > 0" [nzDropdownMenu]="menu">
    <i nz-icon nzType="search"></i>
</nz-filter-trigger>
<nz-dropdown-menu #menu="nzDropdownMenu">
    <div class="ant-table-filter-dropdown">
        <div class="search-box">
            <input
                type="text"
                nz-input
                placeholder="Search"
                [(ngModel)]="searchValue"
                (ngModelChange)="searchChange($event)"
                [nzAutocomplete]="auto"
            />
            <nz-autocomplete #auto [compareWith]="searchValueCompare">
                <nz-auto-option *ngFor="let option of filteredOptions" [nzValue]="option.text" [nzLabel]="option.text">
                    {{ option.text }}
                </nz-auto-option>
            </nz-autocomplete>
            <button nz-button nzSize="small" nzType="primary" (click)="search()" class="search-button">Search</button>
            <button nz-button nzSize="small" (click)="resetTable('searchValue')">Reset</button>
        </div>
    </div>
</nz-dropdown-menu>
