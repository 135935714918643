import { Component, OnInit, Input } from '@angular/core';

import { ShopSettingsDetail, Influencer } from '@shared/type/order-dispatch.type';
import { OrderDispatchService } from '@services/order-dispatch.service';
import { EchartsOption } from '@shared/directive/echarts.directive';

interface SocialItem {
    name: string;
    icon: string;
    data: any;
    initialGenderOption: EchartsOption;
}

@Component({
    selector: 'app-follower-info',
    templateUrl: './follower-info.component.html',
    styleUrls: ['./follower-info.component.scss'],
})
export class FollowerInfoComponent implements OnInit {
    @Input() shopDetail: ShopSettingsDetail;
    tooltips = {
        follower: 'Does this influencer have a real audience? More than 20% of fake followers is typically a sign of fraud.',
        gender: 'What gender categories does the influencer reach?',
        ageGender: 'A breakdown of the age and gender an influencer reaches.',
        locationByCountry: 'This indicates the countries the influencer’s audience is located in.',
        locationByCity: 'This indicates the cities the influencer’s audience is located in.',
        sponsoredPost: '',
        tags: 'These are the hashtags and other users that are frequently adding to their content.',
    };
    isSetHeight = false;
    socialList: SocialItem[] = [
        {
            name: 'Instagram',
            icon: 'assets/images/instagram.jpg',
            data: null,
            initialGenderOption: {},
        },
        {
            name: 'YouTube',
            icon: 'assets/images/youtube.jpg',
            data: null,
            initialGenderOption: {},
        },
        {
            name: 'TikTok',
            icon: 'assets/images/tiktok.jpg',
            data: null,
            initialGenderOption: {},
        },
    ];

    public initialChartOption: EchartsOption = {
        color: ['#0D053C', '#4B57C5'],
        tooltip: {
            trigger: 'item',
        },
        legend: {},
        grid: {
            left: '3%',
            right: '3%',
            bottom: '3%',
            containLabel: true,
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: [],
        },
        yAxis: {
            splitLine: {
                show: false,
            },
            axisLabel: {
                show: false,
            },
        },
        series: [],
    };

    constructor(private orderDispatchService: OrderDispatchService) {}

    prepareData(socialItem: SocialItem, data: Influencer) {
        const genderAgeLabel = [];
        const maleData = [];
        const femaleData = [];
        data?.audience?.gendersPerAge.forEach(item => {
            genderAgeLabel.push(item.code);
            maleData.push(item.male);
            femaleData.push(item.female);
        });
        socialItem.data = data;
        socialItem.initialGenderOption = {
            ...this.initialChartOption,
            xAxis: {
                data: genderAgeLabel,
            },
            series: [
                {
                    name: 'MALE',
                    data: maleData,
                    type: 'bar',
                    barWidth: 12,
                },
                {
                    name: 'FEMALE',
                    data: femaleData,
                    type: 'bar',
                    barWidth: 12,
                },
            ],
            hideLoading: true,
        };
    }

    getModashProfile() {
        if (this.shopDetail.shop_detail.instagram_id) {
            this.orderDispatchService
                .getModashProfile({ platform: 'instagram', userId: this.shopDetail.shop_detail.instagram_id })
                .then(res => {
                    this.prepareData(this.socialList[0], res);
                });
        }
        if (this.shopDetail.shop_detail.youtube_id) {
            this.orderDispatchService
                .getModashProfile({ platform: 'youtube', userId: this.shopDetail.shop_detail.youtube_id })
                .then(res => {
                    this.prepareData(this.socialList[1], res);
                });
        }
        if (this.shopDetail.shop_detail.tiktok_id) {
            this.orderDispatchService.getModashProfile({ platform: 'tiktok', userId: this.shopDetail.shop_detail.tiktok_id }).then(res => {
                this.prepareData(this.socialList[2], res);
            });
        }
    }

    ngOnInit() {
        this.getModashProfile();
    }
}
