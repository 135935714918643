export const CARRIER_LIST = ['UPS', 'DHL', 'FedEx', 'USPS', 'Other/其他'];
export const CN_CARRIER_LIST = [
    '顺丰快递',
    '申通快递',
    '中通快递',
    '圆通快递',
    '韵达快递',
    '德邦快递',
    'EMS',
    '全峰快递',
    '天天快递',
    '百世物流',
    '优速快递',
    '安能物流',
];
export const DESTINATION_CHINA_WAREHOUSE = ['SH Quick', 'GZ GJ', 'Drophaus', 'HZ Office', 'HZ Warehouse', 'W3W Warehouse'];
export const PURCHASE_PLATFORM = ['1688', '淘宝', 'Other'];
export const SHIPPING_METHOD_LIST = ['快递', '空派', '海派', 'Other/其他'];
