<section class="shipping-box">
    <section class="btn-operations">
        <span class="main-content-title">{{ 'SHIPPING_BOX_LIST' | translate }}</span>
    </section>
    <section class="data-table">
        <nz-table
            #shippingScheduleTable
            nzShowPagination
            nzShowSizeChanger
            [nzLoading]="fetchingShippingBoxList"
            [nzData]="shippingBoxList"
            [nzShowPagination]="true"
        >
            <thead>
                <tr>
                    <th>
                        {{ 'SHIPPING_PLAN_NUMBER' | translate }}
                        <app-table-search-dropdown
                            [defaultProductList]="defaultShippingBoxList"
                            [name]="'id'"
                            (searchList)="searchResultList($event)"
                            (resetList)="resetResultList()"
                        ></app-table-search-dropdown>
                    </th>
                    <th nzWidth="250px">{{ 'SCHEDULE_GENERATION_TIME' | translate }}</th>
                    <th nzWidth="250px">{{ 'BOX_LIST_UPLOAD_TIME' | translate }}</th>
                    <th nzWidth="300px">{{ 'OPERATE' | translate }}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of shippingScheduleTable.data">
                    <td>
                        <span class="data-id">{{ data.id }}</span>
                    </td>
                    <td>
                        <p>{{ data.created_at | date: 'MM/dd/yyyy' }}</p>
                    </td>
                    <td>
                        <p *ngIf="data.shipping_file_path !== null">{{ data.shipping_uploaded_at | date: 'MM/dd/yyyy' }}</p>
                        <p *ngIf="data.shipping_file_path === null">/</p>
                    </td>
                    <td>
                        <button [disabled]="data.shipping_file_path === null" nz-button nzType="primary">
                            <span *ngIf="data.shipping_file_path === null">{{ 'NOT_UPLOADED' | translate }}</span>
                            <a [href]="data.shipping_file_path" *ngIf="data.shipping_file_path !== null">{{ 'DOWNLOAD' | translate }}</a>
                        </button>
                        <button
                            nz-button
                            nzType="primary"
                            (click)="uploadFile(data)"
                            *ngIf="permissionService.currentUserPermission.isGeneralInternalNoAccess"
                        >
                            {{ data.shipping_file_path === null ? ('UPLOAD' | translate ): ('RE_UPLOAD' | translate ) }}
                        </button>
                    </td>
                </tr>
            </tbody>
        </nz-table>
    </section>
</section>

<nz-modal
    [nzTitle]="modalTitle"
    [nzFooter]="null"
    [nzContent]="modalContent"
    nzClassName="shippingModal"
    [(nzVisible)]="shippingScheduleModalVisible"
    (nzOnCancel)="shippingScheduleModalVisible = false"
>
    <ng-template #modalTitle> <b>{{ 'UPLOAD_SHIPPING_BOX_LIST' | translate }}</b> </ng-template>
    <ng-template #modalContent>
        <div class="plan-number">{{ 'PLAN_NUMBER' | translate }}： <span>{{ selectRow.id }}</span></div>
        <div class="image-holder" *ngIf="!fileUploaded">
            <div class="add-logo" [ngClass]="{ isOpcity: fileUploaded }">
                <p><i nz-icon nzType="upload" nzTheme="outline"></i> {{ 'UPLOAD_SHIPPING_BOX_LIST' | translate }}</p>
                <input class="file-input" type="file" id="inputFile" (change)="onDrop($event.target.files)" />
            </div>
        </div>
        <div class="upload-result" *ngIf="fileUploaded">
            <p>
                <a [href]="filePath">{{ fileName }}</a>
                <i class="closeIcon" (click)="removeInvoice()" nz-icon nzType="close-circle" nzTheme="fill"></i>
            </p>
        </div>
        <div class="btns">
            <button nz-button nzType="primary" (click)="handleOk()" [disabled]="saveBtnDisabled" [nzLoading]="isConfirmLoading">
                {{ 'CONFIRM' | translate }}
            </button>
            <button nz-button nzType="default" (click)="handleCancel()">{{ 'CANCEL' | translate }}</button>
        </div>
    </ng-template>
</nz-modal>
