import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { cloneDeep } from 'lodash';
import dayjs from 'dayjs';
import { SamplePurchaseItem, CheckRequirementOptions, AddressItem } from '@shared/type/order-dispatch.type';
import { OrderDispatchService } from '@services/order-dispatch.service';
import { NotificationService } from '@src/app/services/notification.service';

@Component({
    selector: 'app-add-sample-purchase-request',
    templateUrl: './add-sample-purchase-request.component.html',
    styleUrls: ['./add-sample-purchase-request.component.less'],
})
export class AddSamplePurchaseRequestComponent implements OnInit {
    @Output() addSampleSuccess = new EventEmitter();
    @Output() selectShop = new EventEmitter();

    savingRecord = false;
    samplePurchaseItem: SamplePurchaseItem = {
        product_name: '',
        shop_id: null,
        request_qty: null,
        sourcing_link: '',
        address_id: null,
        instruction: '',
        urgency: 'medium',
        required_date: null,
        qc_required: false,
        live_demo_requried: false,
        photo_shoot_required: false,
    };

    addressModalVisible = false;
    address: AddressItem = {
        name: '',
        first_name: '',
        last_name: '',
        address_line_1: '',
        address_line_2: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        phone_number: '',
    };
    addressLists: AddressItem[] = [];

    checkRequirementOptions = cloneDeep(CheckRequirementOptions);

    shopList = [];

    urgencyList = [
        { id: 'high', name: '高 High' },
        { id: 'medium', name: '中 Medium' },
        { id: 'low', name: '低 Low' },
    ];

    get addressInfo() {
        const getValue = value => (value ? `${value}, ` : '');
        const matchedEle = this.addressLists?.find(ele => ele.id === Number(this.samplePurchaseItem.address_id));
        return matchedEle
            ? `${matchedEle.name}, ${getValue(matchedEle.phone_number)}${matchedEle.address_line_1}, ${getValue(
                  matchedEle.address_line_2
              )}${matchedEle.city}, ${matchedEle.state}, ${matchedEle.zip}, ${matchedEle.country}`
            : '';
    }

    get saveBtnDisabled() {
        return (
            !this.samplePurchaseItem.product_name ||
            !this.samplePurchaseItem.shop_id ||
            !this.samplePurchaseItem.request_qty ||
            !this.samplePurchaseItem.address_id ||
            !this.samplePurchaseItem.sourcing_link ||
            !this.samplePurchaseItem.instruction ||
            !this.samplePurchaseItem.urgency ||
            !this.samplePurchaseItem.required_date
        );
    }

    constructor(private orderDispatchService: OrderDispatchService, private notificationService: NotificationService) {}

    ngOnInit(): void {
        this.getAddressLists();
    }

    getAddressLists() {
        this.orderDispatchService.getAddressBook().then(res => {
            this.addressLists = res;
        });
    }

    addAddress() {
        this.addressModalVisible = true;
    }

    addAddressConfirm() {
        this.getAddressLists();
        this.addressModalVisible = false;
    }

    changeShop() {
        this.selectShop.emit(this.samplePurchaseItem.shop_id);
    }

    saveRecord() {
        this.savingRecord = true;
        const data = {
            address: this.samplePurchaseItem.address_id,
            instruction: this.samplePurchaseItem.instruction,
            product_name: this.samplePurchaseItem.product_name,
            request_quantity: this.samplePurchaseItem.request_qty,
            shop_id: this.samplePurchaseItem.shop_id,
            sourcing_link: this.samplePurchaseItem.sourcing_link,
            urgency: this.samplePurchaseItem.urgency,
            required_date: this.samplePurchaseItem.required_date,
            qc_required: this.checkRequirementOptions[0].checked,
            live_demo_requried: this.checkRequirementOptions[1].checked,
            photo_shoot_required: this.checkRequirementOptions[2].checked,
        };
        this.orderDispatchService
            .createSampleRequest(data)
            .then(res => {
                this.notificationService.showGeneralSuccessMessage({ message: 'Add Sample Purchase Request Success' });
                this.samplePurchaseItem = {
                    product_name: '',
                    shop_id: null,
                    request_qty: null,
                    sourcing_link: '',
                    address_id: null,
                    instruction: '',
                    urgency: '中',
                    required_date: dayjs().format('MM/DD/YYYY'),
                    qc_required: false,
                    live_demo_requried: false,
                    photo_shoot_required: false,
                };
                this.savingRecord = false;
                this.addSampleSuccess.emit(res);
            })
            .catch(err => {
                this.notificationService.showGeneralErrorMessage({ message: err.error?.message });
                this.savingRecord = false;
            });
    }
}
