<section class="auto-import-box">
    <button
        class="absolute"
        style="top: 40px; right: 20px"
        nz-button
        nzType="primary"
        [nzLoading]="savingRecord"
        [disabled]="saveBtnDisabled"
        (click)="saveRecordEmit()"
    >
        Save Record
    </button>
    <!-- Order info -->
    <section class="order-info-form">
        <div class="section-title mb-20 fs-16 fw-600" style="color: #0d053c">Order Info</div>

        <div class="order-forms d-flex justify-content-between align-items-end">
            <app-form-item
                class="flex-1 pr-20"
                [config]="{
                    type: 'select',
                    options: [{value:'1688',label:'1688'}],
                    disabled:true,
                    title: ('PURCHASE_PLATFORM' | translate),
                    ngModelValue: {
                        fieldName: 'purchase_platform',
                        obj: productPurchaseInfo
                    }
                }"
            ></app-form-item>

            <app-form-item
                class="flex-1 pr-20"
                [config]="{
                    type: 'stringInput',
                    title: ('PURCHASE_ORDER_ID' | translate),
                    ngModelValue: {
                        fieldName: 'purchase_order_id',
                        obj: productPurchaseInfo
                    }
                }"
            ></app-form-item>

            <app-form-item
                class="flex-1 pr-20"
                [config]="{
                    type: 'select',
                    options: CN_WAREHOUSE,
                    title: ('DESTINATION_CN' | translate),
                    ngModelValue: {
                        fieldName: 'destination_address',
                        obj: productPurchaseInfo
                    }
                }"
            ></app-form-item>

            <button
                nz-button
                nzType="primary"
                [disabled]="!productPurchaseInfo.purchase_order_id"
                [nzLoading]="fetchingOrderInfo"
                (click)="fetchOrderInfo()"
            >
                Fetch Order Info
            </button>
        </div>
    </section>

    <!-- Product purchase -->
    <section class="product-purchase mt-20">
        <div class="section-title mb-20 fs-16 fw-600" style="color: #0d053c">Products Purchased</div>
        <div class="product-purchase-item" *ngFor="let item of productPurchaseInfo.product_list">
            <div class="d-flex justify-content-between align-items-center">
                <div class="order-info d-flex justify-content-start align-items-center pr-20">
                    <img class="mr-20 objeft-fit-contain" width="80" height="80" [src]="item.product_img" alt="{{item.product_title}}" />
                    <div class="product-info">
                        <div class="product-title">{{item.product_title}}</div>
                        <div class="option-info fs-14" style="color: grey">{{item.product_option_info}}</div>
                    </div>
                </div>
                <div class="purchse-form flex-1">
                    <div class="row d-flex justify-content-between align-items-center flex-1 mb-20">
                        <app-form-item
                            class="flex-1 pr-20"
                            [config]="{
                                type: 'numberInput',
                                title: 'Unit Cost 单价（￥）',
                                nzPrecision:2,
                                ngModelValue: {
                                    fieldName: 'unit_cost',
                                    obj: item
                                }
                            }"
                        ></app-form-item>

                        <app-form-item
                            class="flex-1"
                            [config]="{
                            type: 'numberInput',
                            title: 'Quantity 数量',
                            nzPrecision:0,
                            ngModelValue: {
                                fieldName: 'quantity',
                                obj: item
                            }
                        }"
                        ></app-form-item>
                    </div>

                    <div class="row d-flex justify-content-between align-items-end flex-1 mb-20">
                        <div class="form-item flex-1 pr-20">
                            <div class="title">Variant (SKU) <span class="required">*</span></div>
                            <div class="inline-box">
                                <input
                                    nz-input
                                    type="text"
                                    placeholder="Enter Variant (SKU)"
                                    [(ngModel)]="item.product_sku"
                                    (focus)="skuChange(item.product_sku, item)"
                                    (ngModelChange)="skuChange($event, item)"
                                    [nzAutocomplete]="variantOptions"
                                />

                                <nz-autocomplete #variantOptions>
                                    <nz-auto-option
                                        *ngIf="item.matched_lifo_sku"
                                        [nzValue]="item.matched_lifo_sku.product_sku"
                                        [nzLabel]="item.matched_lifo_sku.product_sku"
                                    >
                                        <img
                                            src="{{ item.matched_lifo_sku.variant_image }}"
                                            style="width: 40px; height: 40px; margin-right: 10px"
                                        />
                                        {{ item.matched_lifo_sku.variant_name }} ({{ item.matched_lifo_sku.product_sku }})
                                    </nz-auto-option>

                                    <nz-auto-option *ngIf="allSku?.length <= 0" nzDisabled nzCustomContent>
                                        <i nz-icon nzType="loading" nzTheme="outline" style="margin-right: 5px"></i>
                                        <span>Loading Data...</span>
                                    </nz-auto-option>
                                </nz-autocomplete>
                            </div>
                        </div>

                        <div class="form-item flex-1" style="position: relative; min-width: 550px">
                            <div class="title">Purchase for Restock Request 匹配补货请求</div>
                            <div class="inline-box">
                                <nz-select
                                    nzShowSearch
                                    nzAllowClear
                                    nzPlaceHolder="Search by Request ID"
                                    [nzOptionHeightPx]="54"
                                    [(ngModel)]="item.restock_request_id"
                                >
                                    <nz-option
                                        *ngFor="let option of item.restock_request_list"
                                        [nzValue]="option.id"
                                        [nzLabel]="option.product_sku"
                                        nzCustomContent
                                    >
                                        <div
                                            class="request-item-container"
                                            style="display: flex; justify-content: space-between; align-items: center; font-size: 12px"
                                        >
                                            <div style="flex: 1; padding: 0 2px">
                                                <div>ID</div>
                                                <div>{{ option.id }}</div>
                                            </div>

                                            <div style="flex: 2; padding: 0 2px">
                                                <div>Requested QTY</div>
                                                <div>{{ option.requested_quantity }}</div>
                                            </div>
                                            <div style="flex: 2; padding: 0 2px">
                                                <div>Remaining QTY</div>
                                                <div>
                                                    {{ option.requested_quantity - option.in_progress_quantity - option.fulfilled_quantity}}
                                                </div>
                                            </div>

                                            <div style="flex: 2; padding: 0 2px">
                                                <div>Requested Date</div>
                                                <div>{{ option.created_at | date: 'yyyy-MM-dd' }}</div>
                                            </div>

                                            <div style="flex: 2; padding: 0 2px">
                                                <div>Requestor</div>
                                                <div>{{ option.requestor }}</div>
                                            </div>
                                        </div>
                                    </nz-option>
                                    <nz-option *ngIf="restockRequestList.length <= 0" nzDisabled nzCustomContent>
                                        <i nz-icon nzType="loading" nzTheme="outline" style="margin-right: 5px"></i>
                                        <span>Loading Data...</span>
                                    </nz-option>
                                </nz-select>
                            </div>
                        </div>
                    </div>

                    <div class="row d-flex justify-content-between align-items-center flex-1 mb-20">
                        <app-form-item
                            class="flex-1 pr-20"
                            [config]="{
                                type: 'stringInput',
                                title: 'Chinese Declaration Name 报关中文名',
                                ngModelValue: {
                                    fieldName: 'chinese_declaration_name',
                                    obj: item
                                }
                            }"
                        ></app-form-item>

                        <app-form-item
                            class="flex-1"
                            [config]="{
                                type: 'stringInput',
                                title: 'English Declaration Name 报关英文名',
                                ngModelValue: {
                                    fieldName: 'english_declaration_name',
                                    obj: item
                                }
                            }"
                        ></app-form-item>
                    </div>

                    <div class="row d-flex justify-content-between align-items-center flex-1 mb-20">
                        <div class="form-item" style="width: 50%; padding-right: 10px">
                            <div class="title">QC Percentage 质检比例 <span class="required">*</span></div>
                            <div class="inline-box">
                                <nz-input-number
                                    nzPlaceHolder="QC Percentage"
                                    [(ngModel)]="item.qc_ratio"
                                    nzPrecision="2"
                                    [nzMin]="0"
                                    [nzMax]="100"
                                    [nzFormatter]="formatterPercent"
                                    style="width: 100%"
                                ></nz-input-number>
                            </div>
                        </div>
                    </div>

                    <div class="row d-flex justify-content-between align-items-center flex-1 mb-20">
                        <app-form-item
                            class="flex-1 pr-20"
                            [config]="{
                                type: 'stringInput',
                                title: 'Check Requirements 对版要求',
                                ngModelValue: {
                                    fieldName: 'check_requirement',
                                    obj: item
                                },
                                optional: true
                            }"
                        ></app-form-item>

                        <app-form-item
                            class="flex-1"
                            [config]="{
                                type: 'stringInput',
                                title: 'QC Requirements 质检要求',
                                ngModelValue: {
                                    fieldName: 'qc_requirement',
                                    obj: item
                                },
                                optional: true
                            }"
                        ></app-form-item>
                    </div>
                </div>
            </div>
            <nz-divider></nz-divider>
        </div>
    </section>

    <!-- Other form -->
    <section class="other-form">
        <div class="section-title mb-20 fs-16 fw-600" style="color: #0d053c">Purchase Information</div>

        <div class="d-flex justify-content-between align-items-end mb-20">
            <div class="form-item flex-1 mr-20">
                <div class="title">Purchase Date 采购日期 <span class="required">*</span></div>
                <div class="inline-box">
                    <nz-date-picker
                        nzFormat="MM/dd/yyyy"
                        nzPlaceHolder="MM/DD/YYYY"
                        [(ngModel)]="productPurchaseInfo.purchase_date"
                    ></nz-date-picker>
                </div>
            </div>

            <div class="form-item flex-1">
                <div class="title">Order Content 1688货品标题<span class="required">*</span></div>
                <div class="inline-box">
                    <textarea nz-input [(ngModel)]="productPurchaseInfo.order_content" placeholder="Order Content"></textarea>
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-between align-items-end mb-20">
            <div class="form-item flex-1 mr-20">
                <div class="title">Deliver ETA 预计送达日期</div>
                <div class="inline-box">
                    <nz-date-picker
                        nzFormat="MM/dd/yyyy"
                        nzPlaceHolder="MM/DD/YYYY"
                        [(ngModel)]="productPurchaseInfo.deliver_eta"
                    ></nz-date-picker>
                </div>
            </div>

            <div class="form-item flex-1">
                <div class="title">Shipping Cost 运费 (¥)</div>
                <div class="inline-box">
                    <input nz-input type="number" min="0" [(ngModel)]="productPurchaseInfo.shipping_cost" />
                </div>
            </div>
        </div>

        <!-- Add tracking info -->
        <div *ngFor="let logisticsItem of productPurchaseInfo.logistics" class="d-flex justify-content-between align-items-end mb-20">
            <div class="form-item flex-1 mr-20">
                <div class="title">third-party logistics number 第三订单物流单号</div>
                <div class="inline-box">
                    <input nz-input [(ngModel)]="logisticsItem.carrier" placeholder="Carrier" />
                </div>
            </div>

            <div class="form-item flex-1">
                <div class="title">Carrier 物流公司</div>
                <div class="inline-box">
                    <input nz-input placeholder="Third-party logistics number" [(ngModel)]="logisticsItem.tracking_number" />
                </div>
            </div>
        </div>
        <div class="cursor-pointer link-color text-decoration-underline">
            <span (click)="productPurchaseInfo.logistics.push({carrier:'',tracking_number:''})">
                <i nz-icon nzType="plus-circle" nzTheme="outline"></i>
                <span>Add new logistics record 添加新物流记录</span>
            </span>
        </div>
    </section>
</section>
