<div class="main-content product-purchase-content mb-20">
    <section class="btn-operations">
        <span class="main-content-title">Product Purchase 大货采购</span>
        <section class="date-times">
            <nz-range-picker
                [nzFormat]="dateFormat"
                [(ngModel)]="dateTimes"
                (ngModelChange)="onChangeDateTimes(getTitleList(onChangeTitle))"
            ></nz-range-picker>
            <!-- <button nz-button nzType="primary" [nzLoading]="isExporting" [disabled]="!productPurchaseList" (click)="export()">
                Export List
            </button> -->
            <button
                nz-button
                nzType="primary"
                [disabled]="setOfCheckedId.size === 0"
                (click)="showTransportModal()"
                style="margin-left: 10px"
            >
                转运
            </button>
        </section>
    </section>

    <section class="filter-status">
        <div class="uncomplete" [ngClass]="{ select: onChangeTitle === 'unConfirm' }" (click)="selectStatus('unConfirm')">
            Unconfirmed 未确认收货
            <span>{{ unConfirmLength }}</span>
        </div>
        <div class="complete" [ngClass]="{ select: onChangeTitle === 'isConfirm' }" (click)="selectStatus('isConfirm')">
            Confirmed 已确认收货
        </div>
        <div class="complete" [ngClass]="{ select: onChangeTitle === 'isErrors' }" (click)="selectStatus('isErrors')">采购异常</div>
    </section>

    <section class="data-table">
        <nz-table
            #rowSelectionTable
            nzShowPagination
            nzShowSizeChanger
            [nzLoading]="fetchingProductPurchaseList"
            [nzData]="productPurchaseList"
            [nzShowPagination]="true"
            [nzScroll]="{ x: '2800px' }"
        >
            <thead>
                <tr>
                    <th [nzWidth]="'40px'" [(nzChecked)]="allChecked" (nzCheckedChange)="onAllChecked($event)"></th>
                    <ng-container *ngFor="let column of columnList">
                        <th
                            *ngIf="!column.show_search && column.id !=='exceptions' && column.id !=='qc_images'"
                            [nzSortFn]="column.sortFn"
                            [nzWidth]="column.width || '150px'"
                            [nzFilters]="column.listOfFilter"
                            [nzFilterFn]="column.filterFn"
                        >
                            {{ column.name }}
                        </th>
                        <th
                            *ngIf="!column.show_search && column.id ==='qc_images' && onChangeTitle === 'isConfirm'"
                            [nzWidth]="column.width || '150px'"
                        >
                            {{ column.name }}
                        </th>
                        <th
                            *ngIf="!column.show_search && column.id ==='exceptions' && onChangeTitle === 'isErrors'"
                            [nzWidth]="column.width || '150px'"
                        >
                            {{ column.name }}
                        </th>
                        <th *ngIf="column.show_search" [nzSortFn]="column.sortFn" [nzWidth]="column.width || '150px'">
                            {{ column.name }}
                            <app-table-search-dropdown
                                [defaultProductList]="defaultProductList"
                                [name]="column.id"
                                [options]="column.options"
                                (searchList)="searchResultList($event)"
                                (resetList)="resetResultList()"
                            ></app-table-search-dropdown>
                        </th>
                    </ng-container>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of rowSelectionTable.data" (click)="showModal(data, 'product_detail', $event);">
                    <td [nzChecked]="setOfCheckedId.has(data.id)" (nzCheckedChange)="onItemChecked(data.id, $event)"></td>
                    <td>{{ data.id || '---' }}</td>
                    <td>
                        <img src="{{ data.variant_image }}" style="height: 40px; width: 40px" />
                    </td>
                    <td>
                        {{ data.product_sku }} <br />
                        {{ data.variant_name }}
                    </td>
                    <td>
                        {{ data.english_declaration_name }} <br />
                        {{ data.chinese_declaration_name }}
                    </td>
                    <td>
                        {{ data.shop_id }} <br />
                        {{ data.shop_name }} <nz-tag [nzColor]="'cyan'" *ngIf="data.testing_account">Testing</nz-tag>
                    </td>
                    <td>
                        {{ data.product_name }} <br />
                        {{ data.product_id }}
                        <app-kit-tooltip *ngIf="data.is_kit_item" [kitSku]="data.product_sku"></app-kit-tooltip>
                    </td>
                    <td>{{ data.unit_cost | currency: data.currency }}</td>
                    <td>{{ data.purchase_date | date: 'MM/dd/yyyy' }}</td>
                    <td>{{ data.deliver_eta | date: 'MM/dd/yyyy' }}</td>
                    <td>{{ data.destination_address }}</td>
                    <td>{{ data.purchase_platform }}</td>
                    <td>{{ data.purchase_order_id }}</td>
                    <td>
                        <div *ngFor="let logisticsItem of data.logistics">
                            {{ logisticsItem.carrier }} -- {{ logisticsItem.tracking_number }} {{logisticsItem.destination_address ? ('(' +
                            logisticsItem.destination_address + ')') : ''}}
                        </div>
                    </td>
                    <td>{{ data.shipping_cost | currency: data.currency }}</td>
                    <td>
                        <div style="max-height: 100px; overflow-y: auto" [innerHtml]="data.order_content | safeHtml"></div>
                    </td>
                    <td>{{ data.purchased_quantity }}</td>
                    <td>{{ data.arrived_quantity }}</td>
                    <td>
                        {{ isNumber(data.in_stock_quantity) ? data.arrived_quantity - data.in_stock_quantity : '--' }}
                        <span>/</span>
                        {{ isNumber(data.in_stock_quantity) ? data.in_stock_quantity : '--' }}
                    </td>
                    <td>{{ data.restock_request_id || '---' }}</td>
                    <td>{{ data.qc_ratio ? data.qc_ratio + '%' : '' }}</td>
                    <td *ngIf="onChangeTitle === 'isConfirm'">
                        <span
                            *ngIf="data?.qc_result?.images?.length; else notQcImages"
                            style="color: #414bb2; cursor: pointer"
                            (click)="previewImgMode(0, data.qc_result.images, $event)"
                        >
                            View
                        </span>
                        <ng-template #notQcImages>/</ng-template>
                    </td>
                    <td *ngIf="onChangeTitle === 'isErrors'" [innerHTML]="errorException(data.exceptions)"></td>
                    <td>
                        <div class="checkpoint-icon">
                            <i
                                class="check-icon"
                                nz-icon
                                nzType="check-circle"
                                nzTheme="fill"
                                style="color: green"
                                *ngIf="data.purchased_quantity === data.arrived_quantity || onChangeTitle === 'isConfirm'"
                            ></i>
                            <ng-container *ngIf="permissionService.currentUserPermission.isProductPurchaseFullEdit; else closeIcon">
                                <div
                                    *ngIf="data.purchased_quantity > (data.arrived_quantity || 0) && onChangeTitle !== 'isConfirm'"
                                    style="display: flex; justify-content: flex-start; align-items: center"
                                >
                                    <button nz-button nzType="primary" class="mr-20" (click)="showModal(data, 'accept_purchase', $event)">
                                        <i nz-icon nzType="check-circle" nzTheme="outline"></i>
                                        <span>Confirm</span>
                                    </button>

                                    <button nz-button nzType="primary" (click)="showModal(data, 'edit_record', $event)">
                                        <i nz-icon nzType="edit" nzTheme="outline"></i>
                                        <span>Edit</span>
                                    </button>
                                </div>
                            </ng-container>
                            <ng-template #closeIcon>
                                <i
                                    *ngIf="data.purchased_quantity > (data.arrived_quantity || 0) && onChangeTitle !== 'isConfirm'"
                                    nz-icon
                                    nzType="close-circle"
                                    nzTheme="fill"
                                    style="color: red"
                                ></i>
                            </ng-template>
                        </div>
                    </td>
                </tr>
            </tbody>
        </nz-table>
    </section>
</div>

<nz-modal
    nzTitle="Accept Purchase"
    [nzOkDisabled]="acceptProductModal.okBtnDisabled"
    [nzOkLoading]="acceptProductModal.okBtnLoading"
    [(nzVisible)]="acceptProductModal.visible"
    (nzOnCancel)="acceptProductModal.hideModal()"
    (nzOnOk)="acceptProductPurchase()"
>
    <div class="edit-quantity-box">
        <div class="quantity-item" style="margin-bottom: 20px">
            <div class="title">Purchased Quantity</div>
            <input disabled [value]="selectedProductPurchase.purchased_quantity" nz-input style="width: 100%" />
        </div>

        <div class="quantity-item" style="margin-bottom: 20px">
            <div class="title">Arrived Quantity</div>
            <input [(ngModel)]="selectedProductPurchase.arrived_quantity" nz-input type="number" style="width: 100%" />
        </div>

        <section class="confirmReceipt">
            <div style="margin-bottom: 20px">
                <label nz-checkbox [(ngModel)]="selectedProductPurchase.purchase_complete">确认收货完成</label>
            </div>
            <div [hidden]="isShowConfirmNote" style="margin-bottom: 10px">
                <div>备注 <span class="required">*</span></div>
                <textarea nz-input [(ngModel)]="selectedProductPurchase.note" [nzAutosize]="{ minRows: 3, maxRows: 3 }"></textarea>
            </div>
        </section>
    </div>
</nz-modal>

<nz-modal
    nzTitle="Edit Product Purchase Information 修改大货采购记录信息"
    [nzOkDisabled]="isEditDisable(editProductModalComp.productPurchaseInfo)"
    [nzOkLoading]="editProductModal.okBtnLoading"
    [(nzVisible)]="editProductModal.visible"
    [nzWidth]="800"
    (nzOnCancel)="editProductModal.hideModal()"
    (nzOnOk)="editProductPurchase(editProductModalComp.productPurchaseInfo)"
>
    <app-edit-product-purchase #editProductModalComp [selectedProductPurchase]="selectedProductPurchase"></app-edit-product-purchase>
</nz-modal>

<nz-modal
    nzTitle="View Product Purchase Information 查看大货采购记录信息"
    [(nzVisible)]="productDetailsVisible"
    [nzWidth]="1000"
    [nzFooter]="null"
    (nzOnCancel)="productDetailsVisible = false;"
>
    <app-product-details
        [productInfo]="selectedProductPurchase.matchedDataInTable"
        [productInfoList]="productInfoList"
    ></app-product-details>
</nz-modal>

<nz-modal
    nzTitle="转运"
    [(nzVisible)]="transportModalControl.visible"
    [nzOkDisabled]="isDisabledTransportBtn"
    [nzOkLoading]="transportModalControl.okBtnLoading"
    (nzOnOk)="toTransport()"
    (nzOnCancel)="transportModalControl.hideModal()"
>
    <div class="edit-quantity-box">
        <nz-table
            #shipTable
            [nzShowPagination]="false"
            [nzData]="selectedProductPurchaseList"
            [nzScroll]="{ y: '240px' }"
            style="margin-bottom: 20px"
        >
            <thead>
                <tr>
                    <th>采购记录ID</th>
                    <th>Product ID</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of shipTable.data">
                    <td>{{data.id}}</td>
                    <td>
                        {{ data.product_name }} <br />
                        {{ data.product_id }}
                    </td>
                </tr>
            </tbody>
        </nz-table>

        <div style="margin-bottom: 20px">
            <div class="title">目的地仓库</div>
            <nz-select [(ngModel)]="transportParams.destination_address" style="width: 100%">
                <nz-option *ngFor="let item of DESTINATION_CHINA_WAREHOUSE" [nzValue]="item" [nzLabel]="item"></nz-option>
            </nz-select>
        </div>
        <div style="margin-bottom: 20px">
            <div class="title">物流公司</div>
            <nz-select [(ngModel)]="transportParams.logistics[0].carrier" nzShowSearch style="width: 100%">
                <nz-option *ngFor="let item of CN_CARRIER_LIST" [nzValue]="item" [nzLabel]="item"></nz-option>
            </nz-select>
        </div>
        <div style="margin-bottom: 20px">
            <div class="title">物流单号</div>
            <input [(ngModel)]="transportParams.logistics[0].tracking_number" nz-input type="text" style="width: 100%" />
        </div>
    </div>
</nz-modal>
