<header>
    <section class="page-title">{{ moduleTitle }}</section>

    <ul *ngIf="menus?.length >= 0" nz-menu nzMode="horizontal">
        <ng-container *ngFor="let menuItem of menus">
            <li *ngIf="!menuItem.hidden" nz-menu-item [nzSelected]="activatedMenu === menuItem" (click)="go(menuItem.url)">
                <span>{{ menuItem.title }}</span>
            </li>
        </ng-container>
    </ul>
</header>
