import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Api1688Service } from '@services/api-1688.service';
import { DESTINATION_CHINA_WAREHOUSE } from '@shared/const/order-dispatch.const';
import { OrderDispatchCacheService } from '@services/order-dispatch-cache.service';
import { NotificationService } from '@services/notification.service';
import { isNumber } from 'lodash';

interface ProductPurchaseInfo {
    product_list: {
        product_img: string;
        product_title: string;
        product_option_info: string;
        unit_cost: number;
        quantity: number;
        product_sku: string;
        platform_sku: string;
        restock_request_id: string;
        restock_request_list?: any[];
        matched_lifo_sku?: any;
        shop_id: any;
        product_id: any;
        chinese_declaration_name: string;
        english_declaration_name: string;
        qc_ratio: number;
        check_requirement: string;
        qc_requirement: string;
    }[];
    purchase_date: string;
    deliver_eta: string;
    order_content: string;
    shipping_cost: number;
    purchase_platform: '1688';
    purchase_order_id: string;
    destination_address: string;
    chinese_declaration_name: string;
    english_declaration_name: string;
    qc_ratio: number;
    check_requirement: string;
    qc_requirement: string;
    logistics: { carrier: string; tracking_number: string }[];
}

@Component({
    selector: 'app-auto-import-purchase-record',
    templateUrl: './auto-import-purchase-record.component.html',
    styleUrls: ['./auto-import-purchase-record.component.less'],
})
export class AutoImportPurchaseRecordComponent implements OnInit {
    @Input() restockRequestList: any[];
    @Output() saveRecord = new EventEmitter();

    orderInfo = {};
    // eslint-disable-next-line arrow-body-style
    CN_WAREHOUSE = DESTINATION_CHINA_WAREHOUSE.map(val => {
        return { value: val, label: val };
    });

    fetchingOrderInfo = false;
    savingRecord = false;

    productPurchaseInfo: ProductPurchaseInfo = {
        purchase_platform: '1688',
        purchase_order_id: null,
        destination_address: null,
        product_list: [],
        purchase_date: null,
        deliver_eta: null,
        order_content: null,
        shipping_cost: null,
        logistics: [],
        chinese_declaration_name: null,
        english_declaration_name: null,
        qc_ratio: 0,
        check_requirement: null,
        qc_requirement: null,
    };

    constructor(
        private api1688Service: Api1688Service,
        private orderDispatchCacheService: OrderDispatchCacheService,
        private notificationService: NotificationService
    ) {}

    formatterPercent = (value: number) => `${value} %`;

    get allSku() {
        return this.orderDispatchCacheService.allSku;
    }

    get saveBtnDisabled() {
        return (
            !this.productPurchaseInfo.destination_address ||
            !this.productPurchaseInfo.purchase_date ||
            !this.productPurchaseInfo.order_content ||
            !this.productPurchaseInfo.purchase_order_id ||
            !this.productPurchaseInfo.purchase_platform ||
            !this.productPurchaseInfo.chinese_declaration_name ||
            !this.productPurchaseInfo.english_declaration_name ||
            !this.productPurchaseInfo.qc_ratio ||
            this.productPurchaseInfo.product_list.some(
                item =>
                    !item.product_sku ||
                    !item.quantity ||
                    !item.unit_cost ||
                    !item.chinese_declaration_name ||
                    !item.english_declaration_name ||
                    !isNumber(item.qc_ratio)
            )
        );
    }

    ngOnInit(): void {}

    fetchOrderInfo() {
        this.fetchingOrderInfo = true;
        this.api1688Service
            .getOrderDetailByOrderId(this.productPurchaseInfo.purchase_order_id)
            .then(res => {
                const result = res.result;
                const tempProductPurchaseInfo: ProductPurchaseInfo = {} as ProductPurchaseInfo;

                if (result) {
                    tempProductPurchaseInfo.product_list = result.productItems.map(product => {
                        const productOptionInfo = product.skuInfos.map(item => `${item.name}:${item.value}`).join('; ');
                        return {
                            product_img: product.productImgUrl[0],
                            product_title: product.name,
                            product_option_info: productOptionInfo,
                            unit_cost: product.price,
                            quantity: product.quantity,
                            product_sku: this.orderDispatchCacheService.platformSkuMap.get(String(product.skuID))?.product_sku,
                            shop_id: this.orderDispatchCacheService.platformSkuMap.get(String(product.skuID))?.shop_id,
                            product_id: this.orderDispatchCacheService.platformSkuMap.get(String(product.skuID))?.product_id,
                            platform_sku: product.skuID,
                            chinese_declaration_name: '',
                            english_declaration_name: '',
                            qc_ratio: 0,
                            check_requirement: '',
                            qc_requirement: '',
                        };
                    });
                    const logistics = result.nativeLogistics?.logisticsItems || [];
                    const createTime = result.baseInfo.createTime;
                    const formattedCreateTime = `${createTime.slice(0, 4)}/${createTime.slice(4, 6)}/${createTime.slice(6, 8)} 00:00:00`;

                    tempProductPurchaseInfo.logistics = logistics.map(item => {
                        const logisticsInfo = {
                            carrier: item.logisticsCompanyName,
                            tracking_number: item.logisticsBillNo,
                        };

                        return logisticsInfo;
                    });
                    tempProductPurchaseInfo.purchase_date = formattedCreateTime;
                    tempProductPurchaseInfo.shipping_cost = result.baseInfo.shippingFee;
                    tempProductPurchaseInfo.order_content = result.productItems
                        .map(item => {
                            const productOptionInfo = item.skuInfos.map(sku => `${sku.name}:${sku.value}`).join('; ');
                            return `${item.name} \n${productOptionInfo} \n${item.quantity}`;
                        })
                        .join('\n<br /><br />\n');
                    this.productPurchaseInfo = { ...this.productPurchaseInfo, ...tempProductPurchaseInfo };
                } else {
                    throw new Error(res.errorMessage);
                }
            })
            .catch(err => {
                this.notificationService.showGeneralErrorMessage({ message: err.message });
                this.productPurchaseInfo.product_list.length = 0;
            })
            .finally(() => (this.fetchingOrderInfo = false));
    }

    skuChange(value: string, listItem: any, restock_request_id?: string): void {
        if (!value) return;
        listItem.matched_lifo_sku = this.allSku.find(item => item.product_sku === value);
        if (listItem.matched_lifo_sku) {
            listItem.shop_id = listItem.matched_lifo_sku.shop_id;
            listItem.product_id = listItem.matched_lifo_sku.product_id;
            listItem.chinese_declaration_name = listItem.matched_lifo_sku.chinese_declaration_name;
            listItem.english_declaration_name = listItem.matched_lifo_sku.english_declaration_name;
            listItem.qc_ratio = listItem.matched_lifo_sku.qc_ratio;
            listItem.check_requirement = listItem.matched_lifo_sku.check_requirement;
            listItem.qc_requirement = listItem.matched_lifo_sku.qc_requirement;
            listItem.restock_request_list = this.restockRequestList
                .filter(item => item.product_sku === value)
                .filter(
                    item =>
                        item.fulfilled_quantity + item.in_progress_quantity < item.requested_quantity &&
                        item.fulfillment_status !== 'fulfilled' &&
                        item.fulfillment_status !== 'cancelled'
                ); // unfulfilled
        } else {
            listItem.restock_request_list = [];
        }

        listItem.restock_request_id = restock_request_id ? restock_request_id : null;
    }

    saveRecordEmit() {
        this.saveRecord.emit();
    }
}
