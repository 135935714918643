import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { WarehouseService } from '@services/warehouse.service';
import { NotificationService, AlertType } from '@services/notification.service';
import { FirebaseFileUploadService } from '@services/firebase-file-upload.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { NzModalService } from 'ng-zorro-antd/modal';
import { UserPermissionService } from '@services/user-permission.service';
import { DESTINATION_CHINA_WAREHOUSE } from '@shared/const/order-dispatch.const';

import { LogisticRecordErrorComponent } from '@pages/logistics-management/mass-ordering/\
logistic-record-error/logistic-record-error.component';

@Component({
    selector: 'app-shipping-schedule-plan',
    templateUrl: './shipping-schedule.component.html',
    styleUrls: ['./shipping-schedule.component.less'],
})
export class ShippingSchedulePlanComponent implements OnInit {
    @Output() getInStockSKUs = new EventEmitter();

    destinationAddressOptions = DESTINATION_CHINA_WAREHOUSE.map(item => ({ value: item, label: item }));
    isPendingLength = 0;
    shippingScheduleList = [];
    defaultShippingScheduleList = [];
    isPending = false;
    isProgress = false;
    isComplete = false;
    tabSelection = ['isPending', 'isProgress', 'isComplete'];
    fetchingShippingScheduleList = false;
    shippingScheduleModalVisible = false;
    saveBtnDisabled = true;
    isConfirmLoading = false;
    logo_url = '';
    dropDownValue = '';
    filePath = '';
    selectRow: any;
    fileName = '';
    isUnComfirmLength = 0;
    filename: string = null;
    fileDataJSON: any = null;
    excelBthDisable = true;
    snapshot: Observable<any>;

    constructor(
        private warehouseService: WarehouseService,
        public permissionService: UserPermissionService,
        private notificationService: NotificationService,
        private modal: NzModalService,
        private firebaseUploadService: FirebaseFileUploadService
    ) {}

    ngOnInit() {
        this.shippingSchedule();
    }

    shippingSchedule() {
        this.fetchingShippingScheduleList = true;
        this.warehouseService
            .getShippingSchedule(this.dropDownValue)
            .then(res => {
                this.shippingScheduleList = res;
                this.defaultShippingScheduleList = res;
                this.selectStatus('isPending');
            })
            .catch(err => {
                this.notificationService.addMessage({
                    type: AlertType.Error,
                    title: 'Get new records failed, please try again.',
                    message: err,
                    duration: 5000,
                });
            })
            .finally(() => (this.fetchingShippingScheduleList = false));
    }

    changeInbound(key) {
        this.dropDownValue = key;
        this.shippingSchedule();
    }

    beforeUploadExcel = (file: File) => {
        this.handleExcelFile(file);
        return false;
    };

    removeInvoice() {
        this.filePath = '';
        this.logo_url = '';
        this.saveBtnDisabled = true;
        this.excelBthDisable = true;
    }

    onDrop(fileList: FileList) {
        this.firebaseUploadService.startUpload(fileList[0]).then(({ src, path }) => {
            this.filePath = src;
        });
    }

    uploadScheduleFile(path) {
        if (path === '') {
            this.notificationService.addMessage({
                type: AlertType.Error,
                title: 'Error',
                message: 'schedule file path cannot be empty.',
                duration: 5000,
            });
            return false;
        }
        const data = {
            id: this.selectRow.id,
            schedule_file_path: path,
        };

        this.warehouseService
            .putScheduleFile(data)
            .then(res => {
                this.shippingScheduleModalVisible = false;
                this.notificationService.addMessage({
                    title: '上传成功！',
                    type: AlertType.Success,
                    message: '发货计划表上传成功',
                });
                this.shippingSchedule();
                this.getInStockSKUs.emit();
            })
            .catch(err => {
                this.notificationService.addMessage({
                    type: AlertType.Error,
                    title: 'Get new records failed, please try again.',
                    message: err,
                    duration: 5000,
                });
            });
    }

    uploadFile(row) {
        this.saveBtnDisabled = true;
        this.excelBthDisable = true;
        this.selectRow = row;
        this.shippingScheduleModalVisible = true;
    }

    handleOk() {
        this.uploadScheduleFile(this.filePath);
    }

    handleCancel() {
        this.saveBtnDisabled = true;
        this.shippingScheduleModalVisible = false;
    }

    searchResultList(value) {
        this.shippingScheduleList = value;
    }

    resetResultList() {
        this.shippingScheduleList = [...this.defaultShippingScheduleList];
    }

    async handleExcelFile(file: File) {
        this.fileName = file.name;
        this.warehouseService
            .parseLogisticsRecordData(file, {
                warehouseList: this.destinationAddressOptions,
                skuList: [],
            })
            .then(res => {
                this.saveBtnDisabled = false;
                if (res.errors.length > 0) {
                    this.modal.create({
                        nzContent: LogisticRecordErrorComponent,
                        nzComponentParams: { errors: res.errors },
                        nzTitle: 'Save failed',
                    });
                } else {
                    this.firebaseUploadService.startUpload(file).then(({ src, path }) => {
                        this.filePath = src;
                        this.excelBthDisable = false;
                    });
                }
            })
            .catch(err => {
                this.notificationService.addMessage({
                    type: AlertType.Error,
                    title: 'please try again.',
                    message: err,
                    duration: 5000,
                });
            });
    }

    selectStatus(key) {
        const unComplete = [];
        const isComplete = [];
        for (const item of this.tabSelection) {
            if (item === key) {
                this[item] = true;
            } else {
                this[item] = false;
            }
        }
        for (const item of this.defaultShippingScheduleList) {
            if (item.shipping_file_path === null) {
                unComplete.push(item);
            } else {
                isComplete.push(item);
            }
        }
        this.isPendingLength = unComplete.length;
        if (key === 'isPending') {
            this.shippingScheduleList = [...unComplete];
        } else {
            this.shippingScheduleList = [...isComplete];
        }
    }
}
