/* eslint-disable @typescript-eslint/dot-notation */
import { Directive, ElementRef, Input, AfterViewInit, OnChanges } from '@angular/core';

/**
 * Parent class name must include draggable-parent-box
 * Draggable element(like image element) class name must include draggable-box
 */
@Directive({
    selector: '[appImageDragSort]',
})
export class ImageDragSortDirective implements AfterViewInit, OnChanges {
    @Input() data: any[];
    @Input() enableDragSort: boolean;

    constructor(private el: ElementRef) {}

    ngAfterViewInit() {
        if (this.enableDragSort) {
            this.bindElementEvent();
        }
    }

    ngOnChanges() {
        console.log(this.data);
        if (this.data && this.enableDragSort) {
            setTimeout(() => {
                this.bindElementEvent();
            }, 0);
        } else {
            setTimeout(() => {
                this.removeElementEvent();
            }, 0);
        }
    }

    private bindElementEvent() {
        const parentElements = this.el.nativeElement.querySelectorAll('.draggable-parent-box');
        for (let i = 0; i < parentElements.length; i++) {
            const parentEle: HTMLDivElement = parentElements[i];
            parentEle.setAttribute('id', String(i));
            parentEle.ondragover = e => e.preventDefault();
            parentEle.ondrop = e => {
                const startEleId = e.dataTransfer.getData('Text');
                let endParentEle = e.target['parentElement'];
                while (!Array.from(endParentEle.classList || []).find(item => item === 'draggable-parent-box')) {
                    endParentEle = endParentEle.parentElement;
                }

                if (!startEleId) {
                    return;
                }
                const startDataItem = this.data[startEleId];
                this.data[startEleId] = this.data[endParentEle.id];
                this.data[endParentEle.id] = startDataItem;

                document.getElementById(startEleId).setAttribute('id', endParentEle.id);
                endParentEle.setAttribute('id', startEleId);
            };

            const draggableEle: HTMLElement = parentEle.querySelector('.draggable-box');
            draggableEle.setAttribute('draggable', 'true');
            draggableEle.ondragstart = ev => ev.dataTransfer.setData('Text', ev.target['parentElement'].id);
        }
    }

    private removeElementEvent() {
        const parentElements = this.el.nativeElement.querySelectorAll('.draggable-parent-box');
        const childElements = this.el.nativeElement.querySelectorAll('.draggable-box');
        Array.from(parentElements).forEach((ele: HTMLDivElement) => {
            ele.ondragover = null;
            ele.ondrop = null;
        });

        Array.from(childElements).forEach((ele: HTMLDivElement) => {
            ele.ondragstart = null;
        });
    }
}
