import { Injectable } from '@angular/core';

import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserPermissionService } from '@services/user-permission.service';

@Injectable({
    providedIn: 'root',
})
export class UserPermissionGuard implements CanActivate {
    constructor(private permissionService: UserPermissionService, private router: Router) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        switch (this.getHandledUrl(state.url)) {
            case '/product-management':
                if (this.permissionService.currentUserPermission.isProductInventoryNoAccess) {
                    this.goDefaultPage();
                }
                return true;

            case '/order-management':
                if (this.permissionService.currentUserPermission.isOrderManagementNoAccess) {
                    this.goDefaultPage();
                }
                return true;

            case '/shop-management':
                if (this.permissionService.currentUserPermission.isStoreSettingsNoAccess) {
                    this.goDefaultPage();
                }
                return true;

            case '/product-purchase':
                if (this.permissionService.currentUserPermission.isProductPurchaseNoAccess) {
                    this.goDefaultPage();
                }
                return true;

            case '/mass-ordering':
                if (this.permissionService.currentUserPermission.isProductLogisticsNoAccess) {
                    this.goDefaultPage();
                }
                return true;

            case '/track-inventory':
                if (this.permissionService.currentUserPermission.isInventoryTrackingNoAccess) {
                    this.goDefaultPage();
                }
                return true;

            case '/warehouse-management':
                if (this.permissionService.currentUserPermission.isWarehouseManagementNoAccess) {
                    this.goDefaultPage();
                }
                return true;

            case '/restock-request':
                if (this.permissionService.currentUserPermission.isRestockRequestNoAccess) {
                    this.goDefaultPage();
                }
                return true;

            case '/logistics-management':
                if (this.permissionService.currentUserPermission.isProductLogisticsNoAccess) {
                    this.goDefaultPage();
                }
                return true;

            case '/standard-product':
                if (
                    this.permissionService.currentUserPermission.isStandardProductNoAccess &&
                    this.permissionService.currentUserPermission.isGeneralInternalNoAccess
                ) {
                    this.goDefaultPage();
                }
                return true;

            case '/store-management':
            case '/purchase-management':
            case '/payment-request':
            default:
                if (this.permissionService.currentUserPermission.isGeneralInternalNoAccess) {
                    this.goDefaultPage();
                }
                return true;
        }
    }

    private goDefaultPage() {
        this.router.navigateByUrl('/dashboard');
    }

    private getHandledUrl(url: string): string {
        if (url.startsWith('/product-management')) {
            return '/product-management';
        }

        if (url.startsWith('/order-management')) {
            return '/order-management';
        }

        if (url.startsWith('/shop-management')) {
            if (url.startsWith('/shop-management/store-management')) {
                return '/store-management';
            } else {
                return '/shop-management';
            }
        }

        if (url.startsWith('/purchase-management')) {
            if (url.startsWith('/purchase-management/product-purchase')) {
                return '/product-purchase';
            } else if (url.startsWith('/purchase-management/restock-request')) {
                return '/restock-request';
            } else {
                return '/purchase-management';
            }
        }

        if (url.startsWith('/logistics-management')) {
            if (url.startsWith('/logistics-management/mass-ordering')) {
                return '/mass-ordering';
            } else {
                return '/logistics-management';
            }
        }

        if (url.startsWith('/inventory-management/track-inventory')) {
            return '/track-inventory';
        }

        if (url.startsWith('/warehouse-management')) {
            return '/warehouse-management';
        }
        if (url.startsWith('/standard-product')) {
            return '/standard-product';
        }
    }
}
