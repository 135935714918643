<div class="pro-details" *ngIf="productInfo">
    <div class="pro-line" *ngFor="let data of tempList">
        <div class="pro-item" *ngFor="let item of data">
            <h4 class="label">{{ item.label }}</h4>
            <div class="value" [ngSwitch]="item.type">
                <span *ngSwitchCase="'time'">{{ productInfo[item.valueKey] | date: 'MM/dd/yyyy' }}</span>
                <span *ngSwitchCase="'cost'">{{ productInfo[item.valueKey] | currency: productInfo.currency }}</span>
                <span *ngSwitchCase="'multiple'" [innerHTML]="getMultipleValue(item)"></span>
                <span *ngSwitchCase="'format'" [innerHTML]="item.format(productInfo)"></span>
                <span *ngSwitchDefault>{{ productInfo[item.valueKey] || item.default || '' }}</span>
            </div>
        </div>
    </div>
</div>
