<div class="custom-info-modal" *ngIf="customInfo">
    <h2 class="modal-title">Custom Items 定制项目 <i nz-icon nzType="close" nzTheme="outline" (click)="close()"></i></h2>
    <div class="row">
        <h4 class="title">Advanced Customized Sample Category</h4>
        <span class="category">{{ customInfo.title }}</span>
    </div>

    <div class="row" *ngIf="customInfo.template_detail">
        <h4 class="title">Template</h4>
        <a [href]="customInfo.template_detail.product_link" target="_blank">{{customInfo.template_detail.product_link}}</a>
        <div class="d-flex template-detail">
            <div class="option-info">
                <div *ngIf="customInfo.template_detail.sub_category?.name" class="d-flex justify-content-between">
                    <label>{{customInfo.template_detail.sub_category.name}}</label>
                    <span>{{customInfo.template_detail.sub_category.value}}</span>
                </div>
                <div *ngFor="let item of customInfo.template_detail.options" class="d-flex justify-content-between">
                    <label>{{item.name}}</label>
                    <span>{{item.value === 'Customize' ? item.free_input : item.value}}</span>
                </div>
                <div *ngFor="let item of customInfo.template_detail.customization_detail_list" class="d-flex justify-content-between">
                    <label>{{item.name}}</label>
                    <span>{{item.value}}</span>
                </div>
            </div>
            <div class="custom-info">
                <label>Custom Requirements</label>
                <p>{{customInfo.template_detail.requirements}}</p>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="customInfo.customization_detail?.images?.length">
        <h4 class="title">Design Images</h4>
        <div class="d-flex design-box justify-content-start align-items-start flex-wrap">
            <div *ngFor="let item of customInfo.customization_detail?.images;">
                <img [src]="item.src" />
            </div>
        </div>
    </div>
    <div class="row" *ngIf="!customInfo.template_id">
        <h4 class="title">Variant Info 规格</h4>
        <div class="d-flex variant-info-box">
            <div
                *ngFor="let optionItem of customInfo.customization_detail.options"
                class="d-flex justify-content-between align-items-center"
            >
                <label>{{optionItem.name}}</label>
                <p *ngIf="optionItem.name === 'Color'">
                    <span>{{ optionItem.value }}</span>
                    <span *ngIf="optionItem.color">{{ optionItem.color }}</span>
                    <i *ngIf="optionItem.color" [ngStyle]="{ 'background-color': optionItem.color }"></i>
                </p>

                <p *ngIf="optionItem.name !== 'Color'"><span>{{ optionItem.value }}</span></p>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="!customInfo.template_id">
        <h4 class="title">Advanced Requirement 其他需求</h4>
        <div class="d-flex requirement-box" *ngFor="let requirement of requirements">
            <div class="d-flex align-items-center">
                <label class="flex-1">{{ requirement[0] | titlecase }}</label>
                <p class="flex-3">
                    <span>{{ requirement[1] }}</span>
                    <span *ngIf="requirement.length > 2" class="others-detail">{{ requirement[2] }}</span>
                </p>
            </div>
        </div>
    </div>
    <div class="row">
        <h4 class="title">Vector Files</h4>
        <div class="d-flex justify-content-start align-items-start flex-wrap">
            <div *ngFor="let file of customInfo.customization_detail?.logo_images" class="mr-20 mb-20">
                <div class="vector-file">
                    <img class="image-show" src="assets/images/upload_logo_ai_icon.png" />
                    <p class="file-name">{{ file.name }}</p>
                </div>
                <div class="mt-10 fs-12 text-align-center" *ngIf="!customInfo.category_detail.print_on_demand">{{file.craft}}</div>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="customInfo.model_shipping">
        <h4 class="title">该客户寄送了样品,运单号为：</h4>
        <span style="padding-right: 10px">{{customInfo.model_shipping.carrier}}</span>
        <span>{{customInfo.model_shipping.tracking_number}}</span>
    </div>

    <div class="row" *ngIf="customInfo.category_detail?.vendor">
        <h4 class="title">供应商信息</h4>
        <p>{{customInfo.category_detail?.vendor}}</p>
    </div>

    <div class="row" *ngIf="customInfo.customization_detail?.link">
        <h4 class="title">参考链接</h4>

        <div class="mt-10" *ngFor="let link of (customInfo.customization_detail?.link | split:',')">
            <a [href]="link" target="_blank">{{link}}</a>
        </div>
    </div>

    <div class="row" *ngIf="customInfo.customization_detail?.description">
        <h4 class="title">Description 描述</h4>

        <div class="mt-10">{{customInfo.customization_detail?.description}}</div>
    </div>
    <div class="buttons">
        <button nz-button nzType="primary" style="margin-right: 30px" (click)="downloadAll()" [nzLoading]="downloading">
            Download All
        </button>
        <button nz-button nzType="primary" nzGhost (click)="close()">Close</button>
    </div>
</div>
