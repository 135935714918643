import { Injectable } from '@angular/core';
import { RequestService } from '@services/request.service';
import { environment } from '@src/environments/environment';
import { TokenService } from '@services/token.service';

interface SignInInfo {
    idToken: string;
    refreshToken: string;
    expiresIn: string; // second
    email: string;
    localId: string;
    registered: boolean;
}

@Injectable({ providedIn: 'root' })
export class AuthService {
    constructor(private requestService: RequestService, private tokenService: TokenService) {}

    signIn(data: { email: string; password: string }) {
        return this.requestService
            .sendRequest(
                {
                    url: '/login/firebase/token',
                    method: 'POST',
                    data,
                    noAuth: true,
                },
                environment.dataApiService
            )
            .then((info: SignInInfo) => {
                const { idToken, refreshToken } = info;
                this.tokenService.setToken(idToken);
                this.tokenService.setRefreshToken(refreshToken);

                return info;
            });
    }

    signOut() {
        this.tokenService.removeAllToken();
        return Promise.resolve(true);
    }
}
