<section class="package-table">
    <nz-table
        #rowSelectionTable
        nzShowPagination
        nzShowSizeChanger
        [nzLoading]="!shopPackageInfos || fetchingShopDetail"
        [nzData]="shopPackageInfos"
    >
        <thead>
            <tr>
                <th nzWidth="150px">Type</th>
                <th nzWidth="300px">SKU</th>
                <th>Product</th>
                <th nzWidth="100px">Shop</th>
                <th nzWidth="200px">Operate</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let data of rowSelectionTable.data">
                <td style="text-transform: capitalize">
                    <nz-select
                        *ngIf="data.isToBeSavedItem;else savedType"
                        style="width: 150px"
                        nzPlaceHolder="Select Product Type"
                        [(ngModel)]="data.package_type"
                        (ngModelChange)="productTypeChange(data)"
                    >
                        <nz-option *ngFor="let productType of productTypes" [nzLabel]="productType" [nzValue]="productType"></nz-option>
                    </nz-select>

                    <ng-template #savedType> {{ data.package_type }} </ng-template>
                </td>
                <td>
                    <nz-select
                        *ngIf="data.isToBeSavedItem;else savedSKU"
                        style="width: 300px"
                        nzShowSearch
                        nzAllowClear
                        nzPlaceHolder="Search By SKU"
                        [nzFilterOption]="filterOption"
                        [(ngModel)]="data.package_product_id"
                    >
                        <nz-option
                            *ngFor="let product of filterOptionsMap.get(data.package_type)"
                            [nzLabel]=" product.sku_list[0]+ ' / ' + product.title "
                            [nzValue]="product.product_id"
                        ></nz-option>
                    </nz-select>

                    <ng-template #savedSKU> {{ data.package_product_sku }} </ng-template>
                </td>
                <td>
                    <section class="product-info">
                        <app-product-info
                            [removeCount]="true"
                            [productInfo]="productInfoMap.get(data.package_product_id)"
                        ></app-product-info>
                    </section>
                </td>
                <td>{{ productInfoMap.get(data.package_product_id)?.shop_name}}</td>
                <td>
                    <span *ngIf="!data.is_default" class="link-color cursor-pointer" (click)="removePackage(data)">Remove</span>
                    <nz-switch *ngIf="data.is_default" [(ngModel)]="data.is_selected"></nz-switch>
                </td>
            </tr>
        </tbody>
    </nz-table>

    <div class="mt-20">
        <span
            *ngIf="shopPackageInfos && !fetchingShopDetail"
            class="link-color cursor-pointer text-decoration-underline"
            (click)="addMore()"
        >
            <i nz-icon nzType="plus-circle" nzTheme="outline"></i>
            <span>Add new packaging</span>
        </span>
    </div>
</section>
